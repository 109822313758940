import { DB_NODE } from '@napp-inc/jnapp-util';
import { ListGenericForm } from '../../../../components';
import { URL_CONST } from '../../../../util';

export function ListApprovisionnementSimForm() {
    const listFormater = (data) =>
        data.map((item) => ({
            ...item,
            userDesignation: `${item?.user?.prenom || ''} ${
                item?.user?.nom || ''
            }`,
            beneficiaireDesignation: `${item?.validateur?.prenom || ''} ${
                item?.validateur?.nom || ''
            }`
        }));
    return (
        <ListGenericForm
            reduxPropertyName="approvisionnementsSim"
            functionName={URL_CONST.GET_LIST_APPRO_SIM_INITIALS}
            payload={{
                estOrganisation: true
                // dateDebut: TIME.utc000000(TODAY.at000000()),
                // dateFin: TIME.utc235959(TODAY.at235959())
            }}
            listFormater={listFormater}
            nodeName={DB_NODE.APPROVISIONNEMENT_SIM}
            mapper={[
                {
                    field: 'produit.designation',
                    displayName: 'Produit'
                },
                {
                    field: 'quantite',
                    displayName: 'Quantité'
                },
                {
                    displayName: 'Montant',
                    field: 'montant'
                },
                {
                    displayName: 'Initiateur',
                    field: 'userDesignation'
                },
                {
                    displayName: 'Bénéficiaire',
                    field: 'beneficiaireDesignation'
                },
                {
                    displayName: 'Etat',
                    field: 'etat'
                },
                {
                    displayName: 'Création',
                    field: 'dateCreation'
                }
            ]}
        />
    );
}
