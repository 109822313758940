import { ListGenericForm } from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { URL_CONST } from '../../../util';

export function ListAutorisationLotReferenceForm() {
    return (
        <ListGenericForm
            reduxPropertyName="autorisationReference"
            functionName={
                URL_CONST.GET_LIST_AUTORISATION_LOT_REFERENCE_INITIALS
            }
            nodeName={REDUX_NODE_NAME.AUTORISATION_REFERENCE}
            mapper={[
                {
                    field: 'devises.CDF.nombre',
                    displayName: 'Nombre(CDF)'
                },
                {
                    field: 'devises.CDF.montant',
                    displayName: 'Montant CDF'
                },
                {
                    field: 'devises.USD.nombre',
                    displayName: 'Nombre(USD)'
                },
                {
                    field: 'devises.USD.montant',
                    displayName: 'Montant USD'
                },
                {
                    field: 'etat',
                    displayName: 'Etat'
                },
                {
                    displayName: 'Création',
                    field: 'dateCreation'
                }
            ]}
        />
    );
}
