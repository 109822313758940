import { CODE_SERVICE, ETAT, FORM_CONST } from '@napp-inc/jnapp-util';
import { submitPutAction } from '@napp-inc/jnapp-hook';
import {
    ConditionalRenderingWrapper,
    CustomButtonLoader,
    CustomCol,
    CustomConfirmButton,
    CustomRow,
    DetailWrapper,
    UserServiceWrapper
} from '../../../components';
import { URL_CONST, useGetDetailForm } from '../../../util';
import { schema } from './detail-fields';
import { REDUX_NODE_NAME } from '../../../redux';

const defaultFields = {
    actionEncours: ''
};

/**
 * @description defaultDependencies for our state
 */
const defaultDependencies = { entity: true };

export function DetailDecaissementForm() {
    const {
        id,
        reduxDispatcher,
        entity,
        idToken,
        formState,
        formDispatcher,
        uid
    } = useGetDetailForm({
        nodeName: 'decaissements',
        defaultFields,
        defaultDependencies
    });

    const { form, fields } = formState;

    const isUserEncours = entity?.userEncours === uid;

    const updateEtat = (etat) => {
        const payload = {
            idEncaissementDecaissement: entity?.id,
            etat
        };
        formDispatcher(
            submitPutAction({
                idToken,
                functionName: URL_CONST.PUT_ENCAISSEMENT_DECAISSEMENT,
                payload,
                fields,
                reduxNodeName: REDUX_NODE_NAME.DECAISSEMENTS,
                reduxDispatcher
            })
        );
    };
    const renderContent = () => (
        <DetailWrapper
            functionName={URL_CONST.GET_ENCAISSEMENT_DECAISSEMENT_BY_ID}
            nodeName={REDUX_NODE_NAME.DECAISSEMENTS}
            formDispatcher={formDispatcher}
            formState={formState}
            reduxDispatcher={reduxDispatcher}
            subtitle="Detail operation caisse"
            entity={entity}
            id={id}
            schema={schema}
            idToken={idToken}
        >
            <CustomRow>
                <ConditionalRenderingWrapper
                    isShouldBeRendered={
                        entity?.etat !== ETAT.ETAT_VALIDER &&
                        entity?.etat !== ETAT.ETAT_ANNULER
                    }
                >
                    <CustomCol className="offset-6 offset-md-8 col-3 col-md-2">
                        <CustomConfirmButton
                            type="button"
                            text="Annuler"
                            className="btn-danger"
                            confirmOnClick={() => updateEtat(ETAT.ETAT_ANNULER)}
                            disabled={
                                form.status === FORM_CONST.MODIFICATION_EN_COURS
                            }
                            isActionEncours={
                                fields.actionEncours === ETAT.ETAT_ANNULER
                            }
                            confirmText="Poursuivre"
                            cancelText="Abandonner"
                            header="Confirmation Annulation"
                        />
                    </CustomCol>
                </ConditionalRenderingWrapper>
                <UserServiceWrapper
                    services={[
                        { code: 'test' },
                        CODE_SERVICE.SERVICE_CODE_REVENDEUR
                            .CODE_SERVICE_MODULE_FINANCE.VALIDER_DECAISSEMENT
                    ]}
                >
                    <ConditionalRenderingWrapper
                        isShouldBeRendered={entity?.etat === ETAT.ETAT_ENCOURS}
                    >
                        <CustomCol className="col-3 col-md-2">
                            <CustomConfirmButton
                                type="button"
                                text="Mettre en attente"
                                className="btn-success"
                                confirmOnClick={() =>
                                    updateEtat(ETAT.ETAT_ATTENTE_VALIDATION)
                                }
                                disabled={
                                    form.status ===
                                    FORM_CONST.MODIFICATION_EN_COURS
                                }
                                isActionEncours={
                                    fields.actionEncours ===
                                    ETAT.ETAT_ATTENTE_VALIDATION
                                }
                            />
                        </CustomCol>
                    </ConditionalRenderingWrapper>
                    <ConditionalRenderingWrapper
                        isShouldBeRendered={
                            entity?.etat === ETAT.ETAT_ATTENTE_VALIDATION
                        }
                    >
                        <CustomCol className="col-3 col-md-2">
                            <CustomConfirmButton
                                type="button"
                                text="Valider"
                                className="btn-success"
                                confirmOnClick={() =>
                                    updateEtat(ETAT.ETAT_VALIDER)
                                }
                                disabled={
                                    form.status ===
                                    FORM_CONST.MODIFICATION_EN_COURS
                                }
                                isActionEncours={
                                    fields.actionEncours === ETAT.ETAT_VALIDER
                                }
                            />
                        </CustomCol>
                    </ConditionalRenderingWrapper>
                </UserServiceWrapper>
                <UserServiceWrapper
                    services={[
                        { code: 'test' },
                        CODE_SERVICE.SERVICE_CODE_REVENDEUR
                            .CODE_SERVICE_MODULE_FINANCE
                            .CONFIRMER_ENCAISSEMENT_COMPENSE
                    ]}
                >
                    <ConditionalRenderingWrapper
                        isShouldBeRendered={
                            entity?.etat === ETAT.ETAT_PRE_ENCOURS &&
                            isUserEncours
                        }
                    >
                        <CustomCol className="col-3 col-md-2">
                            <CustomButtonLoader
                                type="button"
                                className="btn-success"
                                onClick={() => updateEtat(ETAT.ETAT_ENCOURS)}
                                text="Valider"
                                disabled={
                                    form.status ===
                                    FORM_CONST.MODIFICATION_EN_COURS
                                }
                                isActionEncours={
                                    fields.actionEncours === ETAT.ETAT_ENCOURS
                                }
                            />
                        </CustomCol>
                    </ConditionalRenderingWrapper>
                </UserServiceWrapper>
            </CustomRow>
        </DetailWrapper>
    );
    return renderContent();
}
