import { useSelector } from 'react-redux';
import { useGetUserDetail } from '@napp-inc/jnapp-hook';
import {
    CODE_SERVICE,
    DB_NODE,
    FETCH_LISTENING,
    FILTER_OPERATOR,
    FILTER_TYPE,
    TODAY,
    TYPE_ORGANISATION
} from '@napp-inc/jnapp-util';
import { ListGenericForm } from '../../../../components';
import { REDUX_NODE_NAME } from '../../../../redux';
import { URL_CONST, useHasService } from '../../../../util';
import { formatType } from './util';

const setDefaultProperties = ({ type, service, uid, oid }) => {
    // Default Mapper
    const defaultMapper = [
        {
            field: 'designationAgent',
            displayName: 'Agent'
        },
        {
            field: 'designationType',
            displayName: 'Type'
        },
        {
            field: 'urlImage',
            displayName: 'Url'
        },
        {
            field: 'titre',
            displayName: 'Sujet'
        },
        {
            field: 'description',
            displayName: 'Description'
        },
        {
            field: 'texteTraite',
            displayName: 'Correction'
        },
        {
            field: 'etat',
            displayName: 'Etat'
        },
        {
            displayName: 'Création',
            field: 'dateCreation'
        }
    ];

    // DefaultListerners
    let filters = [];
    const payload = {};

    if (
        service[
            CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERVICE_CLIENT
                .APPROUVER_FORMULAIRE_SERVICE_CLIENT?.code
        ]
    ) {
        payload.estOrganisation = false;
        filters = filters.concat([
            {
                type: FILTER_TYPE.WHERE,
                operator: FILTER_OPERATOR.EQUAL,
                property: 'valeurTypeOrganisation.reference	',
                value: oid,
                isRequired: true
            }
        ]);
    }

    if (
        service[
            CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERVICE_CLIENT
                .APPROUVER_FORMULAIRE_SERVICE_CLIENT?.code
        ]
    ) {
        payload.estOrganisation = false;
        filters = filters.concat([
            {
                type: FILTER_TYPE.WHERE,
                operator: FILTER_OPERATOR.EQUAL,
                property: 'agent.reference',
                value: uid,
                isRequired: true
            }
        ]);
    }
    if (
        !service[
            CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERVICE_CLIENT
                .APPROUVER_FORMULAIRE_SERVICE_CLIENT?.code
        ] &&
        (service[
            CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERVICE_CLIENT
                .VALIDER_FORMULAIRE_SERVICE_CLIENT?.code
        ] ||
            service[
                CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERVICE_CLIENT
                    .CREATION_FORMULAIRE_SERVICE_CLIENT?.code
            ] ||
            service[
                CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERVICE_CLIENT
                    .LECTURE_FORMULAIRE_SERVICE_CLIENT?.code
            ])
    ) {
        payload.estOrganisation = true;
    }
    if (
        service[
            CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERVICE_CLIENT
                .VALIDER_FORMULAIRE_SERVICE_CLIENT?.code
        ] ||
        service[
            CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERVICE_CLIENT
                .CREATION_FORMULAIRE_SERVICE_CLIENT?.code
        ] ||
        service[
            CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERVICE_CLIENT
                .LECTURE_FORMULAIRE_SERVICE_CLIENT?.code
        ] ||
        service[
            CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERVICE_CLIENT
                .APPROUVER_FORMULAIRE_SERVICE_CLIENT?.code
        ]
    ) {
        filters = filters.concat([
            {
                property: 'dateDerniereModification',
                value: 'asc',
                type: FILTER_TYPE.ORDER_BY
            },
            {
                type: FILTER_TYPE.START_AT,
                value: TODAY.nowUtc(),
                isTimestampNowValue: true
            }
        ]);
    }
    if (type === TYPE_ORGANISATION.NAPP)
        return {
            mapper: defaultMapper,
            listerners: filters,
            cfPayload: payload
        };
    return { mapper: [], listerners: [], cfPayload: {} };
};

export function ListServiceClientFormulaireForm() {
    /**
     * Get data on redux store
     */
    const { nappUser, firebaseUser, organisation } = useSelector(
        (reduxState) => ({
            nappUser: reduxState.nappUser,
            firebaseUser: reduxState.firebaseUser,
            organisation: reduxState.organisation
        })
    );
    const { hasService } = useHasService({
        services: [
            CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERVICE_CLIENT
                .VALIDER_FORMULAIRE_SERVICE_CLIENT,
            CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERVICE_CLIENT
                .CREATION_FORMULAIRE_SERVICE_CLIENT,
            CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERVICE_CLIENT
                .LECTURE_FORMULAIRE_SERVICE_CLIENT
        ]
    });
    /**
     * Common form processes
     */
    const { oid, uid, typeEntite } = useGetUserDetail({
        nappUser,
        firebaseUser,
        organisation
    });

    const { mapper, listerners, cfPayload } = setDefaultProperties({
        type: typeEntite,
        oid,
        uid,
        service: hasService
    });
    const listFormater = (data = []) =>
        data.map((item) => ({
            ...item,
            designationAgent:
                `${item?.agent?.prenom} ${item?.agent?.nom}` || '',
            designationType: formatType(item?.type)
        }));
    return (
        <ListGenericForm
            reduxPropertyName="serviceClientForm"
            fetchType={FETCH_LISTENING}
            path={DB_NODE.REGULARISATION_CAISSE}
            listenerFilters={listerners}
            functionName={URL_CONST.GET_LIST_FORMULAIRE_SERVICE_CLIENT_INITIALS}
            nodeName={REDUX_NODE_NAME.SERVICE_CLIENT_FORM}
            formater={listFormater}
            payload={cfPayload}
            mapper={mapper}
        />
    );
}
