import { Array } from '@napp-inc/jnapp-util';
import {
    traitementMouvement,
    URL_CONST,
    useGetReportDetail
} from '../../../../util';
import { StandardReportTemplate } from '../../template';
import { StockSimSelector } from '../../../../components';

const formater = (data) =>
    Array.tri({
        data: traitementMouvement(
            data?.[0].map((item) => ({
                ...item,
                title: item.libelle ? item.libelle : item.typeEntite
            })) || []
        ),
        property: 'dateCreation',
        order: 'desc'
    });

export function ReleveStockSimForm() {
    const { formState, formDispatcher } = useGetReportDetail({
        defaultFields: {
            selectedStockSuperDealer: {},
            selectedMarchandSim: {},
            selectedEtat: {},
            selectedStockSim: {}
        }
    });
    return (
        <>
            {/* <StateShowerComponent state={formState?.elements} /> */}
            <StandardReportTemplate
                listTitle={
                    formState?.fields?.selectedStockSim?.designation || ''
                }
                functionName={URL_CONST.GET_LIST_MOUVEMENT_PLAGE}
                formState={formState}
                formDispatcher={formDispatcher}
                listFormater={formater}
                mapper={[
                    {
                        field: 'user.prenom',
                        displayName: 'Désignation'
                    },
                    {
                        // field: 'typeEntite', // Cette propriété est remplacée par libelle
                        field: 'libelle',
                        displayName: 'Opération'
                    },
                    {
                        field: 'soldePrecedent',
                        displayName: 'Ouverture',
                        isNumber: true
                    },
                    {
                        field: 'entree',
                        displayName: 'Entrée',
                        isNumber: true
                    },
                    {
                        field: 'sortie',
                        displayName: 'Sortie',
                        isNumber: true
                    },
                    {
                        field: 'soldeActuel',
                        displayName: 'Fermeture',
                        isNumber: true
                    },
                    {
                        field: 'dateCreation',
                        displayName: 'Date'
                    }
                ]}
                defaultPayloadMapperValues={{
                    typeProprietaire: 'Stock'
                }}
                payloadMapper={[
                    {
                        name: 'dateDebut',
                        property: 'dateDebut',
                        isRequired: true,
                        displayName: 'Date début'
                    },
                    {
                        name: 'dateFin',
                        property: 'dateFin',
                        isRequired: true,
                        displayName: 'Date fin'
                    },
                    {
                        name: 'selectedStockSim.code',
                        property: 'valeurTypeProprietaire',
                        isRequired: true,
                        displayName: 'Stock'
                    }
                ]}
            >
                <StockSimSelector
                    formState={formState}
                    formDispatcher={formDispatcher}
                    isReleve
                />
            </StandardReportTemplate>
            {/* <StateShowerComponent state={elements} /> */}
        </>
    );
}
