import { Fragment, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
    Add,
    Array,
    resolvePathPropertyOfObject,
    TIME,
    TODAY
} from '@napp-inc/jnapp-util';
import { formatNumber, URL_CONST, useGetReportDetail } from '../../../../util';
import { ReduxReportTemplate } from '../../template';
import { StaticSelector, SuperDealerSelector } from '../../../../components';

export function RapportRetraitNonDeclareKinDBForm() {
    const globalResultRef = useRef([]);

    const updateRef = (data = []) => {
        globalResultRef.current = data;
    };

    const { nappUser } = useSelector((reduxState) => ({
        nappUser: reduxState.nappUser
    }));
    const { formState, formDispatcher } = useGetReportDetail({
        defaultFields: {
            selectedSuperDealer: {},
            selectedStatic: { id: 'USD', designation: 'USD' },
            globalResult: []
        }
    });

    // let globalResult = [];

    const formater = useCallback(
        (data = []) => {
            const result = [];
            const oppositeDevise = [];
            (data?.[0]?.listReturn || []).forEach((item) => {
                if (
                    item?.detail?.devise ===
                    formState?.fields?.selectedStatic?.id
                ) {
                    result.push({
                        designation:
                            item?.detail?.valeurTypeProprietaire?.designation,
                        compte: item?.detail?.type,
                        initial: item?.soldeOuverture,
                        solde: item?.soldeFermeture,
                        ajout: item?.entree,
                        declare: item?.sortie,
                        devise: item?.detail?.devise,
                        resultat: item?.total
                    });
                } else {
                    oppositeDevise.push({
                        designation:
                            item?.detail?.valeurTypeProprietaire?.designation,
                        compte: item?.detail?.type,
                        initial: item?.soldeOuverture,
                        solde: item?.soldeFermeture,
                        ajout: item?.entree,
                        declare: item?.sortie,
                        devise: item?.detail?.devise,
                        resultat: item?.total
                    });
                }
            });
            updateRef(data);
            const groupeResult = Array.groupByProperty({
                array: result,
                property: 'designation'
            });
            const groupedArrayKeys = Object.keys(groupeResult);
            const newResult = [];
            (groupedArrayKeys || []).forEach((item) => {
                const newItem = { designation: item };
                const getMatchedItem = groupeResult[item];
                (getMatchedItem || []).forEach((matchedItem) => {
                    newItem[matchedItem.compte] = matchedItem;
                });
                newResult.push(newItem);
            });
            // formDispatcher({
            //     type: FORM_CONST.FIELD_CHANGE,
            //     payload: {
            //         field: 'globalResult',
            //         data: oppositeDevise.length ? data : []
            //     }
            // });
            return newResult;
        },
        [formState?.fields?.selectedStatic?.id]
    );

    const formaterInOpposite = useCallback(
        (data = []) => {
            const result = [];

            (data?.[0]?.listReturn || []).forEach((item) => {
                if (
                    item?.detail?.devise !==
                    formState?.fields?.selectedStatic?.id
                ) {
                    result.push({
                        designation:
                            item?.detail?.valeurTypeProprietaire?.designation,
                        compte: item?.detail?.type,
                        initial: item?.soldeOuverture,
                        solde: item?.soldeFermeture,
                        ajout: item?.entree,
                        declare: item?.sortie,
                        devise: item?.detail?.devise,
                        resultat: item?.total
                    });
                }
            });
            const groupeResult = Array.groupByProperty({
                array: result,
                property: 'designation'
            });
            const groupedArrayKeys = Object.keys(groupeResult);
            const newResult = [];
            (groupedArrayKeys || []).forEach((item) => {
                const newItem = { designation: item };
                const getMatchedItem = groupeResult[item];
                (getMatchedItem || []).forEach((matchedItem) => {
                    newItem[matchedItem.compte] = matchedItem;
                });
                newResult.push(newItem);
            });
            return newResult;
        },
        [formState?.fields?.selectedStatic?.id]
    );
    const cutomTableHeader = () => (
        <>
            <tr className="border text-center">
                <th className="border" rowSpan={2}>
                    #
                </th>
                <th className="border" rowSpan={2}>
                    SuperDealer
                </th>
                <th className="border" colSpan={3}>
                    Ouverture
                </th>
                <th className="border" colSpan={3}>
                    Ajout
                </th>
                <th className="border" colSpan={3}>
                    Déclaré
                </th>
                <th className="border" colSpan={3}>
                    Fermeture
                </th>
            </tr>
            <tr className="border text-center">
                <th className="border">Client</th>
                <th className="border">Autre</th>
                <th className="border">Revenu</th>
                <th className="border">Client</th>
                <th className="border">Autre</th>
                <th className="border">Revenu</th>
                <th className="border">Client</th>
                <th className="border">Autre</th>
                <th className="border">Revenu</th>
                <th className="border">Client</th>
                <th className="border">Autre</th>
                <th className="border">Revenu</th>
            </tr>
        </>
    );

    // console.log('globalResult', globalResult);
    const bottomTotal = useCallback(() => {
        const data = formaterInOpposite(globalResultRef.current);
        return (
            <tr className="lead fw-bold border">
                <td colSpan={2} className="text-right font-weight-bold border">
                    Total{' '}
                    {data?.[0]?.Autre?.devise ||
                        data?.[0]?.Client?.devise ||
                        data?.[0]?.Revenu?.devise}
                </td>
                {['initial', 'ajout', 'declare', 'solde'].map((item) => (
                    <Fragment key={item}>
                        <td className="border">
                            {formatNumber({
                                value: data.reduce(
                                    (a, b) =>
                                        Add(
                                            a,
                                            resolvePathPropertyOfObject(
                                                `Client.${item}`,
                                                b
                                            )
                                        ),
                                    0
                                )
                            })}
                        </td>
                        <td className="border">
                            {formatNumber({
                                value: data.reduce(
                                    (a, b) =>
                                        Add(
                                            a,
                                            resolvePathPropertyOfObject(
                                                `Autre.${item}`,
                                                b
                                            )
                                        ),
                                    0
                                )
                            })}
                        </td>
                        <td className="border">
                            {formatNumber({
                                value: data.reduce(
                                    (a, b) =>
                                        Add(
                                            a,
                                            resolvePathPropertyOfObject(
                                                `Revenu.${item}`,
                                                b
                                            )
                                        ),
                                    0
                                )
                            })}
                        </td>
                    </Fragment>
                ))}
            </tr>
        );
    }, [formaterInOpposite]);
    return (
        <ReduxReportTemplate
            cutomTableHeader={cutomTableHeader}
            listTitle={`Retrait en ${formState?.fields?.selectedStatic?.id}`}
            dynamicNodeName={`retraitNonDeclareKinDistribution-${TIME.utc000000(
                TODAY.at000000()
            )}-${TIME.utc235959(TODAY.at235959())}`}
            functionName={URL_CONST.GET_LIST_COMPTE_USER}
            listFormater={formater}
            formState={formState}
            formDispatcher={formDispatcher}
            tfootTrToAdd={bottomTotal}
            mapper={[
                {
                    displayName: 'Super dealer',
                    field: 'designation'
                },
                {
                    displayName: 'OuvertureClient',
                    field: 'Client.initial',
                    isTotal: true
                },
                {
                    displayName: 'OuvertureAutre',
                    field: 'Autre.initial',
                    isTotal: true
                },
                {
                    displayName: 'OuvertureRevenu',
                    field: 'Revenu.initial',
                    isTotal: true
                },
                {
                    displayName: 'AjoutClient',
                    field: 'Client.ajout',
                    isTotal: true
                },
                {
                    displayName: 'AjoutAutre',
                    field: 'Autre.ajout',
                    isTotal: true
                },
                {
                    displayName: 'AjoutRevenu',
                    field: 'Revenu.ajout',
                    isTotal: true
                },
                {
                    displayName: 'DeclareClient',
                    field: 'Client.declare',
                    isTotal: true
                },
                {
                    displayName: 'DeclareAutre',
                    field: 'Autre.declare',
                    isTotal: true
                },
                {
                    displayName: 'DeclareRevenu',
                    field: 'Revenu.declare',
                    isTotal: true
                },
                {
                    displayName: 'FermetureClient',
                    field: 'Client.solde',
                    isTotal: true
                },
                {
                    displayName: 'FermetureAutre',
                    field: 'Autre.solde',
                    isTotal: true
                },
                {
                    displayName: 'FermetureRevenu',
                    field: 'Revenu.solde',
                    isTotal: true
                }
            ]}
            isAddTotalTr
            payload={{
                dateDebut: TIME.utc000000(TODAY.at000000()),
                dateFin: TIME.utc235959(TODAY.at235959()),
                typeEntite: 'Journalier',
                type: 'NonDeclarerEmoney',
                typeProprietaire: 'SuperDealer',
                estOrganisation: true,
                estDevise: true,
                typeOrganisation: nappUser?.typeEntite
            }}
            defaultPayloadMapperValues={{
                typeEntite: 'Journalier',
                type: 'NonDeclarerEmoney',
                typeProprietaire: 'SuperDealer',
                estOrganisation: true,
                estDevise: true,
                typeOrganisation: nappUser?.typeEntite
            }}
            payloadMapper={[
                {
                    name: 'dateDebut',
                    property: 'dateDebut',
                    isRequired: true,
                    displayName: 'Date début'
                },
                {
                    name: 'dateFin',
                    property: 'dateFin',
                    isRequired: true,
                    displayName: 'Date fin'
                },
                {
                    name: 'selectedSuperDealer.code',
                    property: 'codeValeurTypeProprietaire',
                    isRequired: false,
                    displayName: 'Super dealer',
                    callback: (value) => {
                        if (!value?.code || value?.code === 'all') return {};
                        return {
                            codeValeurTypeProprietaire: value?.code
                        };
                    }
                }
            ]}
        >
            <StaticSelector
                formState={formState}
                formDispatcher={formDispatcher}
                options={[
                    { id: 'USD', designation: 'USD' },
                    { id: 'CDF', designation: 'CDF' }
                ]}
            />
            <SuperDealerSelector
                formState={formState}
                formDispatcher={formDispatcher}
                isAddAditionalOptions
                idProperty="code"
                estNonAirtime
            />
        </ReduxReportTemplate>
    );
}
