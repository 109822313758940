/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
    RessourceRowSelector,
    SecteurRessourceRowSelector as SecteurRowSelector,
    PeriodesRessourceRowSelector as PeriodesRowSelector
} from './organisme-resssource';

export function RessourceBaseForm({
    ressources,
    formFields,
    setFormFields,
    handleAddHighLevel,
    field,
    index,
    periodes,
    secteurs,
    onToggleView,
    handleSelectChange,
    handleChange,
    handleRemove
}) {
    return (
        <div key={index} className="my-3 d-flex flex-column ms-3">
            <RessourceRowSelector
                ressources={ressources}
                formFields={formFields}
                setFormFields={setFormFields}
                field={field}
                handleSelectChange={handleSelectChange}
                index={index}
                handleChange={handleChange}
            />
            {field.estObjectif ? (
                <div className="d-flex flex-column ms-5 me-5">
                    {formFields[index].details.map((detail, detailIndex) => (
                        <div
                            key={detailIndex}
                            className=""
                            id={`${field.element.code}${index}${detailIndex}`}
                        >
                            <SecteurRowSelector
                                handleRemove={handleRemove}
                                detail={detail}
                                formFields={formFields}
                                setFormFields={setFormFields}
                                secteurs={secteurs}
                                detailIndex={detailIndex}
                                handleAddHighLevel={handleAddHighLevel}
                                handleSelectChange={handleSelectChange}
                                index={index}
                                onToggleView={onToggleView}
                            />
                            {formFields?.[index]?.details?.[detailIndex]
                                ?.isTooggled
                                ? formFields?.[index]?.details?.[
                                      detailIndex
                                  ]?.periodes?.map(
                                      (periodeItem, periodeIndex) => (
                                          <div
                                              key={`${field.element.code}${index}${detailIndex}${periodeIndex}`}
                                              className=""
                                              style={{
                                                  marginLeft: '10px'
                                              }}
                                              id={`${field.element.code}${index}${detailIndex}`}
                                          >
                                              <PeriodesRowSelector
                                                  formFields={formFields}
                                                  setFormFields={setFormFields}
                                                  index={index}
                                                  detailIndex={detailIndex}
                                                  periodeIndex={periodeIndex}
                                                  periodeItem={periodeItem}
                                                  handleSelectChange={
                                                      handleSelectChange
                                                  }
                                                  periodes={periodes}
                                                  handleChange={handleChange}
                                                  onToggleView={onToggleView}
                                                  handleRemove={handleRemove}
                                                  handleAddHighLevel={
                                                      handleAddHighLevel
                                                  }
                                              />
                                          </div>
                                      )
                                  )
                                : null}
                        </div>
                    ))}
                </div>
            ) : null}
        </div>
    );
}
