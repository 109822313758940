import { ListGenericForm } from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { URL_CONST } from '../../../util';
import { aggregerDevises } from './util';

export function ListCrediterBalanceLotForm() {
    const listFormater = (data = []) =>
        data.map((item) => ({
            ...item,
            ...aggregerDevises(item)
        }));

    return (
        <ListGenericForm
            reduxPropertyName="crediterBalanceLot"
            functionName={URL_CONST.GET_LIST_CREDITER_BALANCE_EN_LOT_INITIALS}
            nodeName={REDUX_NODE_NAME.CREDITER_BALANCE_LOT}
            listFormater={listFormater}
            mapper={[
                {
                    field: 'devises.CDF.nombre',
                    displayName: 'Nombre(CDF)'
                },
                {
                    field: 'devises.CDF.montant',
                    displayName: 'Montant CDF'
                },
                {
                    field: 'devises.USD.nombre',
                    displayName: 'Nombre(USD)'
                },
                {
                    field: 'devises.USD.montant',
                    displayName: 'Montant USD'
                },
                {
                    field: 'etat',
                    displayName: 'Etat'
                },
                {
                    displayName: 'Création',
                    field: 'dateCreation'
                }
            ]}
        />
    );
}
