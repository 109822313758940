import { CustomDropdown } from '../dropdown';
import { CustomRow } from '../container';

/**
 * Construit une dropdown où l'on peut sélectionner un type de valeur en unité ou en monnaie
 * @param {Object} param0
 * @param {Function} param0.formDispatcher form dispatcher du parent
 * @param {Object} param0.formState form state du parent
 * @returns {React.Component}
 */
export function TypeExpressionValeurSelector({
    formDispatcher,
    formState,
    idProperty = 'id',
    name = 'selectedTypeExpression',
    isSim = false
}) {
    const options = !isSim
        ? [
              { id: 'USD', code: 'USD', designation: 'en USD' },
              { id: 'Unité', code: 'Unité', designation: 'en Unité' }
          ]
        : [
              { id: 'USD', code: 'USD', designation: 'en USD' },
              { id: 'Quantite', code: 'Quantite', designation: 'en Quantité' }
          ];
    return (
        <CustomRow>
            <CustomDropdown
                options={options}
                defaultOption="USD"
                label="Valeur : "
                labelClassName="col-12"
                divClassName="col-12"
                formDispatcher={formDispatcher}
                name={name}
                id={`${name}-typeExpressionSelector`}
                formState={formState}
                idProperty={idProperty}
            />
        </CustomRow>
    );
}
