import {
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import { DB_NODE, ETAT, formInitialState } from '@napp-inc/jnapp-util';
import React, { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useDispatch, useSelector } from 'react-redux';
import { FaEdit } from 'react-icons/fa';
import { CustomBadge, CustomCenteredModal } from '../../../components';
import { ListCommandeFlashSenderForm } from './list-commande-sender';
import { REDUX_NODE_NAME } from '../../../redux';
import { ListTotauxSenderFlash } from './totaux-sender';
import { EtatConfigurationSender } from '../sender-emoney/form-detail-configuration';
import { InfoCardSender } from '../sender-emoney/info-card-sender';

const defaultFields = {
    isOpened: {},
    isSmsSynchro: {},
    intervalGetCommande: 0,
    intervalPostSms: 0,
    selectedDevise: {},
    devisesRupture: []
};

export function CommandeFlashSenderViewLayout() {
    const reduxDispatcher = useDispatch();
    const [key, setKey] = useState(ETAT.ETAT_ENCOURS);
    const [show, setShow] = useState(false);
    const {
        count,
        firebaseUser,
        nappUserMarchandType,
        ecouteurEtatConfigurationSender,
        etatConfigurationSender,
        organisation
    } = useSelector((reduxState) => ({
        count: {
            [ETAT.ETAT_ENCOURS]: reduxState.commandesFlashEncours.filter(
                (item) => !item?.demandeAnnulation
            ).length,
            attente_annulation: reduxState.commandesFlashEncours.filter(
                (item) => item?.demandeAnnulation
            ).length,
            [ETAT.ETAT_ATTENTE_VALIDATION]:
                reduxState.commandesFlashAttente.length,
            [ETAT.ETAT_ANNULER]: reduxState.commandesFlashAnnuler.length,
            [ETAT.ETAT_LIVRER]: reduxState.commandesFlashLivrer.length
        },
        firebaseUser: reduxState.firebaseUser,
        nappUserMarchandType: reduxState.nappUser?.marchand?.type,
        ecouteurEtatConfigurationSender:
            reduxState.etat.etatConfigurationSender.ecouteur,
        etatConfigurationSender: reduxState.etatConfigurationSender,
        organisation: reduxState.organisation
    }));
    const setShowModal = () => setShow((e) => !e);
    const { idToken, uid } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isDocument: true,
                path: DB_NODE.CONFIG_SENDER,
                id: uid,
                nodeName: REDUX_NODE_NAME.ETAT_CONFIGURATION_SENDER,
                ecouteur: ecouteurEtatConfigurationSender
            }
        ]
    });
    return (
        <div className="container-fluid">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <CustomCenteredModal
                    header="Configuration Sender"
                    show={show}
                    onHide={() => setShow(false)}
                >
                    <EtatConfigurationSender
                        formDispatcher={formDispatcher}
                        formState={formState}
                        organisation={organisation}
                        nappUserMarchandType={nappUserMarchandType}
                        etatConfigurationSender={etatConfigurationSender}
                        reduxDispatcher={reduxDispatcher}
                        idToken={idToken}
                        closeShow={() => setShow(false)}
                    />
                </CustomCenteredModal>
                <h1 className="h2">Commande flash</h1>
                <FaEdit
                    role="button"
                    onClick={setShowModal}
                    className="icon-custom border rounded"
                />
            </div>
            <InfoCardSender
                etatConfigurationSender={etatConfigurationSender}
                isEmoney={false}
            />
            <Tabs
                defaultActiveKey={ETAT.ETAT_ENCOURS}
                id="fill-tab-example"
                className="mb-3"
                fill
                onSelect={setKey}
            >
                <Tab
                    eventKey={ETAT.ETAT_ENCOURS}
                    title={
                        <p>
                            ENCOURS{' '}
                            <CustomBadge text={count[ETAT.ETAT_ENCOURS]} />{' '}
                        </p>
                    }
                >
                    {key === ETAT.ETAT_ENCOURS && (
                        <ListCommandeFlashSenderForm
                            isRowSelector
                            isConnectRemoveFilter
                            etat={ETAT.ETAT_ENCOURS}
                            viewSender={ETAT.ETAT_ENCOURS}
                            reduxPropertyName="commandesFlashEncours"
                            nodeName={
                                REDUX_NODE_NAME.COMMANDES_FLASH_SENDER_ENCOURS
                            }
                            filterDataList={(item) =>
                                item?.etat === ETAT.ETAT_ENCOURS &&
                                !item?.demandeAnnulation
                            }
                        />
                    )}
                </Tab>
                <Tab
                    eventKey="attente_annulation"
                    title={
                        <p>
                            ATTENTE ANNULATION{' '}
                            <CustomBadge text={count.attente_annulation} />{' '}
                        </p>
                    }
                >
                    {key === 'attente_annulation' && (
                        <ListCommandeFlashSenderForm
                            etat={ETAT.ETAT_ENCOURS}
                            viewSender={ETAT.ETAT_ATTENTE_ANNULATION}
                            reduxPropertyName="commandesFlashEncours"
                            nodeName={
                                REDUX_NODE_NAME.COMMANDES_FLASH_SENDER_ENCOURS
                            }
                            filterDataList={(item) =>
                                item?.etat === ETAT.ETAT_ENCOURS &&
                                item?.demandeAnnulation
                            }
                        />
                    )}
                </Tab>
                <Tab
                    eventKey={ETAT.ETAT_ATTENTE_VALIDATION}
                    title={
                        <p>
                            ATTENTE VALIDATION{' '}
                            <CustomBadge
                                text={count[ETAT.ETAT_ATTENTE_VALIDATION]}
                            />{' '}
                        </p>
                    }
                >
                    {key === ETAT.ETAT_ATTENTE_VALIDATION && (
                        <ListCommandeFlashSenderForm
                            isRowSelector
                            isConnectRemoveFilter
                            etat={ETAT.ETAT_ATTENTE_VALIDATION}
                            viewSender={ETAT.ETAT_ATTENTE_VALIDATION}
                            reduxPropertyName="commandesFlashAttente"
                            nodeName={
                                REDUX_NODE_NAME.COMMANDES_FLASH_SENDER_ATTENTE
                            }
                            filterDataList={(item) =>
                                item?.etat === ETAT.ETAT_ATTENTE_VALIDATION
                            }
                        />
                    )}
                </Tab>
                <Tab
                    eventKey={ETAT.ETAT_ANNULER}
                    title={
                        <p>
                            ANNULEES{' '}
                            <CustomBadge text={count[ETAT.ETAT_ANNULER]} />{' '}
                        </p>
                    }
                >
                    {key === ETAT.ETAT_ANNULER && (
                        <ListCommandeFlashSenderForm
                            etat={ETAT.ETAT_ANNULER}
                            viewSender={ETAT.ETAT_ANNULER}
                            reduxPropertyName="commandesFlashAnnuler"
                            nodeName={
                                REDUX_NODE_NAME.COMMANDES_FLASH_SENDER_ANNULER
                            }
                            filterDataList={(item) =>
                                item?.etat === ETAT.ETAT_ANNULER
                            }
                            isSenderFlash={false}
                        />
                    )}
                </Tab>
                <Tab
                    eventKey={ETAT.ETAT_LIVRER}
                    title={
                        <p>
                            LIVREES{' '}
                            <CustomBadge text={count[ETAT.ETAT_LIVRER]} />{' '}
                        </p>
                    }
                >
                    {key === ETAT.ETAT_LIVRER && (
                        <ListCommandeFlashSenderForm
                            etat={ETAT.ETAT_LIVRER}
                            viewSender={ETAT.ETAT_LIVRER}
                            reduxPropertyName="commandesFlashLivrer"
                            nodeName={
                                REDUX_NODE_NAME.COMMANDES_FLASH_SENDER_LIVRER
                            }
                            filterDataList={(item) =>
                                item?.etat === ETAT.ETAT_LIVRER
                            }
                            isSenderFlash={false}
                        />
                    )}
                </Tab>
                <Tab eventKey="totaux" title="TOTAUX">
                    {key === 'totaux' && <ListTotauxSenderFlash />}
                </Tab>
            </Tabs>
        </div>
    );
}
