import * as yup from 'yup';

/**
 * @description : Schema de validation Heure Supplementaire création form
 */
export const HeureSupSchema = yup.object().shape({
    nombreHeure: yup.number(),
    selectedProfil: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    selectedPeriode: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    selectedTache: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    selectedAgent: yup.object().shape({
        uid: yup.string().required(),
        designation: yup.string().required()
    }),
    motif: yup.string()
});
