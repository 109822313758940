import {
    useFormGlobalReducer,
    fetchElement,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    ETAT,
    FORM_CONST,
    TODAY,
    checkPlageDate,
    formInitialState
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import { MainReportComponentLarge } from '../releve/generic';
import { URL_CONST } from '../../../util';
import {
    CustomDropdown,
    CustomRow,
    MouvementCardsComponent,
    ReportCommandeFlashSelector
} from '../../../components';
// import { commandeMapper } from './mapper';

const defaultFields = {
    debut: '',
    fin: '',
    dateDebut: TODAY.at000000(),
    dateFin: TODAY.at235959(),
    selectedDateRangeReport: {
        code: 'TODAY',
        designation: "Aujourd'hui"
    },
    isFetching: false,
    selectedSuperDealer: {},
    selectedTypeBusiness: {},
    selectedEtat: {},
    selectedProperty: {}
};

export function RapportCommandeEmoneyForm() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser
    }));
    const { idToken } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        elements: ['mouvements']
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const displayTypeBussiness = (type) => {
        if (type === 'TraditionnelBusiness') return 'Traditionnel';
        if (type === 'BusinessModel') return 'Classique';
        return 'Sim';
    };
    const { fields, elements } = formState;
    const fetchReport = () => {
        const {
            dateFin,
            dateDebut,
            selectedSuperDealer,
            selectedProperty,
            selectedEtat
        } = fields;
        const { isValid } = checkPlageDate(dateDebut, dateFin);
        if (!isValid) {
            return;
        }
        const payloadToAdd = { property: 'dateAttenteValidation' };
        if (selectedEtat.code) {
            payloadToAdd.etat = selectedEtat.code;
        }
        if (
            selectedEtat.code === ETAT.ETAT_ANNULER ||
            selectedEtat.code === ETAT.ETAT_VALIDER
        ) {
            payloadToAdd.property = selectedProperty.code;
        }
        if (selectedSuperDealer.code) {
            payloadToAdd.codeSuperDealer = selectedSuperDealer.code;
        }
        const payload = {
            dateDebut,
            dateFin,
            ...payloadToAdd,
            estNonSendeur: true
        };
        formDispatcher(
            fetchElement({
                idToken,
                elementPropertyName: 'mouvements',
                payload,
                functionName: URL_CONST.GET_LIST_COMMANDE_EMONEY_BY_SENDEUR,
                etat: elements.mouvements.status,
                formDispatcher,
                reduxDispatcher,
                formater: (data) =>
                    data.map((item) => ({
                        ...item,
                        type: item?.estRetrait ? 'Retrait' : 'Dépôt',
                        montant: {
                            [item?.devise]: item?.montant
                        },
                        userName: `${item?.user?.prenom || ''} ${
                            item?.user?.nom || ''
                        }`,
                        clientName:
                            item?.client?.designation ||
                            `${item?.client?.prenom || ''} ${
                                item?.client?.nom || ''
                            }`,
                        numero: item?.numero || item?.client?.numero || ''
                    }))
            })
        );
    };
    const getMention = ({ item = {} }) => {
        if (
            Object.prototype.hasOwnProperty.call(item, 'performance') &&
            item.etat === ETAT.ETAT_NON_UTILISER
        )
            return <span className="badge bg-danger">AUTRE</span>;
        return <span>---</span>;
    };
    return (
        <>
            <MainReportComponentLarge
                isDataFetched={
                    elements?.mouvements?.status ===
                    FORM_CONST.FETCH_ELEMENT_SUCCESS
                }
                isDetaille={false}
                formState={formState}
                formDispatcher={formDispatcher}
                fetchReport={fetchReport}
                mapper={[
                    {
                        displayName: 'Marchand',
                        field: 'marchand.designation'
                    },
                    {
                        displayName: 'User',
                        field: 'userName'
                        // dataExtractor: (item) =>
                        //     `${item?.user?.prenom || ''} ${item?.user?.nom || ''}`
                    },
                    {
                        displayName: 'Revendeur',
                        field: 'valeurTypeOrganisation.designation'
                    },
                    {
                        field: 'business',
                        displayName: 'Type Business'
                    },
                    {
                        displayName: 'Type',
                        field: 'type'
                    },
                    {
                        displayName: 'Client',
                        field: 'clientName'
                        // dataExtractor: (item) =>
                        //     item?.client?.designation ||
                        //     `${item?.client?.prenom || ''} ${
                        //         item?.client?.nom || ''
                        //     }`
                    },
                    {
                        displayName: 'Nature',
                        tdExtractor: (item) => getMention({ item })
                        // dataExtractor: (item) =>
                        //     item?.client?.designation ||
                        //     `${item?.client?.prenom || ''} ${
                        //         item?.client?.nom || ''
                        //     }`
                    },
                    {
                        field: 'numero',
                        displayName: 'Numéro'
                    },
                    {
                        field: 'montant.CDF',
                        displayName: 'CDF',
                        isNumber: true
                    },
                    {
                        displayName: 'USD',
                        field: 'montant.USD',
                        isNumber: true
                    },
                    {
                        field: 'etat',
                        displayName: 'Etat'
                    },
                    {
                        field: 'numeroReference',
                        displayName: 'Référence'
                    },
                    {
                        field: 'motif',
                        displayName: 'Motif'
                    },
                    {
                        field: 'dateCreation',
                        displayName: 'Création'
                    },
                    {
                        field: 'dateAttenteValidation',
                        displayName: 'Date Attente Validation',
                        isDate: true
                    },
                    {
                        field: 'dateDerniereModification',
                        displayName: 'Dernière modification'
                    }
                ]}
                dataList={
                    elements.mouvements?.value?.map((item) => ({
                        ...item,
                        business: displayTypeBussiness(item.typeBusiness)
                    })) || []
                }
                isFetching={
                    elements?.mouvements?.status ===
                    FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                }
                topOfChart={MouvementCardsComponent}
                isCommandeLitigieuseEmoney
            >
                <ReportCommandeFlashSelector
                    formState={formState}
                    formDispatcher={formDispatcher}
                    estMoney
                />
                <CustomRow
                    isShouldBeRendered={
                        fields.selectedEtat.code === ETAT.ETAT_VALIDER ||
                        fields.selectedEtat.code === ETAT.ETAT_ANNULER
                    }
                >
                    <CustomDropdown
                        options={[
                            {
                                code: 'dateCreation',
                                designation: 'Date Création'
                            },
                            {
                                code: 'dateAttenteValidation',
                                designation: 'Date Partenaire'
                            }
                        ]}
                        label="Property : "
                        labelClassName="col-12"
                        divClassName="col-12"
                        formDispatcher={formDispatcher}
                        name="selectedProperty"
                        id="selectedPropertytypeExpressionSelector"
                        formState={formState}
                        idProperty="code"
                    />
                </CustomRow>
            </MainReportComponentLarge>
            {/* <StateShowerComponent state={elements} /> */}
        </>
    );
}
