import { memo, useCallback } from 'react';
import {
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    formInitialState,
    FORM_CONST,
    ETAT,
    ONLY_FETCH,
    fromStringToTimestamp
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomButtonLoader,
    CustomCol,
    CustomCollapse,
    CustomDropdown,
    CustomInput,
    CustomInputDateTime,
    CustomRow,
    FormWrapper,
    SimpleTable
} from '../../../components';
import { URL_CONST } from '../../../util';
import { HeureSupSchema as uiValidator } from './validation';
import { REDUX_NODE_NAME } from '../../../redux';

const defaultFields = {
    nombreHeure: 0,
    heureDebut: 0,
    heureFin: 0,
    dateDebut: 0,
    dateFin: 0,
    selectedAgent: {},
    selectedProfil: {},
    selectedTache: {},
    selectedPeriode: {},
    motif: '',
    list: []
};

const defaultDependencies = {
    fields: ['users', 'fonctions', 'tachesPerformance']
};

const TypeDetail = memo(({ item }) =>
    !item.estAutomatique ? (
        <span className="badge bg-danger rounded-pill">NON AUTOMATIQUE</span>
    ) : (
        <span className="badge bg-primary rounded-pill">AUTOMATIQUE</span>
    )
);

export function CreationHeureSupplementaireForm() {
    const reduxDispatcher = useDispatch();
    /**
     * Get data on redux store
     */
    const {
        firebaseUser,
        users,
        etatUser,
        tachesPerformances,
        etatTachesPerformances,
        fonctions,
        etatFonction
    } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        users: reduxState.users.map((item) => ({
            ...item,
            designation: `${item?.personne?.prenom || ''} ${
                item?.personne?.nom || ''
            }`
        })),
        etatUser: reduxState.etat.users.etat,
        tachesPerformances: reduxState.tachesPerformanceProfilUser.map(
            (item) => ({
                ...item,
                code: item?.tache?.code,
                designation: item?.tache?.designation
            })
        ),
        etatTachesPerformances:
            reduxState.etat.tachesPerformanceProfilUser.etat,
        fonctions: reduxState.allFonction.filter(
            (item) => item.etat === ETAT.ETAT_ADOPTE
        ),
        etatFonction: reduxState.etat.allFonction.etat
    }));
    /**
     * Common form processes
     */
    const { idToken } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: defaultDependencies
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;

    /**
     * Cloud Functions
     */
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_USER,
                nodeName: DB_NODE.USER,
                etat: etatUser
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_PROFIL_USER_TACHE_ORGANISATION,
                nodeName: REDUX_NODE_NAME.TACHES_PERFORMANCE_PROFIL_USER,
                etat: etatTachesPerformances
            },
            {
                fetchType: ONLY_FETCH,
                functionName: URL_CONST.GET_LIST_ALL_FONCTION,
                nodeName: REDUX_NODE_NAME.ALL_FONCTION,
                etat: etatFonction
                // isCondition: typeEntite === TYPE_ORGANISATION.NAPP
            }
        ]
    });

    const filterUserAndTacheByProfilUser = useCallback(() => {
        if (fields.selectedProfil.code) {
            return {
                filteredUsers: users.filter(
                    (item) =>
                        item.profilUser.code === fields.selectedProfil.code
                ),
                filteredTaches: tachesPerformances.filter(
                    (item) =>
                        item.profilUser.code === fields.selectedProfil.code
                )
            };
        }
        return { filteredUsers: [], filteredTaches: [] };
    }, [fields.selectedProfil.code, tachesPerformances, users]);

    const { filteredUsers, filteredTaches } = filterUserAndTacheByProfilUser();

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!fields.list.length) {
            formDispatcher({
                type: FORM_CONST.SET_FORM_ERROR,
                payload: {
                    message:
                        'Veuillez ajouter au moins un élément dans la liste',
                    status: FORM_CONST.CREATION_ECHEC
                }
            });
            return;
        }
        const payload = {
            list: fields.list.map(
                ({ referenceAgent, estAutomatique, plage, codeTache }) => ({
                    referenceAgent,
                    estAutomatique,
                    plage: {
                        debut: !estAutomatique
                            ? fromStringToTimestamp(plage.debut)
                            : plage.debut,
                        fin: !estAutomatique
                            ? fromStringToTimestamp(plage.fin)
                            : plage.fin
                    },
                    codeTache
                })
            )
        };
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_DEFINITION_HEURE_SUPPLEMENTAIRE,
                payload,
                fields,
                reduxNodeName: DB_NODE.HEURE_SUPPLEMENTAIRE,
                reduxDispatcher,
                uiValidator
            })
        );
    };
    const onAddDetail = () => {
        if (
            !fields.selectedAgent.uid ||
            !fields.selectedTache.code ||
            !fields.selectedPeriode.code
        )
            return;
        if (fields.selectedPeriode.code === `TODAY` && !fields.dateFin) return;
        if (fields.selectedPeriode.code === `EVERY_DAY` && !fields.heureFin)
            return;
        const debut =
            fields.selectedPeriode.code === `TODAY`
                ? fields.dateDebut
                : fields.heureDebut;
        const fin =
            fields.selectedPeriode.code === `TODAY`
                ? fields.dateFin
                : fields.heureFin;
        const data = {
            code: `${fields.selectedAgent.uid}-${fields.selectedTache.code}-${fields.selectedPeriode.code}`,
            referenceAgent: fields.selectedAgent.uid,
            nomAgent: fields.selectedAgent.designation,
            estAutomatique: fields.selectedPeriode.code === `EVERY_DAY`,
            plage: {
                debut: fields.selectedPeriode.code === `EVERY_DAY` ? 0 : debut,
                fin
            },
            codeTache: fields.selectedTache.code,
            designationTache: fields.selectedTache.designation
        };
        formDispatcher({
            type: FORM_CONST.ADD_ITEM,
            payload: {
                data,
                identifier: 'code',
                field: 'list'
            }
        });
    };

    const renderContent = () => (
        <FormWrapper
            formState={formState}
            formDispatcher={formDispatcher}
            onSubmit={handleSubmit}
            listDependencies={[
                {
                    dependency: 'users',
                    etat: etatUser
                },
                {
                    dependency: 'fonctions',
                    etat: etatFonction
                    // isCondition: typeEntite === TYPE_ORGANISATION.NAPP
                },
                {
                    dependency: 'tachesPerformance',
                    etat: etatTachesPerformances
                }
            ]}
        >
            <CustomRow>
                <CustomDropdown
                    options={fonctions}
                    formDispatcher={formDispatcher}
                    formState={formState}
                    defaultOption="Sélectionner le profil"
                    label="Profil* :"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    name="selectedProfil"
                    id="selectedProfilCreationHeureSupplementaireId"
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    options={filteredUsers}
                    formDispatcher={formDispatcher}
                    formState={formState}
                    defaultOption="Sélectionner l'agent"
                    label="Agent* :"
                    idProperty="uid"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    name="selectedAgent"
                    id="selectedAgentCreationHeureSupplementaireId"
                    uiValidator={uiValidator}
                    disabled={!filteredUsers.length}
                />
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    options={filteredTaches}
                    formDispatcher={formDispatcher}
                    formState={formState}
                    defaultOption="Sélectionner la tache"
                    label="Taches* :"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    name="selectedTache"
                    id="selectedTacheCreationHeureSupplementaireId"
                    uiValidator={uiValidator}
                    disabled={!filteredTaches.length}
                />
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    options={[
                        { code: `TODAY`, designation: `Périodique` },
                        { code: `EVERY_DAY`, designation: `Tous les jours` }
                    ]}
                    formDispatcher={formDispatcher}
                    formState={formState}
                    defaultOption="Sélectionner"
                    label="Période* :"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    name="selectedPeriode"
                    id="selectedPeriodeCreationHeureSupplementaireId"
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomCollapse
                isIn={fields?.selectedPeriode?.code === `EVERY_DAY`}
            >
                <CustomRow>
                    <CustomInput
                        type="number"
                        label="Nombre Heure: *"
                        labelClassName="col-12 col-sm-2"
                        divClassName="col-12 col-sm-10"
                        isInputGroup={false}
                        placeholder="Durée"
                        formDispatcher={formDispatcher}
                        name="heureFin"
                        id="heureFinHeureSupId"
                        formState={formState}
                        uiValidator={uiValidator}
                    />
                </CustomRow>
            </CustomCollapse>
            <CustomCollapse isIn={fields?.selectedPeriode?.code === `TODAY`}>
                <CustomRow>
                    <CustomInputDateTime
                        label="Plages (Date+Heure): *"
                        labelClassName="col-12 col-sm-2"
                        divClassName="col-12 col-sm-5"
                        isInputGroup={false}
                        placeholder="Durée"
                        formDispatcher={formDispatcher}
                        name="dateDebut"
                        id="dateDebutHeureSupId"
                        formState={formState}
                        uiValidator={uiValidator}
                    />
                    <CustomInputDateTime
                        divClassName="col-12 col-sm-5"
                        isInputGroup={false}
                        placeholder="Durée"
                        formDispatcher={formDispatcher}
                        name="dateFin"
                        id="dateFinHeureSupId"
                        formState={formState}
                        uiValidator={uiValidator}
                    />
                </CustomRow>
            </CustomCollapse>
            {/* <CustomRow>
                <CustomTextAreaInput
                    isInputGroup={false}
                    label="Motif*: "
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    placeholder="Message à envoyer"
                    formDispatcher={formDispatcher}
                    name="motif"
                    id="motifHeureSupId"
                    uiValidator={uiValidator}
                    formState={formState}
                    rows={5}
                />
            </CustomRow> */}
            <CustomRow className="mx-1">
                <div className="offset-md-11 col-1">
                    <CustomButtonLoader
                        type="button"
                        className="btn-primary"
                        onClick={onAddDetail}
                        text="Ajouter Configuration"
                    />
                </div>
            </CustomRow>
            <CustomCollapse isIn={!!fields.list.length}>
                <SimpleTable
                    tableClass="table table-hover shadow table-bordered table-responsive-sm"
                    filter={false}
                    bottomPagination={false}
                    isLinkToDetail={false}
                    listSelectedItemName="list"
                    identifier="code"
                    formDispatcher={formDispatcher}
                    data={fields.list}
                    mapper={[
                        {
                            field: 'nomAgent',
                            displayName: 'Agent'
                        },
                        {
                            field: 'designationTache',
                            displayName: 'Tache'
                        },
                        {
                            tdExtractor: (item) => <TypeDetail item={item} />,
                            displayName: 'Type'
                        },
                        {
                            displayName: 'Debut',
                            dataExtractor: (item) =>
                                item.estAutomatique
                                    ? String(item.plage.debut)
                                    : item.plage.debut.replace('T', ' ')
                        },
                        {
                            displayName: 'Fin',
                            dataExtractor: (item) =>
                                item.estAutomatique
                                    ? item.plage.fin
                                    : item.plage.fin.replace('T', ' ')
                        }
                    ]}
                />
            </CustomCollapse>
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Sauvegarder"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
