import {
    fetchElement,
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    BENEFICIAIRE,
    DB_NODE,
    formInitialState,
    FORM_CONST,
    CATEGORIE_MARCHAND,
    TYPE_BUSINESS
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomAddRemoveButton,
    CustomButtonLoader,
    CustomCollapse,
    CustomDropdown,
    CustomFormGroup,
    CustomInput,
    CustomLabel,
    CustomRow,
    FormWrapper,
    SelectZone,
    SimpleTable
} from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { useDebounce, URL_CONST } from '../../../util';

const defaultFields = {
    selectedSender: {},
    selectedMarchand: {},
    selectedZone: {},
    selectedZoneDropDown: {},
    searchInput: '',
    preview: '',
    zones: [],
    typeBusinessTable: [],
    selectedTypeBusiness: {}
};

/**
 * @description dependencies du state du formulaire
 */

const defaultElements = ['zones', 'marchand'];

const defaultDependencies = {
    fields: ['user']
};

export function CreationConfigurationSenderForm() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser, nappUser, users, etatUser } = useSelector(
        (reduxState) => ({
            firebaseUser: reduxState.firebaseUser,
            nappUser: reduxState.nappUser,
            users: reduxState.senders.map((item) => ({
                ...item,
                designation: `${item?.sendeur?.prenom || item?.prenom} ${
                    item?.sendeur?.nom || item?.nom
                }`
            })),
            etatUser: reduxState.etat.senders.etat
        })
    );
    const { idToken, creator, typeEntite, isNapp } = useGetUserDetail({
        firebaseUser,
        nappUser
    });
    const initialState = formInitialState({
        fields: defaultFields,
        elements: defaultElements,
        dependencies: defaultDependencies
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form, elements } = formState;
    const { status } = form;
    useDebounce({
        name: 'selectedZone',
        preview: 'preview',
        isObject: true,
        formDispatcher,
        formState,
        delay: 2000,
        idToken,
        functionName: URL_CONST.GET_LIST_COORDONNES_DISPONIBLE_POUR_AGENCE,
        elementPropertyName: 'zones',
        creator,
        reduxNodeName: DB_NODE.ENTITE_ADMINISTRATIVE,
        reduxDispatcher
    });

    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_USER_A_SELECTIONNER,
                nodeName: REDUX_NODE_NAME.AGENT_SENDERS,
                payload: {
                    typeEntite: 'Sendeur'
                },
                etat: etatUser
            }
        ]
    });

    const getMarchandByCode = ({ listMarchand, code }) => {
        const marchand = listMarchand?.find((m) => m.code === code);
        return marchand;
    };

    /**
     * Action to add type business
     * @param {*} event
     */
    /** Start Section */
    const addTypeBusiness = () => {
        if (
            !fields?.selectedTypeBusiness?.code ||
            !fields?.selectedTypeBusiness?.designation
        ) {
            formDispatcher({
                type: FORM_CONST.SET_FORM_ERROR,
                payload: {
                    message: 'Vérifiez les valeurs renseignées',
                    status: FORM_CONST.MODIFICATION_ECHEC
                }
            });
            return;
        }
        const data = {
            code: fields?.selectedTypeBusiness?.code,
            designation: fields?.selectedTypeBusiness?.designation
        };
        formDispatcher({
            type: FORM_CONST.ADD_ITEM,
            payload: {
                identifier: 'code',
                field: 'typeBusinessTable',
                data
            }
        });
    };
    /** End Section */

    /**
     * @description fonction pour fetcher les marchands
     */
    const onSearchMarchand = () => {
        const { status: etat } = elements.marchand;
        formDispatcher(
            fetchElement({
                idToken,
                functionName: URL_CONST.GET_LIST_MARCHAND_BY_TEXT_DESIGNATION,
                elementPropertyName: 'marchand',
                etat,
                payload: {
                    textDesignation: fields.searchInput,
                    codeCategorieParent:
                        CATEGORIE_MARCHAND.MARCHAND_PRODUIT_ELECTRONIQUE.code
                },
                reduxDispatcher
            })
        );
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const payloadToAdd = {};
        if (typeEntite === BENEFICIAIRE.MARCHAND.code) {
            payloadToAdd.zones = fields.zones;
        }
        if (isNapp) {
            const zones = fields.zones.map((item) => Number(item.reference));
            payloadToAdd.codeMarchand = fields.selectedMarchand.code;
            payloadToAdd.zones = zones;
        }
        if (
            getMarchandByCode({
                listMarchand: elements?.marchand?.value,
                code: fields?.selectedMarchand?.code
            })?.categorieMarchand?.code === CATEGORIE_MARCHAND.MOBILE_MONEY.code
        ) {
            payloadToAdd.typeBusiness = fields?.typeBusinessTable.map(
                (item) => item.code
            );
        }
        const payload = {
            uidSendeur: fields.selectedSender.owner,
            ...payloadToAdd,
            creator
        };
        formDispatcher(
            submitPostAction({
                idToken,
                fields,
                functionName: URL_CONST.POST_LIST_ZONE_SENDEUR,
                payload: {
                    ...payload
                },
                reduxDispatcher
                // uiValidator
            })
        );
    };
    return (
        <FormWrapper
            onSubmit={handleSubmit}
            formState={formState}
            formDispatcher={formDispatcher}
            listDependencies={[
                {
                    dependency: 'user',
                    etat: etatUser
                }
            ]}
        >
            <CustomRow>
                <CustomDropdown
                    label="Sender*"
                    labelClassName="col-sm-2"
                    divClassName="col-sm-10"
                    idProperty="owner"
                    defaultOption="Choisir l'agent"
                    formDispatcher={formDispatcher}
                    formState={formState}
                    name="selectedSender"
                    id="selectedSenderId"
                    options={users}
                    // uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow isShouldBeRendered={isNapp}>
                <CustomLabel text="Rechecher Marchand*" className="col-sm-2" />
                <CustomInput
                    isInputGroup={false}
                    divClassName="col-12 col-sm-7"
                    placeholder="Marchand"
                    formDispatcher={formDispatcher}
                    name="searchInput"
                    id="configurationSenderSearchInputId"
                    formState={formState}
                    // uiValidator={uiValidator}
                />
                <CustomButtonLoader
                    className="col-2 h-25"
                    type="button"
                    text="rechercher"
                    onClick={onSearchMarchand}
                    disabled={
                        elements?.marchand?.status ===
                        FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                    }
                    isActionEncours={
                        elements?.marchand?.status ===
                        FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                    }
                />
            </CustomRow>
            {elements.marchand?.value?.length ? (
                <CustomRow>
                    <CustomLabel text="Marchand" className="col-sm-2" />
                    <CustomDropdown
                        options={elements?.marchand?.value}
                        formDispatcher={formDispatcher}
                        formState={formState}
                        defaultOption="Sélectionner le marchand"
                        divClassName="col-12 col-sm-4"
                        name="selectedMarchand"
                        id="selectedMarchandId"
                        // uiValidator={uiValidator}
                    />
                </CustomRow>
            ) : null}
            {getMarchandByCode({
                listMarchand: elements?.marchand?.value,
                code: fields?.selectedMarchand?.code
            })?.categorieMarchand?.code ===
            CATEGORIE_MARCHAND.MOBILE_MONEY.code ? (
                <CustomRow>
                    <CustomDropdown
                        options={[
                            {
                                code: TYPE_BUSINESS.BUSINESS_MODEL,
                                designation:
                                    TYPE_BUSINESS.BUSINESS_MODEL_DESIGNATION
                            },
                            {
                                code: TYPE_BUSINESS.TRADITIONNEL_BUSINESS,
                                designation:
                                    TYPE_BUSINESS.TRADITIONNEL_BUSINESS_DESIGNATION
                            }
                        ]}
                        label="Type Business* :"
                        labelClassName="col-12 col-sm-2"
                        divClassName="col-12 col-sm-8"
                        formDispatcher={formDispatcher}
                        defaultOption="Sélectionner Type Business"
                        name="selectedTypeBusiness"
                        id="selectedTypeBusinessCreationDepot"
                        formState={formState}
                        idProperty="code"
                        // propertyToDisplay="code"
                        // uiValidator={uiValidator}
                    />
                    <CustomAddRemoveButton
                        className="col-sm-2"
                        callback={addTypeBusiness}
                    />
                </CustomRow>
            ) : null}
            <CustomCollapse isIn={!!fields?.typeBusinessTable}>
                <SimpleTable
                    identifier="code"
                    data={fields?.typeBusinessTable}
                    listSelectedItemName="typeBusinessTable"
                    formDispatcher={formDispatcher}
                    // className="w-50"
                    tableClass="table table-hover shadow table-bordered table-responsive-sm"
                    filter={false}
                    bottomPagination={false}
                    isLinkToDetail={false}
                    mapper={[
                        {
                            position: 2,
                            displayName: 'designation',
                            field: 'designation'
                        }
                    ]}
                />
            </CustomCollapse>
            {typeEntite === 'Napp' ? (
                <CustomRow>
                    <CustomFormGroup className="col-md-12">
                        <SelectZone
                            zoneFound={
                                elements?.zones?.value?.length
                                    ? elements.zones.value
                                    : []
                            }
                            entitesAdministrative={
                                elements?.zones?.value?.length
                                    ? elements.zones.value
                                    : []
                            }
                            // callback={processChanges}
                            formDispatcher={formDispatcher}
                            formState={formState}
                            // uiValidator={uiValidator}
                        />
                    </CustomFormGroup>
                </CustomRow>
            ) : null}
            <CustomButtonLoader
                className="btn mt-3 w-25"
                text="Affecter Sender"
                disabled={status === FORM_CONST.CREATION_EN_COURS}
            />
        </FormWrapper>
    );
}
