/* eslint-disable react/no-array-index-key */
import { Fragment, useCallback, useEffect, useState } from 'react';
// import { useLocation, useNavigate } from 'react-router-dom';
import {
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    TIME,
    formInitialState,
    BUDGET_ELABORATION,
    TYPE_BUDGET_ADDITIONNEL,
    TYPE_BUDGET_PREVISIONNEL,
    CHARGE,
    BUDGET_AMENDEMENT,
    BENEFICIAIRE,
    ETAT,
    MOYEN_PAIEMENT,
    GenericModel,
    FETCH_LISTENING,
    FILTER_TYPE,
    TODAY,
    FILTER_OPERATOR,
    FORM_CONST
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
// import { IoIosAddCircleOutline } from 'react-icons/io';
import { FaRegFilePdf } from 'react-icons/fa';
import generatePDF, { Resolution, Margin } from 'react-to-pdf';
import { URL_CONST } from '../../../../../util';
import { REDUX_NODE_NAME } from '../../../../../redux';
import {
    BackComponent,
    ConditionalRenderingWrapper,
    CustomButton,
    CustomCenteredModal,
    // CustomCheckButton,
    // CustomConfirmButton,
    CustomDropdown,
    CustomSwitchButton,
    CustomToast,
    FormWrapper
    // StateShowerComponent
} from '../../../../../components';
import {
    ChargeFixeBaseForm,
    ElaborationTablePreview,
    LeftSidePannel
} from '../base';
import {
    handleAddHighLevel,
    handleChange,
    handleRemove,
    handleSelectChange,
    toggledView,
    toggledVisibilityView,
    useGetTypeBeneficiaires,
    useGeneratePayloadAndDataList,
    useGetPeriodesExercice,
    tablePreviewData
} from '../util';
import { getCustomUrl } from '../../../../../helpers';

/**
 * @description fields du state du formulaire
 */
const defaultFields = {
    selectedExercice: {},
    selectedDirection: {},
    estPrevisionnel: true,
    isDraft: false,
    draftNetWorkBehavior: {}
};

/**
 * @description dependencies du state du formulaire
 */
const defaultDependencies = {
    fields: [
        'directions',
        'secteurs',
        'charges',
        'caisses',
        'exercicesFinancier',
        'employes',
        'users',
        'partenaires',
        'brouillon'
    ]
};

/**
 * Principale vue des vues pour les charges fixes
 * @param {Object} param0 : prends en compte toutes les props possibles
 * @param {String} param0.typeBudget: qui sera du type 'Elaboration | Completion | Ammendement'
 * @param {String} param0.isAdditionnel: qui sera du type true | False, pour savoir si c'est un prévisionnel ou un additionnel
 * @param {String} param0.isCompletion: qui sera du type true | False, pour savoir si on fait la completion d'une élaboration existante
 * @returns
 */
export function ChargesFixes({
    isAdditionnel = false,
    isCompletion = false,
    isAmendement = false,
    idEntity,
    payloadUrl
}) {
    const reduxDispatcher = useDispatch();
    // const navigate = useNavigate();
    // const path = useLocation();
    const [show, setShow] = useState(false);
    // const [idTarget, setIdTarget] = useState(null);
    const onHide = () => setShow(false);
    const onShow = () => setShow(true);
    // Begin Region
    const [formFields, setFormFields] = useState([
        {
            element: {
                code: '',
                designation: '' /** Property to add for displaying purpose */
            },
            typeMoyenPaiement: '',
            idCaisse: '',
            caisseDesignation: '' /** Property to add for displaying purpose */,
            estQuantite: false /** Property to add for displaying purpose and logic payload (backend) */,
            estRempliAutomatique: false /** Property to add for displaying purpose and logic payload (backend) */,
            prix: 0,
            isTooggled: true,
            isVisible: false,
            details: [
                {
                    direction: '',
                    directionDesignation:
                        '' /** Property to add for displaying purpose */,
                    secteur: {
                        code: '',
                        designation: ''
                    },
                    isTooggled: true,
                    isVisible: false,
                    idCaisse: '',
                    caisseDesignation:
                        '' /** Property to add for displaying purpose */,
                    prix: 0,
                    beneficiaires: [
                        {
                            typeEntite: '',
                            typeEntiteDesignation:
                                '' /** Property to add for displaying purpose */,
                            valeurTypeEntite: {
                                reference: '',
                                designation:
                                    '' /** Property to add for displaying purpose */
                            },
                            idCaisse: '',
                            caisseDesignation:
                                '' /** Property to add for displaying purpose */,
                            prix: 0,
                            quantite: 0,
                            montant: 0,
                            isTooggled: true,
                            isVisible: false,
                            periodes: [
                                {
                                    dateDebut: 0,
                                    dateFin: 0,
                                    datePaiement: 0,
                                    quantite: 0,
                                    montant: 0,
                                    datePaiementString: '',
                                    mois: '' /** Property to add for displaying month */
                                    // isTooggled: true,
                                    // isVisible: false
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ]);
    // End Region

    const {
        firebaseUser,
        charges,
        etatCharge,
        secteurs,
        etatSecteurs,
        directions,
        etatDirections,
        caisses,
        etatCaisse,
        exerciceFinancier,
        etatExerciceFinancier,
        users,
        etatUsers,
        employesOrganisation,
        etatEmployesOrganisation,
        partenaire,
        etatPartenaire,
        brouillons,
        etatBrouillon,
        ecouteurBrouillon
    } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        charges: reduxState.chargesFixe.map((item) => ({
            ...item,
            typeMoyenPaiement: item.typeMoyenPaiement || MOYEN_PAIEMENT.CASH
        })),
        etatCharge: reduxState.etat.chargesFixe.etat,
        secteurs: reduxState.secteurs,
        etatSecteurs: reduxState.etat.secteurs.etat,
        directions: reduxState.directions,
        etatDirections: reduxState.etat.directions.etat,
        caisses: reduxState.caissesSectorielle.map((item) => ({
            ...item,
            designation: `${item?.valeurTypeEntite?.designation}-${item?.devise}`
        })),
        etatCaisse: reduxState.etat.caissesSectorielle.etat,
        exerciceFinancier: reduxState.exerciceFinancier,
        etatExerciceFinancier: reduxState.etat.exerciceFinancier.etat,

        users: reduxState.users.map((item) => ({
            ...item,
            typeBeneficiaire: BENEFICIAIRE.USER.code,
            designation: `${item?.personne?.prenom || ' '} ${
                item?.personne?.nom || ' '
            }`
        })),
        etatUsers: reduxState.etat.users.etat,
        employesOrganisation: reduxState.employesOrganisation
            .filter((item) => !item?.idUser)
            .map((item) => ({
                ...item,
                typeBeneficiaire: BENEFICIAIRE.EMPLOYE.code,
                designation: `${item?.personne?.prenom || ' '} ${
                    item?.personne?.nom || ' '
                }`
            })),
        etatEmployesOrganisation: reduxState.etat.employesOrganisation.etat,
        partenaire: reduxState.beneficiaires.map((item) => ({
            ...item,
            typeBeneficiaire: BENEFICIAIRE.PARTENAIRE.code,
            designation: `${item?.personne?.prenom || ' '} ${
                item?.personne?.nom || ' '
            }`
        })),
        etatPartenaire: reduxState.etat.beneficiaires.etat,
        brouillons: reduxState.brouillonChargeFixe.filter(
            (item) =>
                item.type ===
                    (isAdditionnel
                        ? TYPE_BUDGET_ADDITIONNEL.code
                        : TYPE_BUDGET_PREVISIONNEL.code) &&
                item.typeEntite ===
                    (isAmendement ? BUDGET_AMENDEMENT : BUDGET_ELABORATION) &&
                !item.estVariable &&
                item.etat === ETAT.ETAT_ENCOURS
        ),
        etatBrouillon: reduxState.etat.brouillonChargeFixe.etat,
        ecouteurBrouillon: reduxState.etat.brouillonChargeFixe.ecouteur
    }));

    /**
     * @description: exercice financier format
     */
    const exercices = exerciceFinancier.map((item) => ({
        ...item,
        designation: `${TIME.fromUtcTimestampToLocalString(
            item.dateDebut
        )} - ${TIME.fromUtcTimestampToLocalString(item.dateFin)}`
    }));

    const { idToken, creator } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: defaultDependencies
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const getTypeBeneficiaire = useGetTypeBeneficiaires({
        employes: employesOrganisation,
        users,
        partenaires: partenaire
    });
    const { fields, form /** elements */ } = formState;

    const exerciceCallback = useCallback(
        ({ data }) =>
            data.find(
                (item) =>
                    item.id === fields?.selectedExercice.id ||
                    (item.dateDebut === payloadUrl?.start &&
                        item.dateFin === payloadUrl?.end)
            ),
        [fields?.selectedExercice.id, payloadUrl?.end, payloadUrl?.start]
    );

    const exerciceCible = exerciceCallback({ data: exercices });

    const periodes = useGetPeriodesExercice({
        exercice: exerciceCible,
        payloadUrl
    });

    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_CHARGE_FIXE,
                nodeName: REDUX_NODE_NAME.CHARGE_FIXE,
                etat: etatCharge
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_EXERCICE,
                nodeName: DB_NODE.EXERCICE_FINANCIER,
                etat: etatExerciceFinancier
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_DIRECTION, // Toutes les directions
                nodeName: REDUX_NODE_NAME.DIRECTION,
                etat: etatDirections
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_SECTEUR_CHARGE, // secteur Napp
                nodeName: REDUX_NODE_NAME.SECTEUR,
                etat: etatSecteurs
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_CAISSE_SECTORIELLE,
                nodeName: REDUX_NODE_NAME.CAISSE_SECTORIELLE,
                etat: etatCaisse
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_USER,
                nodeName: DB_NODE.USER,
                etat: etatUsers
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_EMPLOYE_ORGANISATION,
                nodeName: DB_NODE.EMPLOYE,
                etat: etatEmployesOrganisation
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_PARTENAIRE,
                nodeName: REDUX_NODE_NAME.BENEFICIAIRE,
                etat: etatPartenaire
            },
            {
                fetchType: FETCH_LISTENING,
                path: DB_NODE.BROUILLON_ELABORATION_AMENDEMENT,
                ecouteur: ecouteurBrouillon,
                functionName:
                    URL_CONST.GET_LIST_BROUILLON_ELABORATION_AMENDEMENT_INITIALS,
                nodeName: DB_NODE.BROUILLON_ELABORATION_AMENDEMENT,
                etat: etatBrouillon,
                payload: {
                    newElaborationAmendement: {
                        typeProprietaire: CHARGE.code,
                        estVariable: false
                    }
                },
                listenerFilters: [
                    // filtre pour le brouillon
                    {
                        type: FILTER_TYPE.WHERE,
                        operator: FILTER_OPERATOR.EQUAL,
                        property: 'type',
                        value: isAdditionnel
                            ? TYPE_BUDGET_ADDITIONNEL.code
                            : TYPE_BUDGET_PREVISIONNEL.code,
                        isRequired: true
                    },
                    {
                        type: FILTER_TYPE.WHERE,
                        operator: FILTER_OPERATOR.EQUAL,
                        property: 'typeEntite',
                        value: isAmendement
                            ? BUDGET_AMENDEMENT
                            : BUDGET_ELABORATION,
                        isRequired: true
                    },
                    {
                        type: FILTER_TYPE.WHERE,
                        operator: FILTER_OPERATOR.EQUAL,
                        property: 'estVariable',
                        value: false,
                        isRequired: true
                    },
                    {
                        type: FILTER_TYPE.ORDER_BY,
                        property: 'dateDerniereModification',
                        order: 'asc'
                    },
                    {
                        type: FILTER_TYPE.START_AT,
                        value: TODAY.nowUtc()
                    }
                ]
            }
        ]
    });

    /**
     * End Region
     */

    const { payloadList, tableList } = useGeneratePayloadAndDataList({
        formFields,
        tablePreviewData
    });

    /**
     * Fonction qui permet de scroller jusqu'à un élément précis
     */
    // const smoothScroll = ({ idDocument }) => {
    //     const element = document.getElementById(idDocument);
    //     if (element) {
    //         element.scrollIntoView({top: 56, behavior: 'smooth', block: 'start' });
    //     }
    // };

    // useEffect(() => {
    //     if (path.pathname && idTarget) {
    //         smoothScroll({ idDocument: idTarget });
    //     }
    // }, [path, idTarget]);

    const memoisedDraft = useCallback(
        ({ draft }) => {
            if (
                draft.length &&
                ((exerciceCible?.dateDebut && exerciceCible?.dateFin) ||
                    (payloadUrl?.start && payloadUrl?.end))
            ) {
                const payloadChecker = {
                    dateDebut: exerciceCible?.dateDebut || payloadUrl?.start,
                    dateFin: exerciceCible?.dateFin || payloadUrl?.end,
                    estPrevisionnel: idEntity
                        ? isAdditionnel
                        : fields.estPrevisionnel
                };
                const findDraft = draft.find(
                    (item) =>
                        item?.periode?.dateDebut ===
                            payloadChecker?.dateDebut &&
                        item?.periode?.dateFin === payloadChecker?.dateFin &&
                        item?.estPrevisionnel ===
                            payloadChecker?.estPrevisionnel
                );
                const parsedBruillon = findDraft?.brouillon?.list || [];
                setFormFields([
                    ...parsedBruillon,
                    {
                        element: {
                            code: '',
                            designation:
                                '' /** Property to add for displaying purpose */
                        },
                        typeMoyenPaiement: '',
                        idCaisse: '',
                        caisseDesignation:
                            '' /** Property to add for displaying purpose */,
                        estQuantite: false /** Property to add for displaying purpose and logic payload (backend) */,
                        estRempliAutomatique: false /** Property to add for displaying purpose and logic payload (backend) */,
                        prix: 0,
                        isTooggled: true,
                        isVisible: false,
                        details: [
                            {
                                direction: '',
                                directionDesignation:
                                    '' /** Property to add for displaying purpose */,
                                secteur: {
                                    code: '',
                                    designation: ''
                                },
                                isTooggled: true,
                                isVisible: false,
                                idCaisse: '',
                                caisseDesignation:
                                    '' /** Property to add for displaying purpose */,
                                prix: 0,
                                beneficiaires: [
                                    {
                                        typeEntite: '',
                                        typeEntiteDesignation:
                                            '' /** Property to add for displaying purpose */,
                                        valeurTypeEntite: {
                                            reference: '',
                                            designation:
                                                '' /** Property to add for displaying purpose */
                                        },
                                        idCaisse: '',
                                        caisseDesignation:
                                            '' /** Property to add for displaying purpose */,
                                        prix: 0,
                                        quantite: 0,
                                        montant: 0,
                                        isTooggled: true,
                                        isVisible: false,
                                        periodes: [
                                            {
                                                dateDebut: 0,
                                                dateFin: 0,
                                                datePaiement: 0,
                                                quantite: 0,
                                                datePaiementString: '',
                                                mois: '' /** Property to add for displaying month */
                                                // isTooggled: true,
                                                // isVisible: false
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]);
            }
        },
        [
            exerciceCible?.dateDebut,
            exerciceCible?.dateFin,
            fields.estPrevisionnel,
            idEntity,
            isAdditionnel,
            payloadUrl?.end,
            payloadUrl?.start
        ]
    );

    /**
     * UseEffect pour mettre à jour le state au cas où un brouillon existe
     */
    useEffect(() => {
        memoisedDraft({ draft: brouillons || [] });
        return memoisedDraft({ draft: [] });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [memoisedDraft]);

    const handlePostDraft = ({ isDraft = false }) => {
        if (isDraft) {
            formDispatcher({
                type: FORM_CONST.FIELD_CHANGE,
                payload: {
                    field: 'draftNetWorkBehavior',
                    data: {}
                }
            });
            // const findDraft = brouillons.find(
            //     (item) =>
            //         item?.periode?.dateDebut ===
            //             (exerciceCible?.dateDebut || payloadUrl?.start) &&
            //         item?.periode?.dateFin ===
            //             (exerciceCible?.dateFin || payloadUrl?.end) &&
            //         item?.estPrevisionnel ===
            //             (isAdditionnel ? fields.estPrevisionnel : false)
            // );
            const payload = {
                periode: {
                    dateDebut: exerciceCible.dateDebut || payloadUrl?.start,
                    dateFin: exerciceCible.dateFin || payloadUrl?.end
                },
                newElaborationAmendement: {
                    typeEntite: isAmendement
                        ? BUDGET_AMENDEMENT
                        : BUDGET_ELABORATION,
                    typeProprietaire: CHARGE.code,
                    estVariable: false,
                    type: isAdditionnel
                        ? TYPE_BUDGET_ADDITIONNEL.code
                        : TYPE_BUDGET_PREVISIONNEL.code
                },
                creator
            };
            if (isAdditionnel) {
                payload.newElaborationAmendement.estPrevisionnel =
                    fields.estPrevisionnel;
            }
            if ((isCompletion || isAmendement) && idEntity) {
                payload.idElaborationAmendement = idEntity;
            }
            if (formFields.length) {
                payload.elemUpdate = {
                    list: formFields
                        .filter((item) => item.element.code)
                        .map((item) => ({ ...item, isTooggled: false }))
                };
            }
            // if (findDraft?.id) {
            //     payload.idBrouillonElaborationAmendement = findDraft?.id;
            // }
            // console.log(payload, findDraft);
            GenericModel.postObjetViaCF({
                idToken,
                customUrl: getCustomUrl({
                    functionName:
                        URL_CONST.POST_BROUILLON_ELABORATION_AMENDEMENT
                }),
                // functionName: URL_CONST.POST_BROUILLON_ELABORATION_AMENDEMENT,
                ...payload
            })
                .then((response) => {
                    formDispatcher({
                        type: FORM_CONST.FIELD_CHANGE,
                        payload: {
                            field: 'draftNetWorkBehavior',
                            data: {
                                error: undefined,
                                message: response.data.message,
                                isSuccess: true
                            }
                        }
                    });
                })
                .catch((error) => {
                    formDispatcher({
                        type: FORM_CONST.FIELD_CHANGE,
                        payload: {
                            field: 'draftNetWorkBehavior',
                            data: {
                                error: error.message,
                                message: 'Echec lors de la mise à jour!',
                                isSuccess: false
                            }
                        }
                    });
                });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        /**
         * @description : Payload
         */
        const payload = {
            periode: {
                dateDebut: exerciceCible.dateDebut || payloadUrl?.start,
                dateFin: exerciceCible.dateFin || payloadUrl?.end
            },
            newElaborationAmendement: {
                typeEntite: isAmendement
                    ? BUDGET_AMENDEMENT
                    : BUDGET_ELABORATION,
                typeProprietaire: CHARGE.code,
                estVariable: false,
                type: isAdditionnel
                    ? TYPE_BUDGET_ADDITIONNEL.code
                    : TYPE_BUDGET_PREVISIONNEL.code
            },
            creator
        };
        if (isAdditionnel) {
            payload.newElaborationAmendement.estPrevisionnel =
                fields.estPrevisionnel;
        }
        if ((isCompletion || isAmendement) && idEntity) {
            payload.idElaborationAmendement = idEntity;
        }
        if (formFields.length) {
            payload.list = payloadList;
        }
        handlePostDraft({ isDraft: true });
        formDispatcher(
            submitPostAction({
                idToken,
                customUrl: getCustomUrl({
                    functionName: URL_CONST.POST_ELABORATION_AMENDEMENT
                }),
                // functionName: URL_CONST.POST_ELABORATION_AMENDEMENT,
                reduxNodeName: DB_NODE.ELABORATION_AMENDEMENT,
                payload,
                fields,
                reduxDispatcher
                // uiValidator
            })
        );
    };

    /**
     * Options définies pour la prévisualisation en pdf
     */
    const options = {
        // default is `save`
        method: 'open',
        // default is Resolution.MEDIUM = 3, which should be enough, higher values
        // increases the image quality but also the size of the PDF, so be careful
        // using values higher than 10 when having multiple pages generated, it
        // might cause the page to crash or hang.
        resolution: Resolution.HIGH,
        page: {
            // margin is in MM, default is Margin.NONE = 0
            margin: Margin.SMALL,
            // default is 'A4'
            format: 'letter',
            // default is 'portrait'
            orientation: 'landscape'
        },
        canvas: {
            // default is 'image/jpeg' for better size performance
            mimeType: 'image/png',
            qualityRatio: 1
        },
        // Customize any value passed to the jsPDF instance and html2canvas
        // function. You probably will not need this and things can break,
        // so use with caution.
        overrides: {
            // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
            pdf: {
                compress: true
            },
            // see https://html2canvas.hertzen.com/configuration for more options
            canvas: {
                useCORS: true
            }
        }
    };
    // you can use a function to return the target element besides using React refs
    const getTargetElement = () => document.getElementById('content-id');
    const renderContent = () => (
        <>
            <ConditionalRenderingWrapper
                isShouldBeRendered={!payloadUrl?.start || !payloadUrl?.end}
            >
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 border-bottom">
                    <BackComponent className="btn btn-primary" />
                    <ConditionalRenderingWrapper isShouldBeRendered={!idEntity}>
                        <div className="d-flex justify-content-between align-items-center col-4">
                            <CustomDropdown
                                options={exercices}
                                formDispatcher={formDispatcher}
                                formState={formState}
                                defaultOption="Sélectionner un exercice"
                                label="Exercice: "
                                selectionClassName="form-select-sm fw-bold border-dark"
                                labelClassName="col-12 col-sm-2 col-md-2 fs-5 me-5 fw-bold"
                                divClassName="col-12 col-sm-10 col-md-10"
                                name="selectedExercice"
                                id="selectedExerciceId"
                                idProperty="id"
                            />
                        </div>
                    </ConditionalRenderingWrapper>
                    <ConditionalRenderingWrapper
                        isShouldBeRendered={isAdditionnel}
                    >
                        <CustomSwitchButton
                            id="selectionElaborationAdditionnelSwitchId"
                            name="estPrevisionnel"
                            text="Agir sur le Prévisionnel?"
                            value={fields.estPrevisionnel}
                            formState={formState}
                            formDispatcher={formDispatcher}
                            labelClassName="fst-italic fw-bolder"
                        />
                    </ConditionalRenderingWrapper>
                    <h3>
                        Budget {isAdditionnel ? 'Additionnel' : 'Présionnel'}
                    </h3>
                </div>
            </ConditionalRenderingWrapper>
            <FormWrapper
                isRedirect={!isCompletion && !isAmendement}
                onSubmit={handleSubmit}
                formState={formState}
                isStepBack={isCompletion || isAmendement}
                formDispatcher={formDispatcher}
                listDependencies={[
                    {
                        dependency: 'directions',
                        etat: etatDirections
                    },
                    {
                        dependency: 'exercicesFinancier',
                        etat: etatExerciceFinancier
                    },
                    {
                        dependency: 'secteurs',
                        etat: etatSecteurs
                    },
                    {
                        dependency: 'charges',
                        etat: etatCharge
                    },
                    {
                        dependency: 'caisses',
                        etat: etatCaisse
                    },
                    {
                        dependency: 'employes',
                        etat: etatEmployesOrganisation
                    },
                    {
                        dependency: 'users',
                        etat: etatUsers
                    },
                    {
                        dependency: 'partenaires',
                        etat: etatPartenaire
                    },
                    {
                        dependency: 'brouillon',
                        etat: etatBrouillon
                    }
                ]}
            >
                {fields.draftNetWorkBehavior.message ? (
                    <CustomToast
                        error={
                            fields.draftNetWorkBehavior.error
                                ? fields.draftNetWorkBehavior.message
                                : undefined
                        }
                        successMessage={
                            fields.draftNetWorkBehavior.isSuccess
                                ? fields.draftNetWorkBehavior.message
                                : undefined
                        }
                    />
                ) : null}
                <div className="d-flex">
                    <LeftSidePannel
                        onShow={onShow}
                        formFields={formFields}
                        toggledVisibilityView={toggledVisibilityView}
                        toggledView={toggledView}
                        handleSubmit={handleSubmit}
                        form={form}
                        setFormFields={setFormFields}
                        handleRemove={handleRemove}
                    />
                    <div className="col-9 ms-2">
                        <CustomCenteredModal
                            show={show}
                            onHide={onHide}
                            header="Elaboration"
                            fullscreen
                            // size="lg"
                            // backdrop={backdrop}
                        >
                            <div className="text-end mb-2">
                                <button
                                    className="btn btn-primary rounded-pill"
                                    type="button"
                                    onClick={() =>
                                        generatePDF(getTargetElement, options)
                                    }
                                >
                                    Visualiser <FaRegFilePdf />
                                </button>
                            </div>
                            <div id="content-id">
                                <ElaborationTablePreview data={tableList} />
                            </div>
                        </CustomCenteredModal>
                        <ConditionalRenderingWrapper
                            isShouldBeRendered={
                                !!fields.selectedExercice.id ||
                                !!exerciceCible?.id
                            }
                        >
                            {formFields.map((field, index) => (
                                <Fragment key={index}>
                                    {field.isTooggled ? (
                                        <ChargeFixeBaseForm
                                            caisses={caisses}
                                            charges={charges}
                                            directions={directions}
                                            field={field}
                                            formFields={formFields}
                                            setFormFields={setFormFields}
                                            getTypeBeneficiaire={
                                                getTypeBeneficiaire
                                            }
                                            handleAddHighLevel={
                                                handleAddHighLevel
                                            }
                                            handleChange={handleChange}
                                            handleRemove={handleRemove}
                                            handleSelectChange={
                                                handleSelectChange
                                            }
                                            index={index}
                                            periodes={periodes}
                                            secteurs={secteurs}
                                            onToggleView={toggledView}
                                        />
                                    ) : null}
                                </Fragment>
                            ))}
                            <div className="d-flex justify-content-center">
                                <CustomButton
                                    // modalText="Voulez-vous soumettre cette élaboration?"
                                    // modalChildren={draftComponentAction}
                                    type="button"
                                    text="+ Ajouter"
                                    className="btn-success btn"
                                    onClick={() => {
                                        handleAddHighLevel({
                                            type: 'Charge',
                                            setFormFields,
                                            formFields
                                        });
                                        handlePostDraft({ isDraft: true });
                                    }}
                                />
                            </div>
                            {/* <div className="d-flex justify-content-between">
                                <div className="col-5">
                                    <StateShowerComponent state={formFields} />
                                </div>
                                <div className="col-5">
                                    <StateShowerComponent state={payloadList} />
                                </div>
                            </div> */}
                        </ConditionalRenderingWrapper>
                    </div>
                </div>
            </FormWrapper>
        </>
    );
    return renderContent();
}
