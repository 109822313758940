import React from 'react';
import { GENERIC_ACTIONS_TYPE } from '@napp-inc/jnapp-util';
import {
    CenteredSpinner,
    ConditionalRenderingWrapper,
    CustomAlert
} from '../../../components';

export function InfoCardSender({
    monnaiesLocales = [],
    etatConfigurationSender = {},
    etatMonnaieLocale,
    isEmoney = false
}) {
    const styleTable = {
        border: '1px solid transparent',
        borderCollapse: 'collapse'
    };
    return (
        <ConditionalRenderingWrapper
            isShouldBeRendered={
                etatConfigurationSender &&
                (Object.prototype.hasOwnProperty.call(
                    etatConfigurationSender,
                    'estOuvert'
                ) ||
                    Object.prototype.hasOwnProperty.call(
                        etatConfigurationSender,
                        'estSynchronisationSms'
                    ) ||
                    Object.prototype.hasOwnProperty.call(
                        etatConfigurationSender,
                        'intervalleGetCommande'
                    ) ||
                    Object.prototype.hasOwnProperty.call(
                        etatConfigurationSender,
                        'intervallePostSms'
                    ))
            }
        >
            <div className="d-flex flex-wrap">
                <div
                    className="alert alert-warning col-12 col-md-3"
                    role="alert"
                >
                    <span className="fw-bold">Devises</span>
                    <hr />
                    {!isEmoney ? (
                        <>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                style={{ display: 'none' }}
                            >
                                <symbol
                                    id="check-circle-fill"
                                    fill="currentColor"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                </symbol>
                                <symbol
                                    id="info-fill"
                                    fill="currentColor"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                                </symbol>
                                <symbol
                                    id="exclamation-triangle-fill"
                                    fill="currentColor"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                                </symbol>
                            </svg>
                            <svg
                                className="bi flex-shrink-0 me-2"
                                width="24"
                                height="24"
                                role="img"
                                aria-label="Info:"
                            >
                                <use xlinkHref="#info-fill" />
                            </svg>
                            <span className="fst-italic">
                                Devise non configuré
                            </span>
                        </>
                    ) : null}
                    {etatMonnaieLocale ===
                        GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_SUCCESS &&
                    isEmoney ? (
                        <table style={styleTable} className="col-12">
                            <tbody>
                                {monnaiesLocales.map((item) => (
                                    <tr style={styleTable} key={item.devise}>
                                        <td style={styleTable}>
                                            {item.devise}
                                        </td>
                                        <td style={styleTable}>
                                            <span
                                                className={`badge rounded-pill ${
                                                    etatConfigurationSender
                                                        ?.devisesRupture?.[
                                                        item.devise
                                                    ] !== undefined
                                                        ? 'bg-danger'
                                                        : 'bg-success'
                                                }`}
                                            >
                                                {etatConfigurationSender
                                                    ?.devisesRupture?.[
                                                    item.devise
                                                ] !== undefined
                                                    ? 'Désactivé'
                                                    : 'Activé'}
                                            </span>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : null}
                    {etatMonnaieLocale ===
                        GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_ECHEC &&
                    isEmoney ? (
                        <CustomAlert error="une erreur est survenue lors du chargement" />
                    ) : null}
                    {etatMonnaieLocale ===
                        GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_EN_COURS &&
                    isEmoney ? (
                        <CenteredSpinner />
                    ) : null}
                </div>
                <div
                    className="alert alert-warning col-12 col-md-3"
                    role="alert"
                >
                    <span className="fw-bold">Etat du service</span>
                    <hr />
                    <div>
                        {etatConfigurationSender.estOuvert ? (
                            <span className="badge rounded-pill bg-success">
                                Ouvert
                            </span>
                        ) : (
                            <span className="badge rounded-pill bg-danger">
                                Fermé
                            </span>
                        )}
                    </div>
                </div>
                <div
                    className="alert alert-warning col-12 col-md-3"
                    role="alert"
                >
                    <span className="fw-bold">Synchronisation SMS</span>
                    <hr />
                    {etatConfigurationSender.estSynchronisationSms ? (
                        <span className="badge rounded-pill bg-success">
                            Activée
                        </span>
                    ) : (
                        <span className="badge rounded-pill bg-danger">
                            Désactivée
                        </span>
                    )}
                </div>
                <div
                    className="alert alert-warning col-12 col-md-3"
                    role="alert"
                >
                    <span className="fw-bold">Intervals</span>
                    <hr />
                    <table style={styleTable} className="col-12">
                        <tbody>
                            <tr style={styleTable}>
                                <td style={styleTable}>Commande</td>
                                <td style={styleTable}>
                                    {etatConfigurationSender.intervalleGetCommande ||
                                        0}
                                </td>
                            </tr>
                            <tr style={styleTable}>
                                <td style={styleTable}>SMS</td>
                                <td style={styleTable}>
                                    {etatConfigurationSender.intervallePostSms ||
                                        0}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </ConditionalRenderingWrapper>
    );
}
