/**
 * @desc Creation agence
 */

import { CODE_SERVICE } from '@napp-inc/jnapp-util';
import { Configuration, Finance } from '../../../module';
import {
    AutorisationLotReferenceCreateRelPath,
    AutorisationLotReferenceDetailRelPath,
    AutorisationLotReferenceViewLayoutRelPath,
    CreationAgenceCreateRelPath,
    CreationAgenceDetailRelPath,
    CreationAgenceViewLayoutRelPath,
    CrediterBalanceLotCreateRelPath,
    CrediterBalanceLotDetailRelPath,
    CrediterBalanceLotViewLayoutRelPath,
    CrediterCompteViaInstitutFinCreateRelPath,
    CrediterCompteViaInstitutFinDetailRelPath,
    CrediterCompteViaInstitutFinViewLayoutRelPath,
    HeureSupplementaireCreateRelPath,
    HeureSupplementaireDetailRelPath,
    HeureSupplementaireViewLayoutRelPath
} from './shared-config-rel-path';

export const CreationAgenceViewLayoutPath = {
    ...CreationAgenceViewLayoutRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Configuration.CreationAgenceViewLayout,
    element: <Configuration.CreationAgenceViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.AGENCE_SERVICES.LECTURE_AGENCE_ORGANISATION,
        CODE_SERVICE.AGENCE_SERVICES.CREATION_AGENCE_ORGANISATION
    ]
};

export const CreationAgenceCreatePath = {
    ...CreationAgenceCreateRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.CreationCreationAgenceForm,
    element: <Configuration.CreationCreationAgenceForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.AGENCE_SERVICES.CREATION_AGENCE_ORGANISATION
    ]
};

export const CreationAgenceDetailPath = {
    ...CreationAgenceDetailRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.DetailCreationAgenceForm,
    element: <Configuration.DetailCreationAgenceForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.AGENCE_SERVICES.LECTURE_AGENCE_ORGANISATION,
        CODE_SERVICE.AGENCE_SERVICES.CREATION_AGENCE_ORGANISATION
    ]
};

/**
 * @desc Crediter compte via institut fin
 */

export const CrediterCompteViaInstitutFinViewLayoutPath = {
    ...CrediterCompteViaInstitutFinViewLayoutRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Configuration.CrediterCompteViaInstitutFinViewLayout,
    element: <Configuration.CrediterCompteViaInstitutFinViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_CASH_COLLECTION
            .CASH_COLLECTION_CREDITER_COMPTE_ORGANISATION
    ]
};

export const CrediterCompteViaInstitutFinCreatePath = {
    ...CrediterCompteViaInstitutFinCreateRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.CreationCrediterCompteViaInstitutFinForm,
    element: <Configuration.CreationCrediterCompteViaInstitutFinForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_CASH_COLLECTION
            .CASH_COLLECTION_CREDITER_COMPTE_ORGANISATION
    ]
};

export const CrediterCompteViaInstitutFinDetailPath = {
    ...CrediterCompteViaInstitutFinDetailRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.DetailCrediterCompteViaInstitutFinForm,
    element: <Configuration.DetailCrediterCompteViaInstitutFinForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_CASH_COLLECTION
            .CASH_COLLECTION_CREDITER_COMPTE_ORGANISATION
    ]
};

/**
 * @desc Heure supplementaire
 */

export const HeureSupplementaireViewLayoutPath = {
    ...HeureSupplementaireViewLayoutRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Configuration.HeureSupplementaireViewLayout,
    element: <Configuration.HeureSupplementaireViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.HEURE_SUPPLEMENTAIRE.INITIER_HEURE_SUPPLEMENTAIRE,
        CODE_SERVICE.HEURE_SUPPLEMENTAIRE.VALIDER_HEURE_SUPPLEMENTAIRE,
        CODE_SERVICE.HEURE_SUPPLEMENTAIRE.LECTURE_HEURE_SUPPLEMENTAIRE
    ]
};

export const HeureSupplementaireCreatePath = {
    ...HeureSupplementaireCreateRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Configuration.CreationHeureSupplementaireForm,
    element: <Configuration.CreationHeureSupplementaireForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.HEURE_SUPPLEMENTAIRE.INITIER_HEURE_SUPPLEMENTAIRE
    ]
};

export const HeureSupplementaireDetailPath = {
    ...HeureSupplementaireDetailRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Configuration.DetailHeureSupplementaireForm,
    element: <Configuration.DetailHeureSupplementaireForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.HEURE_SUPPLEMENTAIRE.INITIER_HEURE_SUPPLEMENTAIRE,
        CODE_SERVICE.HEURE_SUPPLEMENTAIRE.VALIDER_HEURE_SUPPLEMENTAIRE,
        CODE_SERVICE.HEURE_SUPPLEMENTAIRE.LECTURE_HEURE_SUPPLEMENTAIRE
    ]
};

/**
 * @desc Crediter balance lot
 */

export const CrediterBalanceLotViewLayoutPath = {
    ...CrediterBalanceLotViewLayoutRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Finance.CrediterBalanceLotViewLayout,
    element: <Finance.CrediterBalanceLotViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.FINANCE_ORGANISATION.LECTURE_CREDITER_BALANCE_EN_LOT,
        CODE_SERVICE.FINANCE_ORGANISATION.CREATION_CREDITER_BALANCE_EN_LOT,
        CODE_SERVICE.FINANCE_ORGANISATION.VALIDER_CREDITER_BALANCE_EN_LOT
    ]
};

export const CrediterBalanceLotCreatePath = {
    ...CrediterBalanceLotCreateRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Finance.CreationCrediterBalanceLotForm,
    element: <Finance.CreationCrediterBalanceLotForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.FINANCE_ORGANISATION.CREATION_CREDITER_BALANCE_EN_LOT
    ]
};

export const CrediterBalanceLotDetailPath = {
    ...CrediterBalanceLotDetailRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Finance.DetailCrediterBalanceLotForm,
    element: <Finance.DetailCrediterBalanceLotForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.FINANCE_ORGANISATION.LECTURE_CREDITER_BALANCE_EN_LOT,
        CODE_SERVICE.FINANCE_ORGANISATION.CREATION_CREDITER_BALANCE_EN_LOT,
        CODE_SERVICE.FINANCE_ORGANISATION.VALIDER_CREDITER_BALANCE_EN_LOT
    ]
};

/**
 * @desc Autorisation lot
 */

export const AutorisationLotReferenceViewLayoutPath = {
    ...AutorisationLotReferenceViewLayoutRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Finance.AutorisationLotReferenceViewLayout,
    element: <Finance.AutorisationLotReferenceViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_SENDER.CREATION_AUTORISATION_REFERENCE,
        CODE_SERVICE.CODE_SERVICE_SENDER.LECTURE_AUTORISATION_REFERENCE,
        CODE_SERVICE.CODE_SERVICE_SENDER.VALIDER_AUTORISATION_REFERENCE
    ]
};

export const AutorisationLotReferenceCreatePath = {
    ...AutorisationLotReferenceCreateRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Finance.CreationAutorisationLotReferenceForm,
    element: <Finance.CreationAutorisationLotReferenceForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_SENDER.CREATION_AUTORISATION_REFERENCE
    ]
};

export const AutorisationLotReferenceDetailPath = {
    ...AutorisationLotReferenceDetailRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Finance.DetailAutorisationLotReferenceForm,
    element: <Finance.DetailAutorisationLotReferenceForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_SENDER.CREATION_AUTORISATION_REFERENCE,
        CODE_SERVICE.CODE_SERVICE_SENDER.LECTURE_AUTORISATION_REFERENCE,
        CODE_SERVICE.CODE_SERVICE_SENDER.VALIDER_AUTORISATION_REFERENCE
    ]
};
