import { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
// import { RapportFacturationStockSimKinDBForm } from './rapport-facturation';
import { RapportGainPerteChangeForm } from './rapport-evolution-gain-perte-change';
import { ReleveStockSimForm } from './releve';
// import { RapportCommandeApproSimForm } from './rapport-commande-appro-sim';
// import { RapportRegularisationStockKinDBForm } from './rapport-regularisation';

export function RapportGainPerteChangeKinDBViewLayout() {
    const [key, setKey] = useState('evolution');
    return (
        <div className="container-fluid">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Gain et Perte Change</h1>
            </div>
            <Tabs
                defaultActiveKey="evolution"
                id="fill-tab-example"
                className="mb-3"
                // fill
                onSelect={setKey}
            >
                <Tab eventKey="evolution" title="Evolution">
                    {key === 'evolution' && <RapportGainPerteChangeForm />}
                </Tab>
                {/* <Tab eventKey="etat" title="Etat des commandes">
                    {key === 'etat' && <RapportCommandeApproSimForm />}
                </Tab>
                <Tab eventKey="facturation" title="Facturation">
                    {key === 'facturation' && (
                        <RapportFacturationStockSimKinDBForm />
                    )}
                </Tab> */}
                {/* <Tab eventKey="regul" title="Régularisation">
                    {key === 'regul' && <RapportRegularisationStockKinDBForm />}
                </Tab> */}
                <Tab eventKey="releve" title="Relevé">
                    {key === 'releve' && <ReleveStockSimForm />}
                </Tab>
            </Tabs>
        </div>
    );
}
