import {
    fromTimestampToString,
    resolvePathPropertyOfObject
} from '@napp-inc/jnapp-util';

export function CustomListReferenceGroupSelection({
    data,
    fields,
    property,
    handleChange,
    mapper
}) {
    return data.map((item) => (
        <ul
            key={item?.id}
            className="list-group list-group-horizontal mb-3 col-12 bg-primary"
        >
            <li className="list-group-item d-flex align-items-center col-1">
                <input
                    className="form-check-input me-1"
                    type="checkbox"
                    id={item?.id}
                    checked={
                        !!fields?.[property]?.find(
                            (element) => element === item?.id
                        )
                    }
                    onChange={handleChange}
                />
            </li>
            {mapper.map((element) => (
                <li key={element.path} className={element.liClass}>
                    <div className={element.divClass}>
                        <h5 className={element.hClass}>{element.hTitle}</h5>
                    </div>
                    <p className={element.pClass}>
                        <span>
                            {element?.path?.includes('date')
                                ? fromTimestampToString(
                                      resolvePathPropertyOfObject(
                                          element.path,
                                          item
                                      )
                                  )
                                : resolvePathPropertyOfObject(
                                      element.path,
                                      item
                                  )}
                        </span>{' '}
                        {element.smallPath ? (
                            <small className={element.smallClass}>
                                {item?.devise}
                            </small>
                        ) : null}
                    </p>
                </li>
            ))}
        </ul>
    ));
}
