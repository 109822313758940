import { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { RapportFacturationStockSimKinDBForm } from './rapport-facturation';
import { RapportStockKinDBForm } from './rapport-stock-kindb';
import { ReleveStockSimForm } from './releve-sim';
import { RapportCommandeApproSimForm } from './rapport-commande-appro-sim';
// import { RapportRegularisationStockKinDBForm } from './rapport-regularisation';

export function RapportStockSimKinDBViewLayout() {
    const [key, setKey] = useState('report');
    return (
        <div className="container-fluid">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Stock Sim</h1>
            </div>
            <Tabs
                defaultActiveKey="report"
                id="fill-tab-example"
                className="mb-3"
                // fill
                onSelect={setKey}
            >
                <Tab eventKey="report" title="Evolution stock">
                    {key === 'report' && <RapportStockKinDBForm />}
                </Tab>
                <Tab eventKey="etat" title="Etat des commandes">
                    {key === 'etat' && <RapportCommandeApproSimForm />}
                </Tab>
                <Tab eventKey="facturation" title="Facturation">
                    {key === 'facturation' && (
                        <RapportFacturationStockSimKinDBForm />
                    )}
                </Tab>
                {/* <Tab eventKey="regul" title="Régularisation">
                    {key === 'regul' && <RapportRegularisationStockKinDBForm />}
                </Tab> */}
                <Tab eventKey="releve" title="Relevé">
                    {key === 'releve' && <ReleveStockSimForm />}
                </Tab>
            </Tabs>
        </div>
    );
}
