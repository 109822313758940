import { CODE_SERVICE, ETAT, GENERIC_ACTIONS_TYPE } from '@napp-inc/jnapp-util';
import { useFetchAndListening, useGetUserDetail } from '@napp-inc/jnapp-hook';
import { useDispatch, useSelector } from 'react-redux';
import { CustomDropdown } from '../dropdown';
import { CenteredSpinner } from '../placeholder';
import { URL_CONST, useHasService } from '../../util';
import { REDUX_NODE_NAME } from '../../redux';
import { ConditionalRenderingWrapper } from '../container';
import { CustomAlert } from '../alert';

/**
 * Construit une dropdown où l'on peut sélectionner les comptes
 * @param {Object} param0
 * @param {Function} param0.formDispatcher form dispatcher du parent
 * @param {Object} param0.formState form state du parent
 * @returns {React.Component}
 */
export function ReportCommandeSimSelector({
    formDispatcher,
    formState,
    isReleve
}) {
    const reduxDispatcher = useDispatch();
    const {
        firebaseUser,
        nappUser,
        produitSimMarchand,
        etatProduitSimMarchand
    } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        nappUser: reduxState.nappUser,
        produitSimMarchand: reduxState.produitSimMarchand.map((item) => ({
            ...item,
            code: item?.valeurTypePartenariat?.code,
            designation: item?.valeurTypePartenariat?.designation
        })),
        etatProduitSimMarchand: reduxState.etat.produitSimMarchand.etat
    }));
    const { idToken, isNapp } = useGetUserDetail({ firebaseUser, nappUser });

    const { hasService } = useHasService({
        services: [
            CODE_SERVICE.STOCK.COMMANDE_MARCHAND.LECTURE_COMMANDE_FLASH_MARCHAND
        ]
    });
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_PRODUIT_SIM,
                nodeName: REDUX_NODE_NAME.PRODUIT_SIM_MARCHAND,
                etat: etatProduitSimMarchand
                // payload: { estEmoney: true, typeEntite: nappUser?.typeEntite }
            }
        ]
    });
    const renderContent = () => {
        if (
            etatProduitSimMarchand ===
            GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_SUCCESS
        ) {
            return (
                <>
                    <ConditionalRenderingWrapper
                        isShouldBeRendered={
                            (!!hasService[
                                CODE_SERVICE.STOCK.COMMANDE_MARCHAND
                                    .LECTURE_COMMANDE_FLASH_MARCHAND?.code
                            ] &&
                                isNapp) ||
                            isNapp
                        }
                    >
                        <CustomDropdown
                            options={produitSimMarchand}
                            defaultOption="Selectionner Marchand"
                            label="Marchand: "
                            labelClassName="col-12"
                            divClassName="col-12"
                            formDispatcher={formDispatcher}
                            name="selectedMarchandSim"
                            id="selectedMarchandSimCreationApproCompteEmoney"
                            // uiValidator={CreationApproCompteEmoneySchema}
                            formState={formState}
                        />
                    </ConditionalRenderingWrapper>
                    <ConditionalRenderingWrapper isShouldBeRendered={!isReleve}>
                        <CustomDropdown
                            options={[
                                {
                                    code: ETAT.ETAT_ENCOURS,
                                    designation: 'ENCOURS'
                                },
                                {
                                    code: ETAT.ETAT_ATTENTE_VALIDATION,
                                    designation: 'ATTENTE VALIDATION'
                                },
                                {
                                    code: ETAT.ETAT_VALIDER,
                                    designation: 'VALIDER'
                                },
                                {
                                    code: ETAT.ETAT_ANNULER,
                                    designation: 'ANNULER'
                                }
                            ]}
                            label="Etat: "
                            labelClassName="col-12"
                            defaultOption="Selectionner état"
                            divClassName="col-12"
                            formDispatcher={formDispatcher}
                            name="selectedEtat"
                            id="selectedEtatCreationApproCompteEmoney"
                            // uiValidator={CreationApproCompteEmoneySchema}
                            formState={formState}
                        />
                    </ConditionalRenderingWrapper>
                </>
            );
        }
        if (
            etatProduitSimMarchand ===
            GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_ECHEC
        ) {
            return (
                <CustomAlert error="une erreur est survenue lors du chargement des comtpes" />
            );
        }
        return <CenteredSpinner />;
    };
    return renderContent();
}
