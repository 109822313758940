import React from 'react';
import { IoIosRemoveCircleOutline } from 'react-icons/io';

/**
 *
 * @param {*} param0 :
 * @param {*} param0.text : Le texte qui sera afficher
 * @param {*} param0.button : Le bouton qui aura l'action de suppression
 * @returns
 */

export function CustomFilterBadge({
    text,
    icon = <IoIosRemoveCircleOutline />,
    // formState,
    // formDispatcher,
    className = 'border border-1 bg-light rounded-pill pb-1 px-1 ms-1',
    onClick = () => {},
    style,
    children
}) {
    return (
        <div style={style} className={className}>
            {text}{' '}
            {icon ? (
                <span
                    tabIndex={0}
                    role="button"
                    onClick={onClick}
                    onKeyDown={onClick}
                >
                    {icon}
                </span>
            ) : (
                children
            )}
        </div>
    );
}
