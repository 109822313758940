import React from 'react';
import { ETAT, FORM_CONST } from '@napp-inc/jnapp-util';
import { submitPutAction } from '@napp-inc/jnapp-hook';
import { BsClipboardCheck, BsClipboardMinus } from 'react-icons/bs';
import { MdAssignmentReturn, MdBackHand } from 'react-icons/md';
import { ConditionalRenderingWrapper } from '../container';
import { URL_CONST } from '../../util';

export function SenderMultirowsValidationButton({
    isRowSelector,
    isSenderFlash,
    isSenderEmoney,
    formState,
    selectedFlatRows = [],
    formDispatcher,
    idToken,
    nodeName,
    reduxDispatcher,
    viewSender
}) {
    const functionToSend = (state) => {
        if (state === 'AUTORISATION') return URL_CONST.PUT_CONFIG_SENDER;
        if (isSenderEmoney && state !== 'AUTORISATION')
            return URL_CONST.PUT_COMMANDES_EMONEY;
        return URL_CONST.PUT_COMMANDES_FLASH;
    };
    const handleValidationAll = (state) => {
        const functionName = functionToSend(state);
        const newList =
            selectedFlatRows.length > 30
                ? selectedFlatRows.slice(0, 30)
                : selectedFlatRows;
        const payload = {};
        if (isSenderEmoney && state !== 'AUTORISATION') {
            formDispatcher({
                type: FORM_CONST.SET_FORM_ERROR,
                payload: {
                    message:
                        "Cette fonctionnalité n'est pas encore disponible, veuillez valider individuellement",
                    status: FORM_CONST.MODIFICATION_ECHEC
                }
            });
            return;
        }
        if (isSenderEmoney && state === 'AUTORISATION') {
            payload.elemUpdate = {
                autorisations: newList.map((item) => item.original.id)
            };
        }
        if (state !== 'AUTORISATION') {
            payload.list =
                isSenderFlash && !isSenderEmoney
                    ? newList.map((item) => ({
                          idCommandeFlash: item.original.id
                      }))
                    : newList.map((item) => ({
                          idCommandeEmoney: item.original.id
                      }));
            payload.etat = state;
        }
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'actionEncours',
                data: state
            }
        });
        formDispatcher(
            submitPutAction({
                idToken,
                functionName, // URL_CONST.PUT_COMMANDES_EMONEY, // FIXME:
                payload,
                fields: formState.fields,
                reduxNodeName: nodeName,
                reduxDispatcher
            })
        );
    };
    return (
        <>
            <ConditionalRenderingWrapper
                isShouldBeRendered={
                    !!isRowSelector &&
                    !!isSenderEmoney &&
                    viewSender === ETAT.ETAT_ENCOURS
                }
            >
                <div className="mx-2">
                    <button
                        title="Autoriser tous les éléments sélectionner"
                        type="button"
                        className="btn btn-sm btn-info d-flex align-items-center"
                        onClick={() => handleValidationAll('AUTORISATION')}
                        disabled={
                            formState?.form?.status ===
                                FORM_CONST.MODIFICATION_EN_COURS ||
                            formState?.form?.status ===
                                FORM_CONST.CREATION_EN_COURS ||
                            !selectedFlatRows.length
                        }
                    >
                        {(formState?.form?.status ===
                            FORM_CONST.MODIFICATION_EN_COURS ||
                            formState?.form?.status ===
                                FORM_CONST.CREATION_EN_COURS) &&
                        formState.fields.actionEncours === ETAT.ETAT_ANNULER ? (
                            <span
                                className="spinner-border spinner-border-sm me-1"
                                role="status"
                            >
                                <span className="visually-hidden">
                                    Loading...
                                </span>
                            </span>
                        ) : (
                            <BsClipboardMinus className="me-1" />
                        )}
                        Autoriser
                    </button>
                </div>
            </ConditionalRenderingWrapper>
            <ConditionalRenderingWrapper
                isShouldBeRendered={
                    !!isRowSelector &&
                    (!!isSenderFlash || !!isSenderEmoney) &&
                    viewSender === ETAT.ETAT_ATTENTE_ANNULATION
                }
            >
                <div className="mx-2">
                    <button
                        title="Annuler tous les éléments sélectionner"
                        type="button"
                        className="btn btn-sm btn-danger d-flex align-items-center"
                        onClick={() => handleValidationAll(ETAT.ETAT_ANNULER)}
                        disabled={
                            formState?.form?.status ===
                                FORM_CONST.MODIFICATION_EN_COURS ||
                            formState?.form?.status ===
                                FORM_CONST.CREATION_EN_COURS ||
                            !selectedFlatRows.length
                        }
                    >
                        {(formState?.form?.status ===
                            FORM_CONST.MODIFICATION_EN_COURS ||
                            formState?.form?.status ===
                                FORM_CONST.CREATION_EN_COURS) &&
                        formState.fields.actionEncours === ETAT.ETAT_ANNULER ? (
                            <span
                                className="spinner-border spinner-border-sm me-1"
                                role="status"
                            >
                                <span className="visually-hidden">
                                    Loading...
                                </span>
                            </span>
                        ) : (
                            <BsClipboardMinus className="me-1" />
                        )}
                        Annuler
                    </button>
                </div>
            </ConditionalRenderingWrapper>
            <ConditionalRenderingWrapper
                isShouldBeRendered={
                    !!isRowSelector &&
                    (!!isSenderFlash || !!isSenderEmoney) &&
                    viewSender === ETAT.ETAT_ATTENTE_VALIDATION
                }
            >
                <div className="mx-2">
                    <button
                        title="Valider tous les éléments sélectionner"
                        type="button"
                        className="btn btn-sm btn-secondary d-flex align-items-center"
                        onClick={() => handleValidationAll(ETAT.ETAT_ENCOURS)}
                        disabled={
                            formState?.form?.status ===
                                FORM_CONST.MODIFICATION_EN_COURS ||
                            formState?.form?.status ===
                                FORM_CONST.CREATION_EN_COURS ||
                            !selectedFlatRows.length
                        }
                    >
                        {(formState?.form?.status ===
                            FORM_CONST.MODIFICATION_EN_COURS ||
                            formState?.form?.status ===
                                FORM_CONST.CREATION_EN_COURS) &&
                        formState.fields.actionEncours === ETAT.ETAT_ENCOURS ? (
                            <span
                                className="spinner-border spinner-border-sm me-1"
                                role="status"
                            >
                                <span className="visually-hidden">
                                    Loading...
                                </span>
                            </span>
                        ) : (
                            <MdAssignmentReturn className="me-1" />
                        )}
                        Revenir
                    </button>
                </div>
            </ConditionalRenderingWrapper>
            <ConditionalRenderingWrapper
                isShouldBeRendered={
                    !!isRowSelector &&
                    (!!isSenderFlash || !!isSenderEmoney) &&
                    viewSender === ETAT.ETAT_ENCOURS
                }
            >
                <div className="mx-2">
                    <button
                        title="Valider tous les éléments sélectionner"
                        type="button"
                        className="btn btn-sm btn-warning d-flex align-items-center"
                        onClick={() =>
                            handleValidationAll(ETAT.ETAT_ATTENTE_VALIDATION)
                        }
                        disabled={
                            formState?.form?.status ===
                                FORM_CONST.MODIFICATION_EN_COURS ||
                            formState?.form?.status ===
                                FORM_CONST.CREATION_EN_COURS ||
                            !selectedFlatRows.length
                        }
                    >
                        {(formState?.form?.status ===
                            FORM_CONST.MODIFICATION_EN_COURS ||
                            formState?.form?.status ===
                                FORM_CONST.CREATION_EN_COURS) &&
                        formState.fields.actionEncours ===
                            ETAT.ETAT_ATTENTE_VALIDATION ? (
                            <span
                                className="spinner-border spinner-border-sm me-1"
                                role="status"
                            >
                                <span className="visually-hidden">
                                    Loading...
                                </span>
                            </span>
                        ) : (
                            <MdBackHand className="me-1" />
                        )}
                        Attente
                    </button>
                </div>
            </ConditionalRenderingWrapper>
            <ConditionalRenderingWrapper
                isShouldBeRendered={
                    !!isRowSelector && (!!isSenderFlash || !!isSenderEmoney)
                }
            >
                <div className="">
                    <button
                        title="Valider tous les éléments sélectionner"
                        type="button"
                        className="btn btn-sm btn-success d-flex align-items-center"
                        onClick={() => handleValidationAll(ETAT.ETAT_LIVRER)}
                        disabled={
                            formState?.form?.status ===
                                FORM_CONST.MODIFICATION_EN_COURS ||
                            formState?.form?.status ===
                                FORM_CONST.CREATION_EN_COURS ||
                            !selectedFlatRows.length
                        }
                    >
                        {(formState?.form?.status ===
                            FORM_CONST.MODIFICATION_EN_COURS ||
                            formState?.form?.status ===
                                FORM_CONST.CREATION_EN_COURS) &&
                        formState.fields.actionEncours === ETAT.ETAT_LIVRER ? (
                            <span
                                className="spinner-border spinner-border-sm me-1"
                                role="status"
                            >
                                <span className="visually-hidden">
                                    Loading...
                                </span>
                            </span>
                        ) : (
                            <BsClipboardCheck className="me-1" />
                        )}
                        {isSenderEmoney ? 'Valider' : 'Livrer'}
                    </button>
                </div>
            </ConditionalRenderingWrapper>
        </>
    );
}
