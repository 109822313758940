import { FORM_CONST, formInitialState } from '@napp-inc/jnapp-util';
import { useFormGlobalReducer } from '@napp-inc/jnapp-hook';
import { Fragment /* useCallback */ } from 'react';
import { BsThreeDots } from 'react-icons/bs';
import { RiExchangeFundsLine } from 'react-icons/ri';
import { AiOutlinePlusCircle } from 'react-icons/ai';
// import { IoIosRemoveCircleOutline } from 'react-icons/io';
import {
    BackComponent,
    CustomDropdownLite,
    CustomFilterBadge,
    CustomPopover,
    CustomSimpleBadge,
    // DetailWrapper,
    ShowNewComponent
} from '../../../components';
// import { useGetDetailForm } from '../../../util';
// import { schema } from './detail-fields';
import { EditableData } from './editable-data';

const defaultFields = {
    selectedPeriode: {},
    selectedSecteur: {},
    typeBeneficiaireFilters: ['Secteur', 'Beneficiaire'],
    estSecteur: false,
    estBeneficiaire: false
};
export function DetailExerciceFinancier() {
    /**
     * @desc: initialState
     */
    const initialState = formInitialState({
        fields: defaultFields
        // dependencies,
        // elements
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    // const { fields } = formState;
    // const getElementById = [];
    // const onClickCheckBox = useCallback(() => {
    //     if (fields.estSecteur) {
    //         formDispatcher({
    //             type: FORM_CONST.ADD_PRIMITIVE_ITEM,
    //             payload: {
    //                 field: 'typeBeneficiaireFilters',
    //                 data: 'Secteur'
    //             }
    //         });
    //     }
    //     if (fields.estBeneficiaire) {
    //         formDispatcher({
    //             type: FORM_CONST.ADD_PRIMITIVE_ITEM,
    //             payload: {
    //                 field: 'typeBeneficiaireFilters',
    //                 data: 'Bénéficiaire'
    //             }
    //         });
    //     }
    //     if (!fields.estSecteur) {
    //         formDispatcher({
    //             type: FORM_CONST.REMOVE_PRIMITIVE_ITEM,
    //             payload: {
    //                 field: 'typeBeneficiaireFilters',
    //                 data: 'Secteur'
    //             }
    //         });
    //     }
    //     if (!fields.estBeneficiaire) {
    //         formDispatcher({
    //             type: FORM_CONST.REMOVE_PRIMITIVE_ITEM,
    //             payload: {
    //                 field: 'typeBeneficiaireFilters',
    //                 data: 'Bénéficiaire'
    //             }
    //         });
    //     }
    // }, [fields.estBeneficiaire, fields.estSecteur, formDispatcher]);
    const removeFilter = (type) => {
        if (type) {
            formDispatcher({
                type: FORM_CONST.REMOVE_PRIMITIVE_ITEM,
                payload: { field: 'typeBeneficiaireFilters', data: type }
            });
        }
    };

    return (
        <>
            <BackComponent className="btn btn-primary mb-5" />
            <div>
                <CustomDropdownLite
                    divClassName="col-3"
                    selectionClassName="form-select-sm col-3"
                    label="Periodes"
                    required
                    idProperty="code"
                    propertyToDisplay="code"
                    id="periodeLiteSelectorId"
                    formDispatcher={formDispatcher}
                    formState={formState}
                    name="selectedPeriode"
                    options={[
                        { code: 'annuel' },
                        { code: 'janvier' },
                        { code: 'février' },
                        { code: 'mars' },
                        { code: 'avril' },
                        { code: 'mai' },
                        { code: 'juin' },
                        { code: 'juillet' },
                        { code: 'aout' },
                        { code: 'septembre' },
                        { code: 'octobre' },
                        { code: 'novembre' },
                        { code: 'decembre' }
                    ]}
                />
            </div>
            <div>
                <table className="table table-bordered text-center table-responsive-sm">
                    <thead className="bg-light">
                        <tr>
                            <th rowSpan={2}>Budget</th>
                            <th rowSpan={2}>Type</th>
                            <th colSpan={3}>Périodes [selected periode]</th>
                        </tr>
                        <tr>
                            <th>Previsionnel</th>
                            <th>Factuel</th>
                            <th>Ecart</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="bg-secondary" />
                            <td>Charge Variable</td>
                            <td>1000000</td>
                            <td>800000</td>
                            <td>200000</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div>
                <div>Détails Elaboration</div>
                <div>
                    <div className="mb-3">
                        <CustomDropdownLite
                            divClassName="col-3"
                            selectionClassName="form-select-sm col-3"
                            label="Secteurs"
                            required
                            idProperty="code"
                            propertyToDisplay="code"
                            id="secteurLiteSelectorId"
                            formDispatcher={formDispatcher}
                            formState={formState}
                            name="selectedSecteur"
                            options={[
                                { code: 'Ville' },
                                { code: 'Rond-Point' },
                                { code: 'UPN' },
                                { code: 'KINTAMBO' },
                                { code: 'Masina' }
                            ]}
                        />
                        <div className="d-flex justify-content-end">
                            <div
                                style={{ border: '1px dashed gray' }}
                                className="d-flex justify-content-end rounded-pill px-2 py-1 me-1"
                            >
                                <div>
                                    <CustomSimpleBadge text="Filtres : " />
                                </div>
                                <CustomFilterBadge
                                    text="Secteur"
                                    onClick={() => removeFilter('Secteur')}
                                />
                                <CustomFilterBadge
                                    text="Bénéficiaire"
                                    onClick={() => removeFilter('Bénéficiaire')}
                                />
                            </div>
                            <div className="d-flex align-items-center ">
                                <CustomPopover
                                    isNexted
                                    text=""
                                    trigger={['focus', 'click']}
                                    button={
                                        <div className="border border-1 bg-light rounded-pill pb-1 px-1">
                                            <AiOutlinePlusCircle role="button" />{' '}
                                            Filtres
                                        </div>
                                    }
                                >
                                    <div>
                                        <input
                                            className="form-check-input me-1"
                                            type="checkbox"
                                            value=""
                                            aria-label="..."
                                        />
                                        Secteurs
                                    </div>
                                    <div>
                                        <input
                                            className="form-check-input me-1"
                                            type="checkbox"
                                            value=""
                                            aria-label="..."
                                        />
                                        Beneficiaires
                                    </div>
                                </CustomPopover>
                            </div>
                        </div>
                    </div>
                    <div>
                        {/* <table className="table table-bordered text-center table-responsive-sm">
                                <thead>
                                    <tr>
                                        <th>Secteur</th>
                                        <th>Montant</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>ville</td>
                                        <td>1000</td>
                                        <td>
                                            <CustomPopover
                                                isNexted
                                                text=""
                                                trigger={['focus','click']}
                                                button={
                                                    <div
                                                        role="button"
                                                        className="pb-2 text-center"
                                                    >
                                                        <BsThreeDots className="me-2" />
                                                    </div>
                                                }
                                            >
                                                <ShowNewComponent
                                                    className="btn"
                                                    services={[
                                                        { code: 'test' }
                                                    ]}
                                                    text="Réévaluation"
                                                    icon={
                                                        <RiExchangeFundsLine className="me-1" />
                                                    }
                                                />
                                            </CustomPopover>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>rond-point</td>
                                        <td>10000</td>
                                        <td>
                                            <CustomPopover
                                                isNexted
                                                text=""
                                                trigger={['focus','click']}
                                                button={
                                                    <div
                                                        role="button"
                                                        className="pb-2 text-center"
                                                    >
                                                        <BsThreeDots className="me-2" />
                                                    </div>
                                                }
                                            >
                                                <ShowNewComponent
                                                    className="btn"
                                                    services={[
                                                        { code: 'test' }
                                                    ]}
                                                    text="Réévaluation"
                                                    icon={
                                                        <RiExchangeFundsLine className="me-1" />
                                                    }
                                                />
                                            </CustomPopover>
                                        </td>
                                    </tr>
                                </tbody>
                            </table> */}
                        <table className="table table-bordered text-center table-responsive-sm">
                            <thead className="bg-light">
                                <tr>
                                    <th>Bénéficiaire</th>
                                    <th>Montant</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Jhon</td>
                                    <td>1000</td>
                                    <td>
                                        <CustomPopover
                                            isNexted
                                            text=""
                                            trigger={['focus', 'click']}
                                            button={
                                                <div
                                                    role="button"
                                                    className="pb-2 text-center"
                                                >
                                                    <BsThreeDots className="me-2" />
                                                </div>
                                            }
                                        >
                                            <ShowNewComponent
                                                className="btn"
                                                services={[{ code: 'test' }]}
                                                text="Réévaluation"
                                                icon={
                                                    <RiExchangeFundsLine className="me-1" />
                                                }
                                            />
                                        </CustomPopover>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Henock</td>
                                    <td>10000</td>
                                    <td>
                                        <CustomPopover
                                            isNexted
                                            text=""
                                            trigger={['focus', 'click']}
                                            button={
                                                <div
                                                    role="button"
                                                    className="pb-2 text-center"
                                                >
                                                    <BsThreeDots className="me-2" />
                                                </div>
                                            }
                                        >
                                            <ShowNewComponent
                                                className="btn"
                                                services={[{ code: 'test' }]}
                                                text="Réévaluation"
                                                icon={
                                                    <RiExchangeFundsLine className="me-1" />
                                                }
                                            />
                                        </CustomPopover>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <EditableData />
        </>
    );
}
