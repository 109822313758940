import React from 'react';
// import { useLocation, useNavigate } from 'react-router-dom';
// import { useFormGlobalReducer } from '@napp-inc/jnapp-hook';
import { DB_NODE, ONLY_FETCH } from '@napp-inc/jnapp-util';
// import { DB_NODE, ONLY_FETCH } from '@napp-inc/jnapp-util';
// import { ListGenericForm } from '../../../components';
// import { URL_CONST } from '../../../util';
import { ViewLayout } from './view-layout';
// import { fakeData } from './fakedata';
import { ListGenericForm } from '../../../components';
import { URL_CONST } from '../../../util';

// const defaultFields = {
//     selectedExercice: {}
// };

export function ListExerciceFinancierForm() {
    /**
     * @desc: initialState
     */
    // const initialState = formInitialState({
    //     fields: defaultFields
    //     // dependencies,
    //     // elements
    // });
    // const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
    //     {
    //         initialState
    //     }
    // );
    // const { fields } = formState;

    // const { pathname } = useLocation();
    // const navigate = useNavigate();
    // const gotoDetails = (id) => {
    //     if (!pathname) return;
    //     navigate(`${pathname}/${id}`, { replace: true });
    // };
    // const groupeDataByType = ({ data = fakeData }) => {
    //     const finalx = [];
    //     data.forEach((item) => {
    //         if (item.type) {
    //             const element = {
    //                 code: item.type,
    //                 designation: item.type,
    //                 colSpan: 39
    //             };
    //             finalx.push({ element });
    //         }
    //         if (item.element) {
    //             finalx.push(item);
    //         }
    //         if (item.details) {
    //             item.details.forEach((detail) => {
    //                 finalx.push(detail);
    //             });
    //         }
    //     });
    //     return finalx;
    // };
    // const tableData = groupeDataByType({ data: fakeData });
    // const periodes = [
    //     'annuel',
    //     'janvier',
    //     'fevrier',
    //     'mars',
    //     'avril',
    //     'mai',
    //     'juin',
    //     'juillet',
    //     'aout',
    //     'septembre',
    //     'octobre',
    //     'novembre',
    //     'decembre'
    // ];
    // const bodyRender = (item) => {
    //     if (item.element.colSpan) {
    //         return (
    //             <tr
    //                 key={item.element.designation}
    //                 className="bg-info bg-gradient"
    //             >
    //                 <td>{item.element.designation}</td>
    //                 <td colSpan={39} />
    //             </tr>
    //         );
    //     }
    //     if (!item.element.colSpan) {
    //         return (
    //             <tr
    //                 key={`${item.element}`}
    //                 className={
    //                     item.element.code === 'Global'
    //                         ? 'bg-light bg-gradient'
    //                         : ''
    //                 }
    //             >
    //                 <td
    //                     // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
    //                     role="button"
    //                     tabIndex={0}
    //                     onKeyDown={() =>
    //                         item.element.code === 'Global'
    //                             ? null
    //                             : gotoDetails(item.element.code)
    //                     }
    //                     onClick={() =>
    //                         item.element.code === 'Global'
    //                             ? null
    //                             : gotoDetails(item.element.code)
    //                     }
    //                 >
    //                     {item?.element?.designation}
    //                 </td>
    //                 {periodes.map((periode) => (
    //                     <React.Fragment
    //                         key={`${periode}${item?.element?.designation}${item?.element?.code}`}
    //                     >
    //                         <td>{item?.periode?.[periode]?.previsionnel}</td>
    //                         <td>{item?.periode?.[periode]?.factuel}</td>
    //                         <td>{item?.periode?.[periode]?.ecart}</td>
    //                     </React.Fragment>
    //                 ))}
    //             </tr>
    //         );
    //     }
    //     return null;
    // };
    // const renderContent = () => (
    //     <ViewLayout>
    //         <div>
    //             <CustomDropdownLite
    //                 divClassName="col-3"
    //                 selectionClassName="form-select-sm col-3"
    //                 label="Exercice"
    //                 required
    //                 idProperty="code"
    //                 propertyToDisplay="code"
    //                 id="exerciceLiteSelectorId"
    //                 formDispatcher={formDispatcher}
    //                 formState={formState}
    //                 name="selectedExercice"
    //                 options={[
    //                     { code: '2022-2023' },
    //                     { code: '2023-2024' },
    //                     { code: '2024-2025' }
    //                 ]}
    //             />
    //         </div>
    //         <div className="aio-table-container">
    //             <table className="table table-bordered text-center table-responsive-sm">
    //                 <thead>
    //                     <tr className="bg-danger text-white">
    //                         <th rowSpan={2}>
    //                             Budget {fields.selectedExercice.code}
    //                         </th>
    //                         <th colSpan={3}>Annuel</th>
    //                         <th colSpan={3}>Janvier</th>
    //                         <th colSpan={3}>Février</th>
    //                         <th colSpan={3}>Mars</th>
    //                         <th colSpan={3}>Avril</th>
    //                         <th colSpan={3}>Mai</th>
    //                         <th colSpan={3}>Juin</th>
    //                         <th colSpan={3}>Juillet</th>
    //                         <th colSpan={3}>Août</th>
    //                         <th colSpan={3}>Septembre</th>
    //                         <th colSpan={3}>Octobre</th>
    //                         <th colSpan={3}>Novembre</th>
    //                         <th colSpan={3}>Décembre</th>
    //                     </tr>
    //                     <tr className="bg-danger text-white">
    //                         <th>Prévisionnel</th>
    //                         <th>Factuel</th>
    //                         <th>Ecart</th>
    //                         <th>Prévisionnel</th>
    //                         <th>Factuel</th>
    //                         <th>Ecart</th>
    //                         <th>Prévisionnel</th>
    //                         <th>Factuel</th>
    //                         <th>Ecart</th>
    //                         <th>Prévisionnel</th>
    //                         <th>Factuel</th>
    //                         <th>Ecart</th>
    //                         <th>Prévisionnel</th>
    //                         <th>Factuel</th>
    //                         <th>Ecart</th>
    //                         <th>Prévisionnel</th>
    //                         <th>Factuel</th>
    //                         <th>Ecart</th>
    //                         <th>Prévisionnel</th>
    //                         <th>Factuel</th>
    //                         <th>Ecart</th>
    //                         <th>Prévisionnel</th>
    //                         <th>Factuel</th>
    //                         <th>Ecart</th>
    //                         <th>Prévisionnel</th>
    //                         <th>Factuel</th>
    //                         <th>Ecart</th>
    //                         <th>Prévisionnel</th>
    //                         <th>Factuel</th>
    //                         <th>Ecart</th>
    //                         <th>Prévisionnel</th>
    //                         <th>Factuel</th>
    //                         <th>Ecart</th>
    //                         <th>Prévisionnel</th>
    //                         <th>Factuel</th>
    //                         <th>Ecart</th>
    //                         <th>Prévisionnel</th>
    //                         <th>Factuel</th>
    //                         <th>Ecart</th>
    //                     </tr>
    //                 </thead>
    //                 <tbody>
    //                     {tableData.map((item) => (
    //                         <Fragment key={`${item}`}>
    //                             {bodyRender(item)}
    //                         </Fragment>
    //                     ))}
    //                 </tbody>
    //             </table>
    //         </div>
    //         <StateShowerComponent state={fields} />
    //     </ViewLayout>
    // );
    const renderContent = () => (
        <ViewLayout>
            <ListGenericForm
                isLinkToDetail={false}
                fetchType={ONLY_FETCH}
                reduxPropertyName="exerciceFinancier"
                functionName={URL_CONST.GET_LIST_EXERCICE}
                nodeName={DB_NODE.EXERCICE_FINANCIER}
                identifier="id"
                mapper={[
                    {
                        field: 'dateDebut',
                        displayName: 'Debut',
                        position: 1,
                        isDate: true
                    },
                    {
                        field: 'dateFin',
                        displayName: 'Fin',
                        position: 2,
                        isDate: true
                    },
                    {
                        field: 'estCloturer',
                        displayName: 'Cloturer',
                        position: 4
                    },
                    {
                        field: 'dateCreation',
                        displayName: 'Création',
                        position: 5
                    }
                ]}
            />
        </ViewLayout>
    );
    return renderContent();
}
