/**
 * Composant personnalisé CustomInput
 * @param {Object} param0 object qui contient les props du composant:
 * @param {string} param0.id Identifiant unique du composant
 * @param {string} param0.placeholder Texte affiché dans le composant avant la saisie de données
 * @param {string} param0.type Type de données saisies dans le composant
 * @param {string} param0.isFloat Indique si un composant de type number accepte les valeurs deciamles, par défaut non
 * @param {string} param0.name Nom du champ du formulaire qui correspond à une propriété du state
 * @param {boolean} param0.disabled Désactive le composant ou Active le composant
 * @param {string} param0.label Texte affiché dans le label du composant
 * @param {string} param0.labelClassName Classe CSS du label
 * @param {string} param0.inputClassName Classe CSS du input du composant
 * @param {string} param0.divClassName Classe CSS du div du composant
 * @param {boolean} param0.readOnly Indique si le composant est en lecture seule ou non
 * @param {Object} param0.formState State du composant
 * @param {Function} param0.formDispatcher Fonction qui permet d'envoyer des actions au store du composant
 * @param {Object} param0.uiValidator Schema de validation du composant
 * @param {Boolean} param0.isInputGroup vrai si la dispostion doit être en colonne
 * @param {Boolean} param0.min valeur minimale du champ (type : number)
 * @param {Boolean} param0.max valeur maximale du champ (type : number)
 * @param {Function} param0.callback fonction à executer en cas de handle change si jamais elle exite
 * @param {NUmber} param0.numberAfertComma Nombre après la virgule
 * @returns {React.Component}
 */
export function CustomLiteDynamicInput({
    id,
    type = 'number',
    placeholder,
    containerClassName,
    labelClassName,
    labelStyle,
    label,
    inputClassName,
    ariaLabel,
    name,
    customproperty,
    value,
    onChange,
    inputProps = {},
    disabled
}) {
    return (
        <div className={containerClassName} id={id}>
            <span className={labelClassName} style={labelStyle}>
                {label}
            </span>
            <input
                disabled={disabled}
                aria-label={ariaLabel}
                type={type}
                step={type === 'number' ? '0.1' : null}
                name={name}
                placeholder={placeholder}
                className={inputClassName}
                value={value}
                customproperty={customproperty}
                onChange={(event) =>
                    onChange({
                        event,
                        ...inputProps
                    })
                }
            />
            <div
                style={{
                    height: '16px'
                }}
            >
                <div
                    style={{
                        height: 0
                    }}
                />
            </div>
        </div>
    );
}
