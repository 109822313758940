// For input

import { floatParser } from '@napp-inc/jnapp-util';
import { castTypeOfData } from './cast-type-of-data';

/**
 * @description: Logique handleChange pour les inputs
 * @param {Object} param0 : Tout le paramètre
 * @param {Number} param0.index : Index de l'element au plus haut niveau de l'arborescence, voir le state
 * @param {Event} param0.event : Event de l'object input de HTML
 * @param {Number} param0.indexAtSecondLevel : Index de l'element au second niveau de l'arborescence, voir le state
 * @param {Number} param0.indexAtThirdLevel : Index de l'element au troisième niveau de l'arborescence, voir le state
 * @param {Number} param0.id : Index de l'element au quatrième niveau de l'arborescence, voir le state
 */
export const handleChange = ({
    setFormFields,
    formFields,
    index,
    event,
    indexAtSecondLevel,
    indexAtThirdLevel,
    id,
    view = 'ChargeFixe',
    isFloat = true,
    numberAfertComma = 2
}) => {
    const values = [...formFields];

    if (view === 'ChargeFixe') {
        /**
         * @desc: A ce stade, nous traitons toutes les valeurs
         * au premier niveau de l'arborescence (Voir le state)
         */
        if (
            typeof index === 'number' &&
            event &&
            typeof indexAtSecondLevel !== 'number' &&
            typeof indexAtThirdLevel !== 'number' &&
            typeof id !== 'number'
        ) {
            values[index][event.target.name] = castTypeOfData({
                type: event.target.type,
                data:
                    isFloat && event.target.type === 'number'
                        ? floatParser(event.target.value, numberAfertComma)
                        : event.target.value
            });
        }
        /**
         * @desc: A ce stade, nous traitons toutes les valeurs
         * au deuxième niveau de l'arborescence (Voir le state)
         */
        if (
            typeof index === 'number' &&
            event &&
            typeof indexAtSecondLevel === 'number' &&
            typeof indexAtThirdLevel !== 'number' &&
            typeof id !== 'number'
        ) {
            values[index].details[indexAtSecondLevel][event.target.name] =
                castTypeOfData({
                    type: event.target.type,
                    data:
                        isFloat && event.target.type === 'number'
                            ? floatParser(event.target.value, numberAfertComma)
                            : event.target.value
                });
        }
        /**
         * @desc: A ce stade, nous traitons toutes les valeurs
         * au troisième niveau de l'arborescence (Voir le state)
         */
        if (
            typeof index === 'number' &&
            event &&
            typeof indexAtSecondLevel === 'number' &&
            typeof indexAtThirdLevel === 'number' &&
            typeof id !== 'number'
        ) {
            values[index].details[indexAtSecondLevel].beneficiaires[
                indexAtThirdLevel
            ][event.target.name] = castTypeOfData({
                type: event.target.type,
                data:
                    isFloat && event.target.type === 'number'
                        ? floatParser(event.target.value, numberAfertComma)
                        : event.target.value
            });
        }
        /**
         * @desc: A ce stade, nous traitons toutes les valeurs
         * au quatrième niveau de l'arborescence (Voir le state)
         */
        if (
            typeof index === 'number' &&
            event &&
            typeof indexAtSecondLevel === 'number' &&
            typeof indexAtThirdLevel === 'number' &&
            typeof id === 'number'
        ) {
            if (
                event.target.attributes?.customproperty?.nodeValue ===
                'datePaiement'
            ) {
                values[index].details[indexAtSecondLevel].beneficiaires[
                    indexAtThirdLevel
                ].periodes[id].datePaiement = castTypeOfData({
                    type: event.target.type,
                    data: event.target.value
                });
            }
            if (event.target.name === 'datePaiementString') {
                values[index].details[indexAtSecondLevel].beneficiaires[
                    indexAtThirdLevel
                ].periodes[id][event.target.name] = castTypeOfData({
                    // type: event.target.type,
                    data: event.target.value
                });
            } else {
                values[index].details[indexAtSecondLevel].beneficiaires[
                    indexAtThirdLevel
                ].periodes[id][event.target.name] = castTypeOfData({
                    type: event.target.type,
                    data:
                        isFloat && event.target.type === 'number'
                            ? floatParser(event.target.value, numberAfertComma)
                            : event.target.value
                });
            }
        }
    }
    if (view === 'ChargeVariable') {
        /**
         * @desc: A ce stade, nous traitons toutes les valeurs
         * au premier niveau de l'arborescence (Voir le state)
         */
        if (
            typeof index === 'number' &&
            event &&
            typeof indexAtSecondLevel !== 'number' &&
            typeof indexAtThirdLevel !== 'number' &&
            typeof id !== 'number'
        ) {
            values[index][event.target.name] = castTypeOfData({
                type: event.target.type,
                data:
                    isFloat && event.target.type === 'number'
                        ? floatParser(event.target.value, numberAfertComma)
                        : event.target.value
            });
        }
    }
    if (view === 'Ressource') {
        /**
         * @desc: A ce stade, nous traitons toutes les valeurs
         * au premier niveau de l'arborescence (Voir le state)
         */
        if (
            typeof index === 'number' &&
            event &&
            typeof indexAtSecondLevel !== 'number' &&
            typeof indexAtThirdLevel !== 'number' &&
            typeof id !== 'number'
        ) {
            values[index][event.target.name] = castTypeOfData({
                type: event.target.type,
                data:
                    isFloat && event.target.type === 'number'
                        ? floatParser(event.target.value, numberAfertComma)
                        : event.target.value
            });
        }
        /**
         * @desc: A ce stade, nous traitons toutes les valeurs
         * au deuxième niveau de l'arborescence (Voir le state)
         */
        if (
            typeof index === 'number' &&
            event &&
            typeof indexAtSecondLevel === 'number' &&
            typeof indexAtThirdLevel !== 'number' &&
            typeof id !== 'number'
        ) {
            values[index].details[indexAtSecondLevel][event.target.name] =
                castTypeOfData({
                    type: event.target.type,
                    data:
                        isFloat && event.target.type === 'number'
                            ? floatParser(event.target.value, numberAfertComma)
                            : event.target.value
                });
        }
        /**
         * @desc: A ce stade, nous traitons toutes les valeurs
         * au troisième niveau de l'arborescence (Voir le state)
         */
        if (
            typeof index === 'number' &&
            event &&
            typeof indexAtSecondLevel === 'number' &&
            typeof indexAtThirdLevel === 'number' &&
            typeof id !== 'number'
        ) {
            values[index].details[indexAtSecondLevel].periodes[
                indexAtThirdLevel
            ].devises[0][event.target.name] = castTypeOfData({
                type: event.target.type,
                data:
                    isFloat && event.target.type === 'number'
                        ? floatParser(event.target.value, numberAfertComma)
                        : event.target.value
            });
        }
    }
    setFormFields(values);
};
