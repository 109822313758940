import { useCallback, useRef } from 'react';
import { TIME, TODAY } from '@napp-inc/jnapp-util';
import { URL_CONST, useGetReportDetail } from '../../../../util';
import { ReduxReportTemplate } from '../../template';
import { CustomDropdown, CustomRow } from '../../../../components';

export function RapportRegularisationProduitForm() {
    const devises = useRef([]);
    const { formState, formDispatcher } = useGetReportDetail({
        defaultFields: {
            selectedStockSuperDealer: {},
            selectedSelector: { code: 'ALL', designation: 'Tous' },
            deviseFilter: [],
            listHeaders: []
        }
    });
    const { fields } = formState;
    const getType = (type) => {
        if (type === 'ImputationStock') return 'Stock';
        if (type === 'Imputation') return 'Caisse';
        return '';
    };
    const listFormater = useCallback(
        (donnees) => {
            const resulat = (donnees?.[0]?.listReturn || []).map((item) => ({
                organisation: item?.detail?.valeurTypeProprietaire?.designation,
                imputation: item?.detail?.valeurTypeEntite?.designation,
                type: getType(item?.detail?.typeEntite),
                entree: item?.entree || 0,
                sortie: item?.sortie || 0,
                resultat: item?.total || 0,
                devise: item?.detail?.devise
            }));
            // formDispatcher({
            //     type: FORM_CONST.FIELD_CHANGE,
            //     payload: {
            //         name: 'deviseFilter',
            // data:
            //     }
            // });
            devises.current = Object.keys(donnees?.[0]?.devises || {}).map(
                (item) => ({ code: item, designation: item })
            );
            // const groupedByOrganisation = Array.groupByProperty({
            //     array: resulat,
            //     property: 'organisation'
            // });
            // TODO: grouped elment to render in complexe table
            // const groupedByOrganisationMapped = Object.keys(
            //     groupedByOrganisation || {}
            // ).map((item) => ({
            //     organisation: item,
            //     data: groupedByOrganisation[item]
            // }));
            return resulat
                .filter((item) =>
                    fields.selectedSelector.code === 'ALL'
                        ? true
                        : item.devise === fields.selectedSelector.code
                )
                .filter((item) => item.entree || item.sortie);
        },
        [fields.selectedSelector.code]
    );

    const cutomTableHeader = () => (
        <tr className="border text-center text-justify">
            <th className="border">#</th>
            <th className="border">Organisation</th>
            <th className="border">Type</th>
            <th className="border">Imputation</th>
            <th className="border">Devise</th>
            <th className="border">Entrée</th>
            <th className="border">Sortie</th>
            <th className="border">Résultat</th>
        </tr>
    );

    const mapper = () => [
        {
            displayName: 'Organisation',
            field: 'organisation'
        },
        {
            displayName: 'Type',
            field: 'type'
        },
        {
            displayName: 'Imputation',
            field: 'imputation'
        },
        {
            displayName: 'Devise',
            field: 'devise'
        },
        {
            displayName: 'Entrée',
            field: 'entree',
            isTotal: true
        },
        {
            displayName: 'Sortie',
            field: 'sortie',
            isTotal: true
        },
        {
            displayName: 'Résultat',
            field: 'resultat',
            isTotal: true
        }
    ];

    const titleToRender = (code) => {
        if (code === 'ALL') return 'Tous les comptes';
        return `Comptes ${code}`;
    };

    return (
        <>
            {/* <StateShowerComponent state={formState?.fields} /> */}
            <ReduxReportTemplate
                isGroupedRow
                cutomTableHeader={cutomTableHeader}
                listTitle={titleToRender(fields.selectedSelector.code)}
                dynamicNodeName={`evolutionsGainPerteChange-${TIME.utc000000(
                    TODAY.at000000()
                )}-${TIME.utc235959(TODAY.at235959())}`}
                formState={formState}
                formDispatcher={formDispatcher}
                functionName={URL_CONST.GET_LIST_COMPTE_USER}
                listFormater={listFormater}
                formater={(data) => [data]}
                mapper={mapper()}
                isAddTotalTr
                payload={{
                    dateDebut: TIME.utc000000(TODAY.at000000()),
                    dateFin: TIME.utc235959(TODAY.at235959()),
                    typeEntite: 'Journalier',
                    type: 'Imputation',
                    // estObjet: true,
                    estOrganisation: true,
                    estAllOrganisation: true,
                    estNotDate: true
                }}
                defaultPayloadMapperValues={{
                    typeEntite: 'Journalier',
                    type: 'Imputation',
                    estObjet: true,
                    typesEntite: ['VenteSuperDealer', 'VenteRevendeur'],
                    estOrganisation: true,
                    estAllOrganisation: true
                }}
                payloadMapper={[
                    {
                        name: 'dateDebut',
                        property: 'dateDebut',
                        isRequired: true,
                        displayName: 'Date début'
                    },
                    {
                        name: 'dateFin',
                        property: 'dateFin',
                        isRequired: true,
                        displayName: 'Date fin'
                    }
                ]}
            >
                <CustomRow>
                    <CustomDropdown
                        isSort={false}
                        options={[
                            { code: 'ALL', designation: 'Tous' },
                            ...devises.current
                        ]}
                        label="Valeur : "
                        labelClassName="col-12"
                        divClassName="col-12"
                        formDispatcher={formDispatcher}
                        name="selectedSelector"
                        id="selectedSelectortypeExpressionSelector"
                        formState={formState}
                        idProperty="code"
                    />
                </CustomRow>
            </ReduxReportTemplate>
        </>
    );
}
