import { GENERIC_ITEM_ACTIONS_CREATOR } from '@napp-inc/jnapp-redux';
import { submitPutAction } from '@napp-inc/jnapp-hook';
import { CODE_SERVICE, ETAT, FORM_CONST } from '@napp-inc/jnapp-util';
import {
    ConditionalRenderingWrapper,
    CustomCol,
    CustomConfirmButton,
    CustomRow,
    CustomTextAreaInput,
    DetailWrapper,
    UserServiceWrapper
} from '../../../../components';
import { URL_CONST, useGetDetailForm } from '../../../../util';
import { schema } from './detail-fields';
import { REDUX_NODE_NAME } from '../../../../redux';
import { formater } from './util';
import { useUpdateInitialState } from '../../../../util/hook';

export function DetailServiceClientFormulaireForm() {
    const { id, reduxDispatcher, entity, idToken, formState, formDispatcher } =
        useGetDetailForm({
            defaultFields: { texteCorrige: '', actionEncours: '' },
            nodeName: 'serviceClientForm'
        });

    const onSuccess = ({ element }) => {
        reduxDispatcher(
            GENERIC_ITEM_ACTIONS_CREATOR.ajouterItem(
                formater(element),
                REDUX_NODE_NAME.SERVICE_CLIENT_FORM
            )
        );
    };

    const { fields, form } = formState;

    useUpdateInitialState({
        formDispatcher,
        formState,
        condition:
            !entity?.texteTraite &&
            entity?.type === `UrgenceCommunication` &&
            entity?.etat === ETAT.ETAT_ENCOURS &&
            !fields?.texteCorrige,
        entity,
        paths: ['description'],
        fieldsKeys: ['texteCorrige']
    });

    const updateEtat = (etat) => {
        const payload = {
            idFormulaireServiceClient: entity?.id,
            etat
        };
        if (fields?.texteCorrige) {
            payload.elemUpdate = {
                texteTraite: fields?.texteCorrige
            };
        }
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'actionEncours',
                data: etat
            }
        });
        formDispatcher(
            submitPutAction({
                idToken,
                functionName: URL_CONST.PUT_FORMULAIRE_SERVICE_CLIENT,
                payload,
                fields,
                reduxNodeName: REDUX_NODE_NAME.SERVICE_CLIENT_FORM,
                reduxDispatcher,
                onSuccess
            })
        );
    };
    const renderContent = () => (
        <DetailWrapper
            functionName={URL_CONST.GET_FORMULAIRE_SERVICE_CLIENT_BY_ID}
            nodeName={REDUX_NODE_NAME.SERVICE_CLIENT_FORM}
            formDispatcher={formDispatcher}
            formState={formState}
            reduxDispatcher={reduxDispatcher}
            subtitle="Detail"
            entity={entity}
            id={id}
            schema={schema}
            idToken={idToken}
        >
            <UserServiceWrapper
                services={[
                    { code: 'test' },
                    CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
                        .SERVICE_CLIENT.APPROUVER_FORMULAIRE_SERVICE_CLIENT
                ]}
            >
                <CustomRow
                    isShouldBeRendered={
                        entity?.type === `UrgenceCommunication` &&
                        entity?.etat === ETAT.ETAT_ENCOURS
                    }
                >
                    <CustomTextAreaInput
                        isInputGroup={false}
                        label="Correction*: "
                        labelClassName="col-12 col-sm-2"
                        divClassName="col-12 col-sm-10"
                        placeholder="Saisissez le texte corrigé..."
                        formDispatcher={formDispatcher}
                        name="texteCorrige"
                        id="texteCorrigeCreationFormulaireServiceClientId"
                        // uiValidator={uiValidator}
                        formState={formState}
                        rows={2}
                    />
                    <div className="form-text offset-sm-2">
                        Veuillez saisir un texte, si celui-ci doit être modifié.
                    </div>
                </CustomRow>
            </UserServiceWrapper>
            <UserServiceWrapper
                services={[
                    { code: 'test' },
                    CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
                        .SERVICE_CLIENT.VALIDER_FORMULAIRE_SERVICE_CLIENT
                ]}
            >
                <CustomRow>
                    <ConditionalRenderingWrapper
                        isShouldBeRendered={entity?.etat !== ETAT.ETAT_VALIDER}
                    >
                        <CustomCol className="offset-6 offset-md-8 col-3 col-md-2">
                            <CustomConfirmButton
                                type="button"
                                text="Annuler"
                                className="btn-danger"
                                confirmOnClick={() =>
                                    updateEtat(ETAT.ETAT_ANNULER)
                                }
                                disabled={
                                    form.status ===
                                    FORM_CONST.MODIFICATION_EN_COURS
                                }
                                isActionEncours={
                                    fields.actionEncours === ETAT.ETAT_ANNULER
                                }
                                confirmText="Poursuivre"
                                cancelText="Abandonner"
                                header="Confirmation Annulation"
                            />
                        </CustomCol>
                    </ConditionalRenderingWrapper>
                    <UserServiceWrapper
                        services={[
                            { code: 'test' },
                            CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
                                .SERVICE_CLIENT
                                .APPROUVER_FORMULAIRE_SERVICE_CLIENT
                        ]}
                    >
                        <ConditionalRenderingWrapper
                            isShouldBeRendered={
                                entity?.etat === ETAT.ETAT_ENCOURS &&
                                !!fields.texteCorrige
                            }
                        >
                            <CustomCol className="col-3 col-md-2">
                                <CustomConfirmButton
                                    type="button"
                                    text="Attente"
                                    className="btn-info"
                                    confirmOnClick={() =>
                                        updateEtat(ETAT.ETAT_ATTENTE_VALIDATION)
                                    }
                                    disabled={
                                        form.status ===
                                        FORM_CONST.MODIFICATION_EN_COURS
                                    }
                                    isActionEncours={
                                        fields.actionEncours ===
                                        ETAT.ETAT_ATTENTE_VALIDATION
                                    }
                                />
                            </CustomCol>
                        </ConditionalRenderingWrapper>
                    </UserServiceWrapper>
                    <UserServiceWrapper
                        services={[
                            { code: 'test' },
                            CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
                                .SERVICE_CLIENT
                                .VALIDER_FORMULAIRE_SERVICE_CLIENT
                        ]}
                    >
                        <ConditionalRenderingWrapper
                            isShouldBeRendered={
                                (entity?.type !== `UrgenceCommunication` &&
                                    entity?.etat === ETAT.ETAT_ENCOURS) ||
                                (entity?.etat ===
                                    ETAT.ETAT_ATTENTE_VALIDATION &&
                                    !fields.texteCorrige)
                            }
                        >
                            <CustomCol className="col-3 col-md-2">
                                <CustomConfirmButton
                                    type="button"
                                    text="Valider"
                                    className="btn-success"
                                    confirmOnClick={() =>
                                        updateEtat(ETAT.ETAT_VALIDER)
                                    }
                                    disabled={
                                        form.status ===
                                        FORM_CONST.MODIFICATION_EN_COURS
                                    }
                                    isActionEncours={
                                        fields.actionEncours ===
                                        ETAT.ETAT_VALIDER
                                    }
                                />
                            </CustomCol>
                        </ConditionalRenderingWrapper>
                    </UserServiceWrapper>
                </CustomRow>
            </UserServiceWrapper>
        </DetailWrapper>
    );
    return renderContent();
}
