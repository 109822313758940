import { useCallback } from 'react';

function transformer({ data }) {
    return data.map((item) => {
        if (item.element.code && item.estRempliAutomatique) {
            const newElement = {
                element: item.element,
                typeMoyenPaiement: item.typeMoyenPaiement
            };
            if (item.idCaisse) {
                newElement.idCaisse = item.idCaisse;
            }
            return newElement;
        }
        if (item.element.code && !item.estRempliAutomatique) {
            const {
                caisseDesignation,
                estQuantite,
                estRempliAutomatique,
                isTooggled,
                isVisible,
                ...rest
            } = item;
            const newDetails = item?.details?.map((detail) => {
                const {
                    secteur: detailSecteur,
                    idCaisse: detailIdCaisse,
                    prix: detailPrix
                } = detail;
                const newBeneficiairesByPeriode = {};
                const newPeriode = {};

                detail?.beneficiaires?.forEach((beneficiaire) => {
                    beneficiaire?.periodes?.forEach((periode) => {
                        const { dateDebut, dateFin, datePaiement, mois } =
                            periode;
                        if (!newPeriode[periode?.dateDebut]) {
                            newPeriode[periode?.dateDebut] = [
                                { dateDebut, dateFin, datePaiement, mois }
                            ];
                        }
                        if (!newBeneficiairesByPeriode[periode?.dateDebut]) {
                            newBeneficiairesByPeriode[periode?.dateDebut] = [];
                        }
                        newBeneficiairesByPeriode[periode?.dateDebut].push({
                            typeEntite: beneficiaire.typeEntite,
                            valeurTypeEntite: beneficiaire.valeurTypeEntite,
                            quantite: periode.quantite,
                            prix: beneficiaire.prix,
                            idCaisse: beneficiaire.idCaisse
                        });
                    });
                });
                return {
                    secteur: detailSecteur,
                    idCaisse: detailIdCaisse,
                    prix: detailPrix,
                    periodes: Object.keys(newBeneficiairesByPeriode).map(
                        (dateDebut) => ({
                            ...newPeriode[dateDebut][0],
                            beneficiaires: newBeneficiairesByPeriode[dateDebut]
                        })
                    )
                };
            });
            // console.log(newDetails[0]);
            return {
                ...rest,
                details: newDetails
            };
        }
        return {};
    });
}

function transformerVariable({ data, fields }) {
    const resultat = data.map((item) => {
        const model = {
            element: {
                code: item.element.code
            },
            typeMoyenPaiement:
                fields.modePaiement.code || item.modePaiement.code
        };
        if (item.tauxCroissance) {
            model.tauxCroissance = item.tauxCroissance;
        }
        if (item.idCaisse) {
            model.idCaisse = fields.selectedCaisse.id || item.idCaisse;
        }
        return model;
    });
    return resultat;
}

function transformerRessource({ data }) {
    const resultat = data.map((item) => {
        if (!item.estObjectif) {
            return {
                element: item.element,
                tauxCroissance: item.tauxCroissance
            };
        }
        return item;
    });
    return resultat;
}

export function useGeneratePayloadAndDataList({
    formFields,
    tablePreviewData,
    view = 'ChargeFixe',
    fields
}) {
    const result = useCallback(() => {
        if (
            formFields.length < 0 ||
            (formFields.length === 1 && !formFields[0].element.code)
        )
            return [];
        const dataFiltered = formFields.filter((item) => item.element.code);
        let newListMapped = [];
        if (view === 'ChargeFixe') {
            newListMapped = transformer({ data: dataFiltered });
        }
        if (view === 'ChargeVariable') {
            newListMapped = transformerVariable({ data: dataFiltered, fields });
        }
        if (view === 'Ressource') {
            newListMapped = transformerRessource({ data: dataFiltered });
        }
        // const newListMapped = dataFiltered.map((item) => {
        //     if (item.element.code && item.estRempliAutomatique) {
        //         return {
        //             element: item.element,
        //             typeMoyenPaiement: item.typeMoyenPaiement
        //         };
        //     }
        //     if (item.element.code && !item.estRempliAutomatique) {
        //         const newelement = {
        //             element: item.element,
        //             typeMoyenPaiement: item.typeMoyenPaiement,
        //             details: []
        //         };
        //         if (item.typeMoyenPaiement === 'Cash') {
        //             newelement.idCaisse = item.idCaisse;
        //         }
        //         if (item.estQuantite) {
        //             newelement.prix = item.prix;
        //         }
        //         if (item?.details?.length) {
        //             const elementDetails = item.details.map((detail) => {
        //                 const newDetail = {
        //                     secteur: { code: detail.secteur.code },
        //                     periodes: []
        //                 };
        //                 if (detail?.periodes?.length) {
        //                     const DetailPeriodes = detail.periodes.map(
        //                         (periode) => {
        //                             const newPeriodes = {
        //                                 dateDebut: periode.dateDebut,
        //                                 dateFin: periode.dateFin,
        //                                 datePaiement: periode.datePaiement,
        //                                 mois: periode.mois,
        //                                 beneficiaires: []
        //                             };
        //                             if (periode.beneficiaires.length) {
        //                                 const beneficiaireArray =
        //                                     periode.beneficiaires.map(
        //                                         (beneficiaire) => {
        //                                             const newBenficiaire = {
        //                                                 typeEntite:
        //                                                     beneficiaire.typeEntite,
        //                                                 valeurTypeEntite:
        //                                                     beneficiaire.valeurTypeEntite
        //                                             };
        //                                             if (item.estQuantite) {
        //                                                 newBenficiaire.quantite =
        //                                                     beneficiaire.quantite;
        //                                             }
        //                                             if (!item.estQuantite) {
        //                                                 newBenficiaire.montant =
        //                                                     beneficiaire.montant;
        //                                             }
        //                                             return newBenficiaire;
        //                                         }
        //                                     );
        //                                 newPeriodes.beneficiaires =
        //                                     beneficiaireArray;
        //                             }
        //                             return newPeriodes;
        //                         }
        //                     );
        //                     newDetail.periodes = DetailPeriodes;
        //                 }
        //                 return newDetail;
        //             });
        //             newelement.details = elementDetails;
        //         }
        //         return {
        //             ...newelement
        //         };
        //     }
        //     return {};
        // });
        return {
            payloadList: newListMapped || [],
            tableList: tablePreviewData({ list: dataFiltered }) || []
        };
    }, [formFields, tablePreviewData, view, fields]);
    return result();
}
