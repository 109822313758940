import React from 'react';

export function CustomLiteDynamicDropdown({
    id,
    containerClassName,
    labelClassName,
    labelStyle,
    label,
    onChange,
    selectClassName,
    ariaLabel,
    name,
    customproperty,
    customtypecharge,
    value,
    options,
    displayProperty = 'designation',
    idProperty = 'code',
    handleProps = {},
    hiddenOption = 'Selectionnez...',
    isSort = false,
    disabled = false,
    customtypemoyenpaiement,
    customprops = {}
}) {
    const selectionOptions = isSort
        ? options.sort((a, b) =>
              a?.[displayProperty]?.localeCompare(b?.[displayProperty])
          )
        : options;
    return (
        <div className={containerClassName} id={id}>
            <span style={labelStyle} className={labelClassName}>
                {label}
            </span>
            <select
                onChange={(event) =>
                    onChange({
                        event,
                        ...handleProps
                    })
                }
                className={selectClassName}
                aria-label={ariaLabel}
                name={name}
                customproperty={customproperty}
                customtypecharge={customtypecharge}
                customtypemoyenpaiement={customtypemoyenpaiement}
                value={value}
                disabled={disabled}
                customprops={{ ...customprops }}
            >
                {/* <option hidden>{hiddenOption}</option> */}
                <option value="">{hiddenOption}</option>
                {selectionOptions.map((element) => (
                    <option
                        value={element?.[idProperty]}
                        key={element?.[idProperty]}
                    >
                        {element?.[displayProperty]}
                    </option>
                ))}
            </select>
            <div style={{ height: '16px' }}>
                <div
                    style={{
                        height: 0
                    }}
                />
            </div>
        </div>
    );
}
