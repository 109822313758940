import { GENERIC_ACTIONS_TYPE } from '@napp-inc/jnapp-util';
import { useFetchAndListening, useGetUserDetail } from '@napp-inc/jnapp-hook';
import { useDispatch, useSelector } from 'react-redux';
import { CustomDropdown } from '../dropdown';
import { CustomAlert } from '../alert';
import { CenteredSpinner } from '../placeholder';
import { URL_CONST } from '../../util';

/**
 * Construit une dropdown où l'on peut sélectionner les caisses
 * @param {Object} param0
 * @param {Function} param0.formDispatcher form dispatcher du parent
 * @param {Object} param0.formState form state du parent
 * @param {String} param0.label Label du dropdown, par défaut "Caisse : "
 * @param {String} param0.propertyName propriété où la valeur de la caisse selectionnée est enregistrée dans le form state, par defaut "selectedCaisse"
 * @param {String} param0.labelClassName className du label
 * @param {String} param0.divClassName className du div
 * @param {String} param0.propertyToDisplay nom de la propriété à afficher dans le dropdown, par défaut : "designation"
 * @param {String} param0.idProperty propriété id à utiliser, par défaut : "id"
 * @returns {React.Component}
 */
export function GainPerteVenteSelector({
    formDispatcher,
    formState,
    label = 'Compte : ',
    propertyName = 'selectedCompte',
    labelClassName = '',
    divClassName = 'col-12',
    propertyToDisplay = 'designation',
    idProperty = 'id',
    payload = {
        estOrganisation: true,
        estAllOrganisation: true,
        type: 'Vente'
    },
    functionName = URL_CONST.GET_LIST_COMPTE_USER,
    dynamicNodeName = 'compteGainPerteVente',
    defaultOption = 'Choisir Compte',
    formater
}) {
    const reduxDispatcher = useDispatch();
    /**
     * Get data on redux store
     */
    const { firebaseUser, dynamicNode, dynamicEtatNode } = useSelector(
        (reduxState) => ({
            firebaseUser: reduxState.firebaseUser,
            dynamicNode: reduxState.dynamicNode,
            dynamicEtatNode: reduxState.dynamicEtatNode
        })
    );
    const { idToken } = useGetUserDetail({ firebaseUser });
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                dynamicNodeName,
                isDynamicNode: true,
                functionName,
                etat: dynamicEtatNode[dynamicNodeName]?.etat,
                payload
            }
        ]
    });
    const renderContent = () => {
        if (
            dynamicEtatNode[dynamicNodeName]?.etat ===
            GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_SUCCESS
        ) {
            return (
                <CustomDropdown
                    defaultOption={defaultOption}
                    options={
                        formater
                            ? formater(dynamicNode[dynamicNodeName] || [])
                            : dynamicNode[dynamicNodeName] || []
                    }
                    formDispatcher={formDispatcher}
                    formState={formState}
                    label={label}
                    labelClassName={labelClassName}
                    divClassName={divClassName}
                    name={propertyName}
                    id={`${propertyName}${dynamicNodeName}`}
                    propertyToDisplay={propertyToDisplay}
                    idProperty={idProperty}
                />
            );
        }
        if (
            dynamicEtatNode[dynamicNodeName]?.etat ===
            GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_ECHEC
        ) {
            return (
                <CustomAlert error="une erreur est survenue lors du chargement des caisses" />
            );
        }
        return <CenteredSpinner />;
    };
    return renderContent();
}
