export const schema = [
    {
        title: 'Marchand',
        description: 'marchand.designation',
        className: 'border-bottom'
    },
    {
        title: 'Reference',
        description: 'numeroReference',
        className: 'border-bottom'
    },
    {
        title: 'Quantité',
        description: 'quantite',
        className: 'border-bottom'
    },
    {
        title: 'Montant',
        description: 'montant',
        className: 'border-bottom'
    },
    {
        title: 'Paiement',
        description: 'typeEntite',
        className: 'border-bottom'
    },
    {
        title: 'Type Facturation',
        callback: (item) => (item?.estSim ? 'SIM' : 'FLASH'),
        className: 'border-bottom'
    },
    {
        title: 'Devise',
        description: 'valeurTypeEntite.devise',
        className: 'border-bottom'
    },
    {
        title: 'Etat',
        description: 'etat',
        className: 'border-bottom'
    },
    {
        title: 'Creation',
        description: 'dateCreation',
        className: 'border-bottom'
    }
];
