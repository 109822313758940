// import { BackComponent } from '../../../../components';
import { ChargesFixes, BudgetRessources, ChargesVariables } from '../common';

export function CreationBudgetElaborationPrevisionnelForm({
    typeBudget = 'Previsionnel',
    isAdditionnel = false,
    isCompletion = false,
    isFixe = false,
    isVariable = false,
    isRessource = false
}) {
    return (
        <div className="container-fluid">
            {/* <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <BackComponent className="btn btn-primary" />
                <h3>Budget Présionnel</h3>
            </div> */}
            {isFixe ? (
                <>
                    {/* <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                        <BackComponent className="btn btn-primary" />
                        <h3>Budget Présionnel</h3>
                    </div> */}
                    <ChargesFixes
                        typeBudget={typeBudget}
                        isAdditionnel={isAdditionnel}
                        isCompletion={isCompletion}
                    />
                </>
            ) : null}
            {isVariable ? (
                <>
                    {/* <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                        <BackComponent className="btn btn-primary" />
                        <h3>Budget Présionnel</h3>
                    </div> */}
                    <ChargesVariables
                        typeBudget={typeBudget}
                        isAdditionnel={isAdditionnel}
                        isCompletion={isCompletion}
                    />
                </>
            ) : null}
            {isRessource ? (
                <>
                    {/* <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                        <BackComponent className="btn btn-primary" />
                        <h3>Budget Présionnel</h3>
                    </div> */}
                    <BudgetRessources
                        typeBudget={typeBudget}
                        isAdditionnel={isAdditionnel}
                        isCompletion={isCompletion}
                    />
                </>
            ) : null}
        </div>
    );
}
