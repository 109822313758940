import {
    fetchElement,
    submitPostAction,
    useFetchAndListening,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    BENEFICIAIRE,
    BUDGET_AMENDEMENT,
    BUDGET_ELABORATION,
    DB_NODE,
    FORM_CONST,
    getPeriodesOfYear,
    MOYEN_PAIEMENT
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    CustomAddRemoveButton,
    CustomAlert,
    CustomButtonLoader,
    CustomCol,
    CustomCollapse,
    CustomDropdown,
    CustomInput,
    CustomInputDate,
    CustomLabel,
    CustomRadioButton,
    CustomRow,
    FormWrapper,
    InputSearchGroup,
    SimpleTable
} from '../../../../components';
import { URL_CONST } from '../../../../util';
import { REDUX_NODE_NAME } from '../../../../redux';
import {
    buildingObjectBySecteur,
    buildingOnlyByCharge,
    buildingPeriodesByChargeAndSecteur
} from '../util';
import { getCustomUrl } from '../../../../helpers';

export function AmendementElaborationCharge({ formState, formDispatcher }) {
    const { id } = useParams();
    const reduxDispatcher = useDispatch();
    const {
        firebaseUser,
        entity,
        chargesFixe,
        etatCharge,
        secteursNapp,
        secteursKinDB,
        etatSecteurs,
        directions,
        etatDirections,
        caisses,
        etatCaisse
    } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        entity: reduxState.elaborations.find((element) => element.id === id),
        chargesFixe: reduxState.chargesFixe,
        etatCharge: reduxState.etat.chargesFixe.etat,
        etatSecteurs: reduxState.etat.secteurs.etat,
        secteursNapp: reduxState.secteurs.filter(
            (item) => item?.direction?.estNapp
        ),
        secteursKinDB: reduxState.secteurs.filter(
            (item) => !item?.direction?.estNapp
        ),
        directions: reduxState.directions,
        etatDirections: reduxState.etat.directions.etat,
        caisses: reduxState.caissesSectorielle.map((item) => ({
            ...item,
            designation: `${item.valeurTypeEntite.designation}-${item.devise}`
        })),
        etatCaisse: reduxState.etat.caissesSectorielle.etat
    }));
    const { idToken } = useGetUserDetail({ firebaseUser });
    const { fields, form, elements } = formState;

    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_CHARGE_FIXE,
                nodeName: REDUX_NODE_NAME.CHARGE_FIXE,
                etat: etatCharge
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_DIRECTION, // Toutes les directions
                nodeName: REDUX_NODE_NAME.DIRECTION,
                etat: etatDirections
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_SECTEUR_CHARGE, // secteur Napp
                nodeName: REDUX_NODE_NAME.SECTEUR,
                etat: etatSecteurs
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_CAISSE_SECTORIELLE,
                nodeName: REDUX_NODE_NAME.CAISSE_SECTORIELLE,
                etat: etatCaisse
            }
        ]
    });

    const typeOfCharge = chargesFixe.find(
        (item) => item?.code === fields?.selectedCharge?.code
    ) || { estRempliAutomatique: false };

    const periodes =
        getPeriodesOfYear({
            startOf: entity?.budget?.dateDebut,
            endOf: entity?.budget?.dateFin
        }).map((item) => ({
            code: item.dateDebut,
            designation: item.mois,
            ...item
        })) || [];

    const targetedMonth = periodes.length
        ? periodes.find(
              (item) => item.designation === fields.selectedMonth.designation
          )
        : {};

    /**
     * @description fonction pour fetcher les beneficiaires
     */
    const onSearchBeneficiaire = () => {
        const { status: etat } = elements.beneficiaires;
        formDispatcher(
            fetchElement({
                idToken,
                functionName: URL_CONST.GET_LIST_BENEFICIAIRES,
                elementPropertyName: 'beneficiaires',
                etat,
                payload: {
                    typeEntite: fields.selectedTypeBeneficiaire?.code,
                    value: fields.searchInput
                },
                reduxDispatcher
            })
        );
    };

    /**
     * @description action pour rajouter le partenaires et le montant à payer
     */
    const addBeneficiaire = () => {
        if (
            !fields.selectedBeneficiaire.id &&
            (!fields.quantiteParBeneficiaire || !fields.montantParBeneficiaire)
        )
            return;

        const propertyToAdd = {};
        if (typeOfCharge.estQuantite)
            propertyToAdd.quantite = fields.quantiteParBeneficiaire;
        if (!typeOfCharge.estQuantite)
            propertyToAdd.montant = fields.montantParBeneficiaire;

        const data = {
            id: fields.selectedBeneficiaire.id,
            typeEntite: fields.selectedTypeBeneficiaire.code,
            valeurTypeEntite: {
                designation: fields.selectedBeneficiaire.designation,
                reference: fields.selectedBeneficiaire.id
            },
            ...propertyToAdd
        };
        formDispatcher({
            type: FORM_CONST.ADD_ITEM,
            payload: {
                identifier: 'id',
                field: 'beneficiaireAndMontant',
                data
            }
        });
    };

    /**
     * @description action pour rajouter les elements qui partage le même secteur
     */
    const addEntireChargeElaboration = () => {
        // if (!fields.selectedBeneficiaire.id || !fields.quantiteParBeneficiaire)
        //     return;
        const newPaiement = new Date(fields.datePaiement);
        const propertyToAdd = {};
        const montantByBeneficiaire = fields.beneficiaireAndMontant.map(
            (item) => ({
                ...item,
                valeurTypeEntite: { reference: item.valeurTypeEntite.reference }
            })
        );
        if (fields.modePaiement.code === 'Cash')
            propertyToAdd.idCaisse = fields.selectedCaisseSectorielle.id;
        if (!typeOfCharge?.estRempliAutomatique) {
            propertyToAdd.beneficiaires = montantByBeneficiaire;
            propertyToAdd.secteur = { code: fields.selectedSecteur.code };
        }
        if (typeOfCharge.estQuantite)
            propertyToAdd.prixCharge = fields.prixCharge;
        const data = {
            id: `${fields.selectedCharge.code}${fields.selectedSecteur.code}${fields.selectedDirection.code}`,
            charge: fields.selectedCharge.code,
            typeMoyenPaiement: fields.modePaiement.code,
            dateDebut: targetedMonth.dateDebut,
            dateFin: targetedMonth.dateFin,
            datePaiement: newPaiement.valueOf(),
            ...propertyToAdd
        };
        // formDispatcher({
        //     type: FORM_CONST.ADD_ITEM,
        //     payload: {
        //         identifier: 'id',
        //         field: 'chargeElaboration',
        //         data
        //     }
        // });
        /**
         * @description: Je réinitialise un ensemble des propriétés du state
         */
        formDispatcher({
            type: FORM_CONST.REINITIALISER,
            payload: {
                initialState: {
                    ...formState,
                    fields: {
                        ...formState.fields,
                        beneficiaireAndMontant: [],
                        chargeElaboration: fields.chargeElaboration
                            .filter((item) => item.id !== data.id)
                            .concat(data)
                    }
                }
            }
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { chargeElaboration } = fields;
        const payloadToAdd = {};
        if (fields.typeOperation === BUDGET_ELABORATION) {
            payloadToAdd.idElaborationAmendement = id;
        }

        const list = buildingOnlyByCharge(
            buildingObjectBySecteur(
                buildingPeriodesByChargeAndSecteur(chargeElaboration)
            )
        );
        const payload = {
            ...payloadToAdd,
            periode: {
                dateDebut: entity?.budget?.dateDebut,
                dateFin: entity?.budget?.dateFin
            },
            newElaborationAmendement: {
                typeEntite:
                    fields.typeOperation === BUDGET_ELABORATION
                        ? BUDGET_ELABORATION
                        : BUDGET_AMENDEMENT,
                typeProprietaire: entity?.typeProprietaire,
                estVariable: entity?.estVariable,
                estPrevisionnel: entity?.estPrevisionnel,
                type: entity?.type
            },
            list
        };
        // console.log(JSON.stringify(payload));
        formDispatcher(
            submitPostAction({
                idToken,
                customUrl: getCustomUrl({
                    functionName: URL_CONST.PUT_ELABORATION_AMENDEMENT_V2
                }),
                // functionName: URL_CONST.POST_ELABORATION_AMENDEMENT,
                reduxNodeName: DB_NODE.ELABORATION_AMENDEMENT,
                payload,
                fields,
                reduxDispatcher
                // uiValidator
            })
        );
    };

    const newListBenefinciaires = (listes = []) => {
        if (!listes?.length) return [];
        return listes.map((item) => ({
            ...item,
            designation: `${item.personne.prenom} ${item.personne.nom}`
        }));
    };

    const renderContent = () => (
        <FormWrapper
            onSubmit={handleSubmit}
            formState={formState}
            formDispatcher={formDispatcher}
            entity={entity}
            listDependencies={[
                {
                    dependency: 'directions',
                    etat: etatDirections
                },
                {
                    dependency: 'secteurs',
                    etat: etatSecteurs
                },
                {
                    dependency: 'charges',
                    etat: etatCharge
                },
                {
                    dependency: 'caisses',
                    etat: etatCaisse
                }
            ]}
        >
            <CustomRow>
                <h1 className="lead">Elaboration Charges et Ressources</h1>
            </CustomRow>
            <CustomRow>
                <CustomLabel
                    text="Type Opération*"
                    className="col-12 col-md-2"
                />
                <CustomRadioButton
                    divClassName="col-6 col-sm-3 ms-3"
                    label="Amendement"
                    id="typeOperationAmendementID"
                    name="typeOperation"
                    value={BUDGET_AMENDEMENT}
                    formDispatcher={formDispatcher}
                    // uiValidator={uiValidator}
                    formState={formState}
                />
                <CustomRadioButton
                    divClassName="col-6 col-sm-3"
                    label="Compléter Elaboration"
                    id="typeOperationElaborationID"
                    name="typeOperation"
                    value={BUDGET_ELABORATION}
                    formDispatcher={formDispatcher}
                    // uiValidator={uiValidator}
                    formState={formState}
                />
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    options={chargesFixe}
                    formDispatcher={formDispatcher}
                    formState={formState}
                    defaultOption="Sélectionner la charge"
                    label="Charge*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    name="selectedCharge"
                    id="selectedChargeId"
                    // uiValidator={uiValidator}
                />
            </CustomRow>
            {typeOfCharge?.estQuantite ? (
                <CustomRow>
                    <CustomInput
                        isInputGroup={false}
                        label="Prix Unitaire*"
                        labelClassName="col-12 col-sm-2"
                        divClassName="col-12 col-sm-10"
                        placeholder="Saisissez le montant"
                        formDispatcher={formDispatcher}
                        type="number"
                        name="prixCharge"
                        id="prixChargeId"
                        formState={formState}
                        // uiValidator={uiValidator}
                    />
                </CustomRow>
            ) : null}
            <CustomRow>
                <CustomDropdown
                    options={directions}
                    formDispatcher={formDispatcher}
                    formState={formState}
                    defaultOption="Sélectionner la direction"
                    label="Direction*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    name="selectedDirection"
                    id="selectedDirectionId"
                    // uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    options={
                        (fields.selectedDirection.code === 'OKAPI_DIRECT_NAPP'
                            ? secteursNapp
                            : secteursKinDB) || []
                    }
                    formDispatcher={formDispatcher}
                    formState={formState}
                    defaultOption="Sélectionner le secteur"
                    label="Secteur*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    name="selectedSecteur"
                    id="selectedSecteurId"
                    // uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    isSort={false}
                    options={periodes}
                    formDispatcher={formDispatcher}
                    formState={formState}
                    defaultOption="Sélectionner le mois"
                    label="Mois*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    name="selectedMonth"
                    id="selectedMonthId"
                    // uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomInputDate
                    id="datePaiementId"
                    name="datePaiement"
                    type="date"
                    label="Date de Paiement*"
                    isInputGroup={false}
                    labelClassName="col-sm-2"
                    divClassName="col-sm-10"
                    placeholder="Date paiement"
                    formDispatcher={formDispatcher}
                    // uiValidator={uiValidator}
                    formState={formState}
                />
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    options={MOYEN_PAIEMENT.ALL_MOYEN_PAIEMENT}
                    formDispatcher={formDispatcher}
                    formState={formState}
                    defaultOption="Sélectionner le mode de paiement"
                    label="Mode de paiement*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    name="modePaiement"
                    id="modePaiementId"
                    // uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomCollapse isIn={fields.modePaiement.code === 'Cash'}>
                <CustomRow>
                    <CustomDropdown
                        idProperty="id"
                        options={caisses.filter(
                            (item) =>
                                item.devise ===
                                chargesFixe.find(
                                    (charge) =>
                                        charge.code ===
                                        fields.selectedCharge.code
                                )?.devise
                        )}
                        formDispatcher={formDispatcher}
                        formState={formState}
                        defaultOption="Sélectionner la caisse"
                        label="Caisse Sectorielle*"
                        labelClassName="col-12 col-sm-2"
                        divClassName="col-12 col-sm-10"
                        name="selectedCaisseSectorielle"
                        id="selectedCaisseSectorielleId"
                        // uiValidator={uiValidator}
                    />
                </CustomRow>
            </CustomCollapse>
            {!typeOfCharge?.estRempliAutomatique ? (
                <>
                    <CustomRow>
                        <CustomDropdown
                            options={BENEFICIAIRE.ALL_TYPE}
                            formDispatcher={formDispatcher}
                            formState={formState}
                            defaultOption="Sélectionner le type beneficiaire"
                            label="Type Beneficiaire*"
                            labelClassName="col-12 col-sm-2"
                            divClassName="col-12 col-sm-10"
                            name="selectedTypeBeneficiaire"
                            id="selectedTypeBeneficiaireId"
                            // uiValidator={uiValidator}
                        />
                    </CustomRow>
                    <InputSearchGroup
                        placeholder="Till Number ou Identité"
                        formDispatcher={formDispatcher}
                        name="searchInput"
                        id="creationElaborationSearchInputId"
                        formState={formState}
                        isInputGroup={false}
                        label="Bénéficiaire*"
                        labelClassName="col-12 col-sm-2"
                        divClassName="col-10 col-sm-10"
                        callback={onSearchBeneficiaire}
                        buttonDisabled={
                            elements?.beneficiaires?.status ===
                            FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                        }
                        // uiValidator={uiValidator}
                    />
                    {elements?.beneficiaires?.status ===
                        FORM_CONST.FETCH_ELEMENT_SUCCESS &&
                    !elements.beneficiaires?.value?.length ? (
                        <CustomAlert successMessage="Aucun resultat pour cette recherche" />
                    ) : null}
                </>
            ) : null}
            {/* {!typeOfCharge?.estRempliAutomatique ? (
                <CustomRow>
                    <CustomLabel
                        text="Rechecher Bénéficiaire*"
                        className="col-sm-2"
                    />
                    <CustomInput
                        isInputGroup={false}
                        divClassName="col-12 col-sm-7"
                        // placeholder={`Saisissez le ${fields?.selectedBeneficiaire?.code === BENEFICIAIRE.PARTENAIRE.code ? 'code' : 'TillNumber'} du bénéficiaire`}
                        formDispatcher={formDispatcher}
                        name="searchInput"
                        id="creationElaborationSearchInputId"
                        formState={formState}
                        // uiValidator={uiValidator}
                    />
                    <CustomButtonLoader
                        className="col-2 h-25"
                        type="button"
                        text="rechercher"
                        onClick={onSearchBeneficiaire}
                        disabled={
                            elements?.beneficiaires?.status ===
                            FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                        }
                        isActionEncours={
                            elements?.beneficiaires?.status ===
                            FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                        }
                    />
                </CustomRow>
            ) : null} */}
            {!typeOfCharge?.estRempliAutomatique ? (
                <CustomCollapse isIn={!!elements.beneficiaires?.value?.length}>
                    <CustomRow>
                        <CustomLabel
                            text="Beneficiaire*"
                            className="col-sm-2"
                        />
                        <CustomDropdown
                            idProperty="id"
                            options={newListBenefinciaires(
                                elements.beneficiaires.value
                            )}
                            formDispatcher={formDispatcher}
                            formState={formState}
                            defaultOption="Sélectionner le beneficiaire"
                            divClassName="col-12 col-sm-4"
                            name="selectedBeneficiaire"
                            id="selectedBeneficiaireId"
                            // uiValidator={uiValidator}
                        />
                        {typeOfCharge?.estQuantite ? (
                            <CustomInput
                                isInputGroup={false}
                                divClassName="col-12 col-sm-4"
                                placeholder="Quantité..."
                                formDispatcher={formDispatcher}
                                name="quantiteParBeneficiaire"
                                type="number"
                                id="quantiteParBeneficiaireId"
                                formState={formState}
                                // uiValidator={uiValidator}
                            />
                        ) : (
                            <CustomInput
                                isInputGroup={false}
                                divClassName="col-12 col-sm-4"
                                placeholder="Montant..."
                                formDispatcher={formDispatcher}
                                name="montantParBeneficiaire"
                                type="number"
                                id="montantParBeneficiaireId"
                                formState={formState}
                                // uiValidator={uiValidator}
                            />
                        )}
                        <CustomAddRemoveButton
                            className="col-sm-2"
                            callback={addBeneficiaire}
                        />
                    </CustomRow>
                </CustomCollapse>
            ) : null}
            <CustomRow className="row">
                <CustomCol className="offset-9 col-3">
                    <CustomButtonLoader
                        type="button"
                        className="btn-primary"
                        onClick={addEntireChargeElaboration}
                        text="Ajouter Element"
                    />
                </CustomCol>
            </CustomRow>
            {fields.beneficiaireAndMontant.length &&
            typeOfCharge.estQuantite ? (
                <SimpleTable
                    identifier="id"
                    formDispatcher={formDispatcher}
                    data={fields.beneficiaireAndMontant}
                    isShowDropButton
                    listSelectedItemName="beneficiaireAndMontant"
                    tableClass="table table-hover shadow table-bordered table-responsive-sm"
                    filter={false}
                    bottomPagination={false}
                    isLinkToDetail={false}
                    mapper={[
                        {
                            position: 1,
                            displayName: 'ID',
                            field: 'id'
                        },
                        {
                            position: 2,
                            displayName: 'Beneficiaire',
                            field: 'valeurTypeEntite.designation'
                        },
                        {
                            position: 3,
                            displayName: 'Quantité',
                            field: 'quantite'
                        }
                    ]}
                />
            ) : null}
            {fields.beneficiaireAndMontant.length &&
            !typeOfCharge.estQuantite ? (
                <SimpleTable
                    identifier="id"
                    formDispatcher={formDispatcher}
                    data={fields.beneficiaireAndMontant}
                    isShowDropButton
                    listSelectedItemName="beneficiaireAndMontant"
                    tableClass="table table-hover shadow table-bordered table-responsive-sm"
                    filter={false}
                    bottomPagination={false}
                    isLinkToDetail={false}
                    mapper={[
                        {
                            position: 1,
                            displayName: 'ID',
                            field: 'id'
                        },
                        {
                            position: 2,
                            displayName: 'Beneficiaire',
                            field: 'valeurTypeEntite.designation'
                        },
                        {
                            position: 3,
                            displayName: 'Montant',
                            field: 'montant'
                        }
                    ]}
                />
            ) : null}

            {fields.chargeElaboration.length ? (
                <SimpleTable
                    identifier="id"
                    formDispatcher={formDispatcher}
                    data={fields.chargeElaboration}
                    isShowDropButton
                    listSelectedItemName="chargeElaboration"
                    tableClass="table table-hover shadow table-bordered table-responsive-sm"
                    filter={false}
                    bottomPagination={false}
                    isLinkToDetail={false}
                    mapper={[
                        {
                            position: 2,
                            displayName: 'Charge',
                            field: 'charge'
                        },
                        {
                            position: 3,
                            displayName: 'Moyen Paiement',
                            field: 'typeMoyenPaiement'
                        },
                        {
                            position: 4,
                            displayName: 'Secteur',
                            field: 'secteur.code'
                        }
                    ]}
                />
            ) : null}
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text={
                            fields.typeOperation === 'Amendement'
                                ? 'Valider amendement'
                                : 'Valider Completion'
                        }
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
