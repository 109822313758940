import { submitPutAction } from '@napp-inc/jnapp-hook';
import {
    CATEGORIE_MARCHAND,
    FORM_CONST,
    GENERIC_ACTIONS_TYPE
} from '@napp-inc/jnapp-util';
import {
    CustomAddRemoveButton,
    CustomAlert,
    CustomButtonLoader,
    CustomCol,
    CustomCollapse,
    CustomDropdown,
    CustomInput,
    CustomRow,
    SimpleTable
} from '../../../components';
import { URL_CONST } from '../../../util';

export function EtatConfigurationSender({
    formDispatcher,
    formState,
    organisation,
    nappUserMarchandType,
    etatConfigurationSender,
    reduxDispatcher,
    idToken,
    devises = [],
    etatMonnaieLocale,
    closeShow
}) {
    const { fields, form } = formState;
    const addProduit = () => {
        if (fields.selectedDevise?.code) {
            const target =
                etatConfigurationSender?.devisesRupture?.[
                    fields?.selectedDevise?.code
                ]?.estActif;
            const etat = target === false;
            formDispatcher({
                type: FORM_CONST.ADD_ITEM,
                payload: {
                    field: 'devisesRupture',
                    identifier: 'devise',
                    data: {
                        devise: fields.selectedDevise.code,
                        estActif: etat
                    }
                }
            });
        }
    };

    const onSuccess = () => {
        formDispatcher({
            type: FORM_CONST.REINITIALISER,
            payload: {
                initialState: {
                    ...formState,
                    fields: {
                        isOpened: {},
                        isSmsSynchro: {},
                        intervalGetCommande: 0,
                        intervalPostSms: 0,
                        selectedDevise: {},
                        devisesRupture: []
                    }
                }
            }
        });
        return closeShow();
    };

    const handleSubmit = () => {
        const payload = {
            idConfigSender: etatConfigurationSender?.id,
            elementUpdate: {}
        };
        if (fields?.isOpened?.code)
            payload.elementUpdate.estOuvert =
                fields?.isOpened?.code === 'OUVRIR';
        if (fields?.isSmsSynchro?.code)
            payload.elementUpdate.estSynchronisationSms =
                fields?.isSmsSynchro?.code === 'ACTIVER';
        if (fields?.intervalGetCommande)
            payload.elementUpdate.intervalleGetCommande =
                fields?.intervalGetCommande;
        if (fields?.intervalPostSms)
            payload.elementUpdate.intervallePostSms = fields?.intervalPostSms;
        if (fields?.devisesRupture?.length) {
            payload.elementUpdate.devisesRupture = fields.devisesRupture;
        }
        formDispatcher(
            submitPutAction({
                reduxDispatcher,
                functionName: URL_CONST.PUT_CONFIG_SENDER,
                fields,
                idToken,
                payload,
                onSuccess
            })
        );
    };

    const renderContent = () => {
        if (
            Object.prototype.hasOwnProperty.call(
                etatConfigurationSender,
                'estOuvert'
            ) ||
            Object.prototype.hasOwnProperty.call(
                etatConfigurationSender,
                'estSynchronisationSms'
            ) ||
            Object.prototype.hasOwnProperty.call(
                etatConfigurationSender,
                'intervalleGetCommande'
            ) ||
            Object.prototype.hasOwnProperty.call(
                etatConfigurationSender,
                'intervallePostSms'
            )
        ) {
            return (
                <>
                    <CustomAlert error={formState?.form?.error} />
                    <CustomRow>
                        <CustomCol>
                            <CustomDropdown
                                name="isOpened"
                                id="isOpenedId"
                                options={[
                                    { code: 'OUVRIR', designation: 'OUVRIR' },
                                    { code: 'FERMER', designation: 'FERMER' }
                                ]}
                                defaultOption="Ouvrir ou Fermer le service"
                                divClassName="col-sm-12"
                                formState={formState}
                                formDispatcher={formDispatcher}
                            />
                            <div className="w-100" />
                            <fieldset>
                                <legend>
                                    <p className="note">
                                        Ceci indique si le service est ouvert ou
                                        fermé.
                                    </p>
                                </legend>
                            </fieldset>
                        </CustomCol>
                    </CustomRow>
                    <CustomRow>
                        <CustomCol>
                            <CustomDropdown
                                name="isSmsSynchro"
                                id="isSmsSynchroId"
                                options={[
                                    { code: 'ACTIVER', designation: 'ACTIVER' },
                                    {
                                        code: 'DESACTIVER',
                                        designation: 'DESACTIVER'
                                    }
                                ]}
                                defaultOption="Synchronisation SMS"
                                // label="Unité*"
                                // labelClassName="col-sm-2"
                                divClassName="col-sm-12"
                                formState={formState}
                                formDispatcher={formDispatcher}
                                // uiValidator={uiValidator}
                            />
                            <div className="w-100" />
                            <fieldset>
                                <legend>
                                    <p className="note">
                                        Ceci indique si la synchronisation avec
                                        les SMS est activée ou pas.
                                    </p>
                                </legend>
                            </fieldset>
                        </CustomCol>
                    </CustomRow>
                    <CustomRow>
                        <CustomInput
                            type="number"
                            isInputGroup={false}
                            divClassName="col-12 col-md-12"
                            placeholder="Interval commande"
                            formDispatcher={formDispatcher}
                            name="intervalGetCommande"
                            id="intervalGetCommandeDetailConfigId"
                            formState={formState}
                        />
                        <div className="w-100" />
                        <fieldset>
                            <legend>
                                <p className="note">
                                    Permet de définir l&apos;interval entre deux
                                    processus de lecture des Commandes.
                                </p>
                            </legend>
                        </fieldset>
                    </CustomRow>
                    <CustomRow>
                        <CustomInput
                            type="number"
                            isInputGroup={false}
                            divClassName="col-12 col-md-12"
                            placeholder="Interval commande"
                            formDispatcher={formDispatcher}
                            name="intervalPostSms"
                            id="intervalPostSmsDetailConfigId"
                            formState={formState}
                        />
                        <div className="w-100" />
                        <fieldset>
                            <legend>
                                <p className="note">
                                    Permet de définir l&apos;interval entre deux
                                    processus d&apos;écriture des Commandes
                                    (SMS).
                                </p>
                            </legend>
                        </fieldset>
                    </CustomRow>
                    <CustomRow
                        isShouldBeRendered={
                            (!!organisation?.estEmoney ||
                                nappUserMarchandType ===
                                    CATEGORIE_MARCHAND.MOBILE_MONEY.code) &&
                            etatMonnaieLocale ===
                                GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_SUCCESS
                        }
                    >
                        <CustomRow>
                            <CustomDropdown
                                options={devises}
                                defaultOption="Selectionner Devise"
                                divClassName="col-12 col-sm-10"
                                formDispatcher={formDispatcher}
                                name="selectedDevise"
                                id="selectedDeviseConfigurationSenderId"
                                formState={formState}
                                disabled={!devises?.length}
                            />
                            <CustomAddRemoveButton
                                className="col-sm-2"
                                callback={addProduit}
                            />
                        </CustomRow>
                        <div className="w-100" />
                        <fieldset>
                            <legend>
                                <p className="note">
                                    La monnaie à activer ou à desactiver.
                                </p>
                            </legend>
                        </fieldset>
                    </CustomRow>
                    <CustomCollapse isIn={fields.devisesRupture.length > 0}>
                        <SimpleTable
                            data={fields.devisesRupture}
                            identifier="devise"
                            formDispatcher={formDispatcher}
                            listSelectedItemName="devisesRupture"
                            tableClass="table table-hover shadow table-bordered table-responsive-sm mt-3"
                            filter={false}
                            bottomPagination={false}
                            isLinkToDetail={false}
                            mapper={[
                                {
                                    position: 1,
                                    displayName: 'Devise',
                                    field: 'devise'
                                },
                                {
                                    position: 2,
                                    displayName: 'Etat à définir',
                                    field: 'estActif',
                                    // tdExtractor: (item) =>
                                    //     !item.estActif ? 'FERMER' : 'ACTIVER',
                                    isBoolean: true
                                }
                            ]}
                        />
                    </CustomCollapse>
                    <CustomButtonLoader
                        onClick={handleSubmit}
                        type="button"
                        className="btn mt-1 w-25"
                        text="Soumettre"
                        disabled={
                            form.status === FORM_CONST.MODIFICATION_EN_COURS
                        }
                        isActionEncours={
                            form.status === FORM_CONST.MODIFICATION_EN_COURS
                        }
                    />
                </>
            );
        }
        if (
            !Object.prototype.hasOwnProperty.call(etatConfigurationSender, 'id')
        ) {
            return <CustomAlert error="Aucune configuration disponible!" />;
        }
        return null;
    };
    return renderContent();
}
