import { RapportRegularisationProduitForm } from './rapport-regularisation-produit';

export function RapportRegularisationProduitViewLayout() {
    return (
        <div className="container-fluid">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Régularisation Produit</h1>
            </div>
            <RapportRegularisationProduitForm />
        </div>
    );
}
