/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
export function aggregerDevises(objet) {
    // Créer un objet vide pour stocker les résultats
    const resultat = { devises: {} };

    // Parcourir tous les états de l'objet
    for (const etat in objet.etats) {
        const devisesEtat = objet.etats[etat].devises;

        // Parcourir toutes les devises de cet état
        for (const devise in devisesEtat) {
            // Initialiser ou incrémenter les montants et nombres dans le résultat
            if (!resultat.devises[devise]) {
                resultat.devises[devise] = { montant: 0, nombre: 0 };
            }
            resultat.devises[devise].montant += devisesEtat[devise].montant;
            resultat.devises[devise].nombre += devisesEtat[devise].nombre;
        }
    }

    return resultat;
}
