import * as yup from 'yup';

/**
 * @description : Schema de Application Mobile Version Obsolete form
 */

export const NotificationPushFormSchema = yup.object().shape({
    message: yup.string().when('selectedNature', {
        is: (selectedNature) => selectedNature.code === `NOTIFICATION`,
        then: yup.string().min(10).max(300),
        otherwise: yup.string()
    }),
    title: yup.string().when('selectedNature', {
        is: (selectedNature) => selectedNature.code === `NOTIFICATION`,
        then: yup.string().min(2).max(50),
        otherwise: yup.string()
    }),
    url: yup.string(),
    meta: yup.string(),
    metaToParse: yup.string(),
    selectedSujet: yup.object().shape({
        valeurTypeEntite: yup.string().required(),
        title: yup.string().required()
    }),
    selectedNature: yup.object().shape({
        code: yup.string().required(),
        designation: yup.string().required()
    }),
    selectedFormulaire: yup.object().when('selectedNature', {
        is: (selectedNature) => selectedNature.code === `FORM`,
        then: yup.object().shape({
            id: yup.string().required(),
            titre: yup.string().required()
        }),
        otherwise: yup.object().shape({
            id: yup.string(),
            titre: yup.string()
        })
    })
});
