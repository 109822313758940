import { Array } from '@napp-inc/jnapp-util';
import {
    traitementMouvement,
    URL_CONST,
    useGetReportDetail
} from '../../../../util';
import { StandardReportTemplate } from '../../template';
import { GainPerteVenteSelector } from '../../../../components';

const formater = (data) =>
    Array.tri({
        data: traitementMouvement(
            data?.[0].map((item) => ({
                ...item,
                title: item.libelle ? item.libelle : item.typeEntite
            })) || []
        ),
        property: 'dateCreation',
        order: 'desc'
    });

export function ReleveStockSimForm() {
    const { formState, formDispatcher } = useGetReportDetail({
        defaultFields: {
            selectedCompte: {}
        }
    });
    const { fields } = formState;
    const dataFormater = (data) =>
        data.map((item) => ({
            ...item,
            designation: `${item?.valeurTypeProprietaire?.designation || ''} ${
                item.devise || ''
            }`
        }));
    const formatTexte = ({ element }) => {
        if (element.nature === 'GainChange') return 'Gain de Change';
        if (element.nature === 'PerteChange') return 'Perte de Change';
        return '';
    };
    return (
        <>
            {/* <StateShowerComponent state={formState?.elements} /> */}
            <StandardReportTemplate
                listTitle={`Compte: ${fields.selectedCompte.designation}`}
                functionName={URL_CONST.GET_LIST_MOUVEMENT_PLAGE}
                formState={formState}
                formDispatcher={formDispatcher}
                listFormater={formater}
                mapper={[
                    {
                        field: 'user.prenom',
                        displayName: 'Nature',
                        dataExtractor: (item) => formatTexte({ element: item })
                    },
                    {
                        field: 'elemAutre.tauxSystem',
                        displayName: 'Taux Systeme',
                        dataExtractor: (item) =>
                            item?.elemAutre?.tauxSystem || 0
                    },
                    {
                        field: 'elemAutre.tauxOperation',
                        displayName: 'Taux Operation',
                        dataExtractor: (item) =>
                            item?.elemAutre?.tauxOperation || 0
                    },
                    {
                        field: 'soldePrecedent',
                        displayName: 'Ouverture',
                        isNumber: true
                    },
                    {
                        field: 'entree',
                        displayName: 'Entrée',
                        isNumber: true
                    },
                    {
                        field: 'sortie',
                        displayName: 'Sortie',
                        isNumber: true
                    },
                    {
                        field: 'soldeActuel',
                        displayName: 'Fermeture',
                        isNumber: true
                    },
                    {
                        field: 'dateCreation',
                        displayName: 'Date'
                    }
                ]}
                defaultPayloadMapperValues={{
                    typeProprietaire: 'Compte'
                }}
                payloadMapper={[
                    {
                        name: 'dateDebut',
                        property: 'dateDebut',
                        isRequired: true,
                        displayName: 'Date début'
                    },
                    {
                        name: 'dateFin',
                        property: 'dateFin',
                        isRequired: true,
                        displayName: 'Date fin'
                    },
                    {
                        name: 'selectedCompte.id',
                        property: 'valeurTypeProprietaire',
                        isRequired: true,
                        displayName: 'Compte'
                    }
                ]}
            >
                <GainPerteVenteSelector
                    formState={formState}
                    dynamicNodeName="compteGainPerteChange"
                    formDispatcher={formDispatcher}
                    payload={{
                        estOrganisation: true,
                        estAllOrganisation: true,
                        type: 'Change'
                    }}
                    formater={dataFormater}
                />
            </StandardReportTemplate>
            {/* <StateShowerComponent state={elements} /> */}
        </>
    );
}
