/* eslint-disable react/no-array-index-key */
import { Fragment, useCallback, useEffect, useState } from 'react';
import {
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    formInitialState,
    // FORM_CONST,
    BUDGET_ELABORATION,
    TYPE_BUDGET_PREVISIONNEL,
    RESSOURCE,
    fromTimestampToString,
    TIME,
    TYPE_BUDGET_ADDITIONNEL,
    BUDGET_AMENDEMENT,
    ETAT,
    GenericModel
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import { FaRegFilePdf } from 'react-icons/fa';
import generatePDF, { Margin, Resolution } from 'react-to-pdf';
import {
    CustomDropdown,
    FormWrapper,
    ConditionalRenderingWrapper,
    CustomSwitchButton,
    BackComponent,
    CustomCenteredModal,
    CustomButton
} from '../../../../../components';
import { URL_CONST } from '../../../../../util';
import { REDUX_NODE_NAME } from '../../../../../redux';
import {
    handleAddHighLevel,
    handleChange,
    handleRemove,
    handleSelectChange,
    tablePreviewData,
    toggledView,
    toggledVisibilityView,
    useGeneratePayloadAndDataList,
    useGetPeriodesExercice
} from '../util';
import {
    ElaborationTablePreview,
    LeftSidePannelRessource as LeftSidePannel,
    RessourceBaseForm
} from '../base';
import { getCustomUrl } from '../../../../../helpers';

/**
 * @description fields du state du formulaire
 */
const defaultFields = {
    selectedExercice: {},
    selectedDirection: {},
    selectedSecteur: {},
    selectedRessource: {},
    modePaiement: {},
    tauxCroissance: 0,
    datePaiement: '',
    list: [],
    target: 0,
    listWithTarget: [],
    ressourceElaboration: [],
    targetByDevise: [],
    selectedDevise: {},
    selectedMonth: {},
    previewValue: '',
    estPrevisionnel: true
};

/**
 * @description dependencies du state du formulaire
 */

const defaultDependencies = {
    fields: ['secteurs', 'ressources', 'exercicesFinancier', 'brouillon']
};

/**
 * Principale vue des vues pour les Ressources
 * @param {Object} param0 : prends en compte toutes les props possibles
 * @param {String} param0.typeBudget: qui sera du type 'Elaboration | Completion | Ammendement'
 * @param {String} param0.isAdditionnel: qui sera du type true | False, pour savoir si c'est un prévisionnel ou un additionnel
 * @returns
 */
export function BudgetRessources({
    isAdditionnel = false,
    isCompletion = false,
    isAmendement = false,
    idEntity,
    payloadUrl = {}
}) {
    const [show, setShow] = useState(false);
    // const [idTarget, setIdTarget] = useState(null);
    const onHide = () => setShow(false);
    const onShow = () => setShow(true);
    const [formFields, setFormFields] = useState([
        {
            element: { code: '', designation: '' },
            estObjectif: false,
            tauxCroissance: 0,
            isTooggled: true,
            isVisible: false,
            details: [
                {
                    secteur: {
                        code: '',
                        designation: ''
                    },
                    isTooggled: true,
                    isVisible: false,
                    periodes: [
                        {
                            dateDebut: 0,
                            dateFin: 0,
                            mois: '',
                            devises: [{ devise: 'USD', target: 0 }]
                        }
                    ]
                }
            ]
        }
    ]);
    const reduxDispatcher = useDispatch();
    const {
        firebaseUser,
        ressources,
        etatRessource,
        etatSecteurs,
        exerciceFinancier,
        etatExerciceFinancier,
        secteursKinDB, // uniquement les directions commerciales
        brouillons,
        etatBrouillon
    } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        ressources: reduxState.ressources,
        etatRessource: reduxState.etat.ressources.etat,
        etatSecteurs: reduxState.etat.secteursRessourcesKindb.etat,
        secteursKinDB: reduxState.secteursRessourcesKindb,
        exerciceFinancier: reduxState.exerciceFinancier.map((item) => ({
            ...item,
            designation: `${fromTimestampToString(
                item.dateDebut
            )} - ${fromTimestampToString(item.dateFin)}`
        })),
        etatExerciceFinancier: reduxState.etat.exerciceFinancier.etat,
        brouillons: reduxState.brouillonRessource.filter(
            (item) =>
                item.type ===
                    (isAdditionnel
                        ? TYPE_BUDGET_ADDITIONNEL.code
                        : TYPE_BUDGET_PREVISIONNEL.code) &&
                item.typeEntite ===
                    (isAmendement ? BUDGET_AMENDEMENT : BUDGET_ELABORATION) &&
                item.etat === ETAT.ETAT_ENCOURS
        ),
        etatBrouillon: reduxState.etat.brouillonRessource.etat
    }));
    const { idToken, creator } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: defaultDependencies
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_RESSOURCE,
                nodeName: DB_NODE.RESSOURCE,
                etat: etatRessource
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_SECTEUR_RESSOURCE_KIN_DB,
                nodeName: REDUX_NODE_NAME.SECTEUR_RESSOURCE_KINDB,
                etat: etatSecteurs
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_EXERCICE,
                nodeName: DB_NODE.EXERCICE_FINANCIER,
                etat: etatExerciceFinancier
            },
            {
                isOnlyFetch: true,
                functionName:
                    URL_CONST.GET_LIST_BROUILLON_ELABORATION_AMENDEMENT_INITIALS,
                nodeName: REDUX_NODE_NAME.BROUILLON_RESSOURCE,
                etat: etatBrouillon,
                payload: {
                    newElaborationAmendement: {
                        typeProprietaire: RESSOURCE.code
                    }
                }
            }
        ]
    });

    /**
     * End Region
     */

    const { payloadList, tableList } = useGeneratePayloadAndDataList({
        formFields,
        tablePreviewData,
        view: 'Ressource'
    });

    const exercices = exerciceFinancier.map((item) => ({
        ...item,
        designation: `${TIME.fromUtcTimestampToLocalString(
            item.dateDebut
        )} - ${TIME.fromUtcTimestampToLocalString(item.dateFin)}`
    }));

    const exerciceCallback = useCallback(
        ({ data }) =>
            data.find(
                (item) =>
                    item.id === fields?.selectedExercice.id ||
                    (item.dateDebut === payloadUrl?.start &&
                        item.dateFin === payloadUrl?.end)
            ),
        [fields?.selectedExercice.id, payloadUrl?.end, payloadUrl?.start]
    );

    const exerciceCible = exerciceCallback({ data: exercices });

    const periodes = useGetPeriodesExercice({
        exercice: exerciceCible,
        payloadUrl
    });

    const memoisedDraft = useCallback(
        ({ draft }) => {
            if (
                draft.length &&
                ((exerciceCible?.dateDebut && exerciceCible?.dateFin) ||
                    (payloadUrl?.start && payloadUrl?.end))
            ) {
                const findDraft = draft.find(
                    (item) =>
                        item.periode.dateDebut ===
                            (exerciceCible?.dateDebut || payloadUrl?.start) &&
                        item.periode.dateFin ===
                            (exerciceCible?.dateFin || payloadUrl?.end) &&
                        item?.estPrevisionnel ===
                            (isAdditionnel ? fields.estPrevisionnel : false)
                );
                const parsedBruillon = findDraft?.brouillon?.list || [];
                setFormFields([
                    ...parsedBruillon,
                    {
                        element: { code: '', designation: '' },
                        estObjectif: false,
                        tauxCroissance: 0,
                        isTooggled: true,
                        isVisible: false,
                        details: [
                            {
                                secteur: {
                                    code: '',
                                    designation: ''
                                },
                                isTooggled: true,
                                isVisible: false,
                                periodes: [
                                    {
                                        dateDebut: 0,
                                        dateFin: 0,
                                        mois: '',
                                        devises: [{ devise: 'USD', target: 0 }]
                                    }
                                ]
                            }
                        ]
                    }
                ]);
            }
        },
        [
            exerciceCible?.dateDebut,
            exerciceCible?.dateFin,
            fields.estPrevisionnel,
            isAdditionnel,
            payloadUrl?.end,
            payloadUrl?.start
        ]
    );

    /**
     * UseEffect pour mettre à jour le state au cas où un brouillon existe
     */
    useEffect(() => {
        memoisedDraft({ draft: brouillons || [] });
        return memoisedDraft({ draft: [] });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [memoisedDraft]);

    const handlePostDraft = ({ isDraft = false }) => {
        if (isDraft) {
            const payload = {
                periode: {
                    dateDebut: exerciceCible?.dateDebut || payloadUrl?.start,
                    dateFin: exerciceCible?.dateFin || payloadUrl?.end
                },
                newElaborationAmendement: {
                    typeEntite: BUDGET_ELABORATION,
                    typeProprietaire: RESSOURCE.code,
                    type: isAdditionnel
                        ? TYPE_BUDGET_ADDITIONNEL.code
                        : TYPE_BUDGET_PREVISIONNEL.code
                },
                creator
            };
            if (isAdditionnel) {
                payload.newElaborationAmendement.estPrevisionnel =
                    fields.estPrevisionnel;
            }
            if ((isCompletion || isAmendement) && idEntity) {
                payload.idElaborationAmendement = idEntity;
            }
            if (formFields.length) {
                payload.elemUpdate = {
                    list: formFields.filter((item) => item.element.code)
                };
            }
            GenericModel.postObjetViaCF({
                idToken,
                customUrl: getCustomUrl({
                    functionName:
                        URL_CONST.POST_BROUILLON_ELABORATION_AMENDEMENT
                }),
                // functionName: URL_CONST.POST_BROUILLON_ELABORATION_AMENDEMENT,
                ...payload
            });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const list = payloadList;
        // const payloadToAddOut = {};
        // if (isCompletion || isAmendement) {
        //     payloadToAddOut.idElaborationAmendement = idEntity;
        // }
        const payload = {
            periode: {
                dateDebut: exerciceCible?.dateDebut || payloadUrl?.start,
                dateFin: exerciceCible?.dateFin || payloadUrl?.end
            },
            newElaborationAmendement: {
                typeEntite: BUDGET_ELABORATION,
                typeProprietaire: RESSOURCE.code,
                type: isAdditionnel
                    ? TYPE_BUDGET_ADDITIONNEL.code
                    : TYPE_BUDGET_PREVISIONNEL.code
            },
            creator,
            list
        };
        if (isAdditionnel) {
            payload.newElaborationAmendement.estPrevisionnel =
                fields.estPrevisionnel;
        }
        if ((isCompletion || isAmendement) && idEntity) {
            payload.idElaborationAmendement = idEntity;
        }
        // console.log(JSON.stringify(payload));
        handlePostDraft({ isDraft: false });
        formDispatcher(
            submitPostAction({
                idToken,
                customUrl: getCustomUrl({
                    functionName: URL_CONST.POST_ELABORATION_AMENDEMENT
                }),
                // functionName: URL_CONST.POST_ELABORATION_AMENDEMENT,
                reduxNodeName: DB_NODE.ELABORATION_AMENDEMENT,
                payload,
                fields,
                reduxDispatcher
                // uiValidator
            })
        );
    };

    /**
     * Options définies pour la prévisualisation en pdf
     */
    const options = {
        // default is `save`
        method: 'open',
        // default is Resolution.MEDIUM = 3, which should be enough, higher values
        // increases the image quality but also the size of the PDF, so be careful
        // using values higher than 10 when having multiple pages generated, it
        // might cause the page to crash or hang.
        resolution: Resolution.HIGH,
        page: {
            // margin is in MM, default is Margin.NONE = 0
            margin: Margin.SMALL,
            // default is 'A4'
            format: 'letter',
            // default is 'portrait'
            orientation: 'landscape'
        },
        canvas: {
            // default is 'image/jpeg' for better size performance
            mimeType: 'image/png',
            qualityRatio: 1
        },
        // Customize any value passed to the jsPDF instance and html2canvas
        // function. You probably will not need this and things can break,
        // so use with caution.
        overrides: {
            // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
            pdf: {
                compress: true
            },
            // see https://html2canvas.hertzen.com/configuration for more options
            canvas: {
                useCORS: true
            }
        }
    };
    // you can use a function to return the target element besides using React refs
    const getTargetElement = () => document.getElementById('content-id');

    const renderContent = () => (
        <>
            <ConditionalRenderingWrapper
                isShouldBeRendered={!payloadUrl?.start || !payloadUrl?.end}
            >
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 border-bottom">
                    <BackComponent className="btn btn-primary" />
                    <ConditionalRenderingWrapper isShouldBeRendered={!idEntity}>
                        <div className="d-flex justify-content-between align-items-center col-4">
                            <CustomDropdown
                                options={exercices}
                                formDispatcher={formDispatcher}
                                formState={formState}
                                defaultOption="Sélectionner un exercice"
                                label="Exercice: "
                                selectionClassName="form-select-sm fw-bold border-dark"
                                labelClassName="col-12 col-sm-2 col-md-2 fs-5 me-5 fw-bold"
                                divClassName="col-12 col-sm-10 col-md-10"
                                name="selectedExercice"
                                id="selectedExerciceId"
                                idProperty="id"
                            />
                        </div>
                    </ConditionalRenderingWrapper>
                    <ConditionalRenderingWrapper
                        isShouldBeRendered={isAdditionnel}
                    >
                        <CustomSwitchButton
                            id="selectionElaborationAdditionnelSwitchId"
                            name="estPrevisionnel"
                            text="Agir sur le Prévisionnel?"
                            value={fields.estPrevisionnel}
                            formState={formState}
                            formDispatcher={formDispatcher}
                            labelClassName="fst-italic fw-bolder"
                        />
                    </ConditionalRenderingWrapper>
                    <h3>
                        Budget {isAdditionnel ? 'Additionnel' : 'Présionnel'}
                    </h3>
                </div>
            </ConditionalRenderingWrapper>
            <FormWrapper
                isRedirect={!isCompletion && !isAmendement}
                onSubmit={handleSubmit}
                isStepBack={isCompletion || isAmendement}
                formState={formState}
                formDispatcher={formDispatcher}
                listDependencies={[
                    {
                        dependency: 'secteurs',
                        etat: etatSecteurs
                    },
                    {
                        dependency: 'ressources',
                        etat: etatRessource
                    },
                    {
                        dependency: 'exercicesFinancier',
                        etat: etatExerciceFinancier
                    },
                    {
                        dependency: 'brouillon',
                        etat: etatBrouillon
                    }
                ]}
            >
                {/* <ConditionalRenderingWrapper
                    isShouldBeRendered={isCompletion || isAmendement}
                >
                    <div className="alert alert-warning" role="alert">
                        Cette opération apportera des ajouts au présent budget!
                        <hr />
                        <div>
                            <span className="">Exercice :</span>{' '}
                            <span>
                                Du {fromTimestampToString(payloadUrl.start)} au{' '}
                                {fromTimestampToString(payloadUrl.end)}
                            </span>
                        </div>
                    </div>
                </ConditionalRenderingWrapper>
                <ConditionalRenderingWrapper
                    isShouldBeRendered={!isCompletion && !isAmendement}
                >
                    {form && form.status === FORM_CONST.CREATION_SUCCESS && (
                        <CustomToast
                            successMessage="Modification Effectuée"
                            status={form.status}
                        />
                    )}
                </ConditionalRenderingWrapper> */}
                <div className="d-flex">
                    <LeftSidePannel
                        pannelLabel="Liste des ressources"
                        onShow={onShow}
                        formFields={formFields}
                        toggledVisibilityView={toggledVisibilityView}
                        toggledView={toggledView}
                        handleSubmit={handleSubmit}
                        form={form}
                        setFormFields={setFormFields}
                        handleRemove={handleRemove}
                    />
                    <div className="col-9 ms-2">
                        <CustomCenteredModal
                            show={show}
                            onHide={onHide}
                            header="Elaboration"
                            fullscreen
                            // size="lg"
                            // backdrop={backdrop}
                        >
                            <div className="text-end mb-2">
                                <button
                                    className="btn btn-primary rounded-pill"
                                    type="button"
                                    onClick={() =>
                                        generatePDF(getTargetElement, options)
                                    }
                                >
                                    Visualiser <FaRegFilePdf />
                                </button>
                            </div>
                            <div id="content-id">
                                <ElaborationTablePreview data={tableList} />
                            </div>
                        </CustomCenteredModal>
                        <ConditionalRenderingWrapper
                            isShouldBeRendered={!!fields.selectedExercice.id}
                        >
                            {formFields.map((field, index) => (
                                <Fragment key={index}>
                                    {field.isTooggled ? (
                                        <RessourceBaseForm
                                            ressources={ressources}
                                            field={field}
                                            formFields={formFields}
                                            setFormFields={setFormFields}
                                            handleAddHighLevel={
                                                handleAddHighLevel
                                            }
                                            handleChange={handleChange}
                                            handleRemove={handleRemove}
                                            handleSelectChange={
                                                handleSelectChange
                                            }
                                            index={index}
                                            periodes={periodes}
                                            secteurs={secteursKinDB}
                                            onToggleView={toggledView}
                                        />
                                    ) : null}
                                </Fragment>
                            ))}
                            <div className="d-flex justify-content-center">
                                <CustomButton
                                    // modalText="Voulez-vous soumettre cette élaboration?"
                                    // modalChildren={draftComponentAction}
                                    type="button"
                                    text="+ Ajouter"
                                    className="btn-success btn"
                                    onClick={() => {
                                        handleAddHighLevel({
                                            type: 'Ressource',
                                            setFormFields,
                                            formFields,
                                            view: 'Ressource'
                                        });
                                        handlePostDraft({ isDraft: false });
                                    }}
                                />
                            </div>
                            {/* <div className="d-flex justify-content-between">
                                <div className="col-5">
                                    <StateShowerComponent state={formFields} />
                                </div>
                                <div className="col-5">
                                    <StateShowerComponent state={[]} />
                                </div>
                            </div> */}
                        </ConditionalRenderingWrapper>
                    </div>
                </div>
            </FormWrapper>
        </>
    );
    return renderContent();
}
