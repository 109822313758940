import { ETAT, FORM_CONST } from '@napp-inc/jnapp-util';
import { CustomDropdown } from '../dropdown';
import { ConditionalRenderingWrapper, CustomRow } from '../container';

/**
 * Construit une dropdown où l'on peut sélectionner les superDealers emoney
 * @param {Object} param0
 * @param {Function} param0.formDispatcher form dispatcher du parent
 * @param {Object} param0.formState form state du parent
 * @returns {React.Component}
 */
export function EtatMultipleSelector({
    formDispatcher,
    formState,
    idProperty = 'id',
    isAddAditionalOptions = false,
    additionnalOptions = [{ id: 'none', code: 'none', designation: 'Aucun' }],
    property = 'list',
    options = [
        {
            id: ETAT.ETAT_PRE_ACTIVE,
            code: ETAT.ETAT_PRE_ACTIVE,
            designation: ETAT.ETAT_PRE_ACTIVE
        },
        {
            id: ETAT.ETAT_PRE_ENCOURS,
            code: ETAT.ETAT_PRE_ENCOURS,
            designation: ETAT.ETAT_PRE_ENCOURS
        },
        {
            id: ETAT.ETAT_VALIDER,
            code: ETAT.ETAT_VALIDER,
            designation: ETAT.ETAT_VALIDER
        }
    ]
}) {
    const { fields } = formState;
    const handleChange = (e) => {
        const { id } = e.target;
        const existe = fields?.list?.find((item) => item === id);
        if (existe) {
            formDispatcher({
                type: FORM_CONST.REMOVE_PRIMITIVE_ITEM,
                payload: {
                    field: 'list',
                    data: id
                }
            });
        } else {
            formDispatcher({
                type: FORM_CONST.ADD_PRIMITIVE_ITEM,
                payload: {
                    field: 'list',
                    data: id
                }
            });
        }
    };
    return (
        <>
            <CustomRow>
                <CustomDropdown
                    options={[
                        {
                            id: 'NONE',
                            code: 'NONE',
                            designation: 'AUCUN'
                        },
                        {
                            id: 'SELECTION_MULTIPLE',
                            code: 'SELECTION_MULTIPLE',
                            designation: 'SELECTION MULTIPLE'
                        },
                        ...options
                    ]}
                    defaultOption="Selectionner Etat"
                    label="Etat: "
                    labelClassName="col-12"
                    divClassName="col-12"
                    formDispatcher={formDispatcher}
                    name="selectedEtat"
                    id="selectedEtatCreationApproCompteEmoney"
                    formState={formState}
                    idProperty={idProperty}
                    keyProperty="id"
                    additionnalOptions={
                        isAddAditionalOptions ? additionnalOptions : []
                    }
                    isSort={false}
                />
            </CustomRow>
            <ConditionalRenderingWrapper
                isShouldBeRendered={
                    fields.selectedEtat[idProperty] === 'SELECTION_MULTIPLE'
                }
            >
                <CustomRow>
                    <ul className="ms-2 list-group">
                        {options.map((item) => (
                            <li key={item?.id} className="list-group-item">
                                <label
                                    className="form-check-label"
                                    htmlFor={item.id}
                                >
                                    <input
                                        className="form-check-input me-1"
                                        type="checkbox"
                                        id={item.id}
                                        checked={
                                            !!fields?.[property]?.find(
                                                (element) =>
                                                    element === item?.id
                                            )
                                        }
                                        onChange={handleChange}
                                    />
                                    {item.designation}
                                </label>
                            </li>
                        ))}
                    </ul>
                </CustomRow>
            </ConditionalRenderingWrapper>
        </>
    );
}
