import { URL_CONST, useGetReportDetail } from '../../../../util';
import { StandardReportTemplate } from '../../template';
import {
    EtatMultipleSelector,
    SuperDealerSelector
} from '../../../../components';

const formater = (data = []) => {
    const result = [];
    (data?.[0]?.listReturn || []).forEach((item) => {
        result.push({
            numero: item?.client?.numero,
            devise: item?.devise,
            dateCreation: item?.dateCreation,
            etat: item?.etat,
            montant: {
                [item?.devise]: item?.montant
            },
            reference: item?.numeroReference
        });
    });
    return result;
};

function grouperMontantsParEtat(value) {
    const data = formater(value);
    // Créer un objet pour stocker les montants groupés par état et devise
    const result = {};

    // Parcourir chaque élément des données
    data.forEach((item) => {
        const { etat, devise, montant } = item;

        // Si l'état n'existe pas encore dans le résultat, l'initialiser
        if (!result[etat]) {
            result[etat] = {};
        }

        // Si la devise n'existe pas encore pour cet état, l'initialiser
        if (!result[etat][devise]) {
            result[etat][devise] = 0;
        }

        // Ajouter le montant à la somme existante
        result[etat][devise] += montant[devise];
    });

    // Convertir l'objet de résultats en un tableau pour correspondre au format de sortie souhaité
    const resultat = Object.entries(result).map(([etat, montants]) => ({
        [etat]: montants
    }));
    let newObject = {};
    if (resultat.length) {
        resultat.forEach((item) => {
            newObject = {
                ...newObject,
                ...item
            };
        });
    }
    return newObject;
}
const dynamicColumnFormater = (dynamicColumn) => {
    const result = [];
    Object.keys(dynamicColumn || {}).forEach((key) => {
        result.push({
            displayName: dynamicColumn[key],
            field: `montant.${key}`,
            isTotal: true
        });
    });
    if (Object.keys(dynamicColumn || {}).length) {
        result.push(
            {
                field: 'etat',
                displayName: 'Etat'
            },
            {
                field: 'dateCreation',
                displayName: 'Création'
            }
        );
    }
    return result;
};

export function RapportEtatRetraitKinDBForm() {
    const { formState, formDispatcher } = useGetReportDetail({
        defaultFields: {
            selectedSuperDealer: {},
            selectedEtat: {},
            etats: [],
            list: []
        }
    });
    const { elements } = formState;
    const { mouvements } = elements;
    // const data = mouvements?.value?.length
    //     ? grouperMontantsParEtat(mouvements.value)
    //     : [];
    // console.log(data);
    const renderHeader = () => {
        const data = mouvements?.value?.length
            ? grouperMontantsParEtat(mouvements.value)
            : {};
        const keys = Object.keys(data);
        return (
            <div className="d-flex justify-items-start">
                {keys.map((key) => (
                    <div
                        key={key}
                        className="card text-dark bg-light mb-3 me-2 w-25"
                        // style={{ maxWidth: '18rem' }}
                    >
                        <div className="card-header">
                            <span>{key}</span>
                        </div>
                        <div className="card-body">
                            <p className="card-text">
                                <span className="h6">Total USD :</span>{' '}
                                <span className="h5">
                                    {data?.[key]?.USD || 0}
                                </span>
                            </p>
                            <p className="card-text">
                                <span className="h6">Total CDF :</span>{' '}
                                <span className="h5">
                                    {data?.[key]?.CDF || 0}
                                </span>
                            </p>
                        </div>
                    </div>
                ))}
            </div>
        );
    };
    return (
        <StandardReportTemplate
            renderHeader={renderHeader}
            functionName={URL_CONST.GET_LIST_RETRAIT_BY_RELEVE}
            listFormater={formater}
            formState={formState}
            formDispatcher={formDispatcher}
            dynamicColumnFormater={dynamicColumnFormater}
            mapper={[
                {
                    displayName: 'Numéro',
                    field: 'numero'
                }
            ]}
            isAddTotalTr
            defaultPayloadMapperValues={{}}
            payloadMapper={[
                {
                    name: 'dateDebut',
                    property: 'dateDebut',
                    isRequired: true,
                    displayName: 'Date début'
                },
                {
                    name: 'dateFin',
                    property: 'dateFin',
                    isRequired: true,
                    displayName: 'Date fin'
                },
                {
                    name: 'selectedSuperDealer.code',
                    property: 'codeSuperDealer',
                    isRequired: false,
                    displayName: 'Super dealer'
                },
                {
                    name: 'selectedEtat',
                    property: 'etat',
                    isRequired: false,
                    callback: (value) => {
                        if (
                            !value?.id ||
                            value?.id === 'NONE' ||
                            (value?.id === 'SELECTION_MULTIPLE' &&
                                !formState?.fields?.list?.length)
                        )
                            return {};
                        if (
                            value?.id === 'SELECTION_MULTIPLE' &&
                            formState?.fields?.list?.length
                        )
                            return { etats: formState.fields.list };
                        return {
                            etats: [value?.id]
                        };
                    }
                }
            ]}
        >
            <SuperDealerSelector
                formState={formState}
                formDispatcher={formDispatcher}
                idProperty="code"
                estNonAirtime
            />
            <EtatMultipleSelector
                formState={formState}
                formDispatcher={formDispatcher}
            />
        </StandardReportTemplate>
    );
}
