import { FORM_CONST, resolvePathPropertyOfObject } from '@napp-inc/jnapp-util';
import { useEffect } from 'react';

/**
 * Ce hook effectue un appel fonction sur base d'une condition et en un certain temps
 * @param {Object} param0
 * @param {String} param0.name : Le nom de la propriété du champ de saisie
 * @param {String} param0.preview : Le nom de la propriété du state qui sauvegardera la valeur précédente de {param0.name}
 * @param {Boolean} param0.isObject : Indique si le champ de saisie est un Input ou une Dropdown
 * @param {Function} param0.formDispatcher : Fonction de dispatch liée au formulaire
 * @param {Object} param0.formState : state global du formulaire
 * @param {Number} param0.delay : temps de delais avant de lancer la fonction
 * @param {String} param0.idToken : token
 * @param {String} param0.functionName : fonction cloud
 * @param {String} param0.elementPropertyName : nom de la propriété cible dans formState.element
 * @param {String} param0.reduxNodeName : Noeud redux
 * @param {String} param0.reduxDispatcher : Dispatcher Redux
 */

/**
 * Ce hook met à jour le state du composant au chargement du composant
 */

export function useUpdateInitialState({
    name = 'actionEncours',
    formDispatcher,
    condition = false,
    data = '',
    paths = [],
    entity = {},
    initialState = {},
    fieldsKeys = []
}) {
    useEffect(() => {
        if (condition && paths.length) {
            const newState = {};
            paths.forEach((path, index) => {
                if (path) {
                    newState[fieldsKeys[index]] = resolvePathPropertyOfObject(
                        path,
                        entity
                    );
                }
            });
            formDispatcher({
                type: FORM_CONST.FIELD_CHANGE_AND_SET_NEW_STATE,
                payload: {
                    field: name,
                    data,
                    newState: {
                        ...initialState,
                        ...newState
                    }
                }
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [condition]);
}
