import React from 'react';

export function ElaborationTablePreview({ data }) {
    return (
        <table className="table table-bordered text-center">
            <thead>
                <tr>
                    <th colSpan={14}>Elaboration Budget</th>
                </tr>
                <tr>
                    <th>Charge</th>
                    <th>Mode Paiement</th>
                    <th>Janvier</th>
                    <th>Février</th>
                    <th>Mars</th>
                    <th>Avril</th>
                    <th>Mai</th>
                    <th>Juin</th>
                    <th>Juillet</th>
                    <th>Août</th>
                    <th>Septembre</th>
                    <th>Octobre</th>
                    <th>Novembre</th>
                    <th>Décembre</th>
                </tr>
            </thead>
            <tbody>
                {data?.map((tableItem) => (
                    <tr key={tableItem.charge.code}>
                        <td>{tableItem.charge.designation}</td>
                        <td>{tableItem.moyenPaiement}</td>
                        <td>{tableItem.synthese.JANVIER}</td>
                        <td>{tableItem.synthese.FEVRIER}</td>
                        <td>{tableItem.synthese.MARS}</td>
                        <td>{tableItem.synthese.AVRIL}</td>
                        <td>{tableItem.synthese.MAI}</td>
                        <td>{tableItem.synthese.JUIN}</td>
                        <td>{tableItem.synthese.JUILLET}</td>
                        <td>{tableItem.synthese.AOUT}</td>
                        <td>{tableItem.synthese.SEPTEMBRE}</td>
                        <td>{tableItem.synthese.OCTOBRE}</td>
                        <td>{tableItem.synthese.NOVEMBRE}</td>
                        <td>{tableItem.synthese.DECEMBRE}</td>
                    </tr>
                ))}
            </tbody>
            {/* <tfoot>
                            <tr>
                                <td colSpan={2}>TOTAL</td>
                                <td colSpan={12}>0</td>
                            </tr>
                        </tfoot> */}
        </table>
    );
}
