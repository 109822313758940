import { CODE_SERVICE } from '@napp-inc/jnapp-util';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { ShowNewComponent } from '../../../components';
import { ListAutorisationLotReferenceForm } from './list-autorisation-lot-reference';
import { ConfigurationPath } from '../../../routes/registration';

export function AutorisationLotReferenceViewLayout() {
    const { pathname } = useLocation();
    return (
        <div className="container-fluid">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Liste Autorisation Reference</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group me-2">
                        {pathname ===
                            `/${ConfigurationPath.AutorisationLotReferenceViewLayoutPath.path}` && (
                            <ShowNewComponent
                                services={[
                                    { code: 'test' },
                                    CODE_SERVICE.CODE_SERVICE_SENDER
                                        .CREATION_AUTORISATION_REFERENCE
                                ]}
                                destination={
                                    ConfigurationPath
                                        .AutorisationLotReferenceCreatePath.path
                                }
                            />
                        )}
                    </div>
                </div>
            </div>
            <ListAutorisationLotReferenceForm />
        </div>
    );
}
