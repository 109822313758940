import { BENEFICIAIRE, MOYEN_PAIEMENT } from '@napp-inc/jnapp-util';
import React from 'react';
import { AiFillMinusCircle } from 'react-icons/ai';
import {
    IoIosAddCircleOutline,
    IoIosArrowDropdown,
    IoIosArrowDropup
} from 'react-icons/io';
import {
    CustomLiteDynamicDropdown,
    CustomLiteDynamicInput
    // CustomLiteEditableDynamicDropdown
} from '../../../../../../components';

export function BeneficiaireRowSelector({
    id,
    index,
    detailIndex,
    // periodeIndex,
    beneficiaireIndex,
    handleChange,
    handleSelectChange,
    handleRemove,
    handleAddHighLevel,
    beneficiaireItem,
    beneficiaresOptions = [],
    charges = [],
    formFields = [],
    setFormFields,
    getClassNames,
    caisses,
    field,
    onToggleView
}) {
    return (
        <div
            className="d-flex flex-row align-items-center justify-content-center"
            id={id}
        >
            {/* Type Beneficiares */}
            <CustomLiteDynamicDropdown
                containerClassName={
                    getClassNames({
                        moyenPaiement: formFields[index]?.typeMoyenPaiement,
                        estQuantite: formFields[index]?.estQuantite
                    })?.typeBeneficiaireClass
                }
                ariaLabel="Type Beneficiaire Selector"
                name="typeEntite"
                value={beneficiaireItem?.typeEntite}
                options={BENEFICIAIRE.CHARGE_FIXE}
                hiddenOption="Selectionnez Type Bénéficiaire"
                onChange={handleSelectChange}
                handleProps={{
                    index,
                    indexAtSecondLevel: detailIndex,
                    indexAtThirdLevel: beneficiaireIndex,
                    // id: beneficiaireIndex,
                    setFormFields,
                    formFields
                }}
                labelStyle={{
                    fontSize: '13px',
                    paddingBottom: '8px',
                    lineHeight: '20px',
                    minHeight: '20px',
                    display: 'block',
                    color: 'rgba(0,0,0,0.5)'
                }}
                label="Type Bénéficiaire"
                selectClassName="form-select form-select-sm col-3"
            />
            {/* Beneficiaire */}
            <CustomLiteDynamicDropdown
                containerClassName={
                    getClassNames({
                        moyenPaiement: formFields[index]?.typeMoyenPaiement,
                        estQuantite: formFields[index]?.estQuantite
                    })?.beneficiaireClass
                }
                ariaLabel="Beneficiaire Selector"
                name="valeurTypeEntite.reference|valeurTypeEntite.designation"
                customproperty="beneficiaire"
                value={beneficiaireItem?.valeurTypeEntite?.reference}
                options={beneficiaresOptions}
                hiddenOption="Selectionnez Bénéficiaire"
                onChange={handleSelectChange}
                handleProps={{
                    index,
                    indexAtSecondLevel: detailIndex,
                    indexAtThirdLevel: beneficiaireIndex,
                    // id: beneficiaireIndex,
                    setFormFields,
                    formFields,
                    isEditable: true
                }}
                labelStyle={{
                    fontSize: '13px',
                    paddingBottom: '8px',
                    lineHeight: '20px',
                    minHeight: '20px',
                    display: 'block',
                    color: 'rgba(0,0,0,0.5)'
                }}
                label="Bénéficiaire"
                selectClassName="form-select form-select-sm col-3"
                idProperty="id"
                inputClassName="form-control form-control-sm col-3"
            />
            {/* Ciasse */}
            {formFields[index].typeMoyenPaiement === MOYEN_PAIEMENT.CASH ? (
                <CustomLiteDynamicDropdown
                    containerClassName={
                        getClassNames({
                            moyenPaiement: formFields[index]?.typeMoyenPaiement,
                            estQuantite: formFields[index]?.estQuantite
                        })?.beneficiaireCaisseClass
                    }
                    ariaLabel="Caisse Selector"
                    name="idCaisse|caisseDesignation"
                    value={beneficiaireItem?.idCaisse}
                    options={
                        caisses.filter(
                            (caisse) =>
                                caisse.devise ===
                                charges.find(
                                    (charge) =>
                                        charge.code === field?.element?.code
                                )?.devise
                        ) || []
                    }
                    hiddenOption="Selectionnez la caisse"
                    onChange={handleSelectChange}
                    handleProps={{
                        index,
                        indexAtSecondLevel: detailIndex,
                        indexAtThirdLevel: beneficiaireIndex,
                        setFormFields,
                        formFields
                    }}
                    labelStyle={{
                        fontSize: '13px',
                        paddingBottom: '8px',
                        lineHeight: '20px',
                        minHeight: '20px',
                        display: 'block',
                        color: 'rgba(0,0,0,0.5)'
                    }}
                    label="Caisse"
                    selectClassName="form-select form-select-sm col-3"
                    idProperty="id"
                />
            ) : null}
            {/* Montant ou Quantité */}
            {formFields[index]?.estQuantite ? (
                <CustomLiteDynamicInput
                    name="prix"
                    placeholder="Prix..."
                    containerClassName={
                        getClassNames({
                            moyenPaiement: formFields[index]?.typeMoyenPaiement,
                            estQuantite: formFields[index]?.estQuantite
                        })?.beneficiairePrixClass
                    }
                    labelStyle={{
                        fontSize: '13px',
                        paddingBottom: '8px',
                        lineHeight: '20px',
                        minHeight: '20px',
                        display: 'block',
                        color: 'rgba(0,0,0,0.5)'
                    }}
                    label="Prix"
                    value={beneficiaireItem.prix}
                    onChange={handleChange}
                    inputProps={{
                        index,
                        indexAtSecondLevel: detailIndex,
                        indexAtThirdLevel: beneficiaireIndex,
                        setFormFields,
                        formFields
                    }}
                    inputClassName="form-control form-control-sm col-2"
                />
            ) : null}
            <div className="col-auto me-1 pt-2">
                <AiFillMinusCircle
                    role="button"
                    title="Supprimer Bénéficiaire"
                    className="text-secondary fs-5"
                    onClick={() =>
                        handleRemove({
                            index,
                            indexAtSecondLevel: detailIndex,
                            indexAtThirdLevel: beneficiaireIndex,
                            formFields,
                            setFormFields
                        })
                    }
                />
            </div>
            <div className="col-auto me-1 pt-2">
                <IoIosAddCircleOutline
                    role="button"
                    title="Ajouter Bénéficiaire"
                    className="text-primary fs-5"
                    onClick={() =>
                        handleAddHighLevel({
                            type: 'Beneficiaires',
                            ancestorId: index,
                            indexAtSecondLevel: detailIndex,
                            // indexAtThirdLevel: beneficiaireIndex,
                            formFields,
                            setFormFields
                        })
                    }
                />
            </div>
            <div className="col-auto pt-2">
                {formFields[index].details[detailIndex].beneficiaires[
                    beneficiaireIndex
                ].isTooggled ? (
                    <IoIosArrowDropup
                        className="fs-5"
                        title="Regrouper les détails"
                        role="button"
                        onClick={() =>
                            onToggleView({
                                code: formFields?.[index]?.details?.[
                                    detailIndex
                                ]?.beneficiaires?.[beneficiaireIndex]
                                    ?.valeurTypeEntite?.reference,
                                index,
                                indexAtSecondLevel: detailIndex,
                                indexAtThirdLevel: beneficiaireIndex,
                                setFormFields,
                                formFields
                            })
                        }
                    />
                ) : (
                    <IoIosArrowDropdown
                        className="fs-5"
                        title="Afficher les détails"
                        role="button"
                        onClick={() =>
                            onToggleView({
                                code: formFields?.[index]?.details?.[
                                    detailIndex
                                ]?.beneficiaires?.[beneficiaireIndex]
                                    ?.valeurTypeEntite?.reference,
                                index,
                                indexAtSecondLevel: detailIndex,
                                indexAtThirdLevel: beneficiaireIndex,
                                setFormFields,
                                formFields
                            })
                        }
                    />
                )}
            </div>
        </div>
    );
}
