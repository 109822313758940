import { fromStringToTimestamp } from '@napp-inc/jnapp-util';

/**
 * @description: Cette fonction recoit le type de la donnée et la donnée
 * pour retourner une valeur dans un format précis
 * @param {Object} param0 : Tout le paramètre
 * @param {string} param0.type : paramètre type
 * @param {Any} param0.data : paramètre data
 * @returns {Any}: data
 */
export const castTypeOfData = ({ type, data }) => {
    if (type === 'date') return fromStringToTimestamp(data);
    if (type === 'number') return Number(data);
    return data;
};
