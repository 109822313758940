import { BENEFICIAIRE } from '@napp-inc/jnapp-util';
import { useCallback } from 'react';

export function useGetTypeBeneficiaires({ employes, users, partenaires }) {
    // For Getting Beneficiaire
    const getTypeBeneficiaire = useCallback(
        ({ id }) => {
            if (id === BENEFICIAIRE?.EMPLOYE?.code) return employes;
            if (id === BENEFICIAIRE?.USER?.code) return users;
            if (id === BENEFICIAIRE?.PARTENAIRE?.code) return partenaires;
            return [];
        },
        [employes, partenaires, users]
    );
    return getTypeBeneficiaire;
}
