import { getPeriodesOfYear } from '@napp-inc/jnapp-util';
import { useCallback } from 'react';

export function useGetPeriodesExercice({ exercice, payloadUrl }) {
    const resulatFunction = useCallback(
        () =>
            getPeriodesOfYear({
                startOf: exercice?.dateDebut || payloadUrl?.start,
                endOf: exercice?.dateFin || payloadUrl?.end
            })?.map((item) => ({
                code: item.dateDebut,
                designation: item.mois,
                ...item
            })) || [],
        [
            exercice?.dateDebut,
            exercice?.dateFin,
            payloadUrl?.end,
            payloadUrl?.start
        ]
    );
    return resulatFunction();
}
