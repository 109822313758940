import {
    useFormGlobalReducer,
    fetchElement,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    FORM_CONST,
    TODAY,
    checkPlageDate,
    formInitialState
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import { URL_CONST } from '../../../../util';
import { MainReportComponentLarge } from '../../releve/generic';
import { ReportCommandeSimSelector } from '../../../../components';

const defaultFields = {
    debut: '',
    fin: '',
    dateDebut: TODAY.at000000(),
    dateFin: TODAY.at235959(),
    selectedDateRangeReport: {
        code: 'TODAY',
        designation: "Aujourd'hui"
    },
    isFetching: false,
    selectedMarchandSim: {},
    selectedEtat: {}
};

export function RapportCommandeApproSimForm() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser
    }));
    const { idToken } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        elements: ['mouvements']
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, elements } = formState;
    const fetchReport = () => {
        const { dateFin, dateDebut, selectedMarchandSim, selectedEtat } =
            fields;
        const { isValid } = checkPlageDate(dateDebut, dateFin);
        if (!isValid) {
            return;
        }
        const payloadToAdd = {};
        if (selectedEtat.code) {
            payloadToAdd.etat = selectedEtat.code;
        }
        if (selectedMarchandSim.code) {
            payloadToAdd.codeMarchand = selectedMarchandSim.code;
        }
        const payload = {
            dateDebut,
            dateFin,
            estOrganisation: true,
            ...payloadToAdd
            // property: 'dateCreation'
        };
        formDispatcher(
            fetchElement({
                idToken,
                elementPropertyName: 'mouvements',
                payload,
                functionName: URL_CONST.GET_LIST_APPRO_SIM_INITIALS,
                etat: elements.mouvements.status,
                formDispatcher,
                reduxDispatcher
            })
        );
    };
    return (
        <>
            <MainReportComponentLarge
                isDataFetched={
                    elements?.mouvements?.status ===
                    FORM_CONST.FETCH_ELEMENT_SUCCESS
                }
                isDetaille={false}
                formState={formState}
                formDispatcher={formDispatcher}
                fetchReport={fetchReport}
                mapper={[
                    {
                        displayName: 'Marchand',
                        field: 'marchand.designation'
                    },
                    {
                        field: 'produit.designation',
                        displayName: 'produit'
                    },
                    {
                        displayName: 'Revendeur',
                        field: 'valeurTypeEntite.designation'
                    },
                    {
                        displayName: 'User',
                        field: 'userDesignation'
                    },
                    {
                        field: 'clientDesignation',
                        displayName: 'Client'
                    },
                    {
                        field: 'numero',
                        displayName: 'Numéro'
                    },
                    {
                        field: 'quantite',
                        displayName: 'quantite',
                        isNumber: true
                    },
                    {
                        field: 'etat',
                        displayName: 'Etat'
                    },
                    {
                        field: 'motif',
                        displayName: 'Motif'
                    },
                    {
                        field: 'dateCreation',
                        displayName: 'Création'
                    },
                    {
                        field: 'dateDerniereModification',
                        displayName: 'Dernière modification'
                    }
                ]}
                dataList={
                    elements.mouvements?.value?.map((item) => ({
                        ...item,
                        clientDesignation: item?.client?.designation
                            ? item?.client?.designation
                            : item?.valeurTypeEntite?.designation,
                        userDesignation: `${item?.user?.prenom || ''} ${
                            item?.user?.nom || ''
                        }`
                    })) || []
                }
                isFetching={
                    elements?.mouvements?.status ===
                    FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                }
                // topOfChart={MouvementCardsComponent}
                isCommandeLitigieuse
            >
                <ReportCommandeSimSelector
                    formState={formState}
                    formDispatcher={formDispatcher}
                />
            </MainReportComponentLarge>
            {/* <StateShowerComponent state={elements} /> */}
        </>
    );
}
