import { useCallback, useState } from 'react';
// import { useSelector } from 'react-redux';
import {
    Add,
    Mul,
    Sub,
    TIME,
    TODAY,
    TYPE_BUSINESS
} from '@napp-inc/jnapp-util';
import { URL_CONST, useGetReportDetail } from '../../../../util';
import { ReduxReportTemplate } from '../../template';
import { CustomDropdown, CustomRow } from '../../../../components';

// function transformerClesEnIndices(objet) {
//     // Créer un nouveau tableau pour stocker les clés
//     const nouvellesCles = objet ? Object.keys(objet) : [];

//     // Créer un nouvel objet pour stocker les paires clé-valeur
//     const nouvelObjet = {};

//     // Itérer sur les clés et affecter les nouvelles valeurs
//     nouvellesCles.forEach((cle, index) => {
//         nouvelObjet[index + 1] = objet[cle];
//     });

//     return nouvelObjet;
// }

export function RapportGainPerteVenteForm() {
    const [headers, setHeaders] = useState({ achats: {}, ventes: {} });
    // const VENTE_REVENDEUR = `VenteRevendeur`;
    const VENTE = `Vente`;
    // const VENTE_SUPER_DEALER = `VenteSuperDealer`;
    const GAINS_VENTE = `GainsVente`;
    const PERTES_VENTE = `PertesVente`;
    // const TRADITIONNEL_BUSINESS = `TraditionnelBusiness`;
    // const BUSINESS_MODEL = `BusinessModel`;

    // const { dataListReturn } = useSelector((reduxState) => ({
    //     dataListReturn:
    //         reduxState.dynamicNode[
    //             `evolutionsGainPerteVente-${TIME.utc000000(
    //                 TODAY.at000000()
    //             )}-${TIME.utc235959(TODAY.at235959())}`
    //         ]
    // }));
    const { formState, formDispatcher } = useGetReportDetail({
        defaultFields: {
            selectedStockSuperDealer: {},
            selectedDevise: { code: 'USD', designation: 'USD' },
            selectedType: { code: 'Quantite', designation: 'Unité' },
            listHeaders: []
        }
    });
    const { fields } = formState;
    const setValueOfHeader = useCallback((data) => {
        setHeaders(data);
    }, []);
    const mergeData = ({ data = [], levels = {} }) => {
        const dataToRender = [];
        const achatsValues = Object.values(levels?.achats || {})?.map(
            (achatItem) => ({
                ...achatItem,
                quantite: 0
            })
        );
        const ventesValues = Object.values(levels?.ventes || {})?.map(
            (venteItem) => ({
                ...venteItem,
                quantite: 0
            })
        );
        data.forEach((element, index, array) => {
            const findSibbling = dataToRender.find(
                (item) => item?.organisation === element?.organisation
            );
            if (!findSibbling) {
                const newElement = {};
                const getSibblingArray = array.filter(
                    (item) => item?.organisation === element?.organisation
                );
                getSibblingArray.forEach(
                    ({ organisation, devise }, indexI, SibblingArray) => {
                        const mappedArray = SibblingArray.map((mappedItem) => ({
                            ...mappedItem.niveaux
                        }));
                        newElement.organisation =
                            newElement.organisation || organisation;
                        newElement.devise = newElement.devise || devise;
                        newElement.gain = SibblingArray.reduce(
                            (a, b) => Add(a, b.gain || 0),
                            0
                        );
                        newElement.perte = SibblingArray.reduce(
                            (a, b) => Add(a, b.perte || 0),
                            0
                        );
                        newElement.profit = SibblingArray.reduce(
                            (a, b) => Add(a, b.profit || 0),
                            0
                        );
                        if (!newElement.niveaux) {
                            const dataObjet = {
                                achats: {},
                                ventes: {},
                                totalQuantite: 0,
                                totalMontant: 0
                            };
                            achatsValues.forEach(({ value }) => {
                                const findAchatExistingArray =
                                    mappedArray.filter(
                                        (mapData) => mapData?.achats?.[value]
                                    );
                                if (findAchatExistingArray.length) {
                                    const reducedValue =
                                        findAchatExistingArray.reduce(
                                            (a, b) =>
                                                Add(
                                                    a,
                                                    b?.achats?.[value]
                                                        ?.quantite || 0
                                                ),
                                            0
                                        );
                                    dataObjet.achats[value] = {
                                        value,
                                        quantite: reducedValue,
                                        montant: Mul(reducedValue, value)
                                    };
                                }
                            });
                            ventesValues.forEach(({ value }) => {
                                const findAchatExistingArray =
                                    mappedArray.filter(
                                        (mapData) => mapData.ventes[value]
                                    );
                                if (findAchatExistingArray.length) {
                                    const reducedValue =
                                        findAchatExistingArray.reduce(
                                            (a, b) =>
                                                Add(
                                                    a,
                                                    b?.ventes?.[value]
                                                        ?.quantite || 0
                                                ),
                                            0
                                        );
                                    dataObjet.ventes[value] = {
                                        value,
                                        quantite: reducedValue,
                                        montant: Mul(reducedValue, value)
                                    };
                                }
                            });

                            newElement.niveaux = dataObjet;
                            newElement.niveaux.totalMontant = Add(
                                achatsValues.reduce(
                                    (a, b) =>
                                        a +
                                        (dataObjet?.achats?.[`${b.value}`]
                                            ?.montant || 0),
                                    0
                                ),
                                ventesValues.reduce(
                                    (a, b) =>
                                        a +
                                        (dataObjet?.ventes?.[`${b.value}`]
                                            ?.montant || 0),
                                    0
                                )
                            );
                            newElement.niveaux.totalQuantite = Add(
                                achatsValues.reduce(
                                    (a, b) =>
                                        a +
                                        (dataObjet?.achats?.[`${b.value}`]
                                            ?.quantite || 0),
                                    0
                                ),
                                ventesValues.reduce(
                                    (a, b) =>
                                        a +
                                        (dataObjet?.ventes?.[`${b.value}`]
                                            ?.quantite || 0),
                                    0
                                )
                            );
                            // console.log(
                            //     'Data: achatsValues - reducedValue Montant',
                            //     achatsValues,
                            //     dataObjet,
                            //     achatsValues.reduce(
                            //         (a, b) =>
                            //             a +
                            //             (dataObjet?.achats?.[`${b.value}`]
                            //                 ?.montant || 0),
                            //         0
                            //     )
                            // );
                        }
                    }
                );
                dataToRender.push(newElement);
            }
        });
        // console.log(dataToRender);
        return dataToRender;
    };
    const listFormater = useCallback(
        (donnees) => {
            const dataTorender = [];
            (donnees?.[0]?.listReturn || []).map((item) => {
                const { operation, detail } = item;
                const { devise, valeurTypeProprietaire } = detail;
                let organisation = '';
                const operationKeys = Object.keys(operation || {});
                operationKeys.forEach((key) => {
                    let gain = 0;
                    let perte = 0;
                    if (key.includes(GAINS_VENTE)) {
                        if (key.includes(TYPE_BUSINESS.BUSINESS_MODEL)) {
                            organisation = `${valeurTypeProprietaire.designation}`;
                            gain += operation[key].montant || 0;
                        }
                        if (key.includes(TYPE_BUSINESS.TRADITIONNEL_BUSINESS)) {
                            organisation = `${valeurTypeProprietaire.designation}`;
                            gain += operation[key].montant || 0;
                        }
                        if (
                            !key.includes(
                                TYPE_BUSINESS.TRADITIONNEL_BUSINESS
                            ) &&
                            !key.includes(TYPE_BUSINESS.BUSINESS_MODEL)
                        ) {
                            organisation = `${valeurTypeProprietaire.designation}`;
                            gain += operation[key].montant || 0;
                        }
                    }
                    if (key.includes(PERTES_VENTE)) {
                        if (key.includes(TYPE_BUSINESS.BUSINESS_MODEL)) {
                            organisation = `${valeurTypeProprietaire.designation}`;
                            perte += operation[key].montant || 0;
                        }
                        if (key.includes(TYPE_BUSINESS.TRADITIONNEL_BUSINESS)) {
                            organisation = `${valeurTypeProprietaire.designation}`;
                            perte += operation[key].montant || 0;
                        }
                        if (
                            !key.includes(
                                TYPE_BUSINESS.TRADITIONNEL_BUSINESS
                            ) &&
                            !key.includes(TYPE_BUSINESS.BUSINESS_MODEL)
                        ) {
                            organisation = `${valeurTypeProprietaire.designation}`;
                            perte += operation[key].montant || 0;
                        }
                    }

                    dataTorender.push({
                        organisation,
                        devise,
                        gain,
                        perte,
                        profit: Sub(gain, perte),
                        niveaux: {
                            achats: operation?.[key]?.achats,
                            ventes: operation?.[key]?.ventes
                        }
                    });
                });

                return {};
            });
            setValueOfHeader(donnees?.[0]?.niveaux);
            // headers.current = donnees?.[0]?.niveaux;
            // console.log(
            //     mergeData({
            //         data: dataTorender.filter(
            //             (item) => !!item.organisation && item.devise === 'USD'
            //         ),
            //         levels: donnees?.[0]?.niveaux
            //     })
            // );
            return mergeData({
                data: dataTorender
                    .filter(
                        (item) => !!item.organisation && item.devise === 'USD'
                    )
                    .filter((item) => item.gain || item.perte),
                levels: donnees?.[0]?.niveaux
            });
            // return dataTorender.filter(
            //     (item) => !!item.organisation && item.devise === 'USD'
            // );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            GAINS_VENTE,
            PERTES_VENTE,
            fields.selectedDevise.code
            // headers?.achats,
            // headers?.ventes,
            // setValueOfHeader
        ]
    );

    const cutomTableHeader = () => (
        <>
            <tr className="border text-center text-justify">
                <th className="border" rowSpan={2}>
                    #
                </th>
                <th className="border" rowSpan={2}>
                    Organisation
                </th>
                <th
                    className="border"
                    colSpan={Object?.keys(headers?.ventes || {})?.length}
                >
                    Prix de Vente
                </th>
                <th
                    className="border"
                    colSpan={Object?.keys(headers?.achats || {})?.length}
                >
                    Prix d&apos;Achat
                </th>
                <th className="border" rowSpan={2}>
                    Total
                </th>
                <th className="border" rowSpan={2}>
                    Gain
                </th>
                <th className="border" rowSpan={2}>
                    Perte
                </th>
                <th className="border" rowSpan={2}>
                    Profit
                </th>
            </tr>
            <tr className="border text-center">
                {Object.keys(headers?.ventes ? headers?.ventes : {}).map(
                    (item) => (
                        <th key={item} className="border">
                            {item}
                        </th>
                    )
                )}
                {Object.keys(headers?.achats ? headers?.achats : {}).map(
                    (item) => (
                        <th key={item} className="border">
                            {item}
                        </th>
                    )
                )}
            </tr>
        </>
    );

    const mapper = useCallback(() => {
        const achats = Object?.keys(headers?.achats || {})?.length
            ? Object?.keys(headers?.achats)?.map((item) => ({
                  displayName: `${item}`,
                  field: `niveaux.achats.${item}.quantite`,
                  tdExtractor: (element) =>
                      fields.selectedType.code === 'Quantite'
                          ? element?.niveaux?.achats?.[`${item}`]?.quantite || 0
                          : element?.niveaux?.achats?.[`${item}`]?.montant || 0
              }))
            : [
                  {
                      displayName: 'Taux Op',
                      field: 'niveaux.achats.montant',
                      tdExtractor: () => 0
                  }
              ];
        const ventes = Object?.keys(headers?.ventes || {})?.length
            ? Object?.keys(headers?.ventes)?.map((item) => ({
                  displayName: `${item}`,
                  field: `niveaux.ventes.${item}.quantite`,
                  tdExtractor: (element) =>
                      fields.selectedType.code === 'Quantite'
                          ? element?.niveaux?.ventes?.[`${item}`]?.quantite || 0
                          : element?.niveaux?.ventes?.[`${item}`]?.montant || 0
              }))
            : [
                  {
                      displayName: 'Taux Op',
                      field: 'niveaux.ventes.montant',
                      tdExtractor: () => 0
                  }
              ];
        return [
            {
                displayName: 'Organisation',
                field: 'organisation'
            },
            ...ventes,
            ...achats,
            {
                displayName: 'Total',
                field:
                    fields.selectedType.code === 'Quantite'
                        ? 'niveaux.totalQuantite'
                        : 'niveaux.totalMontant',
                isTotal: true
            },
            {
                displayName: 'Gain',
                field: 'gain',
                isTotal: true
            },
            {
                displayName: 'Perte',
                field: 'perte',
                isTotal: true
            },
            {
                displayName: 'Profit',
                field: 'profit',
                isTotal: true
            }
        ];
    }, [fields.selectedType.code, headers?.achats, headers?.ventes]);

    return (
        <>
            {/* <StateShowerComponent state={formState?.fields} /> */}
            <ReduxReportTemplate
                cutomTableHeader={cutomTableHeader}
                listTitle={`En ${fields.selectedType.designation}`}
                dynamicNodeName={`evolutionsGainPerteVente-${TIME.utc000000(
                    TODAY.at000000()
                )}-${TIME.utc235959(TODAY.at235959())}`}
                formState={formState}
                formDispatcher={formDispatcher}
                functionName={URL_CONST.GET_LIST_COMPTE_USER}
                listFormater={listFormater}
                formater={(data) => [data]}
                mapper={mapper()}
                isAddTotalTr
                payload={{
                    dateDebut: TIME.utc000000(TODAY.at000000()),
                    dateFin: TIME.utc235959(TODAY.at235959()),
                    typeEntite: 'Journalier',
                    type: VENTE,
                    estObjet: true,
                    estOrganisation: true,
                    estAllOrganisation: true
                }}
                defaultPayloadMapperValues={{
                    typeEntite: 'Journalier',
                    type: VENTE,
                    estObjet: true,
                    typesEntite: ['VenteSuperDealer', 'VenteRevendeur'],
                    estOrganisation: true,
                    estAllOrganisation: true,
                    estNotDate: true
                }}
                payloadMapper={[
                    {
                        name: 'dateDebut',
                        property: 'dateDebut',
                        isRequired: true,
                        displayName: 'Date début'
                    },
                    {
                        name: 'dateFin',
                        property: 'dateFin',
                        isRequired: true,
                        displayName: 'Date fin'
                    }
                ]}
            >
                <CustomRow>
                    <CustomDropdown
                        options={[
                            { code: 'Quantite', designation: 'Unité' },
                            { code: 'Montant', designation: 'USD' }
                        ]}
                        label="Valeur : "
                        labelClassName="col-12"
                        divClassName="col-12"
                        defaultOption="Selectionner une valeur"
                        formDispatcher={formDispatcher}
                        name="selectedType"
                        id="selectedTypetypeExpressionSelector"
                        formState={formState}
                        idProperty="code"
                    />
                </CustomRow>
            </ReduxReportTemplate>
        </>
    );
}
