import React from 'react';
import { AiFillMinusCircle } from 'react-icons/ai';
import {
    IoIosAddCircleOutline,
    IoIosArrowDropdown,
    IoIosArrowDropup
} from 'react-icons/io';
import { MOYEN_PAIEMENT } from '@napp-inc/jnapp-util';
import {
    CustomLiteDynamicDropdown,
    CustomLiteDynamicInput
} from '../../../../../../components';

export function SecteurRowSelector({
    // type = 'Charge',
    // categorie = 'fixe',
    handleRemove,
    handleAddHighLevel,
    index,
    detailIndex,
    formFields,
    onToggleView,
    handleSelectChange,
    directions,
    detail,
    charges,
    field,
    secteurs,
    setFormFields,
    getClassNames,
    handleChange,
    caisses
}) {
    return (
        <div className="d-flex flex-row align-items-center justify-content-center">
            <div
                style={{
                    height: '46px',
                    width: '16px'
                }}
            >
                <p />
            </div>
            {/* Directions */}
            <CustomLiteDynamicDropdown
                containerClassName={`${
                    getClassNames({
                        moyenPaiement: formFields[index]?.typeMoyenPaiement,
                        estQuantite: formFields[index]?.estQuantite
                    })?.directionClass
                }`}
                // containerClassName="col-5 me-4"
                ariaLabel="Direction Selector"
                name="direction|directionDesignation"
                value={detail?.direction}
                options={directions}
                hiddenOption="Selectionnez une direction"
                onChange={handleSelectChange}
                handleProps={{
                    index,
                    indexAtSecondLevel: detailIndex,
                    setFormFields,
                    formFields
                }}
                labelStyle={{
                    fontSize: '13px',
                    paddingBottom: '8px',
                    lineHeight: '20px',
                    minHeight: '20px',
                    display: 'block',
                    color: 'rgba(0,0,0,0.5)'
                }}
                label="Direction"
                selectClassName="form-select form-select-sm col-3"
            />
            {/* Secteur */}
            <CustomLiteDynamicDropdown
                containerClassName={`${
                    getClassNames({
                        moyenPaiement: formFields[index]?.typeMoyenPaiement,
                        estQuantite: formFields[index]?.estQuantite
                    })?.secteurClass
                }`}
                // containerClassName="col-4 me-3"
                ariaLabel="Secteur Selector"
                name="secteur.code|secteur.designation"
                value={detail?.secteur?.code}
                options={secteurs.filter((secteur) => {
                    if (
                        formFields[index].details[detailIndex].direction ===
                        'OKAPI_DIRECT_NAPP'
                    )
                        return secteur?.direction?.estNapp;
                    return !secteur?.direction?.estNapp;
                })}
                hiddenOption="Selectionnez un secteur"
                onChange={handleSelectChange}
                handleProps={{
                    index,
                    indexAtSecondLevel: detailIndex,
                    setFormFields,
                    formFields
                }}
                labelStyle={{
                    fontSize: '13px',
                    paddingBottom: '8px',
                    lineHeight: '20px',
                    minHeight: '20px',
                    display: 'block',
                    color: 'rgba(0,0,0,0.5)'
                }}
                label="Secteur"
                selectClassName="form-select form-select-sm col-3"
                disabled={
                    charges.find((charge) => charge.code === field.element.code)
                        ?.estRempliAutomatique
                }
            />
            {/* Caisse */}
            {formFields[index].typeMoyenPaiement === MOYEN_PAIEMENT.CASH ? (
                <CustomLiteDynamicDropdown
                    containerClassName={
                        getClassNames({
                            moyenPaiement: formFields[index]?.typeMoyenPaiement,
                            estQuantite: formFields[index]?.estQuantite
                        })?.caisseSecteurClass
                    }
                    ariaLabel="Caisse Selector"
                    name="idCaisse|caisseDesignation"
                    value={detail?.idCaisse}
                    options={
                        caisses.filter(
                            (caisse) =>
                                caisse.devise ===
                                charges.find(
                                    (charge) =>
                                        charge.code === field?.element?.code
                                )?.devise
                        ) || []
                    }
                    hiddenOption="Selectionnez la caisse"
                    onChange={handleSelectChange}
                    handleProps={{
                        index,
                        indexAtSecondLevel: detailIndex,
                        setFormFields,
                        formFields
                    }}
                    labelStyle={{
                        fontSize: '13px',
                        paddingBottom: '8px',
                        lineHeight: '20px',
                        minHeight: '20px',
                        display: 'block',
                        color: 'rgba(0,0,0,0.5)'
                    }}
                    label="Caisse"
                    selectClassName="form-select form-select-sm col-3"
                    idProperty="id"
                />
            ) : null}
            {formFields[index].estQuantite ? (
                <CustomLiteDynamicInput
                    name="prix"
                    placeholder="Prix..."
                    containerClassName={
                        getClassNames({
                            moyenPaiement: formFields[index]?.typeMoyenPaiement,
                            estQuantite: formFields[index]?.estQuantite
                        })?.prixSecteurClass
                    }
                    labelStyle={{
                        fontSize: '13px',
                        paddingBottom: '8px',
                        lineHeight: '20px',
                        minHeight: '20px',
                        display: 'block',
                        color: 'rgba(0,0,0,0.5)'
                    }}
                    label="Prix"
                    value={detail.prix}
                    onChange={handleChange}
                    inputProps={{
                        index,
                        indexAtSecondLevel: detailIndex,
                        setFormFields,
                        formFields
                    }}
                    inputClassName="form-control form-control-sm col-2"
                />
            ) : null}
            <div className="d-flex">
                <div className="col-auto pt-2 me-2">
                    <AiFillMinusCircle
                        role="button"
                        title="Supprimer Informations du secteur"
                        className="text-secondary fs-5"
                        onClick={() =>
                            handleRemove({
                                index,
                                indexAtSecondLevel: detailIndex,
                                formFields,
                                setFormFields
                            })
                        }
                    />
                </div>
                <div className="col-auto pt-2 me-2">
                    <IoIosAddCircleOutline
                        role="button"
                        title="Ajouter Secteur"
                        className="text-primary fs-5"
                        onClick={() =>
                            handleAddHighLevel({
                                type: 'Secteur',
                                ancestorId: index,
                                formFields,
                                setFormFields
                            })
                        }
                    />
                </div>
                <div className="col-auto pt-2">
                    {formFields[index].details[detailIndex].isTooggled ? (
                        <IoIosArrowDropup
                            className="fs-5"
                            title="Regrouper les détails"
                            role="button"
                            onClick={() =>
                                onToggleView({
                                    code: formFields[index].details[detailIndex]
                                        .secteur.code,
                                    index,
                                    indexAtSecondLevel: detailIndex,
                                    formFields,
                                    setFormFields
                                })
                            }
                        />
                    ) : (
                        <IoIosArrowDropdown
                            className="fs-5"
                            title="Afficher les détails"
                            role="button"
                            onClick={() =>
                                onToggleView({
                                    code: formFields[index].details[detailIndex]
                                        .secteur.code,
                                    index,
                                    indexAtSecondLevel: detailIndex,
                                    formFields,
                                    setFormFields
                                })
                            }
                        />
                    )}
                </div>
            </div>
        </div>
    );
}
