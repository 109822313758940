import { buildYupErrorObject, FORM_CONST } from '@napp-inc/jnapp-util';
import React from 'react';
import { InputFeedback } from './input-feedback';
// import PropTypes from 'prop-types';

/**
 * Composant personnalisé CustomInput
 * @param {Object} param0 object qui contient les props du composant:
 * @param {string} param0.id Identifiant unique du composant
 * @param {string} param0.placeholder Texte affiché dans le composant avant la saisie de données
 * @param {string} param0.type Type de données saisies dans le composant
 * @param {string} param0.isFloat Indique si un composant de type number accepte les valeurs deciamles, par défaut non
 * @param {string} param0.name Nom du champ du formulaire qui correspond à une propriété du state
 * @param {boolean} param0.disabled Désactive le composant ou Active le composant
 * @param {string} param0.label Texte affiché dans le label du composant
 * @param {string} param0.labelClassName Classe CSS du label
 * @param {string} param0.inputClassName Classe CSS du input du composant
 * @param {string} param0.divClassName Classe CSS du div du composant
 * @param {boolean} param0.readOnly Indique si le composant est en lecture seule ou non
 * @param {Object} param0.formState State du composant
 * @param {Function} param0.formDispatcher Fonction qui permet d'envoyer des actions au store du composant
 * @param {Object} param0.uiValidator Schema de validation du composant
 * @param {Boolean} param0.isInputGroup vrai si la dispostion doit être en colonne
 * @param {Boolean} param0.min valeur minimale du champ (type : number)
 * @param {Boolean} param0.max valeur maximale du champ (type : number)
 * @param {Function} param0.callback fonction à executer en cas de handle change si jamais elle exite
 * @param {NUmber} param0.numberAfertComma Nombre après la virgule
 * @returns {React.Component}
 */
export function CustomInputDateTime({
    id,
    type = 'datetime-local',
    name,
    disabled = false,
    label,
    formDispatcher,
    labelClassName = 'col-sm-2',
    inputClassName,
    readOnly = false,
    formState = {},
    uiValidator,
    isInputGroup = true,
    inputTitle = '',
    divClassName = 'col-sm-10',
    min,
    max,
    onKeyUp
}) {
    const { error, fields, form } = formState;
    const handleChange = async (event) => {
        const { value: currentValue } = event.target;
        formDispatcher({
            type: FORM_CONST.TEXT_FIELD_CHANGE,
            payload: { field: name, data: currentValue }
        });
        if (uiValidator && form.isSubmitted) {
            uiValidator
                .validate(
                    { ...fields, [name]: currentValue },
                    {
                        abortEarly: false
                    }
                )
                .then(() => {
                    formDispatcher({
                        type: FORM_CONST.VALIDATION_SUCCESS
                    });
                })
                .catch((erreur) => {
                    if (erreur.name === 'ValidationError') {
                        const err = buildYupErrorObject(erreur);
                        formDispatcher({
                            type: FORM_CONST.SET_FIELDS_ERROR,
                            payload: {
                                message: { ...err },
                                status: FORM_CONST.CREATION_ECHEC
                            }
                        });
                    }
                });
        }
    };
    return (
        <>
            {label ? (
                <label
                    htmlFor={id}
                    className={`${
                        isInputGroup ? 'form-label' : 'col-form-label'
                    } ${labelClassName}`}
                >
                    {label}
                </label>
            ) : null}

            <div
                className={`${
                    isInputGroup ? 'input-group' : ''
                } has-validation ${divClassName}`}
            >
                <input
                    onKeyUp={onKeyUp}
                    title={inputTitle}
                    name={name}
                    readOnly={readOnly}
                    className={`form-control ${
                        error && error[name] ? `is-invalid` : null
                    } ${inputClassName}`}
                    disabled={disabled}
                    id={id}
                    onChange={handleChange}
                    value={fields && fields[name]}
                    min={min}
                    max={max}
                    type={type}
                />
                <InputFeedback>{error && error[name]}</InputFeedback>
            </div>
        </>
    );
}
