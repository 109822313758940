import { useCallback, useEffect } from 'react';
import {
    submitPutAction,
    useFormGlobalReducer,
    useFetchAndListening,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    formInitialState,
    FORM_CONST,
    PROFIL_SYSTEM,
    Str
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    ConditionalRenderingWrapper,
    CustomAlert,
    CustomButtonLoader,
    CustomCol,
    CustomDropdown,
    CustomInput,
    CustomRow,
    CustomTextAreaInput,
    FormWrapper
} from '../../../../components';
import { REDUX_NODE_NAME } from '../../../../redux';
import { URL_CONST, useDebounce } from '../../../../util';
import { NotificationPushFormSchema as uiValidator } from './validation';

const defaultFields = {
    message: '',
    selectedSujet: {},
    title: '',
    url: '',
    list: [],
    meta: '',
    metaToParse: '',
    selectedNature: {},
    selectedFormulaire: {},
    previewValue: ''
    // imageUrl: ''
};

const defaultDependencies = { fields: ['sujet'] };

export function CreationNotificationPushForm() {
    const reduxDispatcher = useDispatch();
    /**
     * Get data on redux store
     */
    const { firebaseUser, sujetsDisponible, etatSujetDisponible, nappUser } =
        useSelector((reduxState) => ({
            firebaseUser: reduxState.firebaseUser,
            sujetsDisponible: reduxState.sujetsDisponible,
            etatSujetDisponible: reduxState.etat.sujetsDisponible.etat,
            nappUser: reduxState.nappUser
        }));

    /**
     * mapprt Key word
     */
    const mapperKeyWord = [
        {
            key: '[USER.PRENOM]',
            value: 'Prenom'
        },
        {
            key: '[USER.NOM]',
            value: 'Nom'
        },
        {
            key: '[USER.POSTNOM]',
            value: 'Postnom'
        },
        {
            key: '[USER.TILL_NUMBER]',
            value: 'TillNumber'
        },
        {
            key: '[USER.PROFIL]',
            value: 'Profil'
        },
        {
            key: '[USER.ORGANISATION]',
            value: 'Organisation'
        },
        {
            key: '[USER.TYPE_ORGANISATION]',
            value: 'Type Organisation'
        },
        {
            key: '[USER.ORGANISATION_PARRAIN]',
            value: 'Organisation Parrain'
        },
        {
            key: '[USER.PARRAIN]',
            value: 'Parrain'
        }
    ];
    /**
     * Common form processes
     */
    const { idToken, profilUser } = useGetUserDetail({
        firebaseUser,
        nappUser
    });
    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: defaultDependencies,
        elements: ['formulaire']
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form, elements } = formState;

    const subjectTargeted = useCallback(() => {
        const findedSuject = sujetsDisponible?.find(
            (item) =>
                item?.valeurTypeEntite ===
                fields?.selectedSujet?.valeurTypeEntite
        );
        return findedSuject || {};
    }, [fields?.selectedSujet?.valeurTypeEntite, sujetsDisponible])();
    /**
     * Cloud Functions
     */
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_SUJET_DISPONILBLE,
                nodeName: REDUX_NODE_NAME.SUJETS_DISPONIBLE,
                etat: etatSujetDisponible
            }
        ]
    });

    /**
     * Debounce
     */
    useDebounce({
        name: 'selectedSujet',
        preview: 'previewValue',
        propertyInName: 'valeurTypeEntite',
        isObject: true,
        formDispatcher,
        formState,
        delay: 1500,
        idToken,
        functionName: URL_CONST.GET_LIST_FORMULAIRE_DISPONIBLE,
        elementPropertyName: 'formulaire',
        payloadToAdd: {
            sujet: fields.selectedSujet.valeurTypeEntite
        },
        dataLength: 0,
        condition: fields.selectedNature.code === `FORM`
    });

    const addDynamicProperty = (item) => {
        const data = `${fields.message.trim()} ${item}`;
        formDispatcher({
            type: FORM_CONST.TEXT_FIELD_CHANGE,
            payload: { field: 'message', data }
        });
    };

    const getFormulaireById = useCallback(() => {
        if (
            fields.selectedNature.code === `FORM` &&
            fields.selectedFormulaire.id &&
            !!elements?.formulaire?.value?.length
        ) {
            const element = elements?.formulaire?.value?.find(
                (item) => item.id === fields.selectedFormulaire.id
            );
            return element;
        }
        return {};
    }, [
        elements?.formulaire?.value,
        fields.selectedFormulaire.id,
        fields.selectedNature.code
    ]);

    useEffect(() => {
        const element = getFormulaireById();
        if (element?.texteTraite && fields.selectedNature.code === `FORM`) {
            formDispatcher({
                type: FORM_CONST.TEXT_FIELD_CHANGE,
                payload: { field: 'message', data: element?.texteTraite }
            });
        }
    }, [fields.selectedNature.code, formDispatcher, getFormulaireById]);

    const handleSubmit = (e) => {
        e.preventDefault();
        const { selectedSujet, message, title, url, meta, metaToParse } =
            fields;
        // if (!selectedSujet.code || !message) return;
        const payload = {
            sujet: selectedSujet?.valeurTypeEntite,
            // message,
            // title,
            data: {}
            // imageUrl: '', // TODO: properties to add
            // creator: ''
        };

        if (message) {
            payload.message = message;
        }
        if (title) {
            payload.title = title;
        }
        if (fields.selectedFormulaire?.id) {
            payload.idFormulaireServiceClient = fields.selectedFormulaire?.id;
        }
        if (meta && !Str.isJson(meta)) {
            formDispatcher({
                type: FORM_CONST.SET_FORM_ERROR,
                payload: {
                    message: 'Vérifiez les valeurs renseignées dans payload',
                    status: FORM_CONST.MODIFICATION_ECHEC
                }
            });
            return;
        }
        if (meta && Str.isJson(meta)) {
            payload.data.payload = JSON.parse(meta);
        }
        if (url) {
            payload.data.url = url;
        }
        if (metaToParse && !Str.isJson(metaToParse)) {
            formDispatcher({
                type: FORM_CONST.SET_FORM_ERROR,
                payload: {
                    message: 'Vérifiez les valeurs renseignées dans meta',
                    status: FORM_CONST.MODIFICATION_ECHEC
                }
            });
            return;
        }
        if (metaToParse && Str.isJson(metaToParse)) {
            payload.meta = JSON.parse(metaToParse);
        }
        // if (fields.imageFile) {
        //     payload.imageUrl = fields.imageFile;
        // }
        formDispatcher(
            submitPutAction({
                idToken,
                functionName: URL_CONST.SEND_NOTIFICATION_SUJET,
                payload,
                fields,
                reduxNodeName: REDUX_NODE_NAME.NOTIFICATION_PUSH,
                uiValidator,
                reduxDispatcher
            })
        );
    };
    const renderContent = () => (
        <FormWrapper
            formState={formState}
            formDispatcher={formDispatcher}
            listDependencies={[
                {
                    dependency: 'sujet',
                    etat: etatSujetDisponible
                }
            ]}
        >
            <CustomRow>
                <CustomDropdown
                    options={[
                        {
                            code: `NOTIFICATION`,
                            designation: `Formulaire Notification`
                        },
                        {
                            code: `FORM`,
                            designation: `Formulaire Service Client`
                        }
                    ]}
                    defaultOption="Selectionner un nature "
                    label="Nature Notification* : "
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    formDispatcher={formDispatcher}
                    name="selectedNature"
                    id="selectedNatureCreationSendNotification"
                    uiValidator={uiValidator}
                    formState={formState}
                />
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    options={sujetsDisponible}
                    defaultOption="Selectionner Sujet"
                    label="Sujet* : "
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    formDispatcher={formDispatcher}
                    name="selectedSujet"
                    id="selectedSujetCreationSendNotification"
                    uiValidator={uiValidator}
                    formState={formState}
                    idProperty="valeurTypeEntite"
                    propertyToDisplay="title"
                />
            </CustomRow>
            {elements?.formulaire?.status ===
            FORM_CONST.FETCH_ELEMENT_IN_PROGRESS ? (
                <div className="placeholder-glow">
                    <span className="placeholder col-5" />
                    <span className="placeholder col-12" />
                </div>
            ) : null}
            {elements?.formulaire?.status ===
            FORM_CONST.FETCH_ELEMENT_FAILED ? (
                <CustomAlert error="une erreur est survenue lors du chargement" />
            ) : null}
            {elements?.formulaire?.status ===
                FORM_CONST.FETCH_ELEMENT_SUCCESS &&
            !elements?.formulaire?.value?.length ? (
                <CustomAlert successMessage="Aucun élément disponible" />
            ) : null}
            <ConditionalRenderingWrapper
                isShouldBeRendered={!!elements?.formulaire?.value?.length}
            >
                <CustomRow className="mt-3">
                    <CustomDropdown
                        options={elements?.formulaire?.value || []}
                        defaultOption="Selectionner formulaire"
                        label="Formulaire* :"
                        labelClassName="col-12 col-sm-2"
                        divClassName="col-12 col-sm-10"
                        formDispatcher={formDispatcher}
                        name="selectedFormulaire"
                        id="selectedFormulaireNotificationId"
                        formState={formState}
                        idProperty="id"
                        propertyToDisplay="titre"
                    />
                </CustomRow>
            </ConditionalRenderingWrapper>
            <ConditionalRenderingWrapper
                isShouldBeRendered={
                    fields.selectedNature.code === `NOTIFICATION`
                }
            >
                <ConditionalRenderingWrapper
                    isShouldBeRendered={!!subjectTargeted?.description}
                >
                    <CustomAlert
                        successMessage={subjectTargeted?.description}
                    />
                </ConditionalRenderingWrapper>
                <CustomRow>
                    <CustomInput
                        type="text"
                        label="Titre*: "
                        labelClassName="col-12 col-sm-2"
                        divClassName="col-12 col-sm-10"
                        isInputGroup={false}
                        placeholder="Saisissez le titre de la notification..."
                        formDispatcher={formDispatcher}
                        name="title"
                        id="titleNotificationId"
                        formState={formState}
                        uiValidator={uiValidator}
                    />
                </CustomRow>
                <CustomRow>
                    <CustomInput
                        type="text"
                        label="URL: "
                        labelClassName="col-12 col-sm-2"
                        divClassName="col-12 col-sm-10"
                        isInputGroup={false}
                        placeholder="Saisissez l'url..."
                        formDispatcher={formDispatcher}
                        name="url"
                        id="urlNotificationId"
                        formState={formState}
                        uiValidator={uiValidator}
                    />
                </CustomRow>
                <ConditionalRenderingWrapper
                    isShouldBeRendered={
                        profilUser ===
                        PROFIL_SYSTEM?.PROFIL_DEVELOPPEUR_MOBILE?.code
                    }
                >
                    <CustomRow>
                        <CustomInput
                            type="text"
                            label="Payload: "
                            labelClassName="col-12 col-sm-2"
                            divClassName="col-12 col-sm-10"
                            isInputGroup={false}
                            placeholder="Payload..."
                            formDispatcher={formDispatcher}
                            name="meta"
                            id="metaNotificationId"
                            formState={formState}
                            uiValidator={uiValidator}
                        />
                    </CustomRow>
                    <CustomRow>
                        <CustomInput
                            type="text"
                            label="Meta: "
                            labelClassName="col-12 col-sm-2"
                            divClassName="col-12 col-sm-10"
                            isInputGroup={false}
                            placeholder="Meta..."
                            formDispatcher={formDispatcher}
                            name="metaToParse"
                            id="metaNotificationId"
                            formState={formState}
                            uiValidator={uiValidator}
                        />
                    </CustomRow>
                </ConditionalRenderingWrapper>
            </ConditionalRenderingWrapper>
            <CustomRow>
                <CustomRow>
                    <CustomTextAreaInput
                        isInputGroup={false}
                        label="Message*: "
                        labelClassName="col-12 col-sm-2"
                        divClassName="col-12 col-sm-10"
                        placeholder="Message à envoyer"
                        formDispatcher={formDispatcher}
                        name="message"
                        id="messageCreationPushId"
                        uiValidator={uiValidator}
                        formState={formState}
                        rows={5}
                        disabled={fields.selectedNature.code === `FORM`}
                    />
                    <ConditionalRenderingWrapper
                        isShouldBeRendered={
                            fields.selectedNature.code === `FORM`
                        }
                    >
                        <div className="form-text offset-sm-2">
                            Ce texte ne peut pas subir de modification !
                        </div>
                    </ConditionalRenderingWrapper>
                </CustomRow>
                <ConditionalRenderingWrapper
                    isShouldBeRendered={
                        fields.selectedNature.code === `NOTIFICATION`
                    }
                >
                    <div className="offset-sm-2">
                        <span className="fw-light">
                            Valeur à rajouter dynamiquement
                        </span>
                        <div className="mb-3" />
                        {mapperKeyWord.map((item) => (
                            <span
                                role="button"
                                tabIndex={0}
                                className="badge bg-light text-dark me-3 border"
                                key={item?.key}
                                onClick={() => addDynamicProperty(item?.key)}
                                onKeyDown={() => addDynamicProperty(item?.key)}
                            >
                                {item?.value}
                            </span>
                        ))}
                    </div>
                </ConditionalRenderingWrapper>
            </CustomRow>
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Sauvegarder"
                        disabled={
                            form.status === FORM_CONST.MODIFICATION_EN_COURS
                        }
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
