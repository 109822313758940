/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
// import styled from 'styled-components';
import { useTable, usePagination, useSortBy } from 'react-table';
import { makeData } from './fakedata';

// Create an editable cell renderer
function EditableNumberCell({
    cell: { value },
    row: { index },
    column: { id },
    updateMyData // This is a custom function that we supplied to our table instance
}) {
    const onChange = (e) => {
        updateMyData(index, id, parseInt(e.target.value, 10));
    };

    return <input value={value} onChange={onChange} type="number" />;
}

// Be sure to pass our updateMyData and the disablePageResetOnDataChange option
function Table({ columns, data, updateMyData, disablePageResetOnDataChange }) {
    // For this example, we're using pagination to illustrate how to stop
    // the current page from resetting when our data changes
    // Otherwise, nothing is different here.
    const {
        getTableProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize }
    } = useTable(
        {
            columns,
            data,
            disablePageResetOnDataChange,
            // updateMyData isn't part of the API, but
            // anything we put into these options will
            // automatically be available on the instance.
            // That way we can call this function from our
            // cell renderer!
            updateMyData
        },
        useSortBy,
        usePagination
    );

    // Render the UI for your table
    // const maped = page.map((item) => item.original);
    // console.log(maped);
    return (
        <>
            <table className="table table-bordered" {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th
                                    {...column.getHeaderProps(
                                        column.getSortByToggleProps()
                                    )}
                                >
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {page.map(
                        (row /* i */) =>
                            prepareRow(row) || (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => (
                                        <td {...cell.getCellProps()}>
                                            {cell.render('Cell')}
                                        </td>
                                    ))}
                                </tr>
                            )
                    )}
                </tbody>
            </table>
            <div className="pagination">
                <button
                    type="button"
                    onClick={() => gotoPage(0)}
                    disabled={!canPreviousPage}
                >
                    {'<<'}
                </button>{' '}
                <button
                    type="button"
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                >
                    {'<'}
                </button>{' '}
                <button
                    type="button"
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                >
                    {'>'}
                </button>{' '}
                <button
                    type="button"
                    onClick={() => gotoPage(pageCount - 1)}
                    disabled={!canNextPage}
                >
                    {'>>'}
                </button>{' '}
                <span>
                    Page{' '}
                    <strong>
                        {pageIndex + 1} of {pageOptions.length}
                    </strong>{' '}
                </span>
                <span>
                    | Go to page:{' '}
                    <input
                        type="number"
                        defaultValue={pageIndex + 1}
                        onChange={(e) => {
                            const pageT = e.target.value
                                ? Number(e.target.value) - 1
                                : 0;
                            gotoPage(pageT);
                        }}
                        style={{ width: '100px' }}
                    />
                </span>{' '}
                <select
                    value={pageSize}
                    onChange={(e) => {
                        setPageSize(Number(e.target.value));
                    }}
                >
                    {[10, 20, 30, 40, 50].map((pgSize) => (
                        <option key={pgSize} value={pgSize}>
                            Show {pgSize}
                        </option>
                    ))}
                </select>
            </div>
        </>
    );
}

export function EditableData() {
    const columns = React.useMemo(
        () => [
            {
                Header: 'First Name',
                accessor: 'firstName'
            },
            {
                Header: 'Score 1',
                accessor: 'score1',
                Cell: EditableNumberCell
            },
            {
                Header: 'Score 2',
                accessor: 'score2',
                Cell: EditableNumberCell
            },
            {
                Header: 'Score 3',
                accessor: 'score3',
                Cell: EditableNumberCell
            },
            {
                Header: 'Total Score',
                accessor: (row) =>
                    [row.score1, row.score2, row.score3].reduce(
                        (sum, current) => sum + current,
                        0
                    )
                // Cell: ({ row: { original }}) =>
                // [original.score1, original.score2, original.score3].reduce(
                //   (sum, current) => sum + current,
                //   0
                // )
            }
        ],
        []
    );

    const [data, setData] = React.useState(makeData);
    const [originalData] = React.useState(data);
    const [skipPageReset, setSkipPageReset] = React.useState(false);

    // We need to keep the table from resetting the pageIndex when we
    // Update data. So we can keep track of that flag with a ref.

    // When our cell renderer calls updateMyData, we'll use
    // the rowIndex, columnID and new value to update the
    // original data
    const updateMyData = (rowIndex, columnID, value) => {
        // We also turn on the flag to not reset the page
        setSkipPageReset(true);
        setData((old) =>
            old.map((row, index) => {
                if (index === rowIndex) {
                    return {
                        ...old[rowIndex],
                        [columnID]: value
                    };
                }
                return row;
            })
        );
    };

    // After data chagnes, we turn the flag back off
    // so that if data actually changes when we're not
    // editing it, the page is reset
    React.useEffect(() => {
        setSkipPageReset(false);
    }, [data]);

    // Let's add a data resetter/randomizer to help
    // illustrate that flow...
    const resetData = () => setData(originalData);

    return (
        <>
            <button type="button" onClick={resetData}>
                Reset Data
            </button>
            <Table
                columns={columns}
                data={data}
                updateMyData={updateMyData}
                disablePageResetOnDataChange={skipPageReset}
            />
        </>
    );
}
