import { RapportDashboardCaisseEmoneyFormTranspose } from './rapport-caisse-emoney-transpose';

export function RapportCaisseEmoneyOrganisationViewLayout() {
    return (
        <div className="container-fluid">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Evolution Caisse Emoney</h1>
            </div>
            <RapportDashboardCaisseEmoneyFormTranspose />
        </div>
    );
}
