import { Fragment, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Array, TIME, TODAY } from '@napp-inc/jnapp-util';
import { URL_CONST, useGetReportDetail } from '../../../../util';
import { ReduxReportTemplate } from '../../template';
import { TypeExpressionValeurSelector } from '../../../../components';

export function RapportStockKinDBForm() {
    const { dataListReturn } = useSelector((reduxState) => ({
        dataListReturn:
            reduxState.dynamicNode[
                `evolutionsStockSimOranisation-${TIME.utc000000(
                    TODAY.at000000()
                )}-${TIME.utc235959(TODAY.at235959())}`
            ]
    }));
    const { formState, formDispatcher } = useGetReportDetail({
        defaultFields: {
            selectedTypeExpression: { id: 'USD' },
            selectedStockSuperDealer: {},
            listHeaders: []
        }
    });
    function removeDuplicates(array) {
        return [...new Set(array)];
    }
    const dataHeaders = useCallback(() => {
        const dataToExploit =
            formState.elements?.mouvements?.status === 'ETAT_INITIAL'
                ? dataListReturn
                : formState.elements?.mouvements?.value;
        const headers = (dataToExploit?.[0]?.listReturn || []).map(
            (item) => item?.detail?.marchand?.designation
        );
        return removeDuplicates(headers);
    }, [
        formState.elements?.mouvements?.status,
        formState.elements?.mouvements?.value,
        dataListReturn
    ])();
    const listFormater = (donnees) => {
        const result = (donnees?.[0]?.listReturn || []).map((item) => {
            const marchand = item?.detail?.marchand?.designation?.toLowerCase();
            const designation = item?.detail?.emplacement?.valeurTypeEntite
                ? `${item.detail.emplacement.valeurTypeEntite.prenom} ${item.detail.emplacement.valeurTypeEntite.nom}`
                : `Stock`;
            return {
                [marchand]: {
                    ...item
                },
                designation
            };
        });
        const groupedResult = Array.groupByProperty({
            array: result,
            property: 'designation'
        });
        const keys = Object.keys(groupedResult);
        const resultFinal = [];
        keys.forEach((key) => {
            let newObject = {};
            const grouped = groupedResult[key];
            grouped.forEach((data) => {
                newObject = {
                    ...newObject,
                    ...data
                };
            });
            resultFinal.push({
                designation: key,
                ...newObject
            });
        });

        if (result) {
            // setData(headers);
            return result;
        }

        return [];
    };
    const isBalance = formState?.fields?.selectedTypeExpression?.id === 'USD';

    const cutomTableHeader = () => (
        <>
            <tr className="border text-center text-justify">
                <th className="border" rowSpan={3}>
                    #
                </th>
                <th className="border" rowSpan={3}>
                    Stock Principal
                </th>
                {dataHeaders.map((item) => (
                    <th key={item} className="border" colSpan={6}>
                        {item}
                    </th>
                ))}
            </tr>
            <tr className="border text-center">
                {dataHeaders.map((item) => (
                    <Fragment key={item}>
                        <th className="border" rowSpan={2}>
                            Ouverture
                        </th>
                        <th className="border" rowSpan={2}>
                            Approvisionnement
                        </th>
                        <th className="border" rowSpan={2}>
                            Vente
                        </th>
                        <th className="border" colSpan={2}>
                            Transfert
                        </th>
                        <th className="border" rowSpan={2}>
                            Fermeture
                        </th>
                    </Fragment>
                ))}
            </tr>
            <tr className="border text-center">
                {dataHeaders.map((item) => (
                    <Fragment key={item}>
                        <th className="border">Debit</th>
                        <th className="border">Credit</th>
                    </Fragment>
                ))}
            </tr>
        </>
    );
    const mapper = useCallback(() => {
        const mappers = [
            {
                displayName: 'Emplacement',
                field: 'designation'
            }
        ];
        dataHeaders.forEach((item) => {
            const operation = [
                'ouverture',
                'ApproSim',
                'VenteSim',
                'TransfertDebitSim',
                'TransfertCreditSim',
                'fermeture'
            ];
            operation.forEach((element) => {
                const toLowerCaseElement = item.toLowerCase();
                if (element === 'ouverture') {
                    mappers.push({
                        displayName: 'Ouverture',
                        isTotal: true,
                        field: isBalance
                            ? `${toLowerCaseElement}.balance.soldeOuverture`
                            : `${toLowerCaseElement}.soldeOuverture`
                    });
                }
                if (element === 'fermeture') {
                    mappers.push({
                        displayName: 'Fermeture',
                        isTotal: true,
                        field: isBalance
                            ? `${toLowerCaseElement}.balance.soldeFermeture`
                            : `${toLowerCaseElement}.soldeFermeture`
                    });
                }
                if (element !== 'ouverture' && element !== 'fermeture') {
                    mappers.push({
                        displayName: element,
                        isTotal: true,
                        field: isBalance
                            ? `${toLowerCaseElement}.operation.${element}__${item}.balance.montant`
                            : `${toLowerCaseElement}.operation.${element}__${item}.quantite`
                    });
                }
            });
        });
        return mappers;
    }, [dataHeaders, isBalance]);
    return (
        <>
            {/* <StateShowerComponent state={formState?.fields} /> */}
            <ReduxReportTemplate
                cutomTableHeader={cutomTableHeader}
                listTitle={`Valeur en ${isBalance ? 'USD' : 'Quantité'}`}
                dynamicNodeName={`evolutionsStockSimOranisation-${TIME.utc000000(
                    TODAY.at000000()
                )}-${TIME.utc235959(TODAY.at235959())}`}
                formState={formState}
                formDispatcher={formDispatcher}
                functionName={URL_CONST.GET_LIST_STOCK_SIM_ORGANISATION}
                listFormater={listFormater}
                formater={(data) => [data]}
                mapper={mapper()}
                isAddTotalTr
                payload={{
                    dateDebut: TIME.utc000000(TODAY.at000000()),
                    dateFin: TIME.utc235959(TODAY.at235959()),
                    typeEntite: 'Journalier',
                    estOrganisation: true
                    // typeProprietaire: 'SuperDealer',
                    // typeRapport: 'EvolutionStock'
                }}
                defaultPayloadMapperValues={{
                    typeEntite: 'Journalier',
                    // typeRapport: 'EvolutionStock',
                    // typeProprietaire: 'SuperDealer',
                    estOrganisation: true
                }}
                payloadMapper={[
                    {
                        name: 'dateDebut',
                        property: 'dateDebut',
                        isRequired: true,
                        displayName: 'Date début'
                    },
                    {
                        name: 'dateFin',
                        property: 'dateFin',
                        isRequired: true,
                        displayName: 'Date fin'
                    },
                    {
                        name: 'selectedStockSuperDealer',
                        property: 'codeSuperDealer',
                        isRequired: false,
                        displayName: 'Super dealer',
                        callback: (item) => {
                            if (!item?.code || item?.code === 'all') return {};
                            return { codeSuperDealer: item?.code };
                        }
                    }
                ]}
            >
                <TypeExpressionValeurSelector
                    formState={formState}
                    formDispatcher={formDispatcher}
                    isSim
                />
                {/* <StockSuperDealerSelector
                    formState={formState}
                    formDispatcher={formDispatcher}
                    idProperty="code"
                    isAddAditionalOptions
                /> */}
            </ReduxReportTemplate>
        </>
    );
}
