import { ETAT, TIME, TODAY } from '@napp-inc/jnapp-util';
import {
    URL_CONST,
    getDesignationStock,
    useGetReportDetail
} from '../../../../util';
import { ReduxReportTemplate } from '../../template';
import { CustomDropdown } from '../../../../components';

export function RapportFacturationStockSimKinDBForm() {
    const { formState, formDispatcher } = useGetReportDetail({
        defaultFields: {
            selectedSuperDealer: {},
            selectedEtat: {}
        }
    });
    const listFormater = (donnees) => {
        const result = (donnees?.[0]?.listReturn || []).map((item) => {
            const typeBusiness = getDesignationStock(
                item?.detail?.marchand?.designation,
                item?.detail?.typeBusiness
            );
            return {
                ...item,
                id: `${item?.detail?.marchand?.reference}${typeBusiness}`,
                designation: typeBusiness,
                montantCDF:
                    item?.valeurTypeEntite?.devise === 'CDF'
                        ? item?.montant
                        : 0,
                montantUSD:
                    item?.valeurTypeEntite?.devise === 'USD' ? item?.montant : 0
            };
        });

        return result;
    };
    const customTotal = (data, devise) =>
        data
            .filter(
                (item) =>
                    item?.etat === ETAT.ETAT_VALIDER &&
                    item?.valeurTypeEntite?.devise === devise
            )
            .reduce((acc, red) => acc + red.montant, 0) || 0;
    return (
        <>
            {/* <StateShowerComponent state={formState?.fields} /> */}
            <ReduxReportTemplate
                dynamicNodeName={`rapportFacturationStockSimSuperDealer-${TIME.utc000000(
                    TODAY.at000000()
                )}-${TIME.utc235959(TODAY.at235959())}`}
                formState={formState}
                formDispatcher={formDispatcher}
                functionName={URL_CONST.GET_LIST_APPRO_STOCK_SUPER_DEALER}
                listFormater={listFormater}
                formater={(data) => [data]}
                mapper={[
                    {
                        displayName: 'Marchand',
                        field: 'marchand.designation'
                    },
                    {
                        displayName: 'Moyen Paiement',
                        field: 'typeEntite'
                    },
                    // {
                    //     displayName: 'Devise',
                    //     field: 'valeurTypeEntite.devise'
                    // },
                    {
                        displayName: 'Montant(CDF)',
                        field: 'montantCDF',
                        isCustomTotal: true,
                        devise: 'CDF'
                    },
                    {
                        displayName: 'Montant(USD)',
                        field: 'montantUSD',
                        isCustomTotal: true,
                        devise: 'USD'
                    },
                    {
                        displayName: 'Quantité',
                        field: 'quantite',
                        isTotal: true
                    },
                    {
                        displayName: 'Etat',
                        field: 'etat'
                    },
                    {
                        displayName: 'Creation',
                        field: 'dateCreation'
                    }
                ]}
                isAddTotalTr
                customTotal={customTotal}
                payload={{
                    dateDebut: TIME.utc000000(TODAY.at000000()),
                    dateFin: TIME.utc235959(TODAY.at235959()),
                    estListReturn: true,
                    estSim: true
                }}
                defaultPayloadMapperValues={{
                    estListReturn: true,
                    estSim: true
                }}
                payloadMapper={[
                    {
                        name: 'dateDebut',
                        property: 'dateDebut',
                        isRequired: true,
                        displayName: 'Date début'
                    },
                    {
                        name: 'dateFin',
                        property: 'dateFin',
                        isRequired: true,
                        displayName: 'Date fin'
                    },
                    {
                        name: 'selectedSuperDealer',
                        property: 'codeSuperDealer',
                        isRequired: false,
                        displayName: 'Super dealer',
                        callback: (item) => {
                            if (
                                !item?.id ||
                                item?.id === 'all' ||
                                item?.id === 'Napp'
                            )
                                return {};
                            return { referenceSuperDealer: item?.id };
                        }
                    },
                    {
                        name: 'selectedEtat.code',
                        property: 'etat',
                        isRequired: false,
                        displayName: 'Etat'
                    }
                ]}
            >
                {/* <SuperDealerSelector
                    formState={formState}
                    formDispatcher={formDispatcher}
                    estNonEmoney
                    isAddAditionalOptions
                    additionnalOptions={[
                        { id: 'all', code: 'all', designation: 'Tous' },
                        { id: 'Napp', code: 'Napp', designation: 'Napp' }
                    ]}
                /> */}
                <CustomDropdown
                    options={[
                        {
                            code: ETAT.ETAT_ENCOURS,
                            designation: 'ENCOURS'
                        },
                        {
                            code: ETAT.ETAT_ATTENTE_VALIDATION,
                            designation: 'ATTENTE VALIDATION'
                        },
                        {
                            code: ETAT.ETAT_VALIDER,
                            designation: 'VALIDER'
                        },
                        {
                            code: ETAT.ETAT_ANNULER,
                            designation: 'ANNULER'
                        }
                    ]}
                    label="Etat: "
                    labelClassName="col-12"
                    defaultOption="Selectionner état"
                    divClassName="col-12"
                    formDispatcher={formDispatcher}
                    name="selectedEtat"
                    id="selectedEtatRapportFacturationId"
                    // uiValidator={CreationApproCompteEmoneySchema}
                    formState={formState}
                />
            </ReduxReportTemplate>
        </>
    );
}
