/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useCallback } from 'react';
import {
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import { formInitialState, FORM_CONST, Array } from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomButtonLoader,
    CustomCheckButton,
    CustomCol,
    CustomInput,
    CustomListReferenceGroupSelection,
    CustomRow,
    FormWrapper
} from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { fuzzySearch, URL_CONST } from '../../../util';
import { mapper } from './util';

const defaultFields = {
    recherche: '',
    list: [],
    listObject: [],
    selectedAll: false
};

const defaultDependencies = { fields: ['retraitCrediterBalanceLot'] };

export function CreationCrediterBalanceLotForm() {
    const reduxDispatcher = useDispatch();
    /**
     * Get data on redux store
     */
    const {
        firebaseUser,
        retraitCrediterBalanceLot,
        etatRetraitCrediterBalanceLot
    } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        retraitCrediterBalanceLot: reduxState.retraitCrediterBalanceLot,
        etatRetraitCrediterBalanceLot:
            reduxState.etat.retraitCrediterBalanceLot.etat
    }));
    /**
     * Common form processes
     */
    const { idToken } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: defaultDependencies
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;

    const resultat = fuzzySearch({
        array: retraitCrediterBalanceLot,
        keys: [
            'etat',
            'numeroReference',
            'client.numero',
            'devise',
            'marchand.designation',
            'montant',
            'client.nom',
            'client.prenom'
        ],
        word: fields.recherche
    });

    /**
     * Recupération des données nécessaires
     */
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName:
                    URL_CONST.GET_LIST_RETRAIT_CREDITER_BALANCE_EN_LOT,
                nodeName: REDUX_NODE_NAME.RETRAIT_CREDITER_BALANCE_LOT,
                // payload: cfPayload.caisses,
                etat: etatRetraitCrediterBalanceLot
            }
        ]
    });

    const onChangeAllSelection = useCallback(
        (currentValue) => {
            if (currentValue && fields.list.length === resultat.length - 1) {
                formDispatcher({
                    type: FORM_CONST.TEXT_FIELD_CHANGE,
                    payload: { field: 'selectedAll', data: false }
                });
                return;
            }
            if (currentValue) {
                const idList = resultat.map((item) => item.id);
                formDispatcher({
                    type: FORM_CONST.FIELD_CHANGE,
                    payload: {
                        field: 'list',
                        data: idList
                    }
                });
                formDispatcher({
                    type: FORM_CONST.FIELD_CHANGE,
                    payload: {
                        field: 'listObject',
                        data: resultat
                    }
                });
                return;
            }
            if (!currentValue) {
                formDispatcher({
                    type: FORM_CONST.FIELD_CHANGE,
                    payload: {
                        field: 'list',
                        data: []
                    }
                });
                formDispatcher({
                    type: FORM_CONST.FIELD_CHANGE,
                    payload: {
                        field: 'listObject',
                        data: []
                    }
                });
            }
        },
        [fields.list.length, formDispatcher, resultat]
    );

    const totalCalculationOnSelect = useCallback(() => {
        const result = Array.groupByProperty({
            array: fields.listObject,
            property: 'devise'
        }); // fields.listObject.group(({ devise }) => devise);
        const keys = Object.keys(result);
        const total = {};
        keys.forEach((key) => {
            if (!total[key]) {
                total[key] = result[key].reduce(
                    (a, b) => a + (b?.montant || 0),
                    0
                );
            }
        });
        return total;
    }, [fields.listObject])();

    const handleChange = (e) => {
        const { id } = e.target;
        const existe = fields?.list?.find((item) => item === id);
        const sibbling = resultat.find((item) => item.id === id);
        if (existe) {
            formDispatcher({
                type: FORM_CONST.REMOVE_PRIMITIVE_ITEM,
                payload: {
                    field: 'list',
                    data: id
                }
            });
            formDispatcher({
                type: FORM_CONST.REMOVE_ITEM,
                payload: {
                    field: 'listObject',
                    data: { id }
                }
            });
        } else {
            formDispatcher({
                type: FORM_CONST.ADD_PRIMITIVE_ITEM,
                payload: {
                    field: 'list',
                    data: id
                }
            });
            formDispatcher({
                type: FORM_CONST.ADD_ITEM,
                payload: {
                    field: 'listObject',
                    data: sibbling
                }
            });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { list } = fields;
        if (!list.length) return;
        const payload = {
            list
        };
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_CREDITER_BALANCE_EN_LOT,
                payload,
                fields,
                reduxNodeName: REDUX_NODE_NAME.CREDITER_BALANCE_LOT,
                reduxDispatcher
            })
        );
    };
    const renderContent = () => (
        <FormWrapper
            formState={formState}
            formDispatcher={formDispatcher}
            listDependencies={[
                {
                    dependency: 'retraitCrediterBalanceLot',
                    etat: etatRetraitCrediterBalanceLot
                }
            ]}
        >
            <div>
                <div className="row">
                    <div className="mb-3 col-sm-5">
                        <CustomInput
                            inputClassName="form-control-sm"
                            placeholder="Recherche..."
                            formDispatcher={formDispatcher}
                            formState={formState}
                            name="recherche"
                        />
                    </div>
                    <div className="col-sm-2">
                        Résultat :{' '}
                        <span className="badge bg-primary">
                            {resultat?.length || 0}
                        </span>
                    </div>
                    <div className="col-sm-5">
                        Totaux Sélection{' '}
                        <span className="badge bg-primary">
                            {fields.listObject?.length || 0}
                        </span>{' '}
                        :{' '}
                        <span className="badge bg-primary">
                            USD : {totalCalculationOnSelect?.USD || 0}
                        </span>{' '}
                        |{' '}
                        <span className="badge bg-primary">
                            CDF : {totalCalculationOnSelect?.CDF || 0}
                        </span>
                    </div>
                </div>
                <CustomCheckButton
                    divClassName="mb-3"
                    formDispatcher={formDispatcher}
                    name="selectedAll"
                    id="draftChargeComponentId"
                    label="Tout selectionner"
                    formState={formState}
                    callback={onChangeAllSelection}
                />
                <div
                    style={{
                        maxHeight: '300px',
                        overflow: 'auto'
                    }}
                    className="mb-3"
                >
                    <CustomListReferenceGroupSelection
                        data={resultat.map((item) => ({
                            ...item,
                            clientDesignation: `${item?.user?.nom || ''} ${
                                item?.user?.prenom || ''
                            }`
                        }))}
                        fields={fields}
                        handleChange={handleChange}
                        mapper={mapper}
                        property="list"
                    />
                </div>
            </div>
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Valider"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
