// For Adding Element
export const handleAddHighLevel = ({
    type,
    ancestorId,
    indexAtSecondLevel,
    indexAtThirdLevel,
    setFormFields,
    formFields,
    view = 'ChargeFixe'
}) => {
    if (view === 'ChargeFixe') {
        if (type === 'Charge') {
            // console.log(formFields?.[ancestorId]?.element?.code, ancestorId);
            if (!formFields?.[formFields.length - 1]?.element?.code) return;
            const newFormFields = formFields.map((field) => ({
                ...field,
                isTooggled: false,
                isVisible: false
            }));
            setFormFields([
                ...newFormFields,
                {
                    element: {
                        code: '',
                        designation:
                            '' /** Property to add for displaying purpose */
                    },
                    typeMoyenPaiement: '',
                    idCaisse: '',
                    caisseDesignation:
                        '' /** Property to add for displaying purpose */,
                    estQuantite: false /** Property to add for displaying purpose and logic payload (backend) */,
                    estRempliAutomatique: false /** Property to add for displaying purpose and logic payload (backend) */,
                    prix: 0,
                    isTooggled: true,
                    isVisible: false,
                    details: [
                        {
                            direction: '',
                            directionDesignation:
                                '' /** Property to add for displaying purpose */,
                            secteur: {
                                code: '',
                                designation: ''
                            },
                            isTooggled: true,
                            isVisible: false,
                            idCaisse: '',
                            caisseDesignation:
                                '' /** Property to add for displaying purpose */,
                            prix: 0,
                            beneficiaires: [
                                {
                                    typeEntite: '',
                                    typeEntiteDesignation:
                                        '' /** Property to add for displaying purpose */,
                                    valeurTypeEntite: {
                                        reference: '',
                                        designation:
                                            '' /** Property to add for displaying purpose */
                                    },
                                    idCaisse: '',
                                    caisseDesignation:
                                        '' /** Property to add for displaying purpose */,
                                    prix: 0,
                                    quantite: 0,
                                    montant: 0,
                                    isTooggled: true,
                                    isVisible: false,
                                    periodes: [
                                        {
                                            dateDebut: 0,
                                            dateFin: 0,
                                            datePaiement: 0,
                                            quantite: 0,
                                            montant: 0,
                                            datePaiementString: '',
                                            mois: '' /** Property to add for displaying month */
                                            // isTooggled: true,
                                            // isVisible: false
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]);
        }
        if (type === 'Secteur' && typeof ancestorId === 'number') {
            const values = [...formFields];
            values[ancestorId].details.push({
                direction: '',
                directionDesignation:
                    '' /** Property to add for displaying purpose */,
                secteur: {
                    code: '',
                    designation: ''
                },
                isTooggled: true,
                isVisible: false,
                idCaisse: '',
                caisseDesignation:
                    '' /** Property to add for displaying purpose */,
                prix: 0,
                beneficiaires: [
                    {
                        typeEntite: '',
                        typeEntiteDesignation:
                            '' /** Property to add for displaying purpose */,
                        valeurTypeEntite: {
                            reference: '',
                            designation:
                                '' /** Property to add for displaying purpose */
                        },
                        idCaisse: '',
                        caisseDesignation:
                            '' /** Property to add for displaying purpose */,
                        prix: 0,
                        quantite: 0,
                        montant: 0,
                        isTooggled: true,
                        isVisible: false,
                        periodes: [
                            {
                                dateDebut: 0,
                                dateFin: 0,
                                datePaiement: 0,
                                quantite: 0,
                                montant: 0,
                                datePaiementString: '',
                                mois: '' /** Property to add for displaying month */
                                // isTooggled: true,
                                // isVisible: false
                            }
                        ]
                    }
                ]
            });
            setFormFields(values);
        }
        if (
            type === 'Beneficiaires' &&
            typeof ancestorId === 'number' &&
            typeof indexAtSecondLevel === 'number'
        ) {
            const values = [...formFields];
            values[ancestorId].details[indexAtSecondLevel].beneficiaires.push({
                typeEntite: '',
                typeEntiteDesignation:
                    '' /** Property to add for displaying purpose */,
                valeurTypeEntite: {
                    reference: '',
                    designation:
                        '' /** Property to add for displaying purpose */
                },
                idCaisse: '',
                caisseDesignation:
                    '' /** Property to add for displaying purpose */,
                prix: 0,
                quantite: 0,
                montant: 0,
                isTooggled: true,
                isVisible: false,
                periodes: [
                    {
                        dateDebut: 0,
                        dateFin: 0,
                        datePaiement: 0,
                        quantite: 0,
                        montant: 0,
                        datePaiementString: '',
                        mois: '' /** Property to add for displaying month */
                        // isTooggled: true,
                        // isVisible: false
                    }
                ]
            });
            setFormFields(values);
        }
        if (
            type === 'Periodes' &&
            typeof ancestorId === 'number' &&
            typeof indexAtSecondLevel === 'number' &&
            typeof indexAtThirdLevel === 'number'
        ) {
            const values = [...formFields];
            values[ancestorId].details[indexAtSecondLevel].beneficiaires[
                indexAtThirdLevel
            ].periodes.push({
                dateDebut: 0,
                dateFin: 0,
                datePaiement: 0,
                quantite: 0,
                montant: 0,
                datePaiementString: '',
                mois: '' /** Property to add for displaying month */,
                isTooggled: true,
                isVisible: false
            });
            setFormFields(values);
        }
    }
    if (view === 'ChargeVariable') {
        if (type === 'Charge') {
            if (!formFields?.[formFields.length - 1]?.element?.code) return;
            const newFormFields = formFields.map((field) => ({
                ...field,
                isTooggled: false,
                isVisible: false
            }));
            setFormFields([
                ...newFormFields,
                {
                    element: {
                        code: '',
                        designation: ''
                    },
                    tauxCroissance: 0,
                    isTooggled: true,
                    isVisible: true,
                    estRetribution: false
                }
            ]);
        }
    }
    if (view === 'Ressource') {
        if (type === 'Ressource') {
            // console.log(formFields?.[ancestorId]?.element?.code, ancestorId);
            if (!formFields?.[formFields.length - 1]?.element?.code) return;
            const newFormFields = formFields.map((field) => ({
                ...field,
                isTooggled: false,
                isVisible: false
            }));
            setFormFields([
                ...newFormFields,
                {
                    element: { code: '', designation: '' },
                    estObjectif: false,
                    tauxCroissance: 0,
                    isTooggled: true,
                    isVisible: false,
                    details: [
                        {
                            secteur: {
                                code: '',
                                designation: ''
                            },
                            isTooggled: true,
                            isVisible: false,
                            periodes: [
                                {
                                    dateDebut: 0,
                                    dateFin: 0,
                                    mois: '',
                                    devises: [{ devise: 'USD', target: 0 }]
                                }
                            ]
                        }
                    ]
                }
            ]);
        }
        if (type === 'Secteur' && typeof ancestorId === 'number') {
            const values = [...formFields];
            values[ancestorId].details.push({
                secteur: {
                    code: '',
                    designation: ''
                },
                isTooggled: true,
                isVisible: false,
                periodes: [
                    {
                        dateDebut: 0,
                        dateFin: 0,
                        mois: '',
                        devises: [{ devise: 'USD', target: 0 }]
                    }
                ]
            });
            setFormFields(values);
        }
        if (
            type === 'Periodes' &&
            typeof ancestorId === 'number' &&
            typeof indexAtSecondLevel === 'number'
        ) {
            const values = [...formFields];
            values[ancestorId].details[indexAtSecondLevel].periodes.push({
                dateDebut: 0,
                dateFin: 0,
                mois: '',
                devises: [{ devise: 'USD', target: 0 }]
            });
            setFormFields(values);
        }
    }
};

// For Removing Element
export const handleRemove = ({
    index,
    indexAtSecondLevel,
    indexAtThirdLevel,
    id,
    formFields,
    setFormFields,
    view = 'ChargeFixe'
}) => {
    const values = [...formFields];
    if (view === 'ChargeFixe') {
        if (
            typeof index === 'number' &&
            typeof indexAtSecondLevel !== 'number' &&
            typeof indexAtThirdLevel !== 'number' &&
            typeof id !== 'number' &&
            values.length > 1
        ) {
            if (
                values.filter((item) => item.isTooggled).length === 1 &&
                values[index].isTooggled
            )
                return;
            values.splice(index, 1);
        }
        if (
            typeof index === 'number' &&
            typeof indexAtSecondLevel === 'number' &&
            typeof indexAtThirdLevel !== 'number' &&
            typeof id !== 'number' &&
            values[index].details.length > 1
        ) {
            values[index].details.splice(indexAtSecondLevel, 1);
        }
        if (
            typeof index === 'number' &&
            typeof indexAtSecondLevel === 'number' &&
            typeof indexAtThirdLevel === 'number' &&
            typeof id !== 'number' &&
            values[index].details[indexAtSecondLevel].beneficiaires.length > 1
        ) {
            values[index].details[indexAtSecondLevel].beneficiaires.splice(
                indexAtThirdLevel,
                1
            );
        }
        if (
            typeof index === 'number' &&
            typeof indexAtSecondLevel === 'number' &&
            typeof indexAtThirdLevel === 'number' &&
            typeof id === 'number' &&
            values[index].details[indexAtSecondLevel].beneficiaires[
                indexAtThirdLevel
            ].periodes.length > 1
        ) {
            values[index].details[indexAtSecondLevel].beneficiaires[
                indexAtThirdLevel
            ].periodes.splice(id, 1);
        }
    }
    if (view === 'ChargeVariable') {
        if (
            typeof index === 'number' &&
            typeof indexAtSecondLevel !== 'number' &&
            typeof indexAtThirdLevel !== 'number' &&
            typeof id !== 'number' &&
            values.length > 1
        ) {
            if (
                values.filter((item) => item.isTooggled).length === 1 &&
                values[index].isTooggled
            )
                return;
            values.splice(index, 1);
        }
    }
    if (view === 'Ressource') {
        if (
            typeof index === 'number' &&
            typeof indexAtSecondLevel !== 'number' &&
            typeof indexAtThirdLevel !== 'number' &&
            typeof id !== 'number' &&
            values.length > 1
        ) {
            if (
                values.filter((item) => item.isTooggled).length === 1 &&
                values[index].isTooggled
            )
                return;
            values.splice(index, 1);
        }
        if (
            typeof index === 'number' &&
            typeof indexAtSecondLevel === 'number' &&
            typeof indexAtThirdLevel !== 'number' &&
            typeof id !== 'number' &&
            values[index].details.length > 1
        ) {
            values[index].details.splice(indexAtSecondLevel, 1);
        }
        if (
            typeof index === 'number' &&
            typeof indexAtSecondLevel === 'number' &&
            typeof indexAtThirdLevel === 'number' &&
            typeof id !== 'number' &&
            values[index].details[indexAtSecondLevel].periodes.length > 1
        ) {
            values[index].details[indexAtSecondLevel].periodes.splice(
                indexAtThirdLevel,
                1
            );
        }
    }
    setFormFields(values);
};

// For Adding Element Old Version
export const handleAddHighLevelOldVersion = ({
    type,
    ancestorId,
    indexAtSecondLevel,
    indexAtThirdLevel,
    setFormFields,
    formFields
}) => {
    if (type === 'Charge') {
        // console.log(formFields?.[ancestorId]?.element?.code, ancestorId);
        if (!formFields?.[formFields.length - 1]?.element?.code) return;
        const newFormFields = formFields.map((field) => ({
            ...field,
            isTooggled: false,
            isVisible: false
        }));
        // handleChange({
        //     index: formFields.length - 1,
        //     event: {
        //         target: {
        //             name: 'isTooggled',
        //             value: !formFields?.[formFields.length - 1].isTooggled
        //         }
        //     }
        // });
        setFormFields([
            ...newFormFields,
            {
                element: {
                    code: '',
                    designation:
                        '' /** Property to add for displaying purpose */
                },
                typeMoyenPaiement: '',
                idCaisse: '',
                caisseDesignation:
                    '' /** Property to add for displaying purpose */,
                estQuantite: false /** Property to add for displaying purpose and logic payload (backend) */,
                estRempliAutomatique: false /** Property to add for displaying purpose and logic payload (backend) */,
                prix: 0,
                isTooggled: true,
                isVisible: false,
                details: [
                    {
                        direction: '',
                        directionDesignation:
                            '' /** Property to add for displaying purpose */,
                        secteur: {
                            code: '',
                            designation: ''
                        },
                        isTooggled: true,
                        isVisible: false,
                        idCaisse: '',
                        caisseDesignation:
                            '' /** Property to add for displaying purpose */,
                        prix: 0,
                        periodes: [
                            {
                                dateDebut: 0,
                                dateFin: 0,
                                datePaiement: 0,
                                datePaiementString: '',
                                mois: '' /** Property to add for displaying month */,
                                isTooggled: true,
                                isVisible: false,
                                beneficiaires: [
                                    {
                                        typeEntite: '',
                                        typeEntiteDesignation:
                                            '' /** Property to add for displaying purpose */,
                                        valeurTypeEntite: {
                                            reference: '',
                                            designation:
                                                '' /** Property to add for displaying purpose */
                                        },
                                        idCaisse: '',
                                        caisseDesignation:
                                            '' /** Property to add for displaying purpose */,
                                        prix: 0,
                                        quantite: 0,
                                        montant: 0
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ]);
    }
    if (type === 'Secteur' && typeof ancestorId === 'number') {
        const values = [...formFields];
        values[ancestorId].details.push({
            direction: '',
            directionDesignation:
                '' /** Property to add for displaying purpose */,
            secteur: {
                code: '',
                designation: ''
            },
            isTooggled: true,
            isVisible: false,
            idCaisse: '',
            caisseDesignation: '' /** Property to add for displaying purpose */,
            prix: 0,
            periodes: [
                {
                    dateDebut: 0,
                    dateFin: 0,
                    datePaiement: 0,
                    datePaiementString: '',
                    mois: '' /** Property to add for displaying month */,
                    isTooggled: true,
                    beneficiaires: [
                        {
                            typeEntite: '',
                            typeEntiteDesignation:
                                '' /** Property to add for displaying purpose */,
                            valeurTypeEntite: {
                                reference: '',
                                designation:
                                    '' /** Property to add for displaying purpose */
                            },
                            idCaisse: '',
                            caisseDesignation:
                                '' /** Property to add for displaying purpose */,
                            prix: 0,
                            quantite: 0,
                            montant: 0
                        }
                    ]
                }
            ]
        });
        setFormFields(values);
    }
    if (
        type === 'Periodes' &&
        typeof ancestorId === 'number' &&
        typeof indexAtSecondLevel === 'number'
    ) {
        const values = [...formFields];
        values[ancestorId].details[indexAtSecondLevel].periodes.push({
            dateDebut: 0,
            dateFin: 0,
            datePaiement: 0,
            datePaiementString: '',
            mois: '' /** Property to add for displaying month */,
            isTooggled: true,
            isVisible: false,
            beneficiaires: [
                {
                    typeEntite: '',
                    typeEntiteDesignation:
                        '' /** Property to add for displaying purpose */,
                    valeurTypeEntite: {
                        reference: '',
                        designation:
                            '' /** Property to add for displaying purpose */
                    },
                    idCaisse: '',
                    caisseDesignation:
                        '' /** Property to add for displaying purpose */,
                    prix: 0,
                    quantite: 0,
                    montant: 0
                }
            ]
        });
        setFormFields(values);
    }
    if (
        type === 'Beneficiaires' &&
        typeof ancestorId === 'number' &&
        typeof indexAtSecondLevel === 'number' &&
        typeof indexAtThirdLevel === 'number'
    ) {
        const values = [...formFields];
        values[ancestorId].details[indexAtSecondLevel].periodes[
            indexAtThirdLevel
        ].beneficiaires.push({
            typeEntite: '',
            typeEntiteDesignation:
                '' /** Property to add for displaying purpose */,
            valeurTypeEntite: {
                reference: '',
                designation: '' /** Property to add for displaying purpose */
            },
            idCaisse: '',
            caisseDesignation: '' /** Property to add for displaying purpose */,
            prix: 0,
            quantite: 0,
            montant: 0
        });
        setFormFields(values);
    }
};

// For Removing Element Old Version
export const handleRemoveOldVersion = ({
    index,
    indexAtSecondLevel,
    indexAtThirdLevel,
    id,
    formFields,
    setFormFields
}) => {
    const values = [...formFields];
    if (
        typeof index === 'number' &&
        typeof indexAtSecondLevel !== 'number' &&
        typeof indexAtThirdLevel !== 'number' &&
        typeof id !== 'number' &&
        values.length > 1
    ) {
        if (
            values.filter((item) => item.isTooggled).length === 1 &&
            values[index].isTooggled
        )
            return;
        values.splice(index, 1);
    }
    if (
        typeof index === 'number' &&
        typeof indexAtSecondLevel === 'number' &&
        typeof indexAtThirdLevel !== 'number' &&
        typeof id !== 'number' &&
        values[index].details.length > 1
    ) {
        values[index].details.splice(indexAtSecondLevel, 1);
    }
    if (
        typeof index === 'number' &&
        typeof indexAtSecondLevel === 'number' &&
        typeof indexAtThirdLevel === 'number' &&
        typeof id !== 'number' &&
        values[index].details[indexAtSecondLevel].periodes.length > 1
    ) {
        values[index].details[indexAtSecondLevel].periodes.splice(
            indexAtThirdLevel,
            1
        );
    }
    if (
        typeof index === 'number' &&
        typeof indexAtSecondLevel === 'number' &&
        typeof indexAtThirdLevel === 'number' &&
        typeof id === 'number' &&
        values[index].details[indexAtSecondLevel].periodes[indexAtThirdLevel]
            .beneficiaires.length > 1
    ) {
        values[index].details[indexAtSecondLevel].periodes[
            indexAtThirdLevel
        ].beneficiaires.splice(id, 1);
    }
    setFormFields(values);
};
