import { Tab, Tabs } from 'react-bootstrap';
import { useState } from 'react';
import { ReleveNonDeclareKinDistributionForm } from './releve';
import { RapportRetraitNonDeclareKinDBForm } from './rapport-retrait-non-declare';
import { RapportEtatRetraitKinDBForm } from './rapport-etat-retrait';

export function RapportRetraitNonDeclareKinDBViewLayout() {
    const [key, setKey] = useState('retrait');
    return (
        <div className="container-fluid">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Retrait</h1>
            </div>
            <Tabs
                defaultActiveKey="retrait"
                id="fill-tab-example"
                className="mb-3"
                onSelect={setKey}
            >
                <Tab eventKey="retrait" title="Evolution Retrait">
                    {key === 'retrait' && <RapportRetraitNonDeclareKinDBForm />}
                </Tab>
                <Tab eventKey="etat" title="Etat">
                    {key === 'etat' && <RapportEtatRetraitKinDBForm />}
                </Tab>
                <Tab eventKey="releve" title="Relevé">
                    {key === 'releve' && (
                        <ReleveNonDeclareKinDistributionForm />
                    )}
                </Tab>
            </Tabs>
        </div>
    );
}
