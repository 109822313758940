// export const fakeData = [
//     {
//         element: {
//             code: 'Global',
//             designation: 'Global'
//         },
//         details:[
//             {
//                 element: {
//                     code: 'CHARGE_SALAIRE',
//                     designation: 'Salaire Net'
//                 },
//                 periodes: [
//                     {
//                         periode: 'annuel',
//                         previsionnel: 1000000,
//                         factuel: 800000,
//                         ecart: 200000
//                     },
//                     {
//                         periode: 'janvier',
//                         previsionnel: 10000,
//                         factuel: 10000,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'fevrier',
//                         previsionnel: 11000,
//                         factuel: 11000,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'mars',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'avril',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'mai',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'juin',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'juillet',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'septembre',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'octobre',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'novembre',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'decembre',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     }
//                 ]
//             },
//             {
//                 element: {
//                     code: 'CHARGE_CARBURANT',
//                     designation: 'Carburant'
//                 },
//                 periodes: [
//                     {
//                         periode: 'annuel',
//                         previsionnel: 1000000,
//                         factuel: 800000,
//                         ecart: 200000
//                     },
//                     {
//                         periode: 'janvier',
//                         previsionnel: 10000,
//                         factuel: 10000,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'fevrier',
//                         previsionnel: 11000,
//                         factuel: 11000,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'mars',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'avril',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'mai',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'juin',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'juillet',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'septembre',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'octobre',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'novembre',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'decembre',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     }
//                 ]
//             },
//             {
//                 element: {
//                     code: 'CHARGE_COMMUNICATION',
//                     designation: 'Communication'
//                 },
//                 periodes: [
//                     {
//                         periode: 'annuel',
//                         previsionnel: 1000000,
//                         factuel: 800000,
//                         ecart: 200000
//                     },
//                     {
//                         periode: 'janvier',
//                         previsionnel: 10000,
//                         factuel: 10000,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'fevrier',
//                         previsionnel: 11000,
//                         factuel: 11000,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'mars',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'avril',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'mai',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'juin',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'juillet',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'septembre',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'octobre',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'novembre',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'decembre',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     }
//                 ]
//             }
//         ]
//     },
//     {
//         type: 'Charges',
//         details: [
//             {
//                 element: {
//                     code: 'CHARGE_SALAIRE',
//                     designation: 'Salaire Net'
//                 },
//                 periodes: [
//                     {
//                         periode: 'annuel',
//                         previsionnel: 1000000,
//                         factuel: 800000,
//                         ecart: 200000
//                     },
//                     {
//                         periode: 'janvier',
//                         previsionnel: 10000,
//                         factuel: 10000,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'fevrier',
//                         previsionnel: 11000,
//                         factuel: 11000,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'mars',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'avril',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'mai',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'juin',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'juillet',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'septembre',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'octobre',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'novembre',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'decembre',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     }
//                 ]
//             },
//             {
//                 element: {
//                     code: 'CHARGE_CARBURANT',
//                     designation: 'Carburant'
//                 },
//                 periodes: [
//                     {
//                         periode: 'annuel',
//                         previsionnel: 1000000,
//                         factuel: 800000,
//                         ecart: 200000
//                     },
//                     {
//                         periode: 'janvier',
//                         previsionnel: 10000,
//                         factuel: 10000,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'fevrier',
//                         previsionnel: 11000,
//                         factuel: 11000,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'mars',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'avril',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'mai',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'juin',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'juillet',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'septembre',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'octobre',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'novembre',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'decembre',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     }
//                 ]
//             },
//             {
//                 element: {
//                     code: 'CHARGE_COMMUNICATION',
//                     designation: 'Communication'
//                 },
//                 periodes: [
//                     {
//                         periode: 'annuel',
//                         previsionnel: 1000000,
//                         factuel: 800000,
//                         ecart: 200000
//                     },
//                     {
//                         periode: 'janvier',
//                         previsionnel: 10000,
//                         factuel: 10000,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'fevrier',
//                         previsionnel: 11000,
//                         factuel: 11000,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'mars',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'avril',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'mai',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'juin',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'juillet',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'septembre',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'octobre',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'novembre',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'decembre',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     }
//                 ]
//             }
//         ]
//     },
//     {
//         type: 'Ressources',
//         details: [
//             {
//                 element: {
//                     code: 'RESSOURCE_ACHIEVEMENT',
//                     designation: 'Achievement'
//                 },
//                 periodes: [
//                     {
//                         periode: 'annuel',
//                         previsionnel: 1000000,
//                         factuel: 800000,
//                         ecart: 200000
//                     },
//                     {
//                         periode: 'janvier',
//                         previsionnel: 10000,
//                         factuel: 10000,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'fevrier',
//                         previsionnel: 11000,
//                         factuel: 11000,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'mars',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'avril',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'mai',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'juin',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'juillet',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'septembre',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'octobre',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'novembre',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'decembre',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     }
//                 ]
//             },
//             {
//                 element: {
//                     code: 'RESSOURCE_REVENU_SHARING',
//                     designation: 'Revenu Sharing'
//                 },
//                 periodes: [
//                     {
//                         periode: 'annuel',
//                         previsionnel: 1000000,
//                         factuel: 800000,
//                         ecart: 200000
//                     },
//                     {
//                         periode: 'janvier',
//                         previsionnel: 10000,
//                         factuel: 10000,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'fevrier',
//                         previsionnel: 11000,
//                         factuel: 11000,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'mars',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'avril',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'mai',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'juin',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'juillet',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'septembre',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'octobre',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'novembre',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'decembre',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     }
//                 ]
//             },
//             {
//                 element: {
//                     code: 'RESSOURCE_10_SUR_90_RETRAIT',
//                     designation: '10/90 Retrait'
//                 },
//                 periodes: [
//                     {
//                         periode: 'annuel',
//                         previsionnel: 1000000,
//                         factuel: 800000,
//                         ecart: 200000
//                     },
//                     {
//                         periode: 'janvier',
//                         previsionnel: 10000,
//                         factuel: 10000,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'fevrier',
//                         previsionnel: 11000,
//                         factuel: 11000,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'mars',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'avril',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'mai',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'juin',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'juillet',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'septembre',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'octobre',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'novembre',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     },
//                     {
//                         periode: 'decembre',
//                         previsionnel: 0,
//                         factuel: 0,
//                         ecart: 0
//                     }
//                 ]
//             }
//         ]
//     }
// ];
export const fakeData = [
    {
        element: {
            code: 'Global',
            designation: 'Global'
        },
        periode: {
            annuel: {
                periode: 'annuel',
                previsionnel: 1000000,
                factuel: 800000,
                ecart: 200000
            },
            janvier: {
                periode: 'janvier',
                previsionnel: 10000,
                factuel: 10000,
                ecart: 0
            },
            fevrier: {
                periode: 'fevrier',
                previsionnel: 11000,
                factuel: 11000,
                ecart: 0
            },
            mars: {
                periode: 'mars',
                previsionnel: 0,
                factuel: 0,
                ecart: 0
            },
            avril: {
                periode: 'avril',
                previsionnel: 0,
                factuel: 0,
                ecart: 0
            },
            mai: {
                periode: 'mai',
                previsionnel: 0,
                factuel: 0,
                ecart: 0
            },
            juin: {
                periode: 'juin',
                previsionnel: 0,
                factuel: 0,
                ecart: 0
            },
            juillet: {
                periode: 'juillet',
                previsionnel: 0,
                factuel: 0,
                ecart: 0
            },
            aout: {
                periode: 'aout',
                previsionnel: 0,
                factuel: 0,
                ecart: 0
            },
            septembre: {
                periode: 'septembre',
                previsionnel: 0,
                factuel: 0,
                ecart: 0
            },
            octobre: {
                periode: 'octobre',
                previsionnel: 0,
                factuel: 0,
                ecart: 0
            },
            novembre: {
                periode: 'novembre',
                previsionnel: 0,
                factuel: 0,
                ecart: 0
            },
            decembre: {
                periode: 'decembre',
                previsionnel: 0,
                factuel: 0,
                ecart: 0
            }
        }
    },
    {
        type: 'Charges',
        details: [
            {
                element: {
                    code: 'CHARGE_SALAIRE',
                    designation: 'Salaire Net'
                },
                periode: {
                    annuel: {
                        periode: 'annuel',
                        previsionnel: 1000000,
                        factuel: 800000,
                        ecart: 200000
                    },
                    janvier: {
                        periode: 'janvier',
                        previsionnel: 10000,
                        factuel: 10000,
                        ecart: 0
                    },
                    fevrier: {
                        periode: 'fevrier',
                        previsionnel: 11000,
                        factuel: 11000,
                        ecart: 0
                    },
                    mars: {
                        periode: 'mars',
                        previsionnel: 0,
                        factuel: 0,
                        ecart: 0
                    },
                    avril: {
                        periode: 'avril',
                        previsionnel: 0,
                        factuel: 0,
                        ecart: 0
                    },
                    mai: {
                        periode: 'mai',
                        previsionnel: 0,
                        factuel: 0,
                        ecart: 0
                    },
                    juin: {
                        periode: 'juin',
                        previsionnel: 0,
                        factuel: 0,
                        ecart: 0
                    },
                    juillet: {
                        periode: 'juillet',
                        previsionnel: 0,
                        factuel: 0,
                        ecart: 0
                    },
                    aout: {
                        periode: 'aout',
                        previsionnel: 0,
                        factuel: 0,
                        ecart: 0
                    },
                    septembre: {
                        periode: 'septembre',
                        previsionnel: 0,
                        factuel: 0,
                        ecart: 0
                    },
                    octobre: {
                        periode: 'octobre',
                        previsionnel: 0,
                        factuel: 0,
                        ecart: 0
                    },
                    novembre: {
                        periode: 'novembre',
                        previsionnel: 0,
                        factuel: 0,
                        ecart: 0
                    },
                    decembre: {
                        periode: 'decembre',
                        previsionnel: 0,
                        factuel: 0,
                        ecart: 0
                    }
                }
            },
            {
                element: {
                    code: 'CHARGE_CARBURANT',
                    designation: 'Carburant'
                },
                periode: {
                    annuel: {
                        periode: 'annuel',
                        previsionnel: 1000000,
                        factuel: 800000,
                        ecart: 200000
                    },
                    janvier: {
                        periode: 'janvier',
                        previsionnel: 10000,
                        factuel: 10000,
                        ecart: 0
                    },
                    fevrier: {
                        periode: 'fevrier',
                        previsionnel: 11000,
                        factuel: 11000,
                        ecart: 0
                    },
                    mars: {
                        periode: 'mars',
                        previsionnel: 0,
                        factuel: 0,
                        ecart: 0
                    },
                    avril: {
                        periode: 'avril',
                        previsionnel: 0,
                        factuel: 0,
                        ecart: 0
                    },
                    mai: {
                        periode: 'mai',
                        previsionnel: 0,
                        factuel: 0,
                        ecart: 0
                    },
                    juin: {
                        periode: 'juin',
                        previsionnel: 0,
                        factuel: 0,
                        ecart: 0
                    },
                    juillet: {
                        periode: 'juillet',
                        previsionnel: 0,
                        factuel: 0,
                        ecart: 0
                    },
                    aout: {
                        periode: 'aout',
                        previsionnel: 0,
                        factuel: 0,
                        ecart: 0
                    },
                    septembre: {
                        periode: 'septembre',
                        previsionnel: 0,
                        factuel: 0,
                        ecart: 0
                    },
                    octobre: {
                        periode: 'octobre',
                        previsionnel: 0,
                        factuel: 0,
                        ecart: 0
                    },
                    novembre: {
                        periode: 'novembre',
                        previsionnel: 0,
                        factuel: 0,
                        ecart: 0
                    },
                    decembre: {
                        periode: 'decembre',
                        previsionnel: 0,
                        factuel: 0,
                        ecart: 0
                    }
                }
            },
            {
                element: {
                    code: 'CHARGE_COMMUNICATION',
                    designation: 'Communication'
                },
                periode: {
                    annuel: {
                        periode: 'annuel',
                        previsionnel: 1000000,
                        factuel: 800000,
                        ecart: 200000
                    },
                    janvier: {
                        periode: 'janvier',
                        previsionnel: 10000,
                        factuel: 10000,
                        ecart: 0
                    },
                    fevrier: {
                        periode: 'fevrier',
                        previsionnel: 11000,
                        factuel: 11000,
                        ecart: 0
                    },
                    mars: {
                        periode: 'mars',
                        previsionnel: 0,
                        factuel: 0,
                        ecart: 0
                    },
                    avril: {
                        periode: 'avril',
                        previsionnel: 0,
                        factuel: 0,
                        ecart: 0
                    },
                    mai: {
                        periode: 'mai',
                        previsionnel: 0,
                        factuel: 0,
                        ecart: 0
                    },
                    juin: {
                        periode: 'juin',
                        previsionnel: 0,
                        factuel: 0,
                        ecart: 0
                    },
                    juillet: {
                        periode: 'juillet',
                        previsionnel: 0,
                        factuel: 0,
                        ecart: 0
                    },
                    aout: {
                        periode: 'aout',
                        previsionnel: 0,
                        factuel: 0,
                        ecart: 0
                    },
                    septembre: {
                        periode: 'septembre',
                        previsionnel: 0,
                        factuel: 0,
                        ecart: 0
                    },
                    octobre: {
                        periode: 'octobre',
                        previsionnel: 0,
                        factuel: 0,
                        ecart: 0
                    },
                    novembre: {
                        periode: 'novembre',
                        previsionnel: 0,
                        factuel: 0,
                        ecart: 0
                    },
                    decembre: {
                        periode: 'decembre',
                        previsionnel: 0,
                        factuel: 0,
                        ecart: 0
                    }
                }
            }
        ]
    },
    {
        type: 'Ressources',
        details: [
            {
                element: {
                    code: 'RESSOURCE_ACHIEVEMENT',
                    designation: 'Achievement'
                },
                periode: {
                    annuel: {
                        periode: 'annuel',
                        previsionnel: 1000000,
                        factuel: 800000,
                        ecart: 200000
                    },
                    janvier: {
                        periode: 'janvier',
                        previsionnel: 10000,
                        factuel: 10000,
                        ecart: 0
                    },
                    fevrier: {
                        periode: 'fevrier',
                        previsionnel: 11000,
                        factuel: 11000,
                        ecart: 0
                    },
                    mars: {
                        periode: 'mars',
                        previsionnel: 0,
                        factuel: 0,
                        ecart: 0
                    },
                    avril: {
                        periode: 'avril',
                        previsionnel: 0,
                        factuel: 0,
                        ecart: 0
                    },
                    mai: {
                        periode: 'mai',
                        previsionnel: 0,
                        factuel: 0,
                        ecart: 0
                    },
                    juin: {
                        periode: 'juin',
                        previsionnel: 0,
                        factuel: 0,
                        ecart: 0
                    },
                    juillet: {
                        periode: 'juillet',
                        previsionnel: 0,
                        factuel: 0,
                        ecart: 0
                    },
                    aout: {
                        periode: 'aout',
                        previsionnel: 0,
                        factuel: 0,
                        ecart: 0
                    },
                    septembre: {
                        periode: 'septembre',
                        previsionnel: 0,
                        factuel: 0,
                        ecart: 0
                    },
                    octobre: {
                        periode: 'octobre',
                        previsionnel: 0,
                        factuel: 0,
                        ecart: 0
                    },
                    novembre: {
                        periode: 'novembre',
                        previsionnel: 0,
                        factuel: 0,
                        ecart: 0
                    },
                    decembre: {
                        periode: 'decembre',
                        previsionnel: 0,
                        factuel: 0,
                        ecart: 0
                    }
                }
            },
            {
                element: {
                    code: 'RESSOURCE_REVENU_SHARING',
                    designation: 'Revenu Sharing'
                },
                periode: {
                    annuel: {
                        periode: 'annuel',
                        previsionnel: 1000000,
                        factuel: 800000,
                        ecart: 200000
                    },
                    janvier: {
                        periode: 'janvier',
                        previsionnel: 10000,
                        factuel: 10000,
                        ecart: 0
                    },
                    fevrier: {
                        periode: 'fevrier',
                        previsionnel: 11000,
                        factuel: 11000,
                        ecart: 0
                    },
                    mars: {
                        periode: 'mars',
                        previsionnel: 0,
                        factuel: 0,
                        ecart: 0
                    },
                    avril: {
                        periode: 'avril',
                        previsionnel: 0,
                        factuel: 0,
                        ecart: 0
                    },
                    mai: {
                        periode: 'mai',
                        previsionnel: 0,
                        factuel: 0,
                        ecart: 0
                    },
                    juin: {
                        periode: 'juin',
                        previsionnel: 0,
                        factuel: 0,
                        ecart: 0
                    },
                    juillet: {
                        periode: 'juillet',
                        previsionnel: 0,
                        factuel: 0,
                        ecart: 0
                    },
                    aout: {
                        periode: 'aout',
                        previsionnel: 0,
                        factuel: 0,
                        ecart: 0
                    },
                    septembre: {
                        periode: 'septembre',
                        previsionnel: 0,
                        factuel: 0,
                        ecart: 0
                    },
                    octobre: {
                        periode: 'octobre',
                        previsionnel: 0,
                        factuel: 0,
                        ecart: 0
                    },
                    novembre: {
                        periode: 'novembre',
                        previsionnel: 0,
                        factuel: 0,
                        ecart: 0
                    },
                    decembre: {
                        periode: 'decembre',
                        previsionnel: 0,
                        factuel: 0,
                        ecart: 0
                    }
                }
            },
            {
                element: {
                    code: 'RESSOURCE_10_SUR_90_RETRAIT',
                    designation: '10/90 Retrait'
                },
                periode: {
                    annuel: {
                        periode: 'annuel',
                        previsionnel: 1000000,
                        factuel: 800000,
                        ecart: 200000
                    },
                    janvier: {
                        periode: 'janvier',
                        previsionnel: 10000,
                        factuel: 10000,
                        ecart: 0
                    },
                    fevrier: {
                        periode: 'fevrier',
                        previsionnel: 11000,
                        factuel: 11000,
                        ecart: 0
                    },
                    mars: {
                        periode: 'mars',
                        previsionnel: 0,
                        factuel: 0,
                        ecart: 0
                    },
                    avril: {
                        periode: 'avril',
                        previsionnel: 0,
                        factuel: 0,
                        ecart: 0
                    },
                    mai: {
                        periode: 'mai',
                        previsionnel: 0,
                        factuel: 0,
                        ecart: 0
                    },
                    juin: {
                        periode: 'juin',
                        previsionnel: 0,
                        factuel: 0,
                        ecart: 0
                    },
                    juillet: {
                        periode: 'juillet',
                        previsionnel: 0,
                        factuel: 0,
                        ecart: 0
                    },
                    aout: {
                        periode: 'aout',
                        previsionnel: 0,
                        factuel: 0,
                        ecart: 0
                    },
                    septembre: {
                        periode: 'septembre',
                        previsionnel: 0,
                        factuel: 0,
                        ecart: 0
                    },
                    octobre: {
                        periode: 'octobre',
                        previsionnel: 0,
                        factuel: 0,
                        ecart: 0
                    },
                    novembre: {
                        periode: 'novembre',
                        previsionnel: 0,
                        factuel: 0,
                        ecart: 0
                    },
                    decembre: {
                        periode: 'decembre',
                        previsionnel: 0,
                        factuel: 0,
                        ecart: 0
                    }
                }
            }
        ]
    }
];

export const makeData = [
    {
        firstName: 'Jhon',
        score1: 20,
        score2: 23,
        score3: 56
    },
    {
        firstName: 'Dan',
        score1: 27,
        score2: 21,
        score3: 58
    },
    {
        firstName: 'Osamo',
        score1: 37,
        score2: 15,
        score3: 25
    }
];
