import React from 'react';
import { AiFillMinusCircle } from 'react-icons/ai';
import {
    IoIosAddCircleOutline,
    IoIosArrowDropdown,
    IoIosArrowDropup
} from 'react-icons/io';
import { CustomLiteDynamicDropdown } from '../../../../../../components';

export function SecteurRessourceRowSelector({
    handleRemove,
    handleAddHighLevel,
    index,
    detailIndex,
    formFields,
    onToggleView,
    handleSelectChange,
    detail,
    secteurs,
    setFormFields
}) {
    return (
        <div className="d-flex flex-row align-items-center justify-content-center ms-5 me-5">
            <div
                style={{
                    height: '46px',
                    width: '16px'
                }}
            >
                <p />
            </div>
            <CustomLiteDynamicDropdown
                containerClassName="col-12"
                ariaLabel="Secteur Selector"
                name="secteur.code|secteur.designation"
                value={detail?.secteur?.code}
                options={secteurs}
                hiddenOption="Selectionnez un secteur"
                onChange={handleSelectChange}
                handleProps={{
                    index,
                    indexAtSecondLevel: detailIndex,
                    setFormFields,
                    formFields
                }}
                labelStyle={{
                    fontSize: '13px',
                    paddingBottom: '8px',
                    lineHeight: '20px',
                    minHeight: '20px',
                    display: 'block',
                    color: 'rgba(0,0,0,0.5)'
                }}
                label="Secteur"
                selectClassName="form-select form-select-sm col-3"
            />
            <div className="d-flex">
                <div className="col-auto pt-2 me-2">
                    <AiFillMinusCircle
                        role="button"
                        title="Supprimer Informations du secteur"
                        className="text-secondary fs-5"
                        onClick={() =>
                            handleRemove({
                                index,
                                indexAtSecondLevel: detailIndex,
                                formFields,
                                setFormFields,
                                view: 'Ressource'
                            })
                        }
                    />
                </div>
                <div className="col-auto pt-2 me-2">
                    <IoIosAddCircleOutline
                        role="button"
                        title="Ajouter Secteur"
                        className="text-primary fs-5"
                        onClick={() =>
                            handleAddHighLevel({
                                type: 'Secteur',
                                ancestorId: index,
                                formFields,
                                setFormFields,
                                view: 'Ressource'
                            })
                        }
                    />
                </div>
                <div className="col-auto pt-2">
                    {formFields[index].details[detailIndex].isTooggled ? (
                        <IoIosArrowDropup
                            className="fs-5"
                            title="Regrouper les détails"
                            role="button"
                            onClick={() =>
                                onToggleView({
                                    code: formFields[index].details[detailIndex]
                                        .secteur.code,
                                    index,
                                    indexAtSecondLevel: detailIndex,
                                    formFields,
                                    setFormFields,
                                    view: 'Ressource'
                                })
                            }
                        />
                    ) : (
                        <IoIosArrowDropdown
                            className="fs-5"
                            title="Afficher les détails"
                            role="button"
                            onClick={() =>
                                onToggleView({
                                    code: formFields[index].details[detailIndex]
                                        .secteur.code,
                                    index,
                                    indexAtSecondLevel: detailIndex,
                                    formFields,
                                    setFormFields,
                                    view: 'Ressource'
                                })
                            }
                        />
                    )}
                </div>
            </div>
        </div>
    );
}
