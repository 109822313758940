export const schema = [
    {
        title: 'Agents',
        description: 'nombreAgent',
        className: 'border-bottom'
    },
    {
        title: 'Taches',
        description: 'nombreTache',
        className: 'border-bottom'
    },
    {
        title: 'Etat',
        description: 'etat',
        className: 'border-bottom'
    },
    {
        title: 'Création',
        description: 'dateCreation'
    }
];
