import {
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import { formInitialState, FORM_CONST } from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    ConditionalRenderingWrapper,
    CustomButtonLoader,
    CustomCol,
    CustomDropdown,
    CustomInput,
    CustomRow,
    CustomTextAreaInput,
    FormWrapper
} from '../../../../components';
import { URL_CONST } from '../../../../util';
import { REDUX_NODE_NAME } from '../../../../redux';
import { ServiceClientFormulaireFormSchema as uiValidator } from './validation';
import { formater } from './util';

const defaultFields = {
    selectedTypeForm: {},
    description: '',
    urlImage: '',
    titre: '',
    selectedAgent: {},
    selectedSujet: {}
};

const defaultDependencies = { fields: ['employe', 'sujet'] };

export function CreationServiceClientFormulaireForm() {
    const reduxDispatcher = useDispatch();
    /**
     * Get data on redux store
     */
    const {
        firebaseUser,
        employesOrganisation,
        etatEmployesOrganisation,
        sujetsDisponible,
        etatSujetDisponible
    } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        employesOrganisation: reduxState.employesServiceClient.map((item) => ({
            reference: item?.owner,
            designation: `${item?.prenom} ${item?.nom}`
        })),
        etatEmployesOrganisation: reduxState.etat.employesServiceClient.etat,
        sujetsDisponible: reduxState.sujetsDisponible,
        etatSujetDisponible: reduxState.etat.sujetsDisponible.etat,
        dynamicNode: reduxState.dynamicNode,
        dynamicEtatNode: reduxState.dynamicEtatNode
    }));
    /**
     * Common form processes
     */
    const { idToken } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: defaultDependencies
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;
    /**
     * Cloud Functions
     */
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_USER_A_SELECTIONNER,
                nodeName: REDUX_NODE_NAME.EMPLOYE_SERVICE_CLIENT,
                payload: {
                    typeEntite: 'ServiceClient'
                },
                etat: etatEmployesOrganisation
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_SUJET_DISPONILBLE,
                nodeName: REDUX_NODE_NAME.SUJETS_DISPONIBLE,
                etat: etatSujetDisponible
            }
        ]
    });
    const TYPES_FORMULAIRE_SERVICE_CLIENT = {
        VIDEO_EXPLICATIVE: `VideoExplicative`,
        URGENCE_COMMUNICATION: `UrgenceCommunication`
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const { selectedTypeForm, description } = fields;
        const payload = {
            newFormulaireServiceClient: {
                type: selectedTypeForm?.code,
                description
            }
        };
        if (
            selectedTypeForm?.code ===
            TYPES_FORMULAIRE_SERVICE_CLIENT.URGENCE_COMMUNICATION
        ) {
            payload.newFormulaireServiceClient.agent = {
                reference: fields.selectedAgent.reference
            };
            payload.newFormulaireServiceClient.sujet =
                fields.selectedSujet?.valeurTypeEntite;
            payload.newFormulaireServiceClient.titre =
                fields.selectedSujet?.title;
        }
        if (
            selectedTypeForm?.code ===
            TYPES_FORMULAIRE_SERVICE_CLIENT.VIDEO_EXPLICATIVE
        ) {
            payload.newFormulaireServiceClient.urlImage = fields.urlImage;
            payload.newFormulaireServiceClient.titre = fields.titre;
        }
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_FORMULAIRE_SERVICE_CLIENT,
                payload,
                fields,
                reduxNodeName: REDUX_NODE_NAME.SERVICE_CLIENT_FORM,
                reduxDispatcher,
                uiValidator,
                formater
            })
        );
    };
    const renderContent = () => (
        <FormWrapper
            formState={formState}
            formDispatcher={formDispatcher}
            listDependencies={[
                {
                    dependency: 'employe',
                    etat: etatEmployesOrganisation
                },
                {
                    dependency: 'sujet',
                    etat: etatSujetDisponible
                }
            ]}
        >
            <CustomRow>
                <CustomDropdown
                    options={[
                        {
                            code: TYPES_FORMULAIRE_SERVICE_CLIENT.URGENCE_COMMUNICATION,
                            designation: 'Urgence communication'
                        },
                        {
                            code: TYPES_FORMULAIRE_SERVICE_CLIENT.VIDEO_EXPLICATIVE,
                            designation: 'Vidéo Explicative'
                        }
                    ]}
                    label="Type Formulaire*"
                    defaultOption="Sélectionner Type Formulaire"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    formDispatcher={formDispatcher}
                    name="selectedTypeForm"
                    id="selectedTypeFormCreationFormulaireServiceClientId"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow isShouldBeRendered={!!fields.selectedTypeForm.code}>
                <CustomTextAreaInput
                    isInputGroup={false}
                    label="Description*: "
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    placeholder="Saisissez la description"
                    formDispatcher={formDispatcher}
                    name="description"
                    id="descriptionCreationFormulaireServiceClientId"
                    uiValidator={uiValidator}
                    formState={formState}
                    rows={2}
                />
            </CustomRow>
            <ConditionalRenderingWrapper
                isShouldBeRendered={
                    fields.selectedTypeForm.code ===
                    TYPES_FORMULAIRE_SERVICE_CLIENT.VIDEO_EXPLICATIVE
                }
            >
                <CustomRow>
                    <CustomInput
                        type="text"
                        label="URL: "
                        labelClassName="col-12 col-sm-2"
                        divClassName="col-12 col-sm-10"
                        isInputGroup={false}
                        placeholder="Saisissez le lien de la vidéo..."
                        formDispatcher={formDispatcher}
                        name="urlImage"
                        id="urlImageCreationFormulaireServiceClientId"
                        formState={formState}
                        uiValidator={uiValidator}
                    />
                </CustomRow>
                <CustomRow>
                    <CustomTextAreaInput
                        isInputGroup={false}
                        label="Titre*: "
                        labelClassName="col-12 col-sm-2"
                        divClassName="col-12 col-sm-10"
                        placeholder="Saisissez le titre"
                        formDispatcher={formDispatcher}
                        name="titre"
                        id="titreCreationFormulaireServiceClientId"
                        uiValidator={uiValidator}
                        formState={formState}
                        rows={2}
                    />
                </CustomRow>
            </ConditionalRenderingWrapper>
            <ConditionalRenderingWrapper
                isShouldBeRendered={
                    fields.selectedTypeForm.code ===
                    TYPES_FORMULAIRE_SERVICE_CLIENT.URGENCE_COMMUNICATION
                }
            >
                <CustomRow>
                    <CustomDropdown
                        options={employesOrganisation}
                        defaultOption="Selectionner Agent"
                        label="Agent* : "
                        labelClassName="col-12 col-sm-2"
                        divClassName="col-12 col-sm-10"
                        formDispatcher={formDispatcher}
                        name="selectedAgent"
                        id="selectedAgentCreationFormulaireServiceClientId"
                        uiValidator={uiValidator}
                        formState={formState}
                        idProperty="reference"
                    />
                </CustomRow>
                <CustomRow>
                    <CustomDropdown
                        options={sujetsDisponible}
                        defaultOption="Selectionner Sujet"
                        label="Sujet* : "
                        labelClassName="col-12 col-sm-2"
                        divClassName="col-12 col-sm-10"
                        formDispatcher={formDispatcher}
                        name="selectedSujet"
                        id="selectedSujetCreationServiceClientFormId"
                        uiValidator={uiValidator}
                        formState={formState}
                        idProperty="valeurTypeEntite"
                        propertyToDisplay="title"
                    />
                </CustomRow>
            </ConditionalRenderingWrapper>
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Sauvegarder"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
