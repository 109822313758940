import React, { Fragment } from 'react';
import { MdOutlineTableView } from 'react-icons/md';
import { BsDot } from 'react-icons/bs';
import { FORM_CONST } from '@napp-inc/jnapp-util';
import { TiDeleteOutline } from 'react-icons/ti';
import { CustomConfirmButton } from '../../../../../../components';

export function LeftSidePannelVariable({
    onShow,
    formFields,
    toggledView,
    handleSubmit,
    form,
    containerClassName = 'col-3 my-3 me-2',
    pannelLabel = 'Liste des charges',
    setFormFields,
    draftComponentAction,
    handleRemove
}) {
    return (
        <div className={containerClassName}>
            <div className="mt-1 alert alert-secondary d-flex justify-content-between">
                <span>{pannelLabel}</span>
                <MdOutlineTableView
                    className="fs-4"
                    title="Prévisualiser"
                    role="button"
                    onClick={onShow}
                />
            </div>
            {formFields.filter(
                (item) => !item.isTooggled && !!item.element.code
            ).length ? (
                <>
                    <div
                    // style={{ border: '1px dashed gray' }}
                    // className="alert alert-info"
                    >
                        <div className="">
                            {formFields.map((item, index) => (
                                <Fragment key={item.element.designation}>
                                    {!item.isTooggled && !!item.element.code ? (
                                        <ul
                                            style={{
                                                listStyleType: 'none',
                                                margin: 0,
                                                padding: 0
                                            }}
                                        >
                                            <li>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div>
                                                        <span
                                                            role="button"
                                                            tabIndex={0}
                                                            onClick={() =>
                                                                toggledView({
                                                                    code: item
                                                                        ?.element
                                                                        ?.code,
                                                                    isTargeted: true,
                                                                    formFields,
                                                                    setFormFields,
                                                                    view: 'ChargeVariable'
                                                                })
                                                            }
                                                            onKeyDown={() =>
                                                                toggledView({
                                                                    code: item
                                                                        ?.element
                                                                        ?.code,
                                                                    isTargeted: true,
                                                                    formFields,
                                                                    setFormFields,
                                                                    view: 'ChargeVariable'
                                                                })
                                                            }
                                                            className="ms-2"
                                                        >
                                                            <BsDot />
                                                            {
                                                                item?.element
                                                                    ?.designation
                                                            }
                                                            <span className="ms-2 badge bg-warning text-dark">
                                                                {
                                                                    item?.tauxCroissance
                                                                }
                                                            </span>
                                                        </span>
                                                    </div>
                                                    <TiDeleteOutline
                                                        role="button"
                                                        title="Supprimer la charge ainsi que toutes les informations la concernant!"
                                                        className="text-secondary fs-6"
                                                        onClick={() =>
                                                            handleRemove({
                                                                index,
                                                                formFields,
                                                                setFormFields,
                                                                view: 'ChargeVariable'
                                                            })
                                                        }
                                                    />
                                                </div>
                                            </li>
                                        </ul>
                                    ) : null}
                                </Fragment>
                            ))}
                        </div>
                    </div>
                    <CustomConfirmButton
                        modalText="Voulez-vous soumettre cette élaboration?"
                        modalChildren={draftComponentAction}
                        type="button"
                        backdrop="static"
                        text="Soumettre elaboration"
                        className="btn-success btn mt-5 w-100"
                        confirmOnClick={handleSubmit}
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                        isActionEncours={
                            form.status === FORM_CONST.CREATION_EN_COURS
                        }
                    />
                </>
            ) : (
                <div className="text-center">
                    <span>Liste vide...</span>
                </div>
            )}
        </div>
    );
}
