export const schema = [
    {
        description: 'devises.CDF.nombre',
        title: 'Nombre(CDF)'
    },
    {
        description: 'devises.CDF.montant',
        title: 'Montant CDF'
    },
    {
        description: 'devises.USD.nombre',
        title: 'Nombre(USD)'
    },
    {
        description: 'devises.USD.montant',
        title: 'Montant USD'
    },
    {
        title: 'Etat',
        description: 'etat'
    },
    {
        title: 'Création',
        description: 'dateCreation'
    }
];
