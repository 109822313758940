/**
 *
 * @param {Object} param0 : Tout le paramètre
 * @param {String} param0.name : name de l'input du formulaire qui peut être de différent format
 * @returns {Array | String} ['partOne', 'partTwo'] or 'name'
 */
export const getNames = ({ name }) => {
    if (name.includes('|')) return name.split('|');
    if (name.includes('.')) return name.split('.');
    return name;
};
