import { fetchElement, submitPutAction } from '@napp-inc/jnapp-hook';
import {
    CODE_SERVICE,
    DB_NODE,
    ETAT,
    FORM_CONST,
    fromTimestampToString
} from '@napp-inc/jnapp-util';
import {
    CustomButtonLoader,
    CustomCol,
    CustomConfirmButton,
    CustomRow,
    DetailWrapper,
    SimpleTable,
    UserServiceWrapper
} from '../../../components';
import { URL_CONST, useGetDetailForm } from '../../../util';
import { schema } from './detail-fields';

export function DetailHeureSupplementaireForm() {
    const { id, reduxDispatcher, entity, idToken, formState, formDispatcher } =
        useGetDetailForm({
            nodeName: 'heuresSupplementaire',
            defaultElements: ['details']
        });

    const { fields, form, elements } = formState;

    const onFetchDetailHeureSupplemetaireById = () => {
        if (!entity.id) return;
        const { status: etat } = elements.details;
        formDispatcher(
            fetchElement({
                idToken,
                functionName:
                    URL_CONST.GET_LIST_DETAIL_DEFINITION_HEURE_SUPPLEMENTAIRE,
                elementPropertyName: 'details',
                etat,
                payload: {
                    idDefinitionHeureSupplementaire: entity.id
                },
                reduxDispatcher
            })
        );
    };

    const handleSubmit = (etat) => {
        const payload = {
            idHeureSupplementaire: entity?.id,
            etat
        };
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'actionEncours',
                data: etat
            }
        });
        formDispatcher(
            submitPutAction({
                idToken,
                functionName: URL_CONST.PUT_DEFINITION_HEURE_SUPPLEMENTAIRE,
                payload,
                fields,
                reduxNodeName: DB_NODE.HEURE_SUPPLEMENTAIRE,
                reduxDispatcher
            })
        );
    };
    const renderContent = () => (
        <DetailWrapper
            functionName={URL_CONST.GET_DEFINITION_HEURE_SUPPLEMENTAIRE_BY_ID}
            nodeName={DB_NODE.HEURE_SUPPLEMENTAIRE}
            formDispatcher={formDispatcher}
            formState={formState}
            reduxDispatcher={reduxDispatcher}
            subtitle="Détails"
            entity={entity}
            id={id}
            schema={schema}
            idToken={idToken}
        >
            <div>
                <CustomButtonLoader
                    type="button"
                    className="btn-success mt-2"
                    onClick={onFetchDetailHeureSupplemetaireById}
                    text="Voir Détails"
                    disabled={
                        elements?.details?.status ===
                        FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                    }
                />
                <SimpleTable
                    tableClassName=""
                    isShowDropButton={false}
                    data={
                        elements?.details?.value?.map((item) => ({
                            ...item,
                            plage: {
                                debut: !item.estAutomatique
                                    ? fromTimestampToString(item.plage.debut)
                                    : item.plage.debut,
                                fin: !item.estAutomatique
                                    ? fromTimestampToString(item.plage.fin)
                                    : item.plage.fin
                            },
                            agentIdentite: `${item?.agent?.prenom || ''} ${
                                item?.agent?.nom || ''
                            }`
                        })) || []
                    }
                    identifier="id"
                    mapper={[
                        {
                            displayName: 'Agent',
                            field: 'agentIdentite'
                        },
                        {
                            displayName: 'Tache',
                            field: 'tache.designation'
                        },
                        {
                            field: 'plage.debut',
                            displayName: 'Debut'
                        },
                        {
                            field: 'plage.fin',
                            displayName: 'Fin'
                        },
                        {
                            field: 'dateCreation',
                            displayName: 'date création'
                        }
                    ]}
                    filter={false}
                    isLinkToDetail={false}
                />
            </div>
            <UserServiceWrapper
                services={[
                    { code: 'test' },
                    CODE_SERVICE.HEURE_SUPPLEMENTAIRE
                        .VALIDER_HEURE_SUPPLEMENTAIRE
                ]}
            >
                <CustomRow
                    isShouldBeRendered={entity?.etat !== ETAT.ETAT_VALIDER}
                >
                    <CustomCol className="offset-6 offset-md-8 col-3 col-md-2">
                        <CustomConfirmButton
                            type="button"
                            text="Annuler"
                            className="btn-danger"
                            confirmOnClick={() =>
                                handleSubmit(ETAT.ETAT_ANNULER)
                            }
                            disabled={
                                form.status === FORM_CONST.MODIFICATION_EN_COURS
                            }
                            isActionEncours={
                                fields.actionEncours === ETAT.ETAT_ANNULER
                            }
                            confirmText="Poursuivre"
                            cancelText="Abandonner"
                            header="Confirmation Annulation"
                        />
                    </CustomCol>
                    <CustomCol className="col-3 col-md-2">
                        <CustomConfirmButton
                            type="button"
                            text="Valider"
                            className="btn-success"
                            confirmOnClick={() =>
                                handleSubmit(ETAT.ETAT_VALIDER)
                            }
                            disabled={
                                form.status === FORM_CONST.MODIFICATION_EN_COURS
                            }
                            isActionEncours={
                                fields.actionEncours === ETAT.ETAT_VALIDER
                            }
                        />
                    </CustomCol>
                </CustomRow>
            </UserServiceWrapper>
        </DetailWrapper>
    );
    return renderContent();
}
