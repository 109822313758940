import { OPERATION_RAPPORT, TIME, TODAY } from '@napp-inc/jnapp-util';
import { URL_CONST, useGetReportDetail } from '../../../../util';
import { REDUX_NODE_NAME } from '../../../../redux';
import { ReduxReportTemplate } from '../../template';
import { SuperDealerSelector } from '../../../../components';

export function RapportFondRoulementKinDBForm() {
    const { formState, formDispatcher } = useGetReportDetail({
        defaultFields: {
            selectedSuperDealer: {}
        }
    });
    const formater = (data) => {
        const result = [];
        const operation = data?.[0]?.listReturn?.[0]?.operation || undefined;
        if (operation) {
            Object.getOwnPropertyNames(operation).forEach((property) => {
                const designation = OPERATION_RAPPORT?.[property] || property;
                result.push({
                    ...operation[property],
                    id: property,
                    designation,
                    soldeOuverture:
                        property === 'StockFlashs'
                            ? operation[property]?.balance?.soldeOuverture
                            : operation[property]?.soldeOuverture,
                    soldeFermeture:
                        property === 'StockFlashs'
                            ? operation[property]?.balance?.soldeFermeture
                            : operation[property]?.soldeFermeture,
                    total:
                        property === 'StockFlashs'
                            ? operation[property]?.balance?.montant
                            : operation[property]?.montant
                });
            });
        }
        return result;
    };
    // const secondFormater = (data) =>
    //     data?.[0]?.listReturnOrganisation?.map((item) => ({
    //         ...item,
    //         id: item?.details?.code
    //     }));
    const chartDataListFormater = (data) =>
        data?.[0]?.rapportsDate?.[0]?.dates || [];
    return (
        <>
            {/* <StateShowerComponent state={formState} /> */}
            <ReduxReportTemplate
                listTitle=""
                dynamicNodeName={`organisationsReport-${TIME.utc000000(
                    TODAY.at000000()
                )}-${TIME.utc235959(TODAY.at235959())}`}
                functionName={URL_CONST.GET_LIST_RAPPORT_ORGANISATION_V2}
                nodeName={REDUX_NODE_NAME.ORGANISATION_REPORT}
                listFormater={formater}
                formState={formState}
                formDispatcher={formDispatcher}
                mapper={[
                    {
                        displayName: 'Désignation',
                        field: 'designation',
                        fieldClassname: 'text-secondary fw-bold fs-6'
                    },
                    {
                        displayName: 'Ouverture',
                        field: 'soldeOuverture',
                        isTotal: true
                    },
                    {
                        displayName: 'Solde',
                        field: 'soldeFermeture',
                        isTotal: true
                    },
                    {
                        displayName: 'Résultat',
                        field: 'total',
                        isTotal: true
                    }
                ]}
                seriesMapper={[
                    {
                        displayName: 'Balance',
                        field: 'operation.Balances.soldeFermeture'
                    },
                    {
                        displayName: 'Stock Flash',
                        field: 'operation.StockFlashs.balance.soldeFermeture'
                    },
                    {
                        displayName: 'Liaisons',
                        field: `operation.${OPERATION_RAPPORT.LIAISONS}.soldeFermeture`
                    },
                    {
                        displayName: 'Comptes Bancaires',
                        field: 'operation.ComptesBancaire.soldeFermeture'
                    },
                    {
                        displayName: 'Caisses',
                        field: 'operation.Caisses.soldeFermeture'
                    },
                    {
                        displayName: 'Agents',
                        field: 'operation.Agents.soldeFermeture'
                    },
                    {
                        displayName: 'Caution',
                        field: `operation.${OPERATION_RAPPORT.CAUTIONS}.soldeFermeture`
                    },
                    {
                        displayName: 'Résultat',
                        field: 'total'
                    }
                ]}
                // secondMapper={[
                //     {
                //         displayName: 'Désignation',
                //         field: 'details.designation',
                //         fieldClassname: 'text-secondary fw-bold fs-6'
                //     },
                //     {
                //         displayName: 'Ouverture',
                //         field: 'soldeOuverture',
                //         isNumber: true
                //     },
                //     {
                //         displayName: 'Solde',
                //         field: 'soldeFermeture',
                //         isNumber: true
                //     },
                //     {
                //         displayName: 'Résultat',
                //         field: 'total',
                //         isNumber: true
                //     }
                // ]}
                payload={{
                    dateDebut: TIME.utc000000(TODAY.at000000()),
                    dateFin: TIME.utc235959(TODAY.at235959()),
                    typeEntite: 'Journalier',
                    typeProprietaire: 'SuperDealer',
                    estTotal: true,
                    rapport: {
                        dateDebut: TIME.add(TODAY.at000000(), { days: -15 }),
                        dateFin: TIME.utc235959(TODAY.at235959())
                    },
                    estAllOrganisation: true
                }}
                defaultPayloadMapperValues={{
                    typeEntite: 'Journalier',
                    typeProprietaire: 'SuperDealer',
                    estTotal: true,
                    estAllOrganisation: true
                }}
                payloadMapper={[
                    {
                        name: 'dateDebut',
                        property: 'dateDebut',
                        isRequired: true,
                        displayName: 'Date début'
                    },
                    {
                        name: 'dateFin',
                        property: 'dateFin',
                        isRequired: true,
                        displayName: 'Date fin'
                    },
                    {
                        name: 'selectedSuperDealer',
                        isRequired: false,
                        displayName: 'Super dealer',
                        callback: (value) => {
                            if (!value?.code || value?.code === 'all')
                                return {};
                            return { codeValeurTypeProprietaire: value?.code };
                        }
                    }
                ]}
                // isIncludeSecondTable
                // secondTableListFormater={secondFormater}
                chartDataListFormater={chartDataListFormater}
                chartTitle="Fonds de roulement"
                yAxisChartTitle="Fermerture ($)"
                isDisplayChart
                isAddTotalTr
            >
                <SuperDealerSelector
                    formState={formState}
                    formDispatcher={formDispatcher}
                    idProperty="code"
                    isAddAditionalOptions
                />
            </ReduxReportTemplate>
        </>
    );
}
