/**
 * Fonction pour elaborarer le tableau de prévisualisation
 */
export const tablePreviewData = ({ list = [] }) => {
    if (!list.length) return [];
    const data = [];
    // list.forEach((item) => {
    //     const element = {
    //         charge: item.element,
    //         moyenPaiement: item.typeMoyenPaiement,
    //         synthese: {
    //             JANVIER: 0,
    //             FEVRIER: 0,
    //             MARS: 0,
    //             AVRIL: 0,
    //             MAI: 0,
    //             JUIN: 0,
    //             JUILLET: 0,
    //             AOUT: 0,
    //             SEPTEMBRE: 0,
    //             OCTOBRE: 0,
    //             NOVEMBRE: 0,
    //             DECEMBRE: 0
    //         }
    //     };
    //     item.details.forEach((detail) => {
    //         const detailPeriodes = detail.periodes;
    //         detailPeriodes.forEach((dtp) => {
    //             const month = dtp.mois;
    //             const prix = item.prix || 0;
    //             const montantTotal = prix
    //                 ? prix *
    //                   dtp.beneficiaires.reduce(
    //                       (a, b) => a + (b.quantite || 0),
    //                       0
    //                   )
    //                 : dtp.beneficiaires.reduce(
    //                       (a, b) => a + (b.montant || 0),
    //                       0
    //                   );
    //             element.synthese[month] =
    //                 (element.synthese[month] || 0) + montantTotal;
    //         });
    //     });
    //     data.push(element);
    // });
    return data;
};
