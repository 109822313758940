import React from 'react';
import { ChargeVariableRowSelector } from './organisme-variable';

export function ChargeVariableBaseForm({
    formFields,
    setFormFields,
    field,
    index,
    handleSelectChange,
    charges,
    caisses,
    handleChange
}) {
    return (
        <div key={index} className="my-3 d-flex flex-column ms-3">
            <ChargeVariableRowSelector
                formFields={formFields}
                setFormFields={setFormFields}
                field={field}
                charges={charges}
                handleSelectChange={handleSelectChange}
                caisses={caisses}
                index={index}
                handleChange={handleChange}
            />
        </div>
    );
}
