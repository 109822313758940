import { submitPutAction } from '@napp-inc/jnapp-hook';
import * as Luhn from 'luhn-js';
import {
    DB_NODE,
    ETAT,
    FORM_CONST,
    URL_CONST,
    CODE_SERVICE,
    Add,
    Sub
} from '@napp-inc/jnapp-util';
import {
    // BilletageComponent,
    ConditionalRenderingWrapper,
    CustomAddButton,
    CustomCol,
    CustomCollapse,
    CustomConfirmButton,
    CustomInput,
    CustomLabel,
    CustomRow,
    DetailWrapper,
    SimpleTable,
    UserServiceWrapper
} from '../../../components';
import { useGetDetailForm } from '../../../util';
import { schema } from './detail-fields';

const defaultFields = {
    reference: '',
    actionEncours: '',
    selectedCoupureBilletage: {},
    nombreCoupureBilletage: 0,
    listBilletage: [],
    selectedMarchand: {},
    designationMarchand: '',
    previewMarchand: '',
    marchand: '',
    preview: '',
    debut: '',
    fin: '',
    produitSim: {},
    selectedSuperDealer: {},
    plages: []
};

/**
 * @description defaultDependencies for our state
 */
const defaultDependencies = { entity: true, fields: ['devise'] };

// FIXME: Ce form n'est pas fini
/**
 *
 * - il faut fetcher les devises
 * - si un compte est utilisé, on renseigne la référence et l'etat attente validation, le backend met ça à terminer
 * - si une caisse plutôt, on resnseigne billetage
 * - en suite on resigne référence et on met à valider
 * (si le caissier dispose de la référence, on renseigne cela ensuite on passe letat attente, et le back met à valider)
 *
 */
export function DetailFacturationStockForm() {
    const {
        id,
        reduxDispatcher,
        entity,
        idToken,
        formState,
        formDispatcher,
        uid
        // reduxProps
    } = useGetDetailForm({
        nodeName: 'approvisionnementsStock',
        defaultFields,
        defaultDependencies,
        listNode: [
            {
                name: 'allCoupures'
            }
        ]
    });

    const isValidator = entity?.userDecaissement === uid;
    const estCaisse = entity?.typeEntite === `Caisse`;
    const { fields, form } = formState;
    function comparerDebut(plageA, plageB) {
        return plageA.debut - plageB.debut;
    }
    const updateEtat = (etat) => {
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'actionEncours',
                data: etat
            }
        });
        const payloadToAdd = {};
        if (fields.listBilletage.length) {
            payloadToAdd.coupures = fields.listBilletage.map((billet) => ({
                nombre: billet.nombre,
                valeur: parseInt(billet.coupure.code, 10)
            }));
        }
        if (fields?.plages?.length) {
            payloadToAdd.plages = fields?.plages
                ?.sort(comparerDebut)
                ?.map(({ debut, fin }) => ({
                    debut,
                    fin
                }));
        }
        if (
            !isValidator &&
            ((entity?.etat === ETAT.ETAT_VALIDER_DECAISSEMENT && estCaisse) ||
                (entity?.etat === ETAT.ETAT_ENCOURS && !estCaisse)) &&
            !fields.reference
        ) {
            formDispatcher({
                type: FORM_CONST.SET_FORM_ERROR,
                payload: {
                    message: 'Veuillez renseigner le numéro de référence !',
                    status: FORM_CONST.MODIFICATION_ECHEC
                }
            });
            return;
        }
        const payload = {
            etat,
            idApprovisionnementStock: entity?.id,
            ...payloadToAdd
        };

        if (fields.reference) {
            payload.numeroReference = fields.reference;
        }

        // console.log(payload)
        formDispatcher(
            submitPutAction({
                idToken,
                functionName: URL_CONST.PUT_APPRO_STOCK,
                payload,
                fields,
                reduxNodeName: DB_NODE.APPROVISIONNEMENT_STOCK,
                reduxDispatcher
            })
        );
    };

    /**
     * Fonction d'ajout de plage sim dans un tableau avant la soumission du formulaire
     * @returns {ReduxAction}
     */

    const addProduit = () => {
        const { debut, fin, plages } = fields;
        let debutTraiter = debut;
        let finTraiter = fin;
        const controlDebut = Luhn.isValid(debut);
        const controlFin = Luhn.isValid(fin);

        if (debut.length === 0 || fin.length === 0) {
            formDispatcher({
                type: FORM_CONST.SET_FORM_ERROR,
                payload: {
                    message: 'Renseigner le debut et la fin de la plage',
                    status: FORM_CONST.VALIDATION_ECHEC
                }
            });
            return;
        }
        if (debut.length !== fin.length) {
            formDispatcher({
                type: FORM_CONST.SET_FORM_ERROR,
                payload: {
                    message:
                        "Impossible d'avoir un nombre de caractère different",
                    status: FORM_CONST.VALIDATION_ECHEC
                }
            });
            return;
        }
        if (debutTraiter?.length > 18 || finTraiter?.length > 18) {
            if (controlDebut) {
                debutTraiter = debut.substr(0, debut.length - 1);
            }
            if (controlFin) {
                finTraiter = fin.substr(0, fin.length - 1);
            }
        }
        if (
            plages.find(({ debut: plageDebut }) => plageDebut === debutTraiter)
        ) {
            formDispatcher({
                type: FORM_CONST.SET_FORM_ERROR,
                payload: {
                    message: 'Ce debut existe déjà',
                    status: FORM_CONST.VALIDATION_ECHEC
                }
            });
            return;
        }
        if (plages.find(({ fin: plageFin }) => plageFin === finTraiter)) {
            formDispatcher({
                type: FORM_CONST.SET_FORM_ERROR,
                payload: {
                    message: 'Cette fin existe déjà',
                    status: FORM_CONST.VALIDATION_ECHEC
                }
            });
            return;
        }
        const quantite = Add(
            Sub(
                parseInt(finTraiter.substring(7), 10),
                parseInt(debutTraiter.substring(7), 10)
            ),
            1
        );
        if (quantite <= 0) {
            formDispatcher({
                type: FORM_CONST.SET_FORM_ERROR,
                payload: {
                    message: 'La fin doit être supérieur au début',
                    status: FORM_CONST.VALIDATION_ECHEC
                }
            });
        } else {
            const newState = {
                ...formState,
                fields: {
                    ...formState.fields,
                    debut: '',
                    fin: '',
                    produitSim: fields?.produitSim,
                    plages: fields?.plages
                }
            };
            formDispatcher({
                type: FORM_CONST.ADD_ITEM_ON_NEW_STATE,
                payload: {
                    newState,
                    field: 'plages',
                    identifier: 'id',
                    data: {
                        id: `${debut}-${fin}`,
                        debut,
                        fin,
                        quantite
                    }
                }
            });
        }
    };
    const renderContent = () => (
        <DetailWrapper
            functionName={URL_CONST.GET_APPRO_STOCK_SUPER_DEALER_BY_ID}
            nodeName={DB_NODE.APPROVISIONNEMENT_STOCK}
            formDispatcher={formDispatcher}
            formState={formState}
            reduxDispatcher={reduxDispatcher}
            onSubmit={updateEtat}
            subtitle="Facturation stock"
            entity={entity}
            id={id}
            schema={schema}
            idToken={idToken}
        >
            {/* Gestionnaire compte ou caisse */}
            <UserServiceWrapper
                services={[
                    { code: 'test' },
                    CODE_SERVICE.STOCK.FACTURATION_STOCK
                        .CONFIRMER_FACTURATION_STOCK
                ]}
            >
                <ConditionalRenderingWrapper
                    isShouldBeRendered={entity.etat === ETAT.ETAT_PRE_ENCOURS}
                >
                    <CustomRow>
                        <ConditionalRenderingWrapper
                            isShouldBeRendered={
                                entity.etat !== ETAT.ETAT_ANNULER &&
                                entity.etat !== ETAT.ETAT_VALIDER
                            }
                        >
                            <CustomCol className="offset-6 offset-md-8 col-3 col-md-2">
                                <CustomConfirmButton
                                    type="button"
                                    text="Annuler"
                                    className="btn-danger"
                                    confirmOnClick={() =>
                                        updateEtat(ETAT.ETAT_ANNULER)
                                    }
                                    disabled={
                                        form.status ===
                                        FORM_CONST.MODIFICATION_EN_COURS
                                    }
                                    isActionEncours={
                                        fields.actionEncours ===
                                        ETAT.ETAT_ANNULER
                                    }
                                    confirmText="Poursuivre"
                                    cancelText="Abandonner"
                                    header="Confirmation Annulation"
                                />
                            </CustomCol>
                        </ConditionalRenderingWrapper>
                        <CustomCol className="col-3 col-md-2">
                            <CustomConfirmButton
                                type="button"
                                text="Valider"
                                className="btn-success"
                                confirmOnClick={() =>
                                    updateEtat(ETAT.ETAT_ENCOURS)
                                }
                                disabled={
                                    form.status ===
                                    FORM_CONST.MODIFICATION_EN_COURS
                                }
                                isActionEncours={
                                    fields.actionEncours === ETAT.ETAT_ENCOURS
                                }
                            />
                        </CustomCol>
                    </CustomRow>
                </ConditionalRenderingWrapper>
            </UserServiceWrapper>
            {/* Validation des operations */}
            <UserServiceWrapper
                services={[
                    { code: 'test' },
                    CODE_SERVICE.STOCK.FACTURATION_STOCK
                        .DECAISSER_FACTURATION_STOCK,
                    CODE_SERVICE.STOCK.FACTURATION_STOCK
                        .VALIDER_FACTURATION_STOCK
                ]}
            >
                <CustomRow
                    isShouldBeRendered={
                        !isValidator &&
                        ((entity?.etat === ETAT.ETAT_VALIDER_DECAISSEMENT &&
                            estCaisse) ||
                            (entity?.etat === ETAT.ETAT_ENCOURS && !estCaisse))
                    }
                >
                    <CustomInput
                        isInputGroup={false}
                        label="Reference*"
                        labelClassName="col-12 col-sm-2"
                        divClassName="col-12 col-sm-10"
                        placeholder="Reference de la transaction"
                        formDispatcher={formDispatcher}
                        name="reference"
                        id="idRefFacturation"
                        formState={formState}
                    />
                </CustomRow>
                <ConditionalRenderingWrapper
                    isShouldBeRendered={
                        (!!entity?.estSim &&
                            entity?.etat === ETAT.ETAT_VALIDER_DECAISSEMENT &&
                            estCaisse) ||
                        (!!entity?.estSim &&
                            entity?.etat === ETAT.ETAT_ENCOURS &&
                            !estCaisse)
                    }
                >
                    <CustomRow>
                        <CustomCol className="col-sm-2">
                            <CustomLabel text="Plages" />
                        </CustomCol>
                        <CustomCol>
                            <CustomInput
                                placeholder="Début"
                                name="debut"
                                formState={formState}
                                formDispatcher={formDispatcher}
                            />
                        </CustomCol>
                        <CustomCol>
                            <CustomInput
                                placeholder="Fin"
                                name="fin"
                                formState={formState}
                                formDispatcher={formDispatcher}
                            />
                        </CustomCol>
                        <CustomCol className="col-sm-1">
                            <CustomAddButton
                                list={fields.plages}
                                className="btn btn-primary"
                                formDispatcher={formDispatcher}
                                field="plages"
                                size="25em"
                                identifier="id"
                                callback={addProduit}
                            />
                        </CustomCol>
                    </CustomRow>
                    <CustomCollapse
                        isIn={
                            fields.plages.length > 0 ||
                            entity?.plages?.length > 0
                        }
                        className="offset-sm-2 col-sm-10"
                    >
                        <SimpleTable
                            isShowDropButton={fields.plages.length > 0}
                            identifier="id"
                            data={
                                entity?.plages?.length > 0
                                    ? entity?.plages?.map(
                                          ({ debut, fin, initial }) => ({
                                              debut: `${initial.debut}${debut}`,
                                              fin: `${initial.fin}${fin}`,
                                              quantite: Add(
                                                  Sub(
                                                      parseInt(fin, 10),
                                                      parseInt(debut, 10)
                                                  ),
                                                  1
                                              )
                                          })
                                      )
                                    : fields.plages
                            }
                            listSelectedItemName="plages"
                            formDispatcher={formDispatcher}
                            tableClass="table table-hover shadow table-borderless table-responsive-sm"
                            filter={false}
                            bottomPagination={false}
                            isLinkToDetail={false}
                            mapper={[
                                {
                                    field: 'debut',
                                    displayName: 'Début',
                                    position: 1
                                },
                                {
                                    field: 'fin',
                                    displayName: 'Fin',
                                    position: 2
                                },
                                {
                                    field: 'quantite',
                                    displayName: 'Quantité',
                                    position: 3
                                }
                            ]}
                        />
                    </CustomCollapse>
                </ConditionalRenderingWrapper>
                {/* Caissier validation */}
                <CustomRow
                    isShouldBeRendered={entity.etat !== ETAT.ETAT_PRE_ENCOURS}
                >
                    {/* Button annuler */}
                    <ConditionalRenderingWrapper
                        isShouldBeRendered={
                            entity.etat !== ETAT.ETAT_ANNULER &&
                            entity.etat !== ETAT.ETAT_VALIDER
                        }
                    >
                        <CustomCol className="offset-6 offset-md-8 col-3 col-md-2">
                            <CustomConfirmButton
                                type="button"
                                text="Annuler"
                                className="btn-danger"
                                confirmOnClick={() =>
                                    updateEtat(ETAT.ETAT_ANNULER)
                                }
                                disabled={
                                    form.status ===
                                    FORM_CONST.MODIFICATION_EN_COURS
                                }
                                isActionEncours={
                                    fields.actionEncours === ETAT.ETAT_ANNULER
                                }
                                confirmText="Poursuivre"
                                cancelText="Abandonner"
                                header="Confirmation Annulation"
                            />
                        </CustomCol>
                    </ConditionalRenderingWrapper>
                    {/* Encours */}
                    <ConditionalRenderingWrapper
                        isShouldBeRendered={entity.etat === ETAT.ETAT_ENCOURS}
                    >
                        {/* Caissier */}
                        <UserServiceWrapper
                            services={[
                                { code: 'test' },
                                CODE_SERVICE.STOCK.FACTURATION_STOCK
                                    .DECAISSER_FACTURATION_STOCK
                            ]}
                        >
                            <ConditionalRenderingWrapper
                                isShouldBeRendered={isValidator}
                            >
                                <CustomCol className="col-3 col-md-2">
                                    <CustomConfirmButton
                                        type="button"
                                        text="Valider Décaissement"
                                        className="btn-success"
                                        confirmOnClick={() =>
                                            updateEtat(
                                                ETAT.ETAT_VALIDER_DECAISSEMENT
                                            )
                                        }
                                        disabled={
                                            form.status ===
                                            FORM_CONST.MODIFICATION_EN_COURS
                                        }
                                        isActionEncours={
                                            fields.actionEncours ===
                                            ETAT.ETAT_VALIDER_DECAISSEMENT
                                        }
                                    />
                                </CustomCol>
                            </ConditionalRenderingWrapper>
                        </UserServiceWrapper>
                        {/* Non caissier */}
                        <UserServiceWrapper
                            services={[
                                { code: 'test' },
                                CODE_SERVICE.STOCK.FACTURATION_STOCK
                                    .VALIDER_FACTURATION_STOCK
                            ]}
                        >
                            <ConditionalRenderingWrapper
                                isShouldBeRendered={!estCaisse}
                            >
                                <CustomCol className="col-3 col-md-2">
                                    <CustomConfirmButton
                                        type="button"
                                        text="Mettre en attente"
                                        className="btn-success"
                                        confirmOnClick={() =>
                                            updateEtat(
                                                ETAT.ETAT_ATTENTE_VALIDATION
                                            )
                                        }
                                        disabled={
                                            form.status ===
                                            FORM_CONST.MODIFICATION_EN_COURS
                                        }
                                        isActionEncours={
                                            fields.actionEncours ===
                                            ETAT.ETAT_ATTENTE_VALIDATION
                                        }
                                    />
                                </CustomCol>
                            </ConditionalRenderingWrapper>
                        </UserServiceWrapper>
                    </ConditionalRenderingWrapper>
                    {/* Valider decaissement && not validator */}
                    <ConditionalRenderingWrapper
                        isShouldBeRendered={
                            !isValidator &&
                            entity?.etat === ETAT.ETAT_VALIDER_DECAISSEMENT
                        }
                    >
                        <CustomCol className="col-3 col-md-2">
                            <CustomConfirmButton
                                type="button"
                                text="Mettre en attente"
                                className="btn-success"
                                confirmOnClick={() =>
                                    updateEtat(ETAT.ETAT_ATTENTE_VALIDATION)
                                }
                                disabled={
                                    form.status ===
                                    FORM_CONST.MODIFICATION_EN_COURS
                                }
                                isActionEncours={
                                    fields.actionEncours ===
                                    ETAT.ETAT_ATTENTE_VALIDATION
                                }
                            />
                        </CustomCol>
                    </ConditionalRenderingWrapper>
                    {/* Attente validation */}
                    <UserServiceWrapper
                        services={[
                            { code: 'test' },
                            CODE_SERVICE.STOCK.FACTURATION_STOCK
                                .VALIDER_FACTURATION_STOCK
                        ]}
                    >
                        <ConditionalRenderingWrapper
                            isShouldBeRendered={
                                entity?.etat === ETAT.ETAT_ATTENTE_VALIDATION
                            }
                        >
                            <CustomCol className="col-3 col-md-2">
                                <CustomConfirmButton
                                    type="button"
                                    text="Valider"
                                    className="btn-success"
                                    confirmOnClick={() =>
                                        updateEtat(ETAT.ETAT_VALIDER)
                                    }
                                    disabled={
                                        form.status ===
                                        FORM_CONST.MODIFICATION_EN_COURS
                                    }
                                    isActionEncours={
                                        fields.actionEncours ===
                                        ETAT.ETAT_VALIDER
                                    }
                                />
                            </CustomCol>
                        </ConditionalRenderingWrapper>
                    </UserServiceWrapper>
                </CustomRow>
            </UserServiceWrapper>
        </DetailWrapper>
    );
    return renderContent();
}
