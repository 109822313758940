import * as yup from 'yup';

/**
 * @description : Schema de validation Compte Imputation
 */
export const CompteImputationSchema = yup.object().shape({
    code: yup.string(),
    designation: yup.string().required(),
    selectedType: yup.string().required()
});
