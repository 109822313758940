import { useLocation, useParams } from 'react-router-dom';
import { TYPE_BUDGET_ADDITIONNEL } from '@napp-inc/jnapp-util';
import { BudgetRessources, ChargesFixes, ChargesVariables } from '../common';

/**
 *
 * @param {Object} param0 : Object
 * @param {String} param0.type : Présice si on est fixe | variable | ressource
 * @param {String} param0.isCompletion : Précise si nous somme dans le cas de complétion ou pas
 * @returns
 */
export function Completion() {
    const { id } = useParams();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search.replace('?', ''));
    const renderContent = () => {
        if (searchParams && searchParams.get('type') === 'Charge') {
            if (searchParams.get('isVar') === 'false') {
                return (
                    <ChargesFixes
                        idEntity={id}
                        isCompletion
                        isAdditionnel={
                            searchParams.get('isAdd') ===
                            TYPE_BUDGET_ADDITIONNEL.code
                        }
                        isAmendement={false}
                        payloadUrl={{
                            start: Number(searchParams.get('start')),
                            end: Number(searchParams.get('end'))
                        }}
                    />
                );
            }
            if (searchParams.get('isVar') === 'true') {
                return (
                    <ChargesVariables
                        idEntity={id}
                        isCompletion
                        isAdditionnel={
                            searchParams.get('isAdd') ===
                            TYPE_BUDGET_ADDITIONNEL.code
                        }
                        isAmendement={false}
                        payloadUrl={{
                            start: Number(searchParams.get('start')),
                            end: Number(searchParams.get('end'))
                        }}
                    />
                );
            }
        }
        if (searchParams && searchParams.get('type') === 'Ressource') {
            return (
                <BudgetRessources
                    idEntity={id}
                    isCompletion
                    isAdditionnel={
                        searchParams.get('isAdd') ===
                        TYPE_BUDGET_ADDITIONNEL.code
                    }
                    isAmendement={false}
                    payloadUrl={{
                        start: Number(searchParams.get('start')),
                        end: Number(searchParams.get('end'))
                    }}
                />
            );
        }
        // return <div>Charge {searchParams.get('isPrev')}</div>;
        return null;
    };
    return renderContent();
}
