export const CF_URL = process.env.REACT_APP_CF_BASE_URL;
export const POST_FAKE = 'postObjet';
export const POST_REMBOURSEMENT_COMMANDE = 'postRemboursementCommande';
export const POST_DEMANDE_ANNULATION = 'postDemandeAnnulation';
export const PUT_COMMANDE_FLASH = 'putCommandeFlash';
export const PUT_REGULARISATION_STOCK = 'putRegularisationStock';
export const PUT_CAISSE = 'putCaisse';
export const POST_FONCTION = 'postFonction';
export const PUT_FONCTION = 'putFonction';
export const PUT_DESACTIVE_USER = 'putDesactiveUser';
export const POST_BANQUE = 'postBanque';
export const POST_SERVICE_GENERATEUR = 'postServiceGenerateur';
export const POST_PRESENCE = 'postPresence';
export const POST_TRANSFERT_STOCK_MATERIEL =
    'postTransfertStockProduitMateriel';
export const GET_FAKE = 'getListObjet';
export const GET_ALL_ZONE_SENDEUR = 'getAllZoneSendeur';
export const GET_ZONE_SENDEUR_BY_ID = 'getZoneSendeurById';
export const GET_LIST_TRANSFERT_FONDS_BY_ORGANISATION =
    'getListTransfertFondsByOrganisation';
export const GET_LIST_SYS_ACTIF_OF_A_USER =
    'getListUserSysServiceByOwnerOrganisation';
export const GET_LIST_TRANSFERT_FONDS_BY_ID = 'getTransfertFondsById';
export const GET_LIST_APPRO_MONNAIE = 'getListApprovisionnementMonnaieInitials';
export const GET_LIST_CHARGE_FIXE = 'getListChargeFixe';
export const GET_LIST_IMPUTATION_STOCK = 'getAllImputationStock';
export const GET_LIST_CHARGE_VARIABLE = 'getListChargeVariable';
export const GET_LIST_RESSOURCE = 'getAllRessource';
export const GET_CHARGE_BY_ID = 'getChargeById';
export const GET_IMPUTATION_STOCK_BY_ID = 'getImputationStockId';
export const GET_REGULARISATION_STOCK_BY_ID = 'getRegularisationStockById';
export const GET_TYPE_STOCK_BY_ID = 'getTypeStockById';
export const GET_CAISSE_BY_ID = 'getCaisseById';
export const GET_RAYON_COLONNE_BY_ID = 'GET_RAYON_COLONNE_BY_ID';
export const GET_RESSOURCE_BY_ID = 'getRessourceById';
export const GET_FONCTION_BY_ID = 'getFonctionById';
export const GET_LIST_ALL_FONCTION = 'getAllFonction';
export const GET_INSTITUTION_FINANCIERE_BY_ID = 'getInstitutionFinanciereById';
export const GET_SERVICE_GENERATEUR_BY_ID = 'getServiceGenerateurById';
export const GET_PRESENCE_AGENT_BY_ID = 'GET_PRESENCE_AGENT_BY_ID';
export const GET_TRANSFERT_STOCK_MATERIEL_BY_ID =
    'getTransfertStockProduitMaterielById';
export const PUT_ACCEPTER_NUMERO_REFERENCE = 'putAccepteNumeroReference';
export const PUT_ACCEPTER_TRANSFERT_AUTOMATIQUE =
    'putAccepteTransfertAutomatique';
export const PUT_VIDER_COMPTE_AFTER_FERMETURE = 'putAccepteVideAfterFermeture';
export const PUT_LOGO = 'putLogo';
export const PUT_BANQUE = 'putBanque';
export const GET_USER = 'getUser';
export const GET_USER_V2 = 'getuser';
export const GET_COMMANDE_EMONEY_BY_NUMERO_REFERENCE =
    'getCommandeEmoneyByNumeroReference';
export const POST_EMPLOYE = 'postEmploye';
export const POST_AGENCE = 'postAgence';
export const POST_ENTREPRISE = 'postEntreprise';
export const POST_RAYON_SHOWROOM = 'postListRayonShowRoom';
export const POST_APPOINTAGE_SUPER_DEALER = 'postAppointageSuperDealer';
export const GET_LIST_ENTITE_ADMINISTRATIVE_BY_ZONES =
    'getListEntiteAdministrativeByZones';
export const GET_LIST_PRODUIT_MARCHAND_TYPE_AIRTIME =
    'getListProduitMarchandTypeAirtime';
export const GET_LIST_PRODUIT_MARCHAND_MATERIEL = 'getListProduitMarchand';
export const GET_LIST_SUPER_DEALER_MARCHAND = 'getListSuperDealerMarchand';
export const GET_LIST_BANQUE = 'getListBanque';
export const GET_LIST_BANQUE_INITIALS = 'getListInstitutionFinanciereInitials';
export const GET_LIST_SERVICE_GENERATEUR = 'getListServiceGenerateur';
export const GET_LIST_PRESENCE_AGENT = 'getListPresenceAgent';
export const GET_LIST_TRANSFERT_STOCK_MATERIEL = 'getListTransfertStockProduit';
export const GET_LIST_CATEGORIE_MARCHAND = 'getAllCategorieMarchand';
export const GET_LIST_TYPE_MARCHAND_RESSOURCE = 'getAllTypesMarchandRessource';
export const PUT_MARCHAND_CATEGORIE = 'putMarchandCategorie';
export const POST_LIGNE_CREDIT_DEALER = 'postLigneCreditDealer';
export const PUT_LIGNE_CREDIT_DEALER = 'putLigneCreditDealer';
export const GET_LIST_PRODUIT_SUPER_DEALER = 'getListProduitSuperDealer';
export const POST_APPRO_SIM = 'postApproSim';
export const PUT_APPRO_SIM = 'putApproSim';
export const PUT_TRANSFERT_SIM = 'putTransfertSim';
export const POST_TRANSFERT_SIM = 'postTransfertSim';
export const GET_LIST_USER = 'getListUser';
export const GET_LIST_EMPLOYE_ORGANISATION = 'getListEmployeOrganisation';
export const GET_LIST_RETRAIT_CASH_POINT = 'getListRetraitCashPoint';
export const GET_LIST_STOCK_A_REGULARISER = 'getListStockARegulariser';
export const GET_LIST_LIGNE_CREDIT_INTITIALS = 'getListLigneCreditInitials';
export const GET_LIST_LIGNE_CREDIT_SUPER_DEALER =
    'getListLigneCreditSuperDealer';
export const GET_LIST_COMPTE_BANCAIRE_ORGANISATION =
    'getListCompteBancaireOrganisation';
export const GET_LIST_RECRUTEUR = 'getlistrecruteur';
export const POST_TAUX = 'postTaux';
export const POST_TARIF_CHARGE = 'postTarifCharge';
export const POST_TARIF_RESSOURCE = 'postTarifRessource';
export const POST_TARIF_MARCHAND = 'postTarifMarchand';
export const POST_PARTENAIRE = 'postPartenaire';
export const POST_CAISSE = 'postCaisse';
export const POST_CHARGE = 'postCharge';
export const POST_IMPUTATION_STOCK = 'postImputationStock';
export const POST_REGULARISATION_STOCK = 'postRegularisationStock';
export const POST_TYPE_STOCK = 'postTypeStock';
export const POST_RESSOURCE = 'postRessource';
export const POST_COMMANDE_FLASH = 'postCommandeFlash';
export const POST_CREDITER_COMPTE = 'postCrediterCompte';
export const POST_DEMANDE_AFFILIATION_AGENT_LIVREUR = 'postDemandeAffiliation';
export const POST_VERSEMENT_AGENT_VENDEUR = 'postVersementAgentVendeur';
export const POST_LIST_NUMERO_CLIENT = 'postListNumeroClient';
export const POST_APPRO_MONNAIE = 'postApproMonnaie';
export const POST_MONNAIE_LOCALE = 'postMonnaieLocale';
export const PUT_APPRO_MONNAIE = 'putApproMonnaie';
export const GET_APPROVISIONNEMENT_SIM_BY_BY_REFERENCE =
    'getApprovisionnementSimByByReference';
export const GET_LIST_APPROVISIONNEMENT_SIM = 'getListApprovisionnementSim';
export const GET_APPRO_SIM_BY_ID = 'getApproSimById';
export const GET_LIST_MOUVEMENT_PLAGE = 'getListMouvementByPlage';
export const GET_LIST_COMMANDE_FLASH_BY_LIST = 'getListCommandeFlashByList';
export const GET_STOCK_PHYSIQUE = 'getVerifyStock';
export const GET_LIST_COMPTE_USER = 'getListCompteUser';
export const GET_LIST_RAPPORT_LIAISON = 'getlistrapportliaisoninitials';
export const GET_LIST_RAPPORT_ORGANISATION = 'getListRapportOrganisation';
export const GET_LIST_RAPPORT_ORGANISATION_V2 = 'getlistrapportorganisation';
export const GET_LIST_MOUVEMENT_OPERATION =
    'getListMouvementByTypeEntiteAndValeurTypeEntite';
export const GET_REVENDEUR_BY_TILL_NUMBER = 'getRevendeurByTillNumber';
export const GET_AGENT_LIVREUR_TILL_NUMBER = 'getAgentLivreurByTillNumber';
export const GET_MARCHAND_BY_TILL_NUMBER = 'getMarchandByTillNumber';
export const GET_LIST_DETAIL_TRANSFERT_STOCK_MATERIEL =
    'getListDetailTransfertStockProduitMateriel';
export const GET_LIST_TRANSFERT_INITIALS = 'getListTransfertSimInitials';
export const GET_TRANSFERT_SIM_BY_ID = 'getTransfertSimById';
export const GET_LIGNE_CREDIT_BY_ID = 'getLigneCreditById';
export const POST_APPRO_STOCK = 'postApproStock';
export const PUT_APPRO_STOCK = 'putApproStock';
export const GET_LIST_APPRO_STOCK_SUPER_DEALER = 'getListApproStockSuperDealer';
export const GET_LIST_RAPPORT_DASHBOARD_NAPP = 'getlistrapportdashboard';
export const GET_APPRO_STOCK_SUPER_DEALER_BY_ID =
    'getApproStockSuperDealerById';
export const GET_LIST_STOCK_SUPER_DEALER = 'getliststocksuperdealer';
export const GET_LIST_RAPPORT_VENTE_USER = 'getListRapportVenteUser';
export const GET_LIST_CAISSE = 'getListCaisse';
export const PUT_COMMANDE_EMONEY = 'putCommandeEmoney';
export const PUT_MONNAIE_LOCALE = 'putMonnaieLocale';
export const PUT_TAUX = 'putTaux';
export const PUT_TRANSFERT_STOCK_MATERIEL = 'putTransfertStockProduitMateriel';
export const PUT_TARIF = 'putTarif';
export const PUT_TUNNELS = 'putTunnels';
export const PUT_PASSWORD = 'putPassword';
export const POST_TRANSFERT_FONDS = 'postTransfertFonds';
export const PUT_PARTENAIRE = 'putPartenaire';
export const GET_APPROVISIONNEMENT_MONNAIE_BY_ID =
    'getApprovisionnementMonnaieById';
export const GET_MONNAIE_LOCALE_BY_ID = 'getMonnaieLocaleById';
export const GET_PRODUIT_BY_ID = 'getProduitById';
export const GET_SUPER_DEALER_BY_ID = 'getSuperDealerById';
export const GET_TAUX_BY_ID = 'getTauxById';
export const GET_USER_BY_ID = 'getUserById';
export const GET_EMPLOYE_BY_ID = 'getEmployeById';
export const GET_COMMANDE_FLASH_USER = 'getListCommandeFlashUser';
export const GET_COMMANDE_FLASH_BY_ID = 'getCommandeFlashById';
export const GET_CLIENT_BY_ID = 'getClientById';
export const GET_TARIF_BY_ID = 'getTarifById';
export const GET_CREDITER_COMPTE_BY_ID = 'getCrediterCompteById';
export const GET_DEMANDE_AFFILIATION_BY_ID = 'getDemandeAffiliationById';
export const GET_VERSEMENT_AGENT_VENDEUR_BY_ID = 'getVersementAgentVendeurById';
export const GET_PARTENAIRE_BY_ID = 'getPartenaireById';
export const GET_COMMANDE_EMONEY_BY_ID = 'getCommandeEmoneyById';
export const GET_LIST_NUMERO_CLIENT = 'getListNumeroClient';
export const GET_LIST_MONNAIE_LOCALE = 'getListMonnaieLocale';
export const GET_LIST_MONNAIE_LOCALE_INITIALS = 'getListMonnaieLocaleInitials';
export const GET_LIST_COLLECTION = 'getListCollection';
export const GET_LIST_AGENCE = 'getListAgence';
export const GET_LIST_COLONNE_AGENCE = 'getListColonne';
export const GET_LIST_TAUX_ACTIF_ORGANISATION = 'getListTauxActifOrganisation';
export const GET_LIST_CREDITER_COMPTE_CASH_REVENDEUR =
    'getListCrediterCompteCashRevendeur';
export const GET_LIST_DEMANDE_AFFILIATION = 'getListDemandeAffiliation';
export const GET_LIST_ALL_VERSEMENT_AGENT_VENDEUR =
    'getAllVersementAgentVendeur';
export const GET_LIST_TAUX_ORGANISATION_BY_ETAT =
    'getListTauxOrganisationByEtats';
export const GET_LIST_COMMANDE_FLASH_BY_ETAT = 'getListCommandeFlashBySendeur';
export const GET_LIST_TROP_PERCU_COMMANDE_FLASH =
    'getListTropPercuOrganisation';
export const GET_LIST_COMMANDE_DOUBLON =
    'getListReturnDataAppelFlashPartenaire';
export const GET_LIST_REQUETE_PARTENAIRE = 'getListRequetePartenaire';
export const GET_LIST_REFERENCE_NON_IDENTIFIEE =
    'getListReferenceNonIdentifiee';
export const GET_LIST_TARIF_BY_TYPE_ENTITE = 'getListTarifByTypeEntite';
export const GET_LIST_COMPTE_IMPUTATION = 'getListCompteImputation';
export const GET_LIST_TYPE_STOCK = 'getAllTypeStock';
export const GET_LIST_PARTENAIRE = 'getListPartenaire';
export const GET_LIST_PARTENAIRE_INITIALS = 'getListPartenaireInitials';
export const POST_COMPTE_IMPUTATION = 'postCompteImputation';
export const GET_COMPTE_BY_ID = 'getCompteById';
export const POST_SECTEUR = 'postSecteur';
export const GET_LIST_DIRECTION = 'getListDirection';
export const GET_LIST_SECTEUR_CHARGE = 'getListSecteurCharge';
export const POST_COMPTE_DECLARATION = 'postCompteDeclaration';
export const POST_COMPTE_PARALLELE = 'postCompteParallele';
export const POST_COMPTE_BANCAIRE_ORGANISATION = 'postCompteBancaire';
export const GET_LIST_COMPTE_DECLARATION = 'getListCompteDeclaration';
export const GET_LIST_COMPTE_PARALLELE = 'getListCompteParallele';
export const PUT_APPOINTAGE_SUPER_DEALER = 'putAppointageSuperDealer';
export const PUT_COMPTE_PARALLELE = 'putCompteParallele';
export const GET_LIST_SECTEUR_RESSOURCE_KIN_DB = 'getListSecteurRessourceKinDb';
export const POST_ELABORATION_AMENDEMENT = 'postElaborationAmendement';
export const GET_LIST_ELABORATION = 'getListElaboration';
export const GET_ELABORATION_AMENDEMENT_BY_ID = 'getElaborationAmendementById';
export const PUT_ELABORATION_AMENDEMENT = 'putElaborationAmendement';
export const POST_DECLARATION_RESSOURCE = 'postDeclarationRessource';
export const GET_LIST_DECLARATION_RESSOURCE = 'getListDeclarationRessource';
export const GET_DECLARATION_RESSOURCE_BY_ID = 'getDeclarationRessourceById';
export const PUT_DECLARATION_RESSOURCE = 'putDeclarationRessource';
export const POST_REEVALUATION = 'postReevaluation';
export const PUT_REEVALUATION = 'putReevaluation';
export const GET_LIST_REEVALUATION = 'getListReevaluation';
export const GET_REEVALUATION_REEVALUATION_BY_ID = 'getReevaluationById';
export const POST_PARAMETRE_COMMANDE_EN_LIGNE = 'postParametreCommandeEnLigne';
export const PUT_PARAMETRE_COMMANDE_EN_LIGNE = 'putParametreCommandeEnLigne';
export const GET_LIST_PARAMETRE_COMMANDE_EN_LIGNE =
    'getListParametreCommandeEnLigne';
export const GET_PARAMETRE_COMMANDE_EN_LIGNE_BY_ID =
    'getParametreCommandeEnLigneById';
export const GET_LIST_PRODUIT_MARCHAND = 'getListProduitMarchandTypeAirtime'; // deprecated : This function will be changed because it's same with GET_LIST_PRODUIT_MARCHAND_TYPE_AIRTIME
export const POST_APPRO_STOCK_PRODUIT_MATERIEL =
    'postApproStockProduitMateriel';
export const PUT_APPRO_STOCK_PRODUIT_MATERIEL = 'putApproStockProduitMateriel';
export const GET_LIST_APPRO_STOCK_PRODUIT_MATERIEL =
    'getListApproStockProduitMateriel';
export const GET_APPROVISIONNEMENT_STOCK_PRODUIT_MATERIEL_BY_ID =
    'getApprovisionnementStockProduitMaterielById';
export const GET_LIST_DETAIL_APPROVISIONNEMENT_STOCK_PRODUIT_MATERIEL =
    'getListDetailApprovisionnementStockProduitMateriel';
export const POST_LIST_RAYON_SHOWROOM = 'postListRayonShowRoom';
export const POST_INGREDIENT = 'postIngredient';
export const POST_PRODUIT_MATERIEL = 'postProduitMateriel';
export const GET_LIST_INGREDIENT_MARCHAND = 'getListIngredientMarchand';
export const GET_LIST_EMPLACEMENT_PHYSIQUE_DEPOT =
    'getListEmplacementPhysiqueDepot';
export const POST_POINT_LIVRAISON = 'postPointLivraison';
export const PUT_POINT_LIVRAISON = 'putPointLivraison';
export const POST_SALLE_MANGER = 'postSalleManger';
export const GET_LIST_POINT_LIVRAISON_MARCHAND =
    'getListPointLivraisonMarchand';
export const GET_POINT_LIVRAISON_BY_ID = 'getPointLivraisonById';
export const GET_LIST_SALLE_MANGER = 'getListSalleManger';
export const GET_LIST_SALLE_MANGER_BY_ID = 'getSalleMangerTableById';
export const PUT_SALLE_MANGER = 'putSalleManger';
export const POST_COMMANDE_COMMERCE_GENERAL = 'postCommandeCommerceGeneral';
export const GET_LIST_AGENCE_MARCHAND = 'getListAgenceMarchand';
export const GET_LIST_MARCHAND = 'getListMarchand';
export const POST_LIST_SYS_SERVICE = 'postListSysService';
export const PUT_TYPE_COMPLEMENTARITE = 'putTypeComplementarite';
export const POST_REMISE = 'postRemise';
export const POST_PARAMETRE_ACHAT_CREDIT = 'postParametreAchatCredit';
export const POST_ABONNEMENT = 'postAbonnement';
export const POST_GROUPE = 'postGroupe';
export const GET_LIST_ABONNEMENT = 'getListAbonnement';
export const GET_LIST_GROUPE_MARCHAND = 'getListGroupeMarchand';
export const GET_PRODUIT_MARCHAND = 'getProduitMarchand';
export const POST_TRAITEMENT_MODE_RETRACAGE = 'postTraitementModeRetracage';
export const PUT_TRAITEMENT_MODE_RETRACAGE = 'putTraitementModeRetracage';
export const POST_REMBOURSEMENT_CAUTION = 'postRemboursementCaution';
export const GET_LIST_TRAITEMENT_MODE_RETRACAGE =
    'getListTraitementModeRetracage';
export const GET_TRAITEMENT_MODE_RETRACAGE_BY_ID =
    'getTraitementModeRetracageById';
export const POST_PROFIL_USER = 'postProfilUser';
export const POST_CREDITER_CAUTION = 'postCrediterCaution';
export const GET_LIST_NOTIFICATION_ZONE = 'getListNotificationZone';
export const PUT_NOTIFICATION_ZONE = 'putNotificationZone';
export const GET_NOTIFICATION_ZONE_BY_ID = 'getNotificationZoneById';
export const GET_LIST_ACCUSE_RECEPTION_CAISSE_EXECUTION_CHARGE =
    'getListAccuseReceptionCaisseExecutionCharge';
export const PUT_ACCUSE_RECEPTION_PAIEMENT = 'putAccuseReceptionPaiement';
export const GET_LIST_DISPATCHING_CAISSE_EXECUTION_CHARGE_INITIALS =
    'getListDispatchingCaisseExecutionChargeInitials';
export const PUT_PAIEMENT_CAISSE_SECTORIELLE = 'putPaiementCaisseSectorielle';
export const GET_MOYEN_PAIEMENT_DATE_BY_ID = 'getMoyenPaiementDateById';
export const GET_LIST_INSTRUCTION_INITIALS = 'getListInstructionInitials';
export const GET_INSTRUCTION_BY_ID = 'getInstructionById';
export const PUT_VALIDATION_INSTRUCTION = 'putValidationInstruction';
export const POST_EXERCICE = 'postExercice';
export const GET_LIST_EXERCICE = 'getListExercice';
export const GET_LIST_PROFIL_USER_TYPE_ENTREPRISE =
    'getListProfilUserTypeEntreprise';
export const GET_LIST_DETAIL_FONCTION = 'getListDetailFonction';
export const GET_LIST_DETAIL_FONCTION_TYPE_CONTRAT =
    'getListDetailFonctionTypeContrat';
export const GET_LIST_FONCTION_DISPONIBLE = 'getListFonctionDisponible';
export const GET_LIST_BENEFICIAIRES = 'getListBeneficiaires';
export const POST_PROFIL_USER_DEDIE = 'postProfilUserDedie';
export const GET_ALL_PROFIL_USER = 'getAllProfilUser';
export const GET_ALL_SYS_SERVICE = 'getAllSysService';
export const GET_LIST_TUNNEL = 'getListTunnels';
export const GET_SYS_SERVICE_BY_ID = 'getSystServiceById';
export const GET_TUNNEL_BY_ID = 'getTunnelById';
export const GET_LIST_TARIF_INITIALS = 'getListTarifInitials';
export const GET_LIST_ELABORATION_AMENDEMENT_INITIALS =
    'getListElaborationAmendementInitials';
export const GET_LIST_REEVALUATION_INITIALS = 'getListReevaluationInitials';
export const PUT_LIST_SYS_SERVICE_USER = 'putListSysServiceUser';
export const GET_LIST_CHARGE_A_REEVALUER = 'getListChargeAReevaluer';
export const GET_LIST_MARCHAND_BY_CATEGORIE_AND_TEXT_DESIGNATION =
    'getListMarchandByCategorieAndTextDesignation';
export const GET_LIST_DECLARATION_RESSOURCE_INITIALS =
    'getListDeclarationRessourceInitials';
export const GET_LIST_CHARGE_BENEFICIAIRE_INITIALS =
    'getListChargeBeneficiaireInitials';
export const PUT_CHARGE_BENEFICIAIRE = 'putChargeBeneficiaire';
export const PUT_CREDITER_COMPTE = 'putCrediterCompte';
export const POST_VERSEMENT_AGENT_TERRAIN = 'postVersementAgentTerrain';
export const GET_LIST_CREDITER_CAUTION_BENEFICIAIRE =
    'getListCrediterCautionBeneficiaire';
export const GET_LIST_REMBOURSEMENT_CAUTION_BENEFICIAIRE =
    'getListRemboursementCautionBeneficiaire';
export const GET_LIST_COUPURE_BY_DEVISE = 'getListCoupureByDevise';
export const GET_LIST_CAISSE_ACTIVE_USER = 'getListCaisseActiveUser';
export const GET_LIST_RAPPORT_VENTE_RECRUTEMENT =
    'getlistrapportventerecrutement';
export const GET_LIST_REVENDEUR_BY_RECRUTEUR = 'getlistrevendeurbyrecruteur';
export const GET_LIST_LIMIT_CREDIT_ORGANISATION =
    'getListLimitCreditOrganisation';
export const GET_LIST_VERSEMENT_AGENT_VENDEUR_INITIALS =
    'getListVersementAgentVendeurInitials';
export const GET_LIST_USER_A_SELECTIONNER = 'getListUserASelectionner';
export const GET_LIST_ELEMENT_VIREMENT_KINDB = 'getListElementVirementKinDb';
export const GET_LIST_CAISSE_CASH_COLLECTE = 'getListCaisseCashCollecte';
export const GET_LIST_CREDITER_COMPTE_INITIALS =
    'getListCrediterCompteInitials';
export const GET_LIST_PREUVE_PAIEMENT = 'getListPreuvePaiement';
export const GET_LIST_COORDONNES_DISPONIBLE_POUR_AGENCE =
    'getListCoordonnesDisponiblePourAgence';
export const POST_DEROGATION = 'postDerogation';
export const GET_LIST_ZONE_SECTEUR_BY_TEXT_DESIGNATION =
    'getListZoneSecteurByTextDesignation';
export const GET_LIST_DEROGATION_INITIALS = 'getListDerogationInitials';
export const GET_DEROGATION_BY_ID = 'getDerogationById';
export const PUT_DEROGATION = 'putDerogation';
export const PUT_LIST_PRODUIT_RAYON = 'putListProduitRayon';
export const GET_LIST_RAYON_COLONNE_MARCHAND_AIRTIME =
    'getListRayonColonneMarchandAirtime';
export const POST_SYS_SERVICE_TYPE_ENTREPRISE = 'postSysServicesTypeEntreprise';
export const POST_CONFIGURATION_KIN_DB = 'postConfigurationKinDb';
export const POST_REGULARISATION_STOCK_SUPER_DEALER =
    'postRegularisationStockSuperDealer';
export const GET_LIST_IMPUTATION_STOCK_ORGANISATION =
    'getListImputationStockOrganisation';
export const GET_LIST_REGULARISATION_STOCK_INITIALS =
    'getListRegularisationStockInitials';
export const GET_LIST_SYS_SERVICES_BY_ORGANISATION =
    'getListSysServicesByOrganisation';
export const GET_CONFIGURATION_KIN_DB_BY_ID = 'getConfigurationKinDbById';
export const GET_ALL_CONFIGURATION_KIN_DB = 'getAllConfigurationKinDb';
export const PUT_CONFIG = 'putConfig';
export const PUT_REGULARISATION_CAISSE = 'putRegularisationCaisse';
export const POST_REGULARISATION_CAISSE = 'postRegularisationCaisse';
export const GET_LIST_IMPUTATION_ORGANISATION = 'getListImputationOrganisation';
export const GET_LIST_REGULARISATION_CAISSE_INITIALS =
    'getListRegularisationCaisseInitials';
export const POST_LIST_ZONE_SENDEUR = 'postListZoneSendeur';
export const GET_LIST_COMPTE_PARALLELE_INITIALS =
    'getListCompteParalleleInitials';
export const GET_LIST_SUPER_DEALER_MARCHAND_INITIALS =
    'getListSuperDealerMarchandInitials';
export const GET_CREDITER_CAUTION_BY_ID = 'getCrediterCautionById';
export const GET_PAIEMENT_CAISSE_SECTORIELLE_BY_ID =
    'getPaiementCaisseSectorielleById';
export const GET_PROFIL_USER_BY_ID = 'getProfilUserById';
export const GET_LIST_SYS_SERVICES_BY_TYPE_ENTREPRISE =
    'getListSysServicesByTypeEntreprise';
export const POST_TRAITEMENT_RELEVE_BANCAIRE = 'postTraitementReleveBancaire';
export const GET_LIST_TRAITEMENT_RELEVE_BANCAIRE_INITIALS =
    'getListTraitementReleveBancaireInitials';
export const GET_LIST_PROFIL_USER_DEDIE_BY_TYPE_ENTREPRISE =
    'getListProfilUserDedieByTypeEntreprise';
export const GET_LIST_PROFIL_USER_DEDIE_SYS_SERVICE_BY_PROFIL_USER_DEDIE =
    'getListProfilUserDedieSysServiceByProfilUserDedie';
export const GET_TRAITEMENT_RELEVE_BANCAIRE_BY_ID =
    'getTraitementReleveBancaireById';
export const PUT_VALIDATION_ENTREPRISE = 'putValidationEntreprise';
export const GET_ALL_CHARGE = 'getAllCharge';
export const GET_AGENCE_BY_ID = 'getAgenceById';
export const GET_MARCHAND_BY_TILLNUMBER_AND_CODE_CATEGORIE_MARCHAND =
    'getMarchandByTillNumberAndCodeCategorieMarchand';
export const GET_LIST_LIMIT_CREDIT_INITIALS = 'getListLimitCreditInitials';
export const PUT_LIMIT_CREDIT = 'putLimitCredit';
export const POST_LIMIT_CREDIT = 'postLimitCredit';
export const GET_LIMIT_CREDIT_BY_ID = 'getLimitCreditById';
export const GET_ALL_DEVISE = 'getAllDevise';
export const GET_LIST_CAISSE_SECTORIELLE = 'getListCaisseSectorielle';
export const GET_MONNAIE_MARCHAND = 'getMonnaieMarchand';
export const GET_LIST_COMPTE_DECLARATION_INITIALS =
    'getListCompteDeclarationInitials';
// export const PUT_CHARGE = 'putCharge';
export const PUT_LIST_CHARGE = 'putListCharge';
export const GET_LIST_MONNAIE_LOCALE_MARCHAND = 'getListMonnaieLocaleMarchand';
export const GET_LIST_ELEMENT_ELABORATION_AMENDEMENT =
    'getListElementElaborationAmendement';
export const POST_CASH_COLLECTEUR = 'postCashCollecteur';
export const GET_CASH_COLLECTEUR_BY_ID = 'getCashCollecteurById';
export const PUT_CASH_COLLECTEUR = 'putCashCollecteur';
export const GET_LIST_CASH_COLLECTEUR_INITIALS =
    'getListCashCollecteurInitials';
export const PUT_VALIDATE_CASH_COLLECTEUR = 'putValidateCashCollecteur';
export const POST_APPRO_CASH_VERSEMENT = 'postApproCashVersement';
export const GET_LIST_APPRO_CASH_VERSEMENT_INITIALS =
    'getListApproCashVersementInitials';
export const PUT_APPRO_CASH_VERSEMENT = 'putApproCashVersement';
export const POST_TRANSFERT_CAISSE = 'postTransfertCaisse';
export const GET_TRANSFERT_BY_ID = 'getTransfertById';
export const GET_LIST_TRANSFERT_CAISSE_INITIALS =
    'getListTransfertCaisseInitials';
export const PUT_TRANSFERT_CAISSE = 'putTransfertCaisse';
export const POST_CHANGE_ESPECE = 'postChangeEspece';
export const POST_CHANGE_GUICHET = 'postChangeGuichet';
export const GET_LIST_CHANGE_ESPECE_INITIALS = 'getListChangeEspeceInitials';
export const GET_CHANGE_ESPECE_BY_ID = 'getChangeEspeceById';
export const POST_ENCAISSEMENT_DECAISSEMENT = 'postEncaissementDecaissement';
export const PUT_ENCAISSEMENT_DECAISSEMENT = 'putEncaissementDecaissement';
export const GET_LIST_ENCAISSEMENT_DECAISSEMENT_INITIALS =
    'getListEncaissementDecaissementInitials';
export const GET_ENCAISSEMENT_DECAISSEMENT_BY_ID =
    'getEncaissementDecaissementById'; // FIXME: This function has to be changed
export const GET_LIST_DEVISES = 'getListDevise'; // FIXME: This function has to be changed
export const POST_DEVISE = 'postDevise';
export const GET_LIST_CHANGE_GUICHET_ORGANISATION =
    'getListChangeGuichetOrganisation';
export const POST_OPERATION_VIREMENT = 'postOperationVirement';
export const POST_OPERATION_VIREMENT_KINDB = 'postOperationVirementKinDb';
export const POST_ENCAISSEMENT_DECAISSEMENT_KINDB =
    'postEncaissementDecaissementKinDb';
export const GET_LIST_OPERATION_VIREMENT_KINDB_INITIALS =
    'getListOperationVirementKinDbInitials';
export const GET_LIST_COMPTE_BANCAIRE = 'getListCompteBancaire'; // Pour les comptes bancaires des partenaires
export const GET_LIST_COMPTE_BANCAIRE_PARTENAIRE =
    'getListCompteBancairePartenaire';
export const GET_LIST_OPERATION_VIREMENT_INITIALS =
    'getListOperationVirementInitials';
export const GET_OPERATION_VIREMENT_BY_ID = 'getOperationVirementById';
export const GET_OPERATION_VIREMENT_KINDB_BY_ID =
    'getOperationVirementKinDbById';
export const PUT_OPERATION_VIREMENT = 'putOperationVirement';
export const PUT_OPERATION_VIREMENT_KINDB = 'putOperationVirementKinDb';
export const POST_OPERATION_BANCAIRE = 'postOperationBancaire';
export const PUT_OPERATION_BANCAIRE = 'putOperationBancaire';
export const GET_LIST_OPERATION_BANCAIRE_INITIALS =
    'getListOperationBancaireInitials';
export const GET_OPERATION_BANCAIRE_BY_ID = 'getOperationBancaireById';
// To Check
export const POST_RAISON = 'postRaison';
export const GET_LIST_RAISON = 'getListRaison';
export const GET_RAISON_BY_ID = 'getRaisonById';
// To Check
export const POST_DEPENSE = 'postDepense';
export const PUT_DEPENSE = 'putDepense';
export const GET_LIST_DEPENSE_INITIALS = 'getListDepenseInitials';
export const GET_DEPENSE_BY_ID = 'getDepenseById';
export const GET_LIST_SUPER_DEALER_KIN_DISTRIBUTION =
    'getListSuperDealerKinDistribution';
export const GET_LIST_REVENDEUR = 'getListRevendeur';
export const GET_LIST_CASH_COLLECTEUR_KIN_DISTRIBUTION =
    'getListCashCollecteurKinDistribution';
export const POST_TRAITEMENT_CONFIGURATION_MARCHAND_API =
    'postTraitementConfigurationMarchandApi';
export const PUT_TRAITEMENT_CONFIGURATION_MARCHAND_API =
    'putTraitementConfigurationMarchandApi';
export const GET_TRAITEMENT_CONFIGURATION_MARCHAND_API_BY_ID =
    'getTraitementConfigurationMarchandApiById';
export const GET_LIST_TRAITEMENT_CONFIGURATION_MARCHAND_API_INITIALS =
    'getListTraitementConfigurationMarchandApiInitials';
export const GET_LIST_CONFIGURATION_API_PARTENAIRE =
    'getListConfigurationApiPartenaire';
export const GET_LIST_COMMANDE_LITIGIEUX = 'getListCommandeFlashAProbleme';
export const GET_LIST_COMMANDE_LITIGIEUX_EMONEY = 'getListDepotEmoneyAProbleme';
export const GET_LIST_MARCHAND_KINDB = 'getListMarchandKinDb';
export const GET_LIST_ALL_MARCHAND = 'getAllCollectionMarchand';
export const GET_LIST_MARCHAND_ELECTRONIQUE = 'getListMarchandElectronique';
export const POST_TRAITEMENT_RELEVE_EMONEY = 'postTraitementReleveEmoney';
export const POST_TRAITEMENT_RELEVE_FLASH = 'posttraitementrelevepartenaire';
export const GET_LIST_TRAITEMENT_RELEVE_EMONEY =
    'getListTraitementReleveEmoney';
export const GET_LIST_TRAITEMENT_RELEVE_FLASH =
    'getListTraitementRelevePartenaire';
export const GET_TRAITEMENT_RELEVE_EMONEY_BY_ID =
    'getTraitementReleveEmoneyById';
export const GET_TRAITEMENT_RELEVE_FLASH_BY_ID = 'getTraitementReleveFlashById';
export const GET_LIST_MARCHAND_BY_TEXT_DESIGNATION =
    'getListMarchandByTextDesignation';
export const GET_LIST_OPERATION_INSTITUTION_FINANCIERE =
    'getListOperationInstitutionFinanciere';
export const GET_MONTANT_TOTAL_BY_BALANCE = 'getMontantTotalByBalance';
export const POST_CONFIGURATION_INSTRUCTION = 'postConfigurationInstruction';
export const PUT_CONFIGURATION_INSTRUCTION = 'putConfigurationInstruction';
export const GET_CONFIGURATION_INSTRUCTION_BY_ID =
    'getConfigurationInstructionById';
export const GET_LIST_CONFIGURATION_INSTRUCTION_INITIALS =
    'getListConfigurationInstructionInitials';
export const GET_CREDITER_COMPTE_BY_NUMERO_TRANSACTION =
    'getCrediterCompteByNumeroTransaction';
export const PUT_COMMANDES_FLASH = 'putCommandesFlash';
export const PUT_COMMANDES_EMONEY = 'putCommandesEmoney'; // FIXME: This functions has to be created
export const GET_LIST_COMMANDE_EMONEY_BY_SENDEUR =
    'getListCommandeEmoneyBySendeur';
export const POST_IMPUTATION = 'postImputation';
export const GET_IMPUTATION_BY_ID = 'getImputationById';
export const POST_COMMANDE_EMONEY = 'postCommandeEmoney';
export const GET_LIST_DEPOT_EMONEY_USER = 'getListDepotEmoneyUser';
export const GET_LIST_COMPTE_CREANCE = 'getListCompteCreance';
export const GET_COMMANDE_EMONEY_BY_NUMERO_REFERENCE_AND_TILLNUMBER =
    'getCommandeEmoneyByNumeroReferenceAndTillNumber';
export const POST_LIST_COUPURE_ORGANISATION = 'postListCoupureOrganisation';
export const PUT_STATUS_REVENDEUR = 'putStatusRevendeur';
export const POST_STATUS_REVENDEUR = 'postStatusRevendeur';
export const GET_STATUS_REVENDEUR_BY_ID = 'getStatusRevendeurById';
export const GET_LIST_STATUS_REVENDEUR_INITIALS =
    'getListStatusRevendeurInitials';
export const GET_LIST_RAPPORT_EMONEY_USER = 'getListRapportEmoneyUser';
export const GET_LIST_CASH_BOOK_USER = 'getListCashBookUser';
export const GET_LIST_RETRAIT_USER = 'getListRetraitUser';
export const POST_TRANSFERT_CAISSE_KINDB = 'postTransfertCaisseKinDb';
export const PUT_TRANSFERT_CAISSE_KINDB = 'putTransfertCaisseKinDb';
export const GET_LIST_TRANSFERT_CAISSE_KINDB = 'getListTransfertKinDbInitials';
export const GET_TRANSFERT_CAISSE_KINDB_BY_ID = 'getTransfertKinDbById';
export const GET_LIST_RETRAIT_BY_RELEVE = 'getListRetraitByReleve';
export const POST_TRANSFERT_BALANCE = 'postTransfertBalance';
export const PUT_TRANSFERT_BALANCE = 'putTransfertBalance';
export const GET_LIST_TRANSFERT_BALANCE_INITIALS =
    'getListTransfertBalanceInitials';
export const GET_TRANSFERT_BALANCE_BY_ID = 'getTransfetBalanceById';
export const GET_LIST_TRANSFERT_FONDS_INITIALS =
    'getListTransfertFondsInitials';
export const POST_APPLICATION = 'postapplication';
export const GET_ALL_APPLICATION = 'getallapplication';
export const POST_VERSION_APPLICATION = 'postversionapplication';
export const GET_LIST_VERSION_APPLICATION_INITIALS =
    'getlistversionapplicationinitials';
export const PUT_VERSION_APPLICATION = 'putversionapplication';
export const GET_VERSION_APPLICATION_BY_ID = 'getversionapplicationbyid';
export const GET_LIST_CHANGE_GUICHET_INITIALS = 'getlistchangeguichetinitials';
export const POST_LIST_TAUX = 'postListTaux';
export const GET_LIST_TAUX_ACTIF_ORGANISATION_BY_DEVISES =
    'getListTauxActifOrganisationByDevises';
export const GET_ENTREPRISE_AND_LIST_PRODUIT_MARCHAND =
    'getentrepriseandlistproduitmarchand';
export const GET_LIST_TYPE_CAISSE_ORGANISATION =
    'getListTypeCaisseOrganisation';
export const POST_PRIX_REVENDEUR = 'postPrixRevendeur';
export const GET_LIST_PRIX_REVENDEUR_INITIALS = 'getListPrixRevendeurInitials';
export const GET_LIST_SUJET_DISPONILBLE = 'getlistsujetdisponible';
export const GET_LIST_NOTIFICATION_PUSH_USER = 'getlistnotificationpushuser';
export const SEND_NOTIFICATION_SUJET = 'sendNotificationSujet';
export const GET_LIST_FORMULAIRE_DYNAMIQUE_INITIALS =
    'getlistformulairedynamiqueinitials';
export const PUT_FORMULAIRE_DYNAMIQUE = 'putformulairesdynamique';
export const GET_FORMULAIRE_DYNAMIQUE_BY_ID = 'getFormulaireDynamiqueById';
export const GET_USER_BY_TILL_NUMBER = 'getUserByTillNumber';
export const PUT_RESET_PIN = 'putResetPin';

//
export const POST_TRAITEMENT_PAIEMENT_VENDEUR = 'posttraitementpaiementvendeur';
export const GET_LIST_CONFIGURATION_PARTENAIRE_ORGANISATION =
    'getlistconfigurationpartenaireorganisation';
export const GET_LIST_TRAITEMENT_PAIEMENT_VENDEUR_ORGANISATION =
    'getlisttraitementpaiementvendeurorganisation';
export const GET_TRAITEMENT_PAIEMENT_VENDEUR_BY_ID =
    'getTraitementPaiementVendeurById';

export const GET_LIST_TAUX_KINDISTRIBUTION_INITIALS =
    'getlisttauxkindistributioninitials';
export const GET_TAUX_KINDISTRIBUTION_BY_ID = 'gettauxkindistributionbyid';
export const PUT_TAUX_KINDISTRIBUTION = 'puttauxkindistribution';
export const POST_TAUX_KINDISTRIBUTION = 'posttauxkindistribution';
export const GET_LIST_RAPPORT_CAISSE_EMONEY = 'getlistrapportcaisseemoney';
export const GET_LIST_PAIEMENT_VENDEUR_BY_ORGANISATION =
    'getlistpaiementvendeurbyorganisation';
export const GET_LIST_RAPPORT_BALANCE = 'getlistrapportbalance';
export const PUT_DECLENCHEMENT_SMS_PAIEMENT_VENDEUR =
    'putdeclenchementsmspaiementvendeur';
export const GET_CONFIG_SENDER = 'getconfigsender';
export const PUT_CONFIG_SENDER = 'putconfigsender';
export const GET_REGULARISATION_CAISSE_BY_ID = 'getRegularisationCaisseById';
export const POST_HEURE_SUPPLEMENTAIRE = 'postheuresupplementaire';
export const PUT_HEURE_SUPPLEMENTAIRE = 'putheuresupplementaire';
export const POST_DEFINITION_HEURE_SUPPLEMENTAIRE =
    'postdefinitionheuresupplementaire';
export const GET_DEFINITION_HEURE_SUPPLEMENTAIRE_BY_ID =
    'getdefinitionheuresupplementairebyid';
export const PUT_DEFINITION_HEURE_SUPPLEMENTAIRE =
    'putdefinitionheuresupplementaire';
export const GET_LIST_DEFINITION_HEURE_SUPPLEMENTAIRE_INITIALS =
    'getlistdefinitionheuresupplementaireinitials';
export const GET_LIST_PROFIL_USER_TACHE_ORGANISATION =
    'getlistprofilusertacheorganisation';
export const GET_LIST_HEURE_SUPPLEMENTAIRE_INITIALS =
    'getlistheuresupplementaireinitials';
export const GET_HEURE_SUPPLEMENTAIRE_BY_ID = 'getheuresupplementairebyid';

export const POST_LIST_PROFIL_USER_TACHE = 'postlistprofilusertache';
export const POST_LIST_TACHE_PERFORMANCE_AGENT =
    'postlisttacheperformanceagent';
export const PUT_DEFINITION_OBJECTIF = 'putdefinitionobjectif';
export const POST_DEFINITION_OBJECTIF = 'postdefinitionobjectif';
export const GET_LIST_DEFINITION_OBJECTIF_INITIALS =
    'getlistdefinitionobjectifinitials';
export const GET_DEFINITION_OBJECTIF_BY_ID = 'getdefinitionobjectifbyid';
export const GET_LIST_TACHE_PERFORMANCE_AGENT = 'getlisttacheperformanceagent';
export const GET_LIST_DETAIL_DEFINITION_HEURE_SUPPLEMENTAIRE =
    'getlistdetaildefinitionheuresupplementaire';
export const GET_LIST_DETAIL_DEFINITION_OBJECTIF =
    'getlistdetaildefinitionobjectif';
export const POST_MODIFICATION_FONCTION = 'postModificationFonction';
export const POST_TRAITEMENT_PRESENCE = 'posttraitementpresence';
export const GET_LIST_TRAITEMENT_RELEVE_PRESENCE =
    'getlisttraitementrelevepresence';
export const GET_TRAITEMENT_RELEVE_PRESENCE_BY_ID =
    'gettraitementrelevepresencebyid';
export const GET_LIST_MOUVEMENT_AGENT_ORGANISATION =
    'getlistmouvementagentorganisation';
export const GET_LIST_MODIFICATION_FONCTION_INITIALS =
    'getlistmodificationfonctioninitials';
export const GET_MODIFICATION_FONCTION_BY_ID = 'getmodificationfonctionbyid';
export const GET_LIST_DETAILS_MODIFICATION_FONCTION =
    'getlistdetailmodificationfonction';
export const PUT_MODIFICATION_FONCTION = 'putModificationFonction';
export const GET_LIST_CREDITER_BALANCE_EN_LOT_INITIALS =
    'getlistcrediterbalanceenlotinitials';
export const GET_CREDITER_BALANCE_EN_LOT_BY_ID = 'getcrediterbalanceenlotbyid';
export const GET_LIST_DETAIL_CREDITER_BALANCE_EN_LOT =
    'getlistdetailcrediterbalanceenlot';
export const POST_CREDITER_BALANCE_EN_LOT = 'postcrediterbalanceenlot';
export const PUT_CREDITER_BALANCE_EN_LOT = 'putcrediterbalanceenlot';
export const GET_LIST_RETRAIT_CREDITER_BALANCE_EN_LOT =
    'getlistretraitcrediterbalanceenlot';
export const PUT_NETTOYAGE_DEPOT_NON_KINDB = 'putnettoyagedepotnonkindb';
export const POST_FORMULAIRE_SERVICE_CLIENT = 'postformulaireserviceclient';
export const PUT_FORMULAIRE_SERVICE_CLIENT = 'putformulaireserviceclient';
export const GET_LIST_FORMULAIRE_SERVICE_CLIENT_INITIALS =
    'getlistformulaireserviceclientinitials';
export const GET_FORMULAIRE_SERVICE_CLIENT_BY_ID =
    'getformulaireserviceclientbyid';

export const GET_LIST_CONFIRMATION_SOLDE_COMPTE_ORGANISATION = `getlistconfirmationsoldecompteorganisation`;
export const GET_CONFIRMATION_SOLDE_COMPTE_BY_ID = `getconfirmationsoldecomptebyid`;
export const POST_CONFIRMATION_SOLDE_COMPTE = `postconfirmationsoldecompte`;
export const GET_LIST_COMPTABILISATION_COMPTE_ORGANISATION = `getlistcomptabilisationcompteorganisation`;
export const PUT_FORMULAIRE_TACHE_EXTERNE = `putformulairetacheexterne`;
export const POST_FORMULAIRE_TACHE_EXTERNE = `postformulairetacheexterne`;
export const GET_LIST_FORMULAIRE_TACHE_EXTERNE_INITIALS = `getlistformulairetacheexterneinitials`;
export const GET_FORMULAIRE_TACHE_EXTERNE_BY_ID = `getformulairetacheexternebyid`;

export const PUT_AUTORISATION_LOT_REFERENCE = `putautorisationlotreference`;
export const POST_AUTORISATION_LOT_REFERENCE = `postautorisationlotreference`;
export const GET_LIST_AUTORISATION_LOT_REFERENCE_INITIALS = `getlistautorisationlotreferenceinitials`;
export const GET_AUTORISATION_LOT_REFERENCE_BY_ID = `getautorisationlotreferencebyid`;
export const GET_LIST_FORMULAIRE_DISPONIBLE = `getlistformulairedisponible`;
export const GET_LIST_STOCK_SIM_ORGANISATION = 'getliststocksimorganisation';
export const GET_LIST_APPRO_SIM_INITIALS = 'getlistapprosiminitials';
export const GET_LIST_PRODUIT_SIM = `getlistproduitsim`;
export const POST_BROUILLON_ELABORATION_AMENDEMENT = `postbrouillonelaborationamendement`;
export const GET_LIST_BROUILLON_ELABORATION_AMENDEMENT_INITIALS = `getlistbrouillonelaborationamendementinitials`;
export const GET_BROUILLON_ELABORATION_AMENDEMENT_BY_ID = `getbrouillonelaborationamendementbyid`;
