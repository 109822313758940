import { CODE_SERVICE } from '@napp-inc/jnapp-util';
import { Administration, Configuration } from '../../../module';
import {
    AbonnementMarchandCreateRelPath,
    AbonnementMarchandRelPath,
    // AchatMarchandToMarchandCreateRelPath,
    // AchatMarchandToMarchandDetailRelPath,
    AchatMarchandToMarchandRelPath,
    ApprovisionnementStockMarchandProduitMaterielCreateRelPath,
    ApprovisionnementStockMarchandProduitMaterielDetailRelPath,
    ApprovisionnementStockMarchandProduitMaterielRelPath,
    ArticleColisCreateRelPath,
    ArticleColisDetailRelPath,
    ArticleColisRelPath,
    ArticleProduitMaterielCreateRelPath,
    ArticleProduitMaterielRelPath,
    BanqueCreateRelPath,
    BanqueDetailRelPath,
    BanqueViewLayoutRelPath,
    BudgetAdditionnelAmendementChargeFixeCreateRelPath,
    BudgetAdditionnelAmendementChargeVariableCreateRelPath,
    BudgetAdditionnelAmendementRessourceCreateRelPath,
    BudgetAdditionnelDetailRelPath,
    BudgetAdditionnelRelPath,
    BudgetPrevisionnelAmendementChargeFixeCreateRelPath,
    BudgetPrevisionnelAmendementChargeVariableCreateRelPath,
    BudgetPrevisionnelAmendementRessourceCreateRelPath,
    BudgetPrevisionnelDetailRelPath,
    BudgetPrevisionnelRelPath,
    CaisseCreateRelPath,
    CaisseDetailRelPath,
    CaisseViewLayoutRelPath,
    CashCollectionCreateRelPath,
    CashCollectionDetailRelPath,
    CashCollectionRegisterRelPath,
    CashCollectionViewLayoutRelPath,
    ChargeCreateRelPath,
    ChargeDetailRelPath,
    ChargeViewLayoutRelPath,
    CommanderEnLigneCreateRelPath,
    CommanderEnLigneDetailRelPath,
    CommanderEnLigneRelPath,
    CompteImputationCreateRelPath,
    CompteImputationDetailRelPath,
    CompteImputationViewLayoutRelPath,
    ComptesBancaireCreateRelPath,
    ComptesBancaireDetailRelPath,
    ComptesBancaireRelPath,
    ComptesDistributeurCreateRelPath,
    ComptesDistributeurDetailRelPath,
    ComptesDistributeurRelPath,
    ComptesMobileMoneyCreateRelPath,
    ComptesMobileMoneyDetailRelPath,
    ComptesMobileMoneyRelPath,
    ConfigEquipeTechniqueCreateRelPath,
    ConfigEquipeTechniqueDetailRelPath,
    ConfigEquipeTechniqueViewLayoutRelPath,
    ConfigurationKindistributionCreateRelPath,
    ConfigurationKindistributionDetailRelPath,
    ConfigurationKindistributionViewLayoutRelPath,
    ConfigurationSenderCreateRelPath,
    ConfigurationSenderDetailRelPath,
    ConfigurationSenderViewLayoutRelPath,
    CrediterCautionCreateRelPath,
    CrediterCautionDetailRelPath,
    CrediterCautionRelPath,
    DeclarationRessourceCreateRelPath,
    DeclarationRessourceDetailRelPath,
    DeclarationRessourceRelPath,
    OperationBancaireCreateRelPath,
    DepotRelPath,
    DirectionCreateRelPath,
    DirectionRelPath,
    DispatchingPaiementCompteExecutionDetailRelPath,
    DispatchingPaiementCompteExecutionRelPath,
    ExerciceCreateRelPath,
    ExerciceDetailRelPath,
    ExerciceRelPath,
    FonctionCreateRelPath,
    FonctionDetailRelPath,
    FonctionViewLayoutRelPath,
    HomologuerPointLivraisonCreateRelPath,
    HomologuerPointLivraisonDetailRelPath,
    HomologuerPointLivraisonRelPath,
    IngredientCreateRelPath,
    IngredientRelPath,
    InstructionDetailRelPath,
    InstructionRelPath,
    LimitCreditCreateRelPath,
    LimitCreditDetailRelPath,
    LimitCreditViewLayoutRelPath,
    MonnaieLocaleCreateRelPath,
    MonnaieLocaleDetailRelPath,
    MonnaieLocaleViewLayoutRelPath,
    MoyenRetracageCreateRelPath,
    MoyenRetracageDetailRelPath,
    MoyenRetracageRelPath,
    NotificationEvolutionRevendeurCreateRelPath,
    NotificationEvolutionRevendeurDetailRelPath,
    NotificationEvolutionRevendeurRelPath,
    PaiementCaisseSectorielleDetailRelPath,
    PaiementCaisseSectorielleRelPath,
    PaiementCompteExecutionDetailRelPath,
    PaiementCompteExecutionRelPath,
    PartenaireCreateRelPath,
    PartenaireDetailRelPath,
    PartenaireViewLayoutRelPath,
    PointLivraisonCreateRelPath,
    PointLivraisonRelPath,
    ProfilCreateRelPath,
    ProfilDetailRelPath,
    ProfilRelPath,
    ProfilUserDedierDetailRelPath,
    ProfilUserDedierRelPath,
    ProgrammerPauseCreateRelPath,
    ProgrammerPauseDetailRelPath,
    ProgrammerPauseRelPath,
    ReevaluationChargeDetailRelPath,
    ReevaluationChargeFixeCreateRelPath,
    ReevaluationChargeRelPath,
    ReevaluationChargeVariableCreateRelPath,
    RemboursementCautionCreateRelPath,
    RemboursementCautionRelPath,
    RessourceCreateRelPath,
    RessourceDetailRelPath,
    RessourceViewLayoutRelPath,
    RestitutionPerteCreateRelPath,
    RestitutionPerteRelPath,
    SalleMangerCreateRelPath,
    SalleMangerDetailRelPath,
    SalleMangerRelPath,
    SecteurCreateRelPath,
    SecteurRelPath,
    ServiceGenerateurCreateRelPath,
    ServiceGenerateurDetailRelPath,
    ServiceGenerateurViewLayoutRelPath,
    ShutDownAndUpApiPartnerCreateRelPath,
    ShutDownAndUpApiPartnerDetailRelPath,
    ShutDownAndUpApiPartnerViewLayoutRelPath,
    UploadFichierCreateRelPath,
    UploadFichierDetailRelPath,
    UploadFichierReleveEmoneyCreateRelPath,
    UploadFichierReleveEmoneyDetailRelPath,
    UploadFichierReleveEmoneyViewLayoutRelPath,
    UploadFichierViewLayoutRelPath,
    ConfigurationInstructionViewLayoutRelPath,
    ConfigurationInstructionCreateRelPath,
    ConfigurationInstructionDetailRelPath,
    ElaborationRelPath,
    AmenedementRelPath,
    ElaborationDetailRelPath,
    AmenedementDetailRelPath,
    ElaborationAdditionnelCreateRelPath,
    ElaborationPrevisionnelCreateRelPath,
    AmenedementCreateRelPath,
    ElaborationCompletionRelPath,
    ElaborationAmendementRelPath,
    ElaborationVoirPlusRelPath,
    UploadFichierReleveFlashViewLayoutRelPath,
    UploadFichierReleveFlashCreateRelPath,
    UploadFichierReleveFlashDetailRelPath,
    RegularisationCaisseEmoneyViewLayoutRelPath,
    RegularisationCaisseEmoneyCreateRelPath,
    RegularisationCaisseEmoneyDetailRelPath,
    TunnelViewLayoutRelPath,
    TunnelDetailRelPath,
    GestionRevendeurViewLayoutRelPath,
    GestionRevendeurCreateRelPath,
    GestionRevendeurDetailRelPath,
    FormulaireDynamiqueSimFlashViewLayoutRelPath,
    FormulaireDynamiqueSimFlashDetailRelPath,
    ElaborationAdditionnelFixeCreateRelPath,
    ElaborationAdditionnelVariableCreateRelPath,
    ElaborationAdditionnelRessourceCreateRelPath,
    ElaborationPrevisionnelFixeCreateRelPath,
    ElaborationPrevisionnelVariableCreateRelPath,
    ElaborationPrevisionnelRessourceCreateRelPath,
    UploadFichierPresenceViewLayoutRelPath,
    UploadFichierPresenceCreateRelPath,
    UploadFichierPresenceDetailRelPath,
    ActivateurViewLayoutRelPath,
    ActivateurCreateRelPath,
    ActivateurDetailRelPath,
    FonctionUpdateViewLayoutRelPath,
    FonctionUpdateDetailRelPath,
    FonctionUpdateCreateRelPath,
    ConfirmationSoldeCompteViewLayoutRelPath,
    ConfirmationSoldeCompteCreateRelPath,
    ConfirmationSoldeCompteDetailRelPath
} from './config-napp-rel-path';

/**
 * @desc Directions paths
 */
export const DirectionPath = {
    ...DirectionRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Configuration.ListDirection,
    element: <Configuration.ListDirection />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .SERVICE_DIRECTION_SECTEUR.CREATION_DIRECTION,
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .SERVICE_DIRECTION_SECTEUR.LECTURE_DIRECTION
    ]
};

export const DirectionCreatePath = {
    ...DirectionCreateRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.CreationDirectionForm,
    element: <Configuration.CreationDirectionForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .SERVICE_DIRECTION_SECTEUR.CREATION_DIRECTION
    ]
};

/**
 * @desc Secteur paths
 */
export const SecteurPath = {
    ...SecteurRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Configuration.ListSecteur,
    element: <Configuration.ListSecteur />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .SERVICE_DIRECTION_SECTEUR.CREATION_SECTEUR,
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .SERVICE_DIRECTION_SECTEUR.LECTURE_SECTEUR
    ]
};

export const SecteurCreatePath = {
    ...SecteurCreateRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.CreationSecteurForm,
    element: <Configuration.CreationSecteurForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .SERVICE_DIRECTION_SECTEUR.CREATION_SECTEUR
    ]
};

/**
 * @desc Ingredient paths
 */
export const IngredientPath = {
    ...IngredientRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Configuration.ListIngredient,
    element: <Configuration.ListIngredient />,
    code: [{ code: 'test' }]
};

export const IngredientCreatePath = {
    ...IngredientCreateRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.CreationIngredientForm,
    element: <Configuration.CreationIngredientForm />,
    code: [{ code: 'test' }]
};

/**
 * @desc moyen de retracage
 */

export const MoyenRetracagePath = {
    ...MoyenRetracageRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Configuration.ListMoyenRetracage,
    element: <Configuration.ListMoyenRetracage />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .LECTURE_TRAITEMENT_MOYEN_RETRACAGE,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .CREATION_TRAITEMENT_MOYEN_RETRACAGE,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .METTRE_A_JOUR_TRAITEMENT_MOYEN_RETRACAGE
    ]
};

export const MoyenRetracageCreatePath = {
    ...MoyenRetracageCreateRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.CreationMoyenRetracageForm,
    element: <Configuration.CreationMoyenRetracageForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .CREATION_TRAITEMENT_MOYEN_RETRACAGE
    ]
};

export const MoyenRetracageDetailPath = {
    ...MoyenRetracageDetailRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.DetailMoyenRetracageForm,
    element: <Configuration.DetailMoyenRetracageForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .LECTURE_TRAITEMENT_MOYEN_RETRACAGE,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .CREATION_TRAITEMENT_MOYEN_RETRACAGE,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .METTRE_A_JOUR_TRAITEMENT_MOYEN_RETRACAGE
    ]
};

/**
 * @desc Articles Colis
 */

export const ArticleColisPath = {
    ...ArticleColisRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Configuration.ListArticleColis,
    element: <Configuration.ListArticleColis />,
    code: [{ code: 'test' }]
};

export const ArticleColisCreatePath = {
    ...ArticleColisCreateRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.CreationArticleColisForm,
    element: <Configuration.CreationArticleColisForm />,
    code: [{ code: 'test' }]
};

export const ArticleColisDetailPath = {
    ...ArticleColisDetailRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.DetailArticleColis,
    element: <Configuration.DetailArticleColis />,
    code: [{ code: 'test' }]
};

/**
 * @desc Profil
 */

export const ProfilPath = {
    ...ProfilRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Configuration.ListProfil,
    element: <Configuration.ListProfil />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CONFIGURATION_PROFIL.CREATION_PROFIL_USER,
        CODE_SERVICE.CONFIGURATION_PROFIL.LECTURE_PROFIL_USER
    ]
};

export const ProfilCreatePath = {
    ...ProfilCreateRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.CreationProfilForm,
    element: <Configuration.CreationProfilForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CONFIGURATION_PROFIL.CREATION_PROFIL_USER
    ]
};

export const ProfilDetailPath = {
    ...ProfilDetailRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.DetailProfilConfigForm,
    element: <Configuration.DetailProfilConfigForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CONFIGURATION_PROFIL.CREATION_PROFIL_USER,
        CODE_SERVICE.CONFIGURATION_PROFIL.LECTURE_PROFIL_USER
    ]
};

/**
 * @desc Depot
 */

export const DepotPath = {
    ...DepotRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Configuration.ListDepot,
    element: <Configuration.ListDepot />,
    code: [{ code: 'test' }]
};

export const OperationBancaireCreatePath = {
    ...OperationBancaireCreateRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.CreationDepotProduitMateriel,
    element: <Configuration.CreationDepotProduitMateriel />,
    code: [{ code: 'test' }]
};

/**
 * @desc Homologuer Point de livraison
 */

export const HomologuerPointLivraisonPath = {
    ...HomologuerPointLivraisonRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Configuration.ListHomologuerPointLivraison,
    element: <Configuration.ListHomologuerPointLivraison />,
    code: [{ code: 'test' }]
};

export const HomologuerPointLivraisonCreatePath = {
    ...HomologuerPointLivraisonCreateRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.CreationHomologuerPointDeLivraisonForm,
    element: <Configuration.CreationHomologuerPointDeLivraisonForm />,
    code: [{ code: 'test' }]
};

export const HomologuerPointLivraisonDetailPath = {
    ...HomologuerPointLivraisonDetailRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.DetailHomologuerPointLivraison,
    element: <Configuration.DetailHomologuerPointLivraison />,
    code: [{ code: 'test' }]
};

/**
 * @desc Table à manger
 */

export const TableMangerPath = {
    ...SalleMangerRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Configuration.ListeSalleManger,
    element: <Configuration.ListeSalleManger />,
    code: [{ code: 'test' }]
};

export const TableMangerCreatePath = {
    ...SalleMangerCreateRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.CreationSalleMangerForm,
    element: <Configuration.CreationSalleMangerForm />,
    code: [{ code: 'test' }]
};

export const TableMangerDetailPath = {
    ...SalleMangerDetailRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.DetailSalleManger,
    element: <Configuration.DetailSalleManger />,
    code: [{ code: 'test' }]
};
/**
 * @desc Caisse
 */

export const CaisseViewLayoutPath = {
    ...CaisseViewLayoutRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Configuration.CaisseViewLayout,
    element: <Configuration.CaisseViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_ADMINISTRATION
            .INITIER_CREATION_CAISSE,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_ADMINISTRATION
            .VALIDER_CREATION_CAISSE,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_ADMINISTRATION
            .ANNULER_CREATION_CAISSE,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_ADMINISTRATION
            .LECTURE_LIST_CAISSE
    ]
};

export const CaisseCreatePath = {
    ...CaisseCreateRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.CreationCaisseForm,
    element: <Configuration.CreationCaisseForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_ADMINISTRATION
            .INITIER_CREATION_CAISSE
    ]
};

export const CaisseDetailPath = {
    ...CaisseDetailRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.DetailCaisseForm,
    element: <Configuration.DetailCaisseForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_ADMINISTRATION
            .INITIER_CREATION_CAISSE,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_ADMINISTRATION
            .VALIDER_CREATION_CAISSE,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_ADMINISTRATION
            .ANNULER_CREATION_CAISSE,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_ADMINISTRATION
            .LECTURE_LIST_CAISSE
    ]
};
/**
 * @desc Partenaire
 */

export const PartenaireViewLayoutPath = {
    ...PartenaireViewLayoutRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Configuration.PartenaireViewLayout,
    element: <Configuration.PartenaireViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_ADMINISTRATION
            .INITIER_CREATION_PARTENAIRE,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_ADMINISTRATION
            .VALIDER_CREATION_PARTENAIRE,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_ADMINISTRATION
            .ANNULER_CREATION_PARTENAIRE,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_ADMINISTRATION
            .LECTURE_LIST_PARTENAIRE
    ]
};

export const PartenaireCreatePath = {
    ...PartenaireCreateRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.CreationPartenaireForm,
    element: <Configuration.CreationPartenaireForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_ADMINISTRATION
            .INITIER_CREATION_PARTENAIRE
    ]
};

export const PartenaireDetailPath = {
    ...PartenaireDetailRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.DetailPartenaireForm,
    element: <Configuration.DetailPartenaireForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_ADMINISTRATION
            .INITIER_CREATION_PARTENAIRE,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_ADMINISTRATION
            .VALIDER_CREATION_PARTENAIRE,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_ADMINISTRATION
            .ANNULER_CREATION_PARTENAIRE,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_ADMINISTRATION
            .LECTURE_LIST_PARTENAIRE
    ]
};
/**
 * @desc Service générateur
 */

export const ServiceGenerateurViewLayoutPath = {
    ...ServiceGenerateurViewLayoutRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Configuration.ServiceGenerateurViewLayout,
    element: <Configuration.ServiceGenerateurViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.CREATION_SERVICE_GENERATEUR,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.LECTURE_SERVICE_GENERATEUR
    ]
};

export const ServiceGenerateurCreatePath = {
    ...ServiceGenerateurCreateRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.CreationServiceGenerateurForm,
    element: <Configuration.CreationServiceGenerateurForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.CREATION_SERVICE_GENERATEUR
    ]
};

export const ServiceGenerateurDetailPath = {
    ...ServiceGenerateurDetailRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.DetailServiceGenerateurForm,
    element: <Configuration.DetailServiceGenerateurForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.CREATION_SERVICE_GENERATEUR,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.LECTURE_SERVICE_GENERATEUR
    ]
};
/**
 * @desc Charge
 */

export const ChargeViewLayoutPath = {
    ...ChargeViewLayoutRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Configuration.ChargeViewLayout,
    element: <Configuration.ChargeViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.CREATION_CHARGE,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.METTRE_A_JOUR_CHARGE,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.LECTURE_CHARGE
    ]
};

export const ChargeCreatePath = {
    ...ChargeCreateRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.CreationChargeForm,
    element: <Configuration.CreationChargeForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.CREATION_CHARGE
    ]
};

export const ChargeDetailPath = {
    ...ChargeDetailRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.DetailChargeForm,
    element: <Configuration.DetailChargeForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.CREATION_CHARGE,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.METTRE_A_JOUR_CHARGE,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.LECTURE_CHARGE
    ]
};
/**
 * @desc Ressource
 */

export const RessourceViewLayoutPath = {
    ...RessourceViewLayoutRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Configuration.RessourceViewLayout,
    element: <Configuration.RessourceViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.CREATION_RESSOURCE,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.LECTURE_RESSOURCE,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.METTRE_A_JOUR_RESSOURCE
    ]
};

export const RessourceCreatePath = {
    ...RessourceCreateRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.CreationRessourceForm,
    element: <Configuration.CreationRessourceForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.CREATION_RESSOURCE
    ]
};

export const RessourceDetailPath = {
    ...RessourceDetailRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.DetailRessourceForm,
    element: <Configuration.DetailRessourceForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.CREATION_RESSOURCE,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.LECTURE_RESSOURCE,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.METTRE_A_JOUR_RESSOURCE
    ]
};
/**
 * @desc Compte imputation
 */

export const CompteImputationViewLayoutPath = {
    ...CompteImputationViewLayoutRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Configuration.CompteImputationViewLayout,
    element: <Configuration.CompteImputationViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.CREATION_COMPTE_IMPUTATION,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.LECTURE_COMPTE_IMPUTATION
    ]
};

export const CompteImputationCreatePath = {
    ...CompteImputationCreateRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.CreationCompteImputationForm,
    element: <Configuration.CreationCompteImputationForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.CREATION_COMPTE_IMPUTATION
    ]
};

export const CompteImputationDetailPath = {
    ...CompteImputationDetailRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.DetailCompteImputationForm,
    element: <Configuration.DetailCompteImputationForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.CREATION_COMPTE_IMPUTATION,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.LECTURE_COMPTE_IMPUTATION
    ]
};
/**
 * @desc Fonction
 */

export const FonctionViewLayoutPath = {
    ...FonctionViewLayoutRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Configuration.FonctionViewLayout,
    element: <Configuration.FonctionViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERVICE_FONCTION
            .CREATION_FONCTION,
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERVICE_FONCTION
            .LECTURE_FONCTION,
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERVICE_FONCTION
            .METTRE_A_JOUR_FONCTION
    ]
};

export const FonctionCreatePath = {
    ...FonctionCreateRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.CreationFonctionForm,
    element: <Configuration.CreationFonctionForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERVICE_FONCTION
            .CREATION_FONCTION
    ]
};

// export const FonctionUpdatePath = {
//     ...FonctionUpdateRelPath,
//     isVisible: false,
//     dependencies: ['ConfigMenu'],
//     Component: Configuration.CreationFonctionForm,
//     element: <Configuration.CreationFonctionForm isUpdate />,
//     code: [
//         { code: 'test' },
//         CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERVICE_FONCTION
//             .CREATION_FONCTION
//     ]
// };

export const FonctionDetailPath = {
    ...FonctionDetailRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.DetailFonctionForm,
    element: <Configuration.DetailFonctionForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERVICE_FONCTION
            .CREATION_FONCTION,
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERVICE_FONCTION
            .LECTURE_FONCTION,
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERVICE_FONCTION
            .METTRE_A_JOUR_FONCTION
    ]
};

/**
 * @desc Fonction
 */

export const FonctionUpdateViewLayoutPath = {
    ...FonctionUpdateViewLayoutRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Configuration.FonctionUpdateViewLayout,
    element: <Configuration.FonctionUpdateViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERVICE_FONCTION
            .CREATION_FONCTION,
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERVICE_FONCTION
            .LECTURE_FONCTION,
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERVICE_FONCTION
            .METTRE_A_JOUR_FONCTION
    ]
};

export const FonctionUpdateCreatePath = {
    ...FonctionUpdateCreateRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.CreationFonctionUpdateForm,
    element: <Configuration.CreationFonctionUpdateForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERVICE_FONCTION
            .CREATION_FONCTION
    ]
};

export const FonctionUpdateDetailPath = {
    ...FonctionUpdateDetailRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.DetailFonctionUpdateForm,
    element: <Configuration.DetailFonctionUpdateForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERVICE_FONCTION
            .METTRE_A_JOUR_FONCTION,
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERVICE_FONCTION
            .CREATION_FONCTION,
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERVICE_FONCTION
            .LECTURE_FONCTION
    ]
};
/**
 * @desc Banque
 */

export const BanqueViewLayoutPath = {
    ...BanqueViewLayoutRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Configuration.BanqueViewLayout,
    element: <Configuration.BanqueViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERVICE_BANQUE
            .CREATION_BANQUE,
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERVICE_BANQUE
            .LECTURE_BANQUE,
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERVICE_BANQUE
            .METTRE_A_JOUR_BANQUE
    ]
};

export const BanqueCreatePath = {
    ...BanqueCreateRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.CreationBanqueForm,
    element: <Configuration.CreationBanqueForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERVICE_BANQUE
            .CREATION_BANQUE
    ]
};

export const BanqueDetailPath = {
    ...BanqueDetailRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.DetailBanqueForm,
    element: <Configuration.DetailBanqueForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERVICE_BANQUE
            .CREATION_BANQUE,
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERVICE_BANQUE
            .LECTURE_BANQUE,
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERVICE_BANQUE
            .METTRE_A_JOUR_BANQUE
    ]
};
/**
 * @desc Monnaie locale
 */

export const MonnaieLocaleViewLayoutPath = {
    ...MonnaieLocaleViewLayoutRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Configuration.MonnaieLocaleViewLayout,
    element: <Configuration.MonnaieLocaleViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_ADMINISTRATION
            .INITIER_CREATION_MONNAIE_LOCALE,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_ADMINISTRATION
            .ANNULER_CREATION_MONNAIE_LOCALE,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_ADMINISTRATION
            .VALIDER_CREATION_MONNAIE_LOCALE,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_ADMINISTRATION
            .LECTURE_LIST_MONNAIE_LOCALE
    ]
};

export const MonnaieLocaleCreatePath = {
    ...MonnaieLocaleCreateRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.CreateMonnaieLocaleForm,
    element: <Configuration.CreateMonnaieLocaleForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_ADMINISTRATION
            .INITIER_CREATION_MONNAIE_LOCALE
    ]
};

export const MonnaieLocaleDetailPath = {
    ...MonnaieLocaleDetailRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.DetailMonnaieLocaleForm,
    element: <Configuration.DetailMonnaieLocaleForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_ADMINISTRATION
            .INITIER_CREATION_MONNAIE_LOCALE,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_ADMINISTRATION
            .ANNULER_CREATION_MONNAIE_LOCALE,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_ADMINISTRATION
            .VALIDER_CREATION_MONNAIE_LOCALE,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_ADMINISTRATION
            .LECTURE_LIST_MONNAIE_LOCALE
    ]
};

/**
 * @desc Programmer Pause
 */

export const ProgrammerPausePath = {
    ...ProgrammerPauseRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Configuration.ListProgrammerPause,
    element: <Configuration.ListProgrammerPause />,
    code: [{ code: 'test' }]
};

export const ProgrammerPauseCreatePath = {
    ...ProgrammerPauseCreateRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.CreationProgrammerPauseForm,
    element: <Configuration.CreationProgrammerPauseForm />,
    code: [{ code: 'test' }]
};

export const ProgrammerPauseDetailPath = {
    ...ProgrammerPauseDetailRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.DetailProgrammerPause,
    element: <Configuration.DetailProgrammerPause />,
    code: [{ code: 'test' }]
};

/**
 * @desc Point de Livraison
 */

export const PointLivraisonPath = {
    ...PointLivraisonRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Configuration.ListPointLivraison,
    element: <Configuration.ListPointLivraison />,
    code: [{ code: 'test' }]
};

export const PointLivraisonCreatePath = {
    ...PointLivraisonCreateRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.CreationPointDeLivraisonForm,
    element: <Configuration.CreationPointDeLivraisonForm />,
    code: [{ code: 'test' }]
};

/**
 * @desc Réévaluation Charges
 */

export const ReevaluationChargePath = {
    ...ReevaluationChargeRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Configuration.ListReevalutionCharges,
    element: <Configuration.ListReevalutionCharges />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .INITIER_REEVALUATION_CHARGE_FIXE,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .LECTURE_REEVALUATION_CHARGE_FIXE,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .ATTENTE_VALIDATION_REEVALUATION_CHARGE_FIXE,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .VALIDER_REEVALUATION_CHARGE_FIXE,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .ADOPTER_REEVALUATION_CHARGE_FIXE,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .ANNULER_REEVALUATION_CHARGE_FIXE,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .INITIER_REEVALUATION_CHARGE_VARIABLE,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .LECTURE_REEVALUATION_CHARGE_VARIABLE,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .ATTENTE_VALIDATION_REEVALUATION_CHARGE_VARIABLE,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .VALIDER_REEVALUATION_CHARGE_VARIABLE,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .ADOPTER_REEVALUATION_CHARGE_VARIABLE,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .ANNULER_REEVALUATION_CHARGE_VARIABLE
    ]
};

export const ReevaluationChargeFixeCreatePath = {
    ...ReevaluationChargeFixeCreateRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Configuration.CreationReevaluationChargeFixe,
    element: <Configuration.CreationReevaluationChargeFixe />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.INITIER_REEVALUATION_CHARGE_FIXE
    ]
};

export const ReevaluationChargeVariableCreatePath = {
    ...ReevaluationChargeVariableCreateRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Configuration.CreationReevaluationChargeVariables,
    element: <Configuration.CreationReevaluationChargeVariables />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .INITIER_REEVALUATION_CHARGE_VARIABLE
    ]
};

export const ReevaluationChargeDetailPath = {
    ...ReevaluationChargeDetailRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Configuration.DetailReevaluationCharge,
    element: <Configuration.DetailReevaluationCharge />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .INITIER_REEVALUATION_CHARGE_FIXE,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .LECTURE_REEVALUATION_CHARGE_FIXE,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .ATTENTE_VALIDATION_REEVALUATION_CHARGE_FIXE,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .VALIDER_REEVALUATION_CHARGE_FIXE,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .ADOPTER_REEVALUATION_CHARGE_FIXE,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .ANNULER_REEVALUATION_CHARGE_FIXE,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .INITIER_REEVALUATION_CHARGE_VARIABLE,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .LECTURE_REEVALUATION_CHARGE_VARIABLE,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .ATTENTE_VALIDATION_REEVALUATION_CHARGE_VARIABLE,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .VALIDER_REEVALUATION_CHARGE_VARIABLE,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .ADOPTER_REEVALUATION_CHARGE_VARIABLE,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .ANNULER_REEVALUATION_CHARGE_VARIABLE
    ]
};

/**
 * @desc Appro Stock Produit Materiel
 */

export const ApprovisionnementStockMarchandProduitMaterielPath = {
    ...ApprovisionnementStockMarchandProduitMaterielRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Configuration.ListApproStockProduitMateriel,
    element: <Configuration.ListApproStockProduitMateriel />,
    code: [{ code: 'test' }]
};

export const ApprovisionnementStockMarchandProduitMaterielCreatePath = {
    ...ApprovisionnementStockMarchandProduitMaterielCreateRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.CreationApproStockMarchandProduitMaterielForm,
    element: <Configuration.CreationApproStockMarchandProduitMaterielForm />,
    code: [{ code: 'test' }]
};

export const ApprovisionnementStockMarchandProduitMaterielDetailPath = {
    ...ApprovisionnementStockMarchandProduitMaterielDetailRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.DetailApproStockProduitMateriel,
    element: <Configuration.DetailApproStockProduitMateriel />,
    code: [{ code: 'test' }]
};

/**
 * @desc Produit Materiel
 */

export const ArticleProduitMaterielPath = {
    ...ArticleProduitMaterielRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Configuration.ListProduitMateriel,
    element: <Configuration.ListProduitMateriel />,
    code: [{ code: 'test' }]
};

export const ArticleProduitMaterielCreatePath = {
    ...ArticleProduitMaterielCreateRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.CreationArticleProduitMaterielForm,
    element: <Configuration.CreationArticleProduitMaterielForm />,
    code: [{ code: 'test' }]
};

// export const ArticleProduitMaterielDetailPath = {
//     ...ArticleProduitMaterielDetailRelPath,
//     isVisible: false,
//     dependencies: ['ConfigMenu'],
//     Component: Configuration.DetailApproStockProduitMateriel,
//     element: <Configuration.DetailApproStockProduitMateriel />,
//     code: [{ code: 'test' }]
// };

/**
 * @desc Abonnement Marchand
 */

export const AbonnementMarchandPath = {
    ...AbonnementMarchandRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Configuration.ListAbonnementMarchand,
    element: <Configuration.ListAbonnementMarchand />,
    code: [{ code: 'test' }]
};

export const AbonnementMarchandCreatePath = {
    ...AbonnementMarchandCreateRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.CreationAbonnementMarchandForm,
    element: <Configuration.CreationAbonnementMarchandForm />,
    code: [{ code: 'test' }]
};

/**
 * @desc Declaration Ressource
 */

export const DeclarationRessourcePath = {
    ...DeclarationRessourceRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Configuration.ListDeclarationRessource,
    element: <Configuration.ListDeclarationRessource />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.INITIER_DECLARATION_RESSOURCE,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.LECTURE_DECLARATION_RESSOURCE,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.VALIDER_DECLARATION_RESSOURCE,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .ATTENTE_VALIDATION_DECLARATION_RESSOURCE,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.ANNULER_DECLARATION_RESSOURCE
    ]
};

export const DeclarationRessourceCreatePath = {
    ...DeclarationRessourceCreateRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.CreationDeclarationRessourcesForm,
    element: <Configuration.CreationDeclarationRessourcesForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.INITIER_DECLARATION_RESSOURCE
    ]
};

export const DeclarationRessourceDetailPath = {
    ...DeclarationRessourceDetailRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.DetailDeclarationRessource,
    element: <Configuration.DetailDeclarationRessource />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.INITIER_DECLARATION_RESSOURCE,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.LECTURE_DECLARATION_RESSOURCE,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.VALIDER_DECLARATION_RESSOURCE,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .ATTENTE_VALIDATION_DECLARATION_RESSOURCE,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.ANNULER_DECLARATION_RESSOURCE
    ]
};

/**
 * @desc Commander à ligne
 */

export const CommanderEnLignePath = {
    ...CommanderEnLigneRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Configuration.ListActiverCommanderEnLigne,
    element: <Configuration.ListActiverCommanderEnLigne />,
    code: [{ code: 'test' }]
};

export const CommanderEnLigneCreatePath = {
    ...CommanderEnLigneCreateRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.CreationActiverCommanderEnligneForm,
    element: <Configuration.CreationActiverCommanderEnligneForm />,
    code: [{ code: 'test' }]
};

export const CommanderEnLigneDetailPath = {
    ...CommanderEnLigneDetailRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.DetailActiverCommanderEnLigne,
    element: <Configuration.DetailActiverCommanderEnLigne />,
    code: [{ code: 'test' }]
};

/**
 * @desc Remboursement Caution
 */

export const RemboursementCautionPath = {
    ...RemboursementCautionRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Configuration.ListRemboursementCaution,
    element: <Configuration.ListRemboursementCaution />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_WEB.CODE_SERVICE_ADMINISTRATION_NAPP
            .CREATION_REMBOURSEMENT_CAUTION,
        CODE_SERVICE.SERVICE_CODE_WEB.CODE_SERVICE_ADMINISTRATION_NAPP
            .LECTURE_REMBOURSEMENT_CAUTION
    ]
};

export const RemboursementCautionCreatePath = {
    ...RemboursementCautionCreateRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Configuration.CreationRemboursementCautionForm,
    element: <Configuration.CreationRemboursementCautionForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_WEB.CODE_SERVICE_ADMINISTRATION_NAPP
            .CREATION_REMBOURSEMENT_CAUTION
    ]
};

/**
 * @desc Restitution Perte
 */

export const RestitutionPertePath = {
    ...RestitutionPerteRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Configuration.ListRestitutionPerte,
    element: <Configuration.ListRestitutionPerte />,
    code: [{ code: 'test' }]
};

export const RestitutionPerteCreatePath = {
    ...RestitutionPerteCreateRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.CreationRestitutionPertesForm,
    element: <Configuration.CreationRestitutionPertesForm />,
    code: [{ code: 'test' }]
};

/**
 * @desc Compte Externes
 */

/**
 * @desc Comptes Bancaires
 */

export const ComptesBancairePath = {
    ...ComptesBancaireRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Configuration.ListCompteBancaire,
    element: <Configuration.ListCompteBancaire />,
    code: [
        { code: 'test' },
        CODE_SERVICE.COMPTE_EXTERNE.SERVICE_COMPTE_EXTERNE
            .ATTENTE_VALIDATION_COMPTE_BANCAIRE_PARALLELE,
        CODE_SERVICE.COMPTE_EXTERNE.SERVICE_COMPTE_EXTERNE
            .VALIDER_COMPTE_BANCAIRE_PARALLELE,
        CODE_SERVICE.COMPTE_EXTERNE.SERVICE_COMPTE_EXTERNE
            .INITIER_CREATION_COMPTE_BANCAIRE_PARALLELE,
        CODE_SERVICE.COMPTE_EXTERNE.SERVICE_COMPTE_EXTERNE
            .LECTURE_COMPTE_BANCAIRE_PARALLELE
    ]
};

export const ComptesBancaireCreatePath = {
    ...ComptesBancaireCreateRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.CreationCompteBancaireForm,
    element: <Configuration.CreationCompteBancaireForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.COMPTE_EXTERNE.SERVICE_COMPTE_EXTERNE
            .INITIER_CREATION_COMPTE_BANCAIRE_PARALLELE
    ]
};

export const ComptesBancaireDetailPath = {
    ...ComptesBancaireDetailRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.DetailCompteBancaire,
    element: <Configuration.DetailCompteBancaire />,
    code: [
        { code: 'test' },
        CODE_SERVICE.COMPTE_EXTERNE.SERVICE_COMPTE_EXTERNE
            .ATTENTE_VALIDATION_COMPTE_BANCAIRE_PARALLELE,
        CODE_SERVICE.COMPTE_EXTERNE.SERVICE_COMPTE_EXTERNE
            .VALIDER_COMPTE_BANCAIRE_PARALLELE,
        CODE_SERVICE.COMPTE_EXTERNE.SERVICE_COMPTE_EXTERNE
            .LECTURE_COMPTE_BANCAIRE_PARALLELE
    ]
};

/**
 * @desc Comptes Distrubuteurs
 */

export const ComptesDistributeurPath = {
    ...ComptesDistributeurRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Configuration.ListCompteDistributeur,
    element: <Configuration.ListCompteDistributeur />,
    code: [
        { code: 'test' },
        CODE_SERVICE.COMPTE_EXTERNE.SERVICE_COMPTE_EXTERNE
            .ATTENTE_VALIDATION_COMPTE_DISTRIBUTEUR,
        CODE_SERVICE.COMPTE_EXTERNE.SERVICE_COMPTE_EXTERNE
            .VALIDER_COMPTE_DISTRIBUTEUR,
        CODE_SERVICE.COMPTE_EXTERNE.SERVICE_COMPTE_EXTERNE
            .INITIER_CREATION_COMPTE_DISTRIBUTEUR,
        CODE_SERVICE.COMPTE_EXTERNE.SERVICE_COMPTE_EXTERNE
            .LECTURE_COMPTE_DISTRIBUTEUR
    ]
};

export const ComptesDistributeurCreatePath = {
    ...ComptesDistributeurCreateRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.CreationCompteDistributeurForm,
    element: <Configuration.CreationCompteDistributeurForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.COMPTE_EXTERNE.SERVICE_COMPTE_EXTERNE
            .INITIER_CREATION_COMPTE_DISTRIBUTEUR
    ]
};

export const ComptesDistributeurDetailPath = {
    ...ComptesDistributeurDetailRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.DetailCompteDistributeur,
    element: <Configuration.DetailCompteDistributeur />,
    code: [
        { code: 'test' },
        CODE_SERVICE.COMPTE_EXTERNE.SERVICE_COMPTE_EXTERNE
            .ATTENTE_VALIDATION_COMPTE_DISTRIBUTEUR,
        CODE_SERVICE.COMPTE_EXTERNE.SERVICE_COMPTE_EXTERNE
            .VALIDER_COMPTE_DISTRIBUTEUR,
        CODE_SERVICE.COMPTE_EXTERNE.SERVICE_COMPTE_EXTERNE
            .LECTURE_COMPTE_DISTRIBUTEUR
    ]
};

/**
 * @desc Comptes Mobile Money
 */

export const ComptesMobileMoneyPath = {
    ...ComptesMobileMoneyRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Configuration.ListCompteMobilMoney,
    element: <Configuration.ListCompteMobilMoney />,
    code: [
        { code: 'test' },
        CODE_SERVICE.COMPTE_EXTERNE.SERVICE_COMPTE_EXTERNE
            .ATTENTE_VALIDATION_COMPTE_MOBILE_MONEY,
        CODE_SERVICE.COMPTE_EXTERNE.SERVICE_COMPTE_EXTERNE
            .VALIDER_COMPTE_MOBILE_MONEY,
        CODE_SERVICE.COMPTE_EXTERNE.SERVICE_COMPTE_EXTERNE
            .INITIER_CREATION_COMPTE_MOBILE_MONEY,
        CODE_SERVICE.COMPTE_EXTERNE.SERVICE_COMPTE_EXTERNE
            .LECTURE_COMPTE_MOBILE_MONEY
    ]
};

export const ComptesMobileMoneyCreatePath = {
    ...ComptesMobileMoneyCreateRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.CreationCompteMobilMoneyForm,
    element: <Configuration.CreationCompteMobilMoneyForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.COMPTE_EXTERNE.SERVICE_COMPTE_EXTERNE
            .INITIER_CREATION_COMPTE_MOBILE_MONEY
    ]
};

export const ComptesMobileMoneyDetailPath = {
    ...ComptesMobileMoneyDetailRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.DetailCompteMobilMoney,
    element: <Configuration.DetailCompteMobilMoney />,
    code: [
        { code: 'test' },
        CODE_SERVICE.COMPTE_EXTERNE.SERVICE_COMPTE_EXTERNE
            .ATTENTE_VALIDATION_COMPTE_MOBILE_MONEY,
        CODE_SERVICE.COMPTE_EXTERNE.SERVICE_COMPTE_EXTERNE
            .VALIDER_COMPTE_MOBILE_MONEY,
        CODE_SERVICE.COMPTE_EXTERNE.SERVICE_COMPTE_EXTERNE
            .LECTURE_COMPTE_MOBILE_MONEY
    ]
};

/**
 * @desc  Budget Prévisionnel
 */

export const BudgetPrevisionnelPath = {
    ...BudgetPrevisionnelRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Configuration.ListElaborationBudgetPrevisionnel,
    element: <Configuration.ListElaborationBudgetPrevisionnel />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.INITIER_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.LECTURE_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .ATTENTE_VALIDATION_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.VALIDER_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.ADOPTER_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.ANNULER_ELABORATION_AMENDEMENT
    ]
};

export const BudgetPrevisionnelAmendementChargeFixeCreatePath = {
    ...BudgetPrevisionnelAmendementChargeFixeCreateRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Configuration.CreationElaborationChargeFixe,
    element: <Configuration.CreationElaborationChargeFixe />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.INITIER_ELABORATION_AMENDEMENT
    ]
};

export const BudgetPrevisionnelAmendementChargeVariableCreatePath = {
    ...BudgetPrevisionnelAmendementChargeVariableCreateRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Configuration.CreationElaborationChargeVariable,
    element: <Configuration.CreationElaborationChargeVariable />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.INITIER_ELABORATION_AMENDEMENT
    ]
};

export const BudgetPrevisionnelAmendementRessourceCreatePath = {
    ...BudgetPrevisionnelAmendementRessourceCreateRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Configuration.CreationElaborationRessource,
    element: <Configuration.CreationElaborationRessource />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.INITIER_ELABORATION_AMENDEMENT
    ]
};

export const BudgetPrevisionnelDetailPath = {
    ...BudgetPrevisionnelDetailRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Configuration.DetailElaborationBudgetPrevisionnel,
    element: <Configuration.DetailElaborationBudgetPrevisionnel />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.INITIER_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.LECTURE_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .ATTENTE_VALIDATION_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.VALIDER_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.ADOPTER_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.ANNULER_ELABORATION_AMENDEMENT
    ]
};

/**
 * @desc  Budget Additionnel
 */

export const BudgetAdditionnelPath = {
    ...BudgetAdditionnelRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Configuration.ListElaborationBudgetAdditionnel,
    element: <Configuration.ListElaborationBudgetAdditionnel />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.INITIER_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.LECTURE_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .ATTENTE_VALIDATION_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.VALIDER_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.ADOPTER_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.ANNULER_ELABORATION_AMENDEMENT
    ]
};

export const BudgetAdditionnelAmendementChargeFixeCreatePath = {
    ...BudgetAdditionnelAmendementChargeFixeCreateRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Configuration.CreationElaborationBudgetAdditionnelChargeFixe,
    element: <Configuration.CreationElaborationBudgetAdditionnelChargeFixe />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.INITIER_ELABORATION_AMENDEMENT
    ]
};

export const BudgetAdditionnelAmendementChargeVariableCreatePath = {
    ...BudgetAdditionnelAmendementChargeVariableCreateRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Configuration.CreationElaborationBudgetAdditionnelChargeVariable,
    element: (
        <Configuration.CreationElaborationBudgetAdditionnelChargeVariable />
    ),
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.INITIER_ELABORATION_AMENDEMENT
    ]
};

export const BudgetAdditionnelAmendementRessourceCreatePath = {
    ...BudgetAdditionnelAmendementRessourceCreateRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Configuration.CreationElaborationBudgetAdditionnelRessource,
    element: <Configuration.CreationElaborationBudgetAdditionnelRessource />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.INITIER_ELABORATION_AMENDEMENT
    ]
};

export const BudgetAdditionnelDetailPath = {
    ...BudgetAdditionnelDetailRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Configuration.DetailElaborationBudgetAdditionnel,
    element: <Configuration.DetailElaborationBudgetAdditionnel />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.INITIER_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.LECTURE_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .ATTENTE_VALIDATION_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.VALIDER_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.ADOPTER_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.ANNULER_ELABORATION_AMENDEMENT
    ]
};
/**
 * @desc Achat Marchand to Marchand
 */

export const AchatMarchandToMarchandPath = {
    ...AchatMarchandToMarchandRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Configuration.ListAchatMarchand,
    element: <Configuration.ListAchatMarchand />,
    code: [{ code: 'test' }]
};

// export const AchatMarchandToMarchandCreatePath = {
//     ...AchatMarchandToMarchandCreateRelPath,
//     isVisible: false,
//     dependencies: ['ConfigMenu'],
//     Component: Configuration.CreationCompteMobilMoneyForm,
//     element: <Configuration.CreationCompteMobilMoneyForm />,
//     code: [{ code: 'test' }]
// };

// export const AchatMarchandToMarchandDetailPath = {
//     ...AchatMarchandToMarchandDetailRelPath,
//     isVisible: false,
//     dependencies: ['ConfigMenu'],
//     Component: Configuration.DetailCompteMobilMoney,
//     element: <Configuration.DetailCompteMobilMoney />,
//     code: [{ code: 'test' }]
// };

// Budget

/**
 * @description Notification Evolution revendeur
 */
export const NotificationEvolutionRevendeurPath = {
    ...NotificationEvolutionRevendeurRelPath,
    dependencies: ['ConfigMenu'],
    isVisible: true,
    Component: Configuration.ListNotificationEvolutionRevendeurForm,
    element: <Configuration.ListNotificationEvolutionRevendeurForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .EVOLUTION_STATUT_REVENDEUR
            .INITIER_NOTIFICATION_EVOLUTION_STATUT_REVENDEUR,
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .EVOLUTION_STATUT_REVENDEUR
            .LECTURE_NOTIFICATION_EVOLUTION_STATUT_REVENDEUR,
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .EVOLUTION_STATUT_REVENDEUR
            .VALIDER_NOTIFICATION_EVOLUTION_STATUT_REVENDEUR
    ]
};

export const NotificationEvolutionRevendeurDetailPath = {
    ...NotificationEvolutionRevendeurDetailRelPath,
    dependencies: ['ConfigMenu'],
    isVisible: false,
    Component: Configuration.DetailEvolutionStatutRevendeur,
    element: <Configuration.DetailEvolutionStatutRevendeur />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .EVOLUTION_STATUT_REVENDEUR
            .LECTURE_NOTIFICATION_EVOLUTION_STATUT_REVENDEUR,
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .EVOLUTION_STATUT_REVENDEUR
            .VALIDER_NOTIFICATION_EVOLUTION_STATUT_REVENDEUR
    ]
};

export const NotificationEvolutionRevendeurCreatePath = {
    ...NotificationEvolutionRevendeurCreateRelPath,
    dependencies: ['ConfigMenu'],
    isVisible: false,
    Component: Configuration.CreationEvolutionStatutRevendeurForm,
    element: <Configuration.CreationEvolutionStatutRevendeurForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .EVOLUTION_STATUT_REVENDEUR
            .INITIER_NOTIFICATION_EVOLUTION_STATUT_REVENDEUR
    ]
};

/**
 * @description Paiement caisse execution charge
 */
export const PaiementCompteExecutionPath = {
    ...PaiementCompteExecutionRelPath,
    dependencies: ['ConfigMenu'],
    isVisible: true,
    Component: Configuration.ListPaiementCompteExecutionForm,
    element: <Configuration.ListPaiementCompteExecutionForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .ACCUSER_RECEPTION_CAISSE_EXECUTION_CHARGE_CASH,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .ACCUSER_RECEPTION_CAISSE_EXECUTION_CHARGE_NON_CASH,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .LECTURE_ACCUSER_RECEPTION_CAISSE_EXECUTION_CHARGE_CASH,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .LECTURE_ACCUSER_RECEPTION_CAISSE_EXECUTION_CHARGE_NON_CASH
    ]
};

export const PaiementCompteExecutionDetailPath = {
    ...PaiementCompteExecutionDetailRelPath,
    dependencies: ['ConfigMenu'],
    isVisible: false,
    Component: Configuration.DetailPaiementCompteExecution,
    element: <Configuration.DetailPaiementCompteExecution />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .ACCUSER_RECEPTION_CAISSE_EXECUTION_CHARGE_CASH,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .ACCUSER_RECEPTION_CAISSE_EXECUTION_CHARGE_NON_CASH,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .LECTURE_ACCUSER_RECEPTION_CAISSE_EXECUTION_CHARGE_CASH,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .LECTURE_ACCUSER_RECEPTION_CAISSE_EXECUTION_CHARGE_NON_CASH
    ]
};

/**
 * @description Dispatching Paiement caisse execution charge
 */
export const DispatchingPaiementCompteExecutionPath = {
    ...DispatchingPaiementCompteExecutionRelPath,
    dependencies: ['ConfigMenu'],
    isVisible: true,
    Component: Configuration.ListDispatchingPaiementExecutionChargeForm,
    element: <Configuration.ListDispatchingPaiementExecutionChargeForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .VALIDER_DISPATCHING_CAISSE_EXECUTION_CHARGE,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .LECTURE_DISPATCHING_CAISSE_EXECUTION_CHARGE,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .INITIER_DISPATCHING_CAISSE_EXECUTION_CHARGE
    ]
};

export const DispatchingPaiementCompteExecutionDetailPath = {
    ...DispatchingPaiementCompteExecutionDetailRelPath,
    dependencies: ['ConfigMenu'],
    isVisible: false,
    Component: Configuration.DetailDispatchingPaiementExecutionCharge,
    element: <Configuration.DetailDispatchingPaiementExecutionCharge />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .VALIDER_DISPATCHING_CAISSE_EXECUTION_CHARGE,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .LECTURE_DISPATCHING_CAISSE_EXECUTION_CHARGE,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .INITIER_DISPATCHING_CAISSE_EXECUTION_CHARGE
    ]
};

/**
 * @description Instructions
 */
export const InstructionPath = {
    ...InstructionRelPath,
    dependencies: ['ConfigMenu'],
    isVisible: true,
    Component: Configuration.ListInstructionForm,
    element: <Configuration.ListInstructionForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_WEB.CODE_INSTRUCTION.LECTURE_INSTRUCTION,
        CODE_SERVICE.SERVICE_CODE_WEB.CODE_INSTRUCTION.CONFIRMER_INSTRUCTION,
        CODE_SERVICE.SERVICE_CODE_WEB.CODE_INSTRUCTION
            .VALIDER_FEEDBACK_INSTRUCTION,
        CODE_SERVICE.SERVICE_CODE_WEB.CODE_INSTRUCTION.ADOPTER_INSTRUCTION,
        CODE_SERVICE.SERVICE_CODE_WEB.CODE_INSTRUCTION.VALIDER_INSTRUCTION
    ]
};

export const InstructionDetailPath = {
    ...InstructionDetailRelPath,
    dependencies: ['ConfigMenu'],
    isVisible: false,
    Component: Configuration.DetailInstruction,
    element: <Configuration.DetailInstruction />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_WEB.CODE_INSTRUCTION.LECTURE_INSTRUCTION,
        CODE_SERVICE.SERVICE_CODE_WEB.CODE_INSTRUCTION.CONFIRMER_INSTRUCTION,
        CODE_SERVICE.SERVICE_CODE_WEB.CODE_INSTRUCTION
            .VALIDER_FEEDBACK_INSTRUCTION,
        CODE_SERVICE.SERVICE_CODE_WEB.CODE_INSTRUCTION.ADOPTER_INSTRUCTION,
        CODE_SERVICE.SERVICE_CODE_WEB.CODE_INSTRUCTION.VALIDER_INSTRUCTION
    ]
};

/**
 * @description Exercices
 */
export const ExercicePath = {
    ...ExerciceRelPath,
    dependencies: ['PrincipalMenu'],
    isVisible: true,
    Component: Configuration.ListExerciceFinancierForm,
    element: <Configuration.ListExerciceFinancierForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.INITIER_ELABORATION_AMENDEMENT
    ]
};
export const ExerciceCreatePath = {
    ...ExerciceCreateRelPath,
    dependencies: ['PrincipalMenu'],
    isVisible: false,
    Component: Configuration.CreationExerciceFinancierForm,
    element: <Configuration.CreationExerciceFinancierForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.INITIER_ELABORATION_AMENDEMENT
    ]
};
export const ExerciceDetailPath = {
    ...ExerciceDetailRelPath,
    dependencies: ['PrincipalMenu'],
    isVisible: false,
    Component: Configuration.DetailExerciceFinancier,
    element: <Configuration.DetailExerciceFinancier />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.INITIER_ELABORATION_AMENDEMENT
    ]
};

/**
 * @desc Profil User Dédie
 */

export const ProfilUserDedierPath = {
    ...ProfilUserDedierRelPath,
    dependencies: ['ConfigMenu'],
    isVisible: true,
    Component: Configuration.ViewLayout,
    element: <Configuration.ViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.PROFIL_USER_DEDIER
            .LECTURE_PROFIL_USER_DEDIER,
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.PROFIL_USER_DEDIER
            .CREATION_PROFIL_USER_DEDIER
    ]
};

export const ProfilUserDedierDetailPath = {
    ...ProfilUserDedierDetailRelPath,
    dependencies: ['ConfigMenu'],
    isVisible: false,
    Component: Configuration.DetailProfilUserDedier,
    element: <Configuration.DetailProfilUserDedier />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.PROFIL_USER_DEDIER
            .CREATION_PROFIL_USER_DEDIER,
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.PROFIL_USER_DEDIER
            .LECTURE_PROFIL_USER_DEDIER
    ]
};

/**
 * @desc Paiement caisse sectorielle
 */

export const PaiementCaisseSectoriellePath = {
    ...PaiementCaisseSectorielleRelPath,
    dependencies: ['ConfigMenu'],
    isVisible: true,
    Component: Configuration.ListPaiementCaisseSectorielleForm,
    element: <Configuration.ListPaiementCaisseSectorielleForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.MISE_A_JOUR_CHARGE_BENEFICIAIRE,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.LECTURE_CHARGE_BENEFICIAIRE
    ]
};

export const PaiementCaisseSectorielleDetailPath = {
    ...PaiementCaisseSectorielleDetailRelPath,
    dependencies: ['ConfigMenu'],
    isVisible: false,
    Component: Configuration.DetailPaiementCaisseSectorielle,
    element: <Configuration.DetailPaiementCaisseSectorielle />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.MISE_A_JOUR_CHARGE_BENEFICIAIRE,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.LECTURE_CHARGE_BENEFICIAIRE
    ]
};

/**
 * @desc Crediter caution
 */

export const CrediterCautionPath = {
    ...CrediterCautionRelPath,
    dependencies: ['PrincipalMenu'],
    isVisible: true,
    Component: Configuration.ListCrediterCautionForm,
    element: <Configuration.ListCrediterCautionForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_WEB.CODE_SERVICE_ADMINISTRATION_NAPP
            .LECTURE_CREDITER_CAUTION,
        CODE_SERVICE.SERVICE_CODE_WEB.CODE_SERVICE_ADMINISTRATION_NAPP
            .CREDITER_CAUTION
    ]
};

export const CrediterCautionCreatePath = {
    ...CrediterCautionCreateRelPath,
    dependencies: ['PrincipalMenu'],
    isVisible: false,
    Component: Configuration.CreationCrediterCautionForm,
    element: <Configuration.CreationCrediterCautionForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_WEB.CODE_SERVICE_ADMINISTRATION_NAPP
            .LECTURE_CREDITER_CAUTION,
        CODE_SERVICE.SERVICE_CODE_WEB.CODE_SERVICE_ADMINISTRATION_NAPP
            .CREDITER_CAUTION
    ]
};

export const CrediterCautionDetailPath = {
    ...CrediterCautionDetailRelPath,
    dependencies: ['PrincipalMenu'],
    isVisible: false,
    Component: Configuration.DetailCrediterCautionForm,
    element: <Configuration.DetailCrediterCautionForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_WEB.CODE_SERVICE_ADMINISTRATION_NAPP
            .LECTURE_CREDITER_CAUTION,
        CODE_SERVICE.SERVICE_CODE_WEB.CODE_SERVICE_ADMINISTRATION_NAPP
            .CREDITER_CAUTION
    ]
};

/**
 * @desc Configuration kindistribution
 */

export const ConfigurationKindistributionViewLayoutPath = {
    ...ConfigurationKindistributionViewLayoutRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Configuration.ConfigurationKindistributionViewLayout,
    element: <Configuration.ConfigurationKindistributionViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .SERVICE_CONFIGURATION_KINDB
            .CREATION_CONFIGURATION_KIN_DISTRIBUTION,
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .SERVICE_CONFIGURATION_KINDB.LECTURE_CONFIGURATION_KIN_DISTRIBUTION,
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .SERVICE_CONFIGURATION_KINDB
            .METTRE_A_JOUR_CONFIGURATION_KIN_DISTRIBUTION
    ]
};

export const ConfigurationKindistributionCreatePath = {
    ...ConfigurationKindistributionCreateRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.CreationConfigurationKindistributionForm,
    element: <Configuration.CreationConfigurationKindistributionForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .SERVICE_CONFIGURATION_KINDB.CREATION_CONFIGURATION_KIN_DISTRIBUTION
    ]
};

export const ConfigurationKindistributionDetailPath = {
    ...ConfigurationKindistributionDetailRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.DetailConfigurationKindistributionForm,
    element: <Configuration.DetailConfigurationKindistributionForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .SERVICE_CONFIGURATION_KINDB
            .CREATION_CONFIGURATION_KIN_DISTRIBUTION,
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .SERVICE_CONFIGURATION_KINDB.LECTURE_CONFIGURATION_KIN_DISTRIBUTION,
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .SERVICE_CONFIGURATION_KINDB
            .METTRE_A_JOUR_CONFIGURATION_KIN_DISTRIBUTION
    ]
};

/**
 * @desc Config equipe technique
 */

export const ConfigEquipeTechniqueViewLayoutPath = {
    ...ConfigEquipeTechniqueViewLayoutRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Configuration.ConfigEquipeTechniqueViewLayout,
    element: <Configuration.ConfigEquipeTechniqueViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .SERVICE_CONFIGURATION_SYSTEME.CREATION_CONFIGURATION_SYSTEM,
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .SERVICE_CONFIGURATION_SYSTEME.LECTURE_CONFIGURATION_SYSTEM,
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .SERVICE_CONFIGURATION_SYSTEME.METTRE_A_JOUR_CONFIGURATION_SYSTEM
    ]
};

export const ConfigEquipeTechniqueCreatePath = {
    ...ConfigEquipeTechniqueCreateRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.CreationConfigEquipeTechniqueForm,
    element: <Configuration.CreationConfigEquipeTechniqueForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .SERVICE_CONFIGURATION_SYSTEME.CREATION_CONFIGURATION_SYSTEM
    ]
};

export const ConfigEquipeTechniqueDetailPath = {
    ...ConfigEquipeTechniqueDetailRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.DetailConfigEquipeTechniqueForm,
    element: <Configuration.DetailConfigEquipeTechniqueForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .SERVICE_CONFIGURATION_SYSTEME.CREATION_CONFIGURATION_SYSTEM,
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .SERVICE_CONFIGURATION_SYSTEME.LECTURE_CONFIGURATION_SYSTEM,
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .SERVICE_CONFIGURATION_SYSTEME.METTRE_A_JOUR_CONFIGURATION_SYSTEM
    ]
};

/**
 * @desc Configuration sender
 */

export const ConfigurationSenderViewLayoutPath = {
    ...ConfigurationSenderViewLayoutRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Configuration.ConfigurationSenderViewLayout,
    element: <Configuration.ConfigurationSenderViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .SERVICE_CONFIGURATION_SENDER.CREATION_CONFIGURATION_SENDER,
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .SERVICE_CONFIGURATION_SENDER.LECTURE_CONFIGURATION_SENDER,
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .SERVICE_CONFIGURATION_SENDER.METTRE_A_JOUR_CONFIGURATION_SENDER
    ]
};

export const ConfigurationSenderCreatePath = {
    ...ConfigurationSenderCreateRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.CreationConfigurationSenderForm,
    element: <Configuration.CreationConfigurationSenderForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .SERVICE_CONFIGURATION_SENDER.CREATION_CONFIGURATION_SENDER
    ]
};

export const ConfigurationSenderDetailPath = {
    ...ConfigurationSenderDetailRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.DetailConfigurationSenderForm,
    element: <Configuration.DetailConfigurationSenderForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .SERVICE_CONFIGURATION_SENDER.CREATION_CONFIGURATION_SENDER,
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .SERVICE_CONFIGURATION_SENDER.LECTURE_CONFIGURATION_SENDER,
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .SERVICE_CONFIGURATION_SENDER.METTRE_A_JOUR_CONFIGURATION_SENDER
    ]
};

/**
 * @desc Upload fichier Releve Bancaire
 */

export const UploadFichierViewLayoutPath = {
    ...UploadFichierViewLayoutRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Configuration.UploadFichierViewLayout,
    element: <Configuration.UploadFichierViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .TRAITEMENT_RELEVE_BANCAIRE.CREATION_TRAITEMENT_RELEVE_BANCAIRE,
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .TRAITEMENT_RELEVE_BANCAIRE.LECTURE_TRAITEMENT_RELEVE_BANCAIRE
    ]
};

export const UploadFichierCreatePath = {
    ...UploadFichierCreateRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Configuration.CreationUploadFichierForm,
    element: <Configuration.CreationUploadFichierForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .TRAITEMENT_RELEVE_BANCAIRE.CREATION_TRAITEMENT_RELEVE_BANCAIRE
    ]
};

export const UploadFichierDetailPath = {
    ...UploadFichierDetailRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Configuration.DetailUploadFichierForm,
    element: <Configuration.DetailUploadFichierForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .TRAITEMENT_RELEVE_BANCAIRE.LECTURE_TRAITEMENT_RELEVE_BANCAIRE
    ]
};

/**
 * @desc Upload fichier Releve Emoney
 */

export const UploadFichierReleveEmoneyViewLayoutPath = {
    ...UploadFichierReleveEmoneyViewLayoutRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Configuration.UploadFichierReleveEmoneyViewLayout,
    element: <Configuration.UploadFichierReleveEmoneyViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_EMONEY.CREATION_RELEVE_EMONEY,
        CODE_SERVICE.SERVICE_EMONEY.LECTURE_RELEVE_EMONEY
    ]
};

export const UploadFichierReleveEmoneyCreatePath = {
    ...UploadFichierReleveEmoneyCreateRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.CreationUploadFichierReleveEmoneyForm,
    element: <Configuration.CreationUploadFichierReleveEmoneyForm />,
    code: [{ code: 'test' }, CODE_SERVICE.SERVICE_EMONEY.CREATION_RELEVE_EMONEY]
};

export const UploadFichierReleveEmoneyDetailPath = {
    ...UploadFichierReleveEmoneyDetailRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.DetailUploadFichierReleveEmoneyForm,
    element: <Configuration.DetailUploadFichierReleveEmoneyForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_EMONEY.CREATION_RELEVE_EMONEY,
        CODE_SERVICE.SERVICE_EMONEY.LECTURE_RELEVE_EMONEY
    ]
};

/**
 * @desc Upload fichier Releve Emoney
 */

export const UploadFichierPresenceViewLayoutPath = {
    ...UploadFichierPresenceViewLayoutRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Configuration.UploadFichierPresenceViewLayout,
    element: <Configuration.UploadFichierPresenceViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .TRAITEMENT_FICHIER_PRESENCE.CREATION_TRAITEMENT_FICHIER_PRESENCE,
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .TRAITEMENT_FICHIER_PRESENCE.LECTURE_TRAITEMENT_FICHIER_PRESENCE
    ]
};

export const UploadFichierPresenceCreatePath = {
    ...UploadFichierPresenceCreateRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.CreationUploadFichierPresenceForm,
    element: <Configuration.CreationUploadFichierPresenceForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .TRAITEMENT_FICHIER_PRESENCE.CREATION_TRAITEMENT_FICHIER_PRESENCE,
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .TRAITEMENT_FICHIER_PRESENCE.LECTURE_TRAITEMENT_FICHIER_PRESENCE
    ]
};

export const UploadFichierPresenceDetailPath = {
    ...UploadFichierPresenceDetailRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.DetailUploadFichierPresenceForm,
    element: <Configuration.DetailUploadFichierPresenceForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .TRAITEMENT_FICHIER_PRESENCE.CREATION_TRAITEMENT_FICHIER_PRESENCE,
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .TRAITEMENT_FICHIER_PRESENCE.LECTURE_TRAITEMENT_FICHIER_PRESENCE
    ]
};

/**
 * @desc Upload fichier Releve Flash
 */

export const UploadFichierReleveFlashViewLayoutPath = {
    ...UploadFichierReleveFlashViewLayoutRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Configuration.UploadFichierReleveFlashViewLayout,
    element: <Configuration.UploadFichierReleveFlashViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_EMONEY.CREATION_RELEVE_FLASH,
        CODE_SERVICE.SERVICE_EMONEY.LECTURE_RELEVE_FLASH
    ]
};

export const UploadFichierReleveFlashCreatePath = {
    ...UploadFichierReleveFlashCreateRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.CreationUploadFichierReleveFlashForm,
    element: <Configuration.CreationUploadFichierReleveFlashForm />,
    code: [{ code: 'test' }, CODE_SERVICE.SERVICE_EMONEY.CREATION_RELEVE_FLASH]
};

export const UploadFichierReleveFlashDetailPath = {
    ...UploadFichierReleveFlashDetailRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.DetailUploadFichierReleveFlashForm,
    element: <Configuration.DetailUploadFichierReleveFlashForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_EMONEY.CREATION_RELEVE_FLASH,
        CODE_SERVICE.SERVICE_EMONEY.LECTURE_RELEVE_FLASH
    ]
};

/**
 * @desc Limit credit
 */

export const LimitCreditViewLayoutPath = {
    ...LimitCreditViewLayoutRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Configuration.LimitCreditViewLayout,
    element: <Configuration.LimitCreditViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .INITIER_OCTROI_LIMIT_CREDIT,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .LECTURE_OCTROI_LIMIT_CREDIT,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .APPROBATION_OCTROI_LIMIT_CREDIT
    ]
};

export const LimitCreditCreatePath = {
    ...LimitCreditCreateRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.CreationLimitCreditForm,
    element: <Configuration.CreationLimitCreditForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .INITIER_OCTROI_LIMIT_CREDIT
    ]
};

export const LimitCreditDetailPath = {
    ...LimitCreditDetailRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.DetailLimitCreditForm,
    element: <Configuration.DetailLimitCreditForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .INITIER_OCTROI_LIMIT_CREDIT,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .LECTURE_OCTROI_LIMIT_CREDIT,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .APPROBATION_OCTROI_LIMIT_CREDIT
    ]
};

/**
 * @desc Arret service vente marchand
 */

export const ShutDownAndUpApiPartnerViewLayoutPath = {
    ...ShutDownAndUpApiPartnerViewLayoutRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Configuration.ShutDownAndUpApiPartnerViewLayout,
    element: <Configuration.ShutDownAndUpApiPartnerViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .CODE_SERVICES_API_PARTENAIRE_CONFIGURATION
            .ACTIVER_DESACTIVER_API_PARTENAIRE,
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .CODE_SERVICES_API_PARTENAIRE_CONFIGURATION
            .LECTURE_ACTIVER_DESACTIVER_API_PARTENAIRE,
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .CODE_SERVICES_API_PARTENAIRE_CONFIGURATION
            .ATTENTE_VALIDATION_ACTIVER_DESACTIVER_API_PARTENAIRE,
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .CODE_SERVICES_API_PARTENAIRE_CONFIGURATION
            .VALIDER_ACTIVER_DESACTIVER_API_PARTENAIRE
    ]
};

export const ShutDownAndUpApiPartnerCreatePath = {
    ...ShutDownAndUpApiPartnerCreateRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.CreationShutDownAndUpApiPartnerForm,
    element: <Configuration.CreationShutDownAndUpApiPartnerForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .CODE_SERVICES_API_PARTENAIRE_CONFIGURATION
            .ACTIVER_DESACTIVER_API_PARTENAIRE
    ]
};

export const ShutDownAndUpApiPartnerDetailPath = {
    ...ShutDownAndUpApiPartnerDetailRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.DetailShutDownAndUpApiPartnerForm,
    element: <Configuration.DetailShutDownAndUpApiPartnerForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .CODE_SERVICES_API_PARTENAIRE_CONFIGURATION
            .ACTIVER_DESACTIVER_API_PARTENAIRE,
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .CODE_SERVICES_API_PARTENAIRE_CONFIGURATION
            .LECTURE_ACTIVER_DESACTIVER_API_PARTENAIRE,
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .CODE_SERVICES_API_PARTENAIRE_CONFIGURATION
            .ATTENTE_VALIDATION_ACTIVER_DESACTIVER_API_PARTENAIRE,
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .CODE_SERVICES_API_PARTENAIRE_CONFIGURATION
            .VALIDER_ACTIVER_DESACTIVER_API_PARTENAIRE
    ]
};

/**
 * @desc Cash collection
 */

export const CashCollectionViewLayoutPath = {
    ...CashCollectionViewLayoutRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Configuration.CashCollectionViewLayout,
    element: <Configuration.CashCollectionViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_CASH_COLLECTION
            .INITIER_CREATION_CASH_COLLECTION,
        CODE_SERVICE.CODE_SERVICE_CASH_COLLECTION
            .VALIDER_CREATION_CASH_COLLECTION,
        CODE_SERVICE.CODE_SERVICE_CASH_COLLECTION.LECTURE_CASH_COLLECTION
    ]
};

export const CashCollectionCreatePath = {
    ...CashCollectionCreateRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.CreationCashCollectionForm,
    element: <Configuration.CreationCashCollectionForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_CASH_COLLECTION
            .INITIER_CREATION_CASH_COLLECTION
    ]
};

export const CashCollectionDetailPath = {
    ...CashCollectionDetailRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.DetailCashCollectionForm,
    element: <Configuration.DetailCashCollectionForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_CASH_COLLECTION
            .INITIER_CREATION_CASH_COLLECTION,
        CODE_SERVICE.CODE_SERVICE_CASH_COLLECTION
            .VALIDER_CREATION_CASH_COLLECTION,
        CODE_SERVICE.CODE_SERVICE_CASH_COLLECTION.LECTURE_CASH_COLLECTION
    ]
};

export const CashCollectionRegisterPath = {
    ...CashCollectionRegisterRelPath,
    Component: Configuration.ValiderCashCollectionOrganisation,
    element: <Configuration.ValiderCashCollectionOrganisation />,
    code: [{ code: 'test' }]
};

/**
 * @desc Configuration instruction
 */

export const ConfigurationInstructionViewLayoutPath = {
    ...ConfigurationInstructionViewLayoutRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Configuration.ConfigurationInstructionViewLayout,
    element: <Configuration.ConfigurationInstructionViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.COMPTE_EXTERNE.SERVICE_COMPTE_EXTERNE
            .VALIDER_COMPTE_BANCAIRE_PARALLELE,
        CODE_SERVICE.COMPTE_EXTERNE.SERVICE_COMPTE_EXTERNE
            .VALIDER_COMPTE_BANCAIRE_PARALLELE,
        CODE_SERVICE.COMPTE_EXTERNE.SERVICE_COMPTE_EXTERNE
            .LECTURE_COMPTE_BANCAIRE_PARALLELE
    ]
};

export const ConfigurationInstructionCreatePath = {
    ...ConfigurationInstructionCreateRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Configuration.CreationConfigurationInstructionForm,
    element: <Configuration.CreationConfigurationInstructionForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.COMPTE_EXTERNE.SERVICE_COMPTE_EXTERNE
            .VALIDER_COMPTE_BANCAIRE_PARALLELE,
        CODE_SERVICE.COMPTE_EXTERNE.SERVICE_COMPTE_EXTERNE
            .VALIDER_COMPTE_BANCAIRE_PARALLELE,
        CODE_SERVICE.COMPTE_EXTERNE.SERVICE_COMPTE_EXTERNE
            .LECTURE_COMPTE_BANCAIRE_PARALLELE
    ]
};

export const ConfigurationInstructionDetailPath = {
    ...ConfigurationInstructionDetailRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Configuration.DetailConfigurationInstructionForm,
    element: <Configuration.DetailConfigurationInstructionForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.COMPTE_EXTERNE.SERVICE_COMPTE_EXTERNE
            .VALIDER_COMPTE_BANCAIRE_PARALLELE,
        CODE_SERVICE.COMPTE_EXTERNE.SERVICE_COMPTE_EXTERNE
            .VALIDER_COMPTE_BANCAIRE_PARALLELE,
        CODE_SERVICE.COMPTE_EXTERNE.SERVICE_COMPTE_EXTERNE
            .LECTURE_COMPTE_BANCAIRE_PARALLELE
    ]
};

/**
 * @desc Regularisation caisse Emoney
 */

export const RegularisationCaisseEmoneyViewLayoutPath = {
    ...RegularisationCaisseEmoneyViewLayoutRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Configuration.RegularisationCaisseEmoneyViewLayout,
    element: <Configuration.RegularisationCaisseEmoneyViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CAISSE.REGULARISATION_CAISSE
            .INITIER_CREATION_REGULARISATION_CAISSE,
        CODE_SERVICE.CAISSE.REGULARISATION_CAISSE.LECTURE_REGULARISATION_CAISSE,
        CODE_SERVICE.CAISSE.REGULARISATION_CAISSE.ANNULER_REGULARISATION_CAISSE,
        CODE_SERVICE.CAISSE.REGULARISATION_CAISSE.VALIDER_REGULARISATION_CAISSE
    ]
};

export const RegularisationCaisseEmoneyCreatePath = {
    ...RegularisationCaisseEmoneyCreateRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.CreationRegularisationCaisseEmoneyForm,
    element: <Configuration.CreationRegularisationCaisseEmoneyForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CAISSE.REGULARISATION_CAISSE
            .INITIER_CREATION_REGULARISATION_CAISSE
    ]
};

export const RegularisationCaisseEmoneyDetailPath = {
    ...RegularisationCaisseEmoneyDetailRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.DetailRegularisationCaisseEmoneyForm,
    element: <Configuration.DetailRegularisationCaisseEmoneyForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CAISSE.REGULARISATION_CAISSE
            .INITIER_CREATION_REGULARISATION_CAISSE,
        CODE_SERVICE.CAISSE.REGULARISATION_CAISSE.LECTURE_REGULARISATION_CAISSE,
        CODE_SERVICE.CAISSE.REGULARISATION_CAISSE.ANNULER_REGULARISATION_CAISSE,
        CODE_SERVICE.CAISSE.REGULARISATION_CAISSE.VALIDER_REGULARISATION_CAISSE
    ]
};

/**
 * @desc Tunnel
 */

export const TunnelViewLayoutPath = {
    ...TunnelViewLayoutRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Configuration.TunnelViewLayout,
    element: <Configuration.TunnelViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .CODE_SERVICES_API_PARTENAIRE_CONFIGURATION
            .ACTIVER_DESACTIVER_API_PARTENAIRE
    ]
};

export const TunnelDetailPath = {
    ...TunnelDetailRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.DetailTunnelForm,
    element: <Configuration.DetailTunnelForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .CODE_SERVICES_API_PARTENAIRE_CONFIGURATION
            .ACTIVER_DESACTIVER_API_PARTENAIRE
    ]
};

/**
 * @desc  Budget Prévisionnel
 */

/**
 * Elaboration
 */
export const ElaborationPath = {
    ...ElaborationRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Configuration.ElaborationViewLayout,
    element: <Configuration.ElaborationViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.INITIER_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.LECTURE_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .ATTENTE_VALIDATION_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.VALIDER_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.ADOPTER_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.ANNULER_ELABORATION_AMENDEMENT
    ]
};

export const ElaborationAdditionnelCreatePath = {
    ...ElaborationAdditionnelCreateRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Configuration.CreationBudgetElaborationAdditionnelForm,
    element: <Configuration.CreationBudgetElaborationAdditionnelForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.INITIER_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.LECTURE_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .ATTENTE_VALIDATION_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.VALIDER_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.ADOPTER_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.ANNULER_ELABORATION_AMENDEMENT
    ]
};

/**
 * New Routes
 */

export const ElaborationAdditionnelFixeCreatePath = {
    ...ElaborationAdditionnelFixeCreateRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Configuration.CreationBudgetElaborationAdditionnelForm,
    element: (
        <Configuration.CreationBudgetElaborationAdditionnelForm
            isAdditionnel
            isFixe
        />
    ),
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.INITIER_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.LECTURE_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .ATTENTE_VALIDATION_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.VALIDER_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.ADOPTER_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.ANNULER_ELABORATION_AMENDEMENT
    ]
};
export const ElaborationAdditionnelVariableCreatePath = {
    ...ElaborationAdditionnelVariableCreateRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Configuration.CreationBudgetElaborationAdditionnelForm,
    element: (
        <Configuration.CreationBudgetElaborationAdditionnelForm
            isAdditionnel
            isVariable
        />
    ),
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.INITIER_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.LECTURE_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .ATTENTE_VALIDATION_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.VALIDER_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.ADOPTER_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.ANNULER_ELABORATION_AMENDEMENT
    ]
};
export const ElaborationAdditionnelRessourceCreatePath = {
    ...ElaborationAdditionnelRessourceCreateRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Configuration.CreationBudgetElaborationAdditionnelForm,
    element: (
        <Configuration.CreationBudgetElaborationAdditionnelForm
            isAdditionnel
            isRessource
        />
    ),
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.INITIER_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.LECTURE_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .ATTENTE_VALIDATION_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.VALIDER_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.ADOPTER_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.ANNULER_ELABORATION_AMENDEMENT
    ]
};

export const ElaborationPrevisionnelFixeCreatePath = {
    ...ElaborationPrevisionnelFixeCreateRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Configuration.CreationBudgetElaborationPrevisionnelForm,
    element: <Configuration.CreationBudgetElaborationPrevisionnelForm isFixe />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.INITIER_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.LECTURE_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .ATTENTE_VALIDATION_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.VALIDER_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.ADOPTER_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.ANNULER_ELABORATION_AMENDEMENT
    ]
};

export const ElaborationPrevisionnelVariableCreatePath = {
    ...ElaborationPrevisionnelVariableCreateRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Configuration.CreationBudgetElaborationPrevisionnelForm,
    element: (
        <Configuration.CreationBudgetElaborationPrevisionnelForm isVariable />
    ),
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.INITIER_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.LECTURE_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .ATTENTE_VALIDATION_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.VALIDER_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.ADOPTER_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.ANNULER_ELABORATION_AMENDEMENT
    ]
};

export const ElaborationPrevisionnelRessourceCreatePath = {
    ...ElaborationPrevisionnelRessourceCreateRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Configuration.CreationBudgetElaborationPrevisionnelForm,
    element: (
        <Configuration.CreationBudgetElaborationPrevisionnelForm isRessource />
    ),
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.INITIER_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.LECTURE_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .ATTENTE_VALIDATION_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.VALIDER_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.ADOPTER_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.ANNULER_ELABORATION_AMENDEMENT
    ]
};

// End new routes

export const ElaborationPrevisionnelCreatePath = {
    ...ElaborationPrevisionnelCreateRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Configuration.CreationBudgetElaborationPrevisionnelForm,
    element: <Configuration.CreationBudgetElaborationPrevisionnelForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.INITIER_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.LECTURE_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .ATTENTE_VALIDATION_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.VALIDER_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.ADOPTER_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.ANNULER_ELABORATION_AMENDEMENT
    ]
};

export const ElaborationDetailPath = {
    ...ElaborationDetailRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Configuration.DetailBudgetElaborationForm,
    element: <Configuration.DetailBudgetElaborationForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.INITIER_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.LECTURE_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .ATTENTE_VALIDATION_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.VALIDER_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.ADOPTER_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.ANNULER_ELABORATION_AMENDEMENT
    ]
};

export const ElaborationCompletionPath = {
    ...ElaborationCompletionRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Configuration.Completion,
    element: <Configuration.Completion />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.INITIER_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.LECTURE_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .ATTENTE_VALIDATION_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.VALIDER_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.ADOPTER_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.ANNULER_ELABORATION_AMENDEMENT
    ]
};

export const ElaborationAmendementPath = {
    ...ElaborationAmendementRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Configuration.CreationBudgetAmendementForm,
    element: <Configuration.CreationBudgetAmendementForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.INITIER_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.LECTURE_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .ATTENTE_VALIDATION_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.VALIDER_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.ADOPTER_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.ANNULER_ELABORATION_AMENDEMENT
    ]
};

export const ElaborationVoirPlusPath = {
    ...ElaborationVoirPlusRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Configuration.VoirPlus,
    element: <Configuration.VoirPlus />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.INITIER_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.LECTURE_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .ATTENTE_VALIDATION_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.VALIDER_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.ADOPTER_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.ANNULER_ELABORATION_AMENDEMENT
    ]
};

/**
 * Amendement
 */
export const AmenedementPath = {
    ...AmenedementRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Configuration.AmendementViewLayout,
    element: <Configuration.AmendementViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.INITIER_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.LECTURE_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .ATTENTE_VALIDATION_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.VALIDER_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.ADOPTER_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.ANNULER_ELABORATION_AMENDEMENT
    ]
};

export const AmenedementCreatePath = {
    ...AmenedementCreateRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Configuration.CreationBudgetAmendementForm,
    element: <Configuration.CreationBudgetAmendementForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.INITIER_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.LECTURE_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .ATTENTE_VALIDATION_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.VALIDER_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.ADOPTER_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.ANNULER_ELABORATION_AMENDEMENT
    ]
};

export const AmenedementDetailPath = {
    ...AmenedementDetailRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Configuration.DetailBudgetAmenedementForm,
    element: <Configuration.DetailBudgetAmenedementForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.INITIER_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.LECTURE_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET
            .ATTENTE_VALIDATION_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.VALIDER_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.ADOPTER_ELABORATION_AMENDEMENT,
        CODE_SERVICE.CODE_SERVICE_MODULE_BUDGET.ANNULER_ELABORATION_AMENDEMENT
    ]
};
/**
 * @desc Gestion revendeur
 */

export const GestionRevendeurViewLayoutPath = {
    ...GestionRevendeurViewLayoutRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Configuration.GestionRevendeurViewLayout,
    element: <Configuration.GestionRevendeurViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .CHANGEMENT_STATUT_REVENDEUR.INITIER_CHANGEMENT_STATUT_REVENDEUR,
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .CHANGEMENT_STATUT_REVENDEUR.LECTURE_CHANGEMENT_STATUT_REVENDEUR,
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .CHANGEMENT_STATUT_REVENDEUR.VALIDER_CHANGEMENT_STATUT_REVENDEUR
    ]
};

export const GestionRevendeurCreatePath = {
    ...GestionRevendeurCreateRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Configuration.CreationGestionRevendeurForm,
    element: <Configuration.CreationGestionRevendeurForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .CHANGEMENT_STATUT_REVENDEUR.INITIER_CHANGEMENT_STATUT_REVENDEUR
    ]
};

export const GestionRevendeurDetailPath = {
    ...GestionRevendeurDetailRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Configuration.DetailGestionRevendeurForm,
    element: <Configuration.DetailGestionRevendeurForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .CHANGEMENT_STATUT_REVENDEUR.INITIER_CHANGEMENT_STATUT_REVENDEUR,
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .CHANGEMENT_STATUT_REVENDEUR.LECTURE_CHANGEMENT_STATUT_REVENDEUR,
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .CHANGEMENT_STATUT_REVENDEUR.VALIDER_CHANGEMENT_STATUT_REVENDEUR
    ]
};

/**
 * @desc Formulaire dynamique sim flash
 */

export const FormulaireDynamiqueSimFlashViewLayoutPath = {
    ...FormulaireDynamiqueSimFlashViewLayoutRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Configuration.FormulaireDynamiqueSimFlashViewLayout,
    element: <Configuration.FormulaireDynamiqueSimFlashViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_VENTE
            .CONFIGURATION_SIM_MARCHAND,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_VENTE
            .VALIDER_CONFIGURATION_SIM_MARCHAND
    ]
};

export const FormulaireDynamiqueSimFlashDetailPath = {
    ...FormulaireDynamiqueSimFlashDetailRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Configuration.DetailFormulaireDynamiqueSimFlashForm,
    element: <Configuration.DetailFormulaireDynamiqueSimFlashForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_VENTE
            .VALIDER_CONFIGURATION_SIM_MARCHAND
    ]
};

/**
 * @desc Confirmation solde
 */

export const ConfirmationSoldeCompteViewLayoutPath = {
    ...ConfirmationSoldeCompteViewLayoutRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Administration.ConfirmationSoldeCompteViewLayout,
    element: <Administration.ConfirmationSoldeCompteViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .SERVICE_CONFIRMATION_SOLDE.CREATION_CONFIRMATION_SOLDE_COMPTE,
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .SERVICE_CONFIRMATION_SOLDE.LECTURE_CONFIRMATION_SOLDE_COMPTE
    ]
};

export const ConfirmationSoldeCompteCreatePath = {
    ...ConfirmationSoldeCompteCreateRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Administration.CreationConfirmationSoldeCompteForm,
    element: <Administration.CreationConfirmationSoldeCompteForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .SERVICE_CONFIRMATION_SOLDE.CREATION_CONFIRMATION_SOLDE_COMPTE
    ]
};

export const ConfirmationSoldeCompteDetailPath = {
    ...ConfirmationSoldeCompteDetailRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Administration.DetailConfirmationSoldeCompteForm,
    element: <Administration.DetailConfirmationSoldeCompteForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .SERVICE_CONFIRMATION_SOLDE.CREATION_CONFIRMATION_SOLDE_COMPTE,
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .SERVICE_CONFIRMATION_SOLDE.LECTURE_CONFIRMATION_SOLDE_COMPTE
    ]
};

/**
 * @desc Activateur
 */

export const ActivateurViewLayoutPath = {
    ...ActivateurViewLayoutRelPath,
    isVisible: true,
    dependencies: ['ConfigMenu'],
    Component: Configuration.ActivateurViewLayout,
    element: <Configuration.ActivateurViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.TRAITEMENT_PAIEMENT_VENDEUR
            .CREATION_TRAITEMENT_PAIEMENT_VENDEUR,
        CODE_SERVICE.TRAITEMENT_PAIEMENT_VENDEUR
            .LECTURE_TRAITEMENT_PAIEMENT_VENDEUR
    ]
};

export const ActivateurCreatePath = {
    ...ActivateurCreateRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.CreationActivateurForm,
    element: <Configuration.CreationActivateurForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.TRAITEMENT_PAIEMENT_VENDEUR
            .CREATION_TRAITEMENT_PAIEMENT_VENDEUR
    ]
};

export const ActivateurDetailPath = {
    ...ActivateurDetailRelPath,
    isVisible: false,
    dependencies: ['ConfigMenu'],
    Component: Configuration.DetailActivateurForm,
    element: <Configuration.DetailActivateurForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.TRAITEMENT_PAIEMENT_VENDEUR
            .CREATION_TRAITEMENT_PAIEMENT_VENDEUR,
        CODE_SERVICE.TRAITEMENT_PAIEMENT_VENDEUR
            .LECTURE_TRAITEMENT_PAIEMENT_VENDEUR
    ]
};

/**
 * @desc Taches performance agent
 */

// export const TachesPerformanceAgentViewLayoutPath = {
//     ...TachesPerformanceAgentViewLayoutRelPath,
//     isVisible: true,
//     dependencies: ['PrincipalMenu'],
//     Component: Configuration.TachesPerformanceAgentViewLayout,
//     element: <Configuration.TachesPerformanceAgentViewLayout />,
//     code: [{ code: 'test' }]
// };

// export const TachesPerformanceAgentCreatePath = {
//     ...TachesPerformanceAgentCreateRelPath,
//     isVisible: false,
//     dependencies: ['PrincipalMenu'],
//     Component: Configuration.CreationTachesPerformanceAgentForm,
//     element: <Configuration.CreationTachesPerformanceAgentForm />,
//     code: [{ code: 'test' }]
// };

// export const TachesPerformanceAgentDetailPath = {
//     ...TachesPerformanceAgentDetailRelPath,
//     isVisible: false,
//     dependencies: ['PrincipalMenu'],
//     Component: Configuration.DetailTachesPerformanceAgentForm,
//     element: <Configuration.DetailTachesPerformanceAgentForm />,
//     code: [{ code: 'test' }]
// };

/**
 * @desc Objectifs
 */

// export const ObjectifsViewLayoutPath = {
//     ...ObjectifsViewLayoutRelPath,
//     isVisible: true,
//     dependencies: ['PrincipalMenu'],
//     Component: Configuration.ObjectifsViewLayout,
//     element: <Configuration.ObjectifsViewLayout />,
//     code: [{ code: 'test' }]
// };

// export const ObjectifsCreatePath = {
//     ...ObjectifsCreateRelPath,
//     isVisible: false,
//     dependencies: ['PrincipalMenu'],
//     Component: Configuration.CreationObjectifsForm,
//     element: <Configuration.CreationObjectifsForm />,
//     code: [{ code: 'test' }]
// };

// export const ObjectifsDetailPath = {
//     ...ObjectifsDetailRelPath,
//     isVisible: false,
//     dependencies: ['PrincipalMenu'],
//     Component: Configuration.DetailObjectifsForm,
//     element: <Configuration.DetailObjectifsForm />,
//     code: [{ code: 'test' }]
// };
