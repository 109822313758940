import React from 'react';
// import { AiFillMinusCircle } from 'react-icons/ai';
import {
    ConditionalRenderingWrapper,
    CustomLiteDynamicDropdown,
    CustomLiteDynamicInput
} from '../../../../../../components';

export function ChargeVariableRowSelector({
    // handleRemove,
    field,
    charges,
    handleSelectChange,
    index,
    handleChange,
    formFields,
    setFormFields
}) {
    return (
        <div className="row d-flex align-items-center justify-content-between me-5 ms-5">
            {/* Charges */}
            <CustomLiteDynamicDropdown
                containerClassName={field?.estRetribution ? 'col-12' : 'col-6'}
                id={`${field.element.code}`}
                ariaLabel="Default select example"
                name="element.code|element.designation"
                customproperty="estRetribution"
                customprops={{ typemoyenpaiement: 'typeMoyenPaiement' }}
                value={field?.element?.code}
                options={charges}
                hiddenOption="Selectionnez une charge"
                isSort
                onChange={handleSelectChange}
                handleProps={{
                    index,
                    setFormFields,
                    formFields,
                    charges,
                    view: 'ChargeVariable'
                }}
                labelStyle={{
                    fontSize: '13px',
                    paddingBottom: '8px',
                    lineHeight: '20px',
                    minHeight: '20px',
                    display: 'block',
                    color: 'rgba(0,0,0,0.5)'
                }}
                label="Charge"
                selectClassName="form-select form-select-sm col-3"
            />
            {/* Taux Croissance Input */}
            <ConditionalRenderingWrapper
                isShouldBeRendered={!field?.estRetribution}
            >
                <CustomLiteDynamicInput
                    name="tauxCroissance"
                    placeholder="Taux..."
                    containerClassName="col-6"
                    labelStyle={{
                        fontSize: '13px',
                        paddingBottom: '8px',
                        lineHeight: '20px',
                        minHeight: '20px',
                        display: 'block',
                        color: 'rgba(0,0,0,0.5)'
                    }}
                    label="Taux de Croissance"
                    disabled={!!field?.estRetribution}
                    value={field.tauxCroissance}
                    onChange={handleChange}
                    inputProps={{
                        index,
                        setFormFields,
                        formFields,
                        view: 'ChargeVariable'
                    }}
                    inputClassName="form-control form-control-sm"
                />
            </ConditionalRenderingWrapper>
        </div>
    );
}
