import { useCallback, useState } from 'react';
import { Add, TIME, TODAY } from '@napp-inc/jnapp-util';
import { URL_CONST, useGetReportDetail } from '../../../../util';
import { ReduxReportTemplate } from '../../template';
import { CustomDropdown, CustomRow } from '../../../../components';

export function RapportGainPerteChangeForm() {
    const [headers, setHeaders] = useState({ operations: {}, systemes: {} });
    const CHANGE = `Change`;
    const GAINS_CHANGE = `GainChange`;
    const PERTES_CHANGE = `PerteChange`;
    const { formState, formDispatcher } = useGetReportDetail({
        defaultFields: {
            selectedStockSuperDealer: {},
            selectedDevise: { code: 'USD', designation: 'USD' },
            listHeaders: []
        }
    });
    const { fields } = formState;
    const setValueOfHeader = useCallback((data) => {
        setHeaders(data);
    }, []);
    const mergeData = ({ data = [], levels = {} }) => {
        const dataToRender = [];
        const operationsValues = Object.values(levels?.operations || {})?.map(
            (opItem) => ({
                ...opItem,
                montant: 0
            })
        );
        const systemesValues = Object.values(levels?.systemes || {})?.map(
            (sysItem) => ({
                ...sysItem,
                montant: 0
            })
        );

        data.forEach((element, index, array) => {
            // console.log('Levels', element);
            // console.log('Levels', levels, operationsValues, systemesValues)
            const findSibbling = dataToRender.find(
                (item) => item?.organisation === element?.organisation
            );
            if (!findSibbling) {
                const newElement = {};
                const getSibblingArray = array.filter(
                    (item) => item?.organisation === element?.organisation
                );
                getSibblingArray.forEach(
                    ({ organisation, devise }, indexI, SibblingArray) => {
                        const mappedArray = SibblingArray.map((mappedItem) => ({
                            ...mappedItem.changes
                        }));
                        newElement.organisation =
                            newElement.organisation || organisation;
                        newElement.devise = newElement.devise || devise;
                        newElement.gain = SibblingArray.reduce(
                            (a, b) => Add(a, b.gain || 0),
                            0
                        );
                        newElement.perte = SibblingArray.reduce(
                            (a, b) => Add(a, b.perte || 0),
                            0
                        );
                        newElement.profit = SibblingArray.reduce(
                            (a, b) => Add(a, b.profit || 0),
                            0
                        );
                        if (!newElement.changes) {
                            const dataObjet = { operations: {}, systemes: {} };
                            operationsValues.forEach(({ value }) => {
                                const findAchatExistingArray =
                                    mappedArray.filter(
                                        (mapData) =>
                                            mapData?.operations?.[value]
                                    );
                                if (findAchatExistingArray.length) {
                                    const reducedValue =
                                        findAchatExistingArray.reduce(
                                            (a, b) =>
                                                Add(
                                                    a,
                                                    b?.operations?.[value]
                                                        ?.montant || 0
                                                ),
                                            0
                                        );
                                    dataObjet.operations[value] = {
                                        value,
                                        montant: reducedValue
                                    };
                                }
                            });
                            systemesValues.forEach(({ value }) => {
                                const findAchatExistingArray =
                                    mappedArray.filter(
                                        (mapData) => mapData?.systemes?.[value]
                                    );
                                if (findAchatExistingArray.length) {
                                    const reducedValue =
                                        findAchatExistingArray.reduce(
                                            (a, b) =>
                                                Add(
                                                    a,
                                                    b?.systemes?.[value]
                                                        ?.montant || 0
                                                ),
                                            0
                                        );
                                    dataObjet.systemes[value] = {
                                        value,
                                        montant: reducedValue
                                    };
                                }
                            });

                            newElement.changes = dataObjet;
                        }
                    }
                );
                dataToRender.push(newElement);
            }
        });
        return dataToRender;
    };
    const listFormater = useCallback(
        (donnees) => {
            const dataTorender = [];
            (donnees?.[0]?.listReturn || []).map((item) => {
                const { operation, detail } = item;
                const { devise, valeurTypeProprietaire } = detail;
                let organisation = '';
                const operationKeys = Object.keys(operation || {});
                operationKeys.forEach((key) => {
                    let gain = 0;
                    let perte = 0;
                    organisation = `${valeurTypeProprietaire.designation}`;
                    if (key.includes(GAINS_CHANGE)) {
                        gain += operation[key].montant || 0;
                    }
                    if (key.includes(PERTES_CHANGE)) {
                        // organisation = `${valeurTypeProprietaire.designation}`;
                        perte += operation[key].montant || 0;
                    }

                    dataTorender.push({
                        organisation,
                        devise,
                        gain,
                        perte,
                        profit: Add(gain, perte),
                        changes: {
                            operations: operation?.[key]?.operations,
                            systemes: operation?.[key]?.systemes
                        }
                    });
                });

                return {};
            });
            setValueOfHeader(donnees?.[0]?.changes);

            return mergeData({
                data: dataTorender
                    .filter(
                        (item) =>
                            !!item.organisation &&
                            item.devise === fields.selectedDevise.code
                    )
                    .filter((item) => item.gain || item.perte),
                levels: donnees?.[0]?.changes
            });
            // return dataTorender.filter(
            //     (item) =>
            //         !!item.organisation &&
            //         item.devise === fields.selectedDevise.code
            // );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            GAINS_CHANGE,
            PERTES_CHANGE,
            fields.selectedDevise.code
            // headers?.operations,
            // headers?.systemes,
            // setValueOfHeader
        ]
    );

    const cutomTableHeader = () => (
        <>
            <tr className="border text-center text-justify">
                <th className="border" rowSpan={2}>
                    #
                </th>
                <th className="border" rowSpan={2}>
                    Organisation
                </th>
                <th
                    className="border"
                    colSpan={Object?.keys(headers?.systemes || {})?.length}
                >
                    Systemes
                </th>
                <th
                    className="border"
                    colSpan={Object?.keys(headers?.operations || {})?.length}
                >
                    Operations
                </th>
                <th className="border" rowSpan={2}>
                    Gain
                </th>
                <th className="border" rowSpan={2}>
                    Perte
                </th>
                <th className="border" rowSpan={2}>
                    Profit
                </th>
            </tr>
            <tr className="border text-center">
                {Object.keys(headers?.systemes ? headers?.systemes : {}).map(
                    (item) => (
                        <th key={item} className="border">
                            {item}
                        </th>
                    )
                )}
                {Object.keys(
                    headers?.operations ? headers?.operations : {}
                ).map((item) => (
                    <th key={item} className="border">
                        {item}
                    </th>
                ))}
            </tr>
        </>
    );

    const mapper = useCallback(() => {
        const operations = Object?.keys(headers?.operations || {})?.length
            ? Object?.keys(headers?.operations)?.map((item) => ({
                  displayName: `${item}`,
                  field: `changes.operations.${item}.montant`,
                  tdExtractor: (element) =>
                      element?.changes?.operations?.[`${item}`]?.montant || 0
              }))
            : [
                  {
                      displayName: 'Taux Ope',
                      field: 'changes.operations.montant'
                  }
              ];
        const systemes = Object?.keys(headers?.systemes || {})?.length
            ? Object?.keys(headers?.systemes)?.map((item) => ({
                  displayName: `${item}`,
                  field: `changes.systemes.${item}.montant`,
                  tdExtractor: (element) =>
                      element?.changes?.systemes?.[`${item}`]?.montant || 0
              }))
            : [
                  {
                      displayName: 'Taux Op',
                      field: 'changes.systemes.montant'
                  }
              ];
        return [
            {
                displayName: 'Organisation',
                field: 'organisation'
            },
            ...systemes,
            ...operations,
            {
                displayName: 'Gain',
                field: 'gain',
                isTotal: true
            },
            {
                displayName: 'Perte',
                field: 'perte',
                isTotal: true
            },
            {
                displayName: 'Profit',
                field: 'profit',
                isTotal: true
            }
        ];
    }, [headers?.operations, headers?.systemes]);

    return (
        <>
            {/* <StateShowerComponent state={formState?.fields} /> */}
            <ReduxReportTemplate
                cutomTableHeader={cutomTableHeader}
                listTitle={`${
                    fields.selectedDevise.code === 'USD' ? 'CDF contre USD' : ''
                }${
                    fields.selectedDevise.code === 'CDF' ? 'USD contre CDF' : ''
                }`}
                dynamicNodeName={`evolutionsGainPerteChange-${TIME.utc000000(
                    TODAY.at000000()
                )}-${TIME.utc235959(TODAY.at235959())}`}
                formState={formState}
                formDispatcher={formDispatcher}
                functionName={URL_CONST.GET_LIST_COMPTE_USER}
                listFormater={listFormater}
                formater={(data) => [data]}
                mapper={mapper()}
                isAddTotalTr
                payload={{
                    dateDebut: TIME.utc000000(TODAY.at000000()),
                    dateFin: TIME.utc235959(TODAY.at235959()),
                    typeEntite: 'Journalier',
                    type: CHANGE,
                    estObjet: true,
                    estOrganisation: true,
                    estAllOrganisation: true,
                    estNotDate: true
                }}
                defaultPayloadMapperValues={{
                    typeEntite: 'Journalier',
                    type: CHANGE,
                    estObjet: true,
                    typesEntite: ['VenteSuperDealer', 'VenteRevendeur'],
                    estOrganisation: true,
                    estAllOrganisation: true
                }}
                payloadMapper={[
                    {
                        name: 'dateDebut',
                        property: 'dateDebut',
                        isRequired: true,
                        displayName: 'Date début'
                    },
                    {
                        name: 'dateFin',
                        property: 'dateFin',
                        isRequired: true,
                        displayName: 'Date fin'
                    }
                ]}
            >
                <CustomRow>
                    <CustomDropdown
                        options={[
                            { code: 'USD', designation: 'USD' },
                            { code: 'CDF', designation: 'CDF' }
                        ]}
                        label="Valeur : "
                        labelClassName="col-12"
                        divClassName="col-12"
                        formDispatcher={formDispatcher}
                        name="selectedDevise"
                        id="selectedDevisetypeExpressionSelector"
                        formState={formState}
                        idProperty="code"
                    />
                </CustomRow>
            </ReduxReportTemplate>
        </>
    );
}
