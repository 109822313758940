import { ListGenericForm } from '../../../components';
import { URL_CONST } from '../../../util';
import { REDUX_NODE_NAME } from '../../../redux';

export function ListCompteImputationForm() {
    return (
        <ListGenericForm
            reduxPropertyName="comptesImputation"
            functionName={URL_CONST.GET_LIST_COMPTE_IMPUTATION}
            nodeName={REDUX_NODE_NAME.COMPTE_IMPUTATION}
            mapper={[
                {
                    field: 'valeurTypeProprietaire.designation',
                    displayName: 'Désignation'
                },
                {
                    field: 'typeEntite',
                    displayName: 'Type'
                },
                {
                    field: 'dateCreation',
                    displayName: 'Création'
                }
            ]}
        />
    );
}
